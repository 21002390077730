import React from 'react';
import {Card, Col, Flex, Row, Typography} from 'antd';
import {startCase} from 'lodash';
import {Charter} from '../../model/charter';
import RouteTitle from '../../components/RouteTitle';
import dayjs from '../../dayjs';
import {DATE_STRING} from '../../model/schedule';
import {secsSinceMidnightToDayjs} from '../../model/timeFilter';
import {ReactComponent as BusableLogo} from '../../assets/images/busable-logo-dark.svg';
import './CharterQuoteTemplate.css';
import { useAppContext } from '../../libs/contextLib';
import config from '../../config';
const {Paragraph} = Typography;

const CharterQuoteTemplate = ({charter, allSchedules, operator}) => {
    if (!charter?.duties?.length) {
        return null;
    }

    const firstDuty = charter.duties[0];
    const startDate = firstDuty?.getRunningDates(allSchedules, dayjs())?.[0];
    const startTime = firstDuty?.getStartTime();

    const logo = `https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${operator?.operatorId}/logo.png?${Date.now()}`

    return (
        <div className="print-pdf">
            <div className="quote-header">
                <div className="quote-header-left">
                    <div className="quote-title-section">
                        <h1 style={{fontSize: 28, fontWeight: 600, color: '#333', marginBottom: 10}}>Quote</h1>
                        <h2 className="pdf-title">{startCase(charter.name)}</h2>
                        <div className="quote-meta">
                            <div className="quote-number">
                                <label>Quote Number</label>
                                <strong>{startCase(charter.ref)}</strong>
                            </div>
                            <div className="quote-date">
                                <label>Date</label>
                                <strong>{charter.quoteDate?.format ? charter.quoteDate.format(DATE_STRING) : 'TBC'}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="company-details">
                    <div className="logo-container">
                        <img src={logo} 
                        style={{height: 40}}
                        alt={operator.operatorName}
                        />
                    </div>
                    <p>{operator.operatorName}</p>
                    <p>{operator.operatorPostalAddress}</p>
                    <p>{operator.operatorPhone}</p>
                    <p>{operator.operatorEmail}</p>
                </div>
            </div>

            <div className="quote-content">
                <Row gutter={[20, 20]}>
                    <Col xs={24}>
                        <Card title={''} className="card-main card-info">
                            <Row gutter={[30, 30]}>
                                <Col xs={12}>
                                    <label>To</label>
                                    <strong>{startCase(charter.address1)}</strong>
                                    <strong>{startCase(charter.address2)}</strong>
                                    <strong>{charter.addressContact}</strong>
                                </Col>
                                <Col xs={12}>
                                    <label>Quote Description</label>
                                    <small>{(charter.quoteDescription || '').split('\n').map((p, index) =>
                                        <Paragraph key={`desc-${index}`}>{p}</Paragraph>)}</small>
                                </Col>
                                <Col xs={6}>
                                    <label>Payment Due</label>
                                    <strong>
                                        {charter.paymentDue?.format ? charter.paymentDue.format(DATE_STRING) : 'TBC'}
                                    </strong>
                                </Col>
                                <Col xs={6}>
                                    <label>Start Date & Time</label>
                                    <strong>
                                        {startDate ? `${startDate.format(DATE_STRING)} - ${startTime ? secsSinceMidnightToDayjs(startTime).format('HH:mm') : ''}` : 'TBC'}
                                    </strong>
                                </Col>
                                <Col xs={6}>
                                    <label># Passengers</label>
                                    <strong>{charter.passengerCount}</strong>
                                </Col>
                                <Col xs={6}>
                                    <label>Invoice No</label>
                                    <strong>{startCase(charter.invoiceNumber)}</strong>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24}>
                        <Card bordered={false} className="card-main">
                            <Row className="card-row-header align-items-center">
                                <Col xs={10} className="d-flex align-items-center">
                                    <h3>Charter Quote</h3>
                                </Col>
                                <Col xs={4}>
                                    <span>Unit price</span>
                                </Col>
                                <Col xs={3}>
                                    <span>Qty</span>
                                </Col>
                                <Col xs={3}>
                                    <span>Disc(%)</span>
                                </Col>
                                <Col xs={4} className="text-right">
                                    <span>Amount</span>
                                </Col>
                            </Row>
                            {charter.quoteLineItems?.map((line, index) => (
                                <Row key={`qline-${index}`} className="card-row-content border-full align-items-center">
                                    <Col xs={10}>
                                        <strong>{startCase(line.name)}</strong>
                                        <span>{line.description}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span>${String(line.unitPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                    </Col>
                                    <Col xs={3}>
                                        <span>{line.quantity}</span>
                                    </Col>
                                    <Col xs={3}>
                                        <span>{line.discount}</span>
                                    </Col>
                                    <Col xs={4} className="text-right">
                                        ${(line.unitPrice * line.quantity * (1 - (line.discount || 0) / 100)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </Col>
                                </Row>
                            ))}
                            <Row className="card-row-content border-full align-items-center">
                                <Col xs={18}>
                                    <strong>Subtotal</strong>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <strong style={{fontSize: '15px'}}>${charter.quoteLineItems.reduce((acc, line) => acc + (line.unitPrice * line.quantity * (1 - (line.discount || 0) / 100)), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                                </Col>
                            </Row>
                            <Row className="card-row-content border-full align-items-center no-border">
                                <Col xs={18}>
                                    <strong>Plus GST</strong>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <strong style={{fontSize: '15px'}}>${(charter.quoteLineItems.reduce((acc, line) => acc + (line.unitPrice * line.quantity * (1 - (line.discount || 0) / 100)), 0) * 0.1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                                </Col>
                            </Row>
                            <Row className="card-row-content border-full align-items-center">
                                <Col xs={18}>
                                    <strong style={{fontSize: '18px'}}>Total</strong>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <strong style={{fontSize: '18px'}}>${(charter.quoteLineItems.reduce((acc, line) => acc + (line.unitPrice * line.quantity * (1 - (line.discount || 0) / 100)), 0) * 1.1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default React.memo(CharterQuoteTemplate); 