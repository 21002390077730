import {useEffect, useState} from 'react';

const useAll = ({modelService}) => {
    const [all, setAll] = useState(null);
    const [allAsArray, setAllAsArray] = useState(null);
    const [tempAll, setTempAll] = useState(null);

    useEffect(() => {
        const listener = modelService.addListener({
            setterFn: setTempAll, loaded: all => {
                setTempAll(all);
            }
        });

        return () => {
            if (listener)
                modelService.removeListener(listener);
        };
    }, []);

    useEffect(() => {
        if (tempAll) {
            setAll(tempAll);
            setAllAsArray(Object.values(tempAll));
        }
    }, [tempAll]);

    return {
        all, allAsArray, setAll
    };
};

export default useAll;

