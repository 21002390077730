import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, InputNumber, Switch, TimePicker } from "antd";
import { dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs } from "../../model/timeFilter";
import { WorkItem } from "../../model/shiftBat";
import { useRoster } from "./RosterContext";
import { Check } from "../../components/Icons";

export const Actuals = ({ close }) => {
  const { selectedWorkItems, handleSetActuals } = useRoster();

  const workItem = useMemo(() => {
    return WorkItem.from(selectedWorkItems?.[0] ?? {});
  }, [selectedWorkItems]);

  const [actualStart, setActualStart] = useState(workItem.actualStart);
  const [actualEnd, setActualEnd] = useState(workItem.actualEnd);
  const [actualPayHours, setActualPayHours] = useState(workItem.actualPayHours);
  const [isCompleted, setIsCompleted] = useState(!!workItem.isCompleted);

  const save = () => {
    handleSetActuals({
      ...workItem,
      actualStart,
      actualEnd,
      actualPayHours,
      isCompleted,
    });
    close();
  };

  useEffect(() => {
    setActualStart(workItem.actualStart);
    setActualEnd(workItem.actualEnd);
    setActualPayHours(workItem.actualPayHours);
    setIsCompleted(workItem.isCompleted);
  }, [workItem]);

  if (!selectedWorkItems?.length) {
    return null;
  }

  return (
    <div className="SecondaryMenuPopup">
      <div className="d-flex flex-column mb-2">
        <label className="info-label">Scheduled hours</label>
        <strong style={{ fontSize: "16px", fontWeight: 500 }}>{(workItem?.scheduledHours || 0).toFixed(2)}</strong>
      </div>
      <div className="d-flex flex-column mb-2">
        <label className="info-label">Actual start</label>
        <TimePicker
          value={secsSinceMidnightToDayjs(actualStart || 0)}
          needConfirm={false}
          showNow={false}
          format={"HH:mm"}
          onChange={(time) => {
            setActualStart(dayjsToSecsSinceMidnight(time));
          }}
          className="w-100"
          disabled={isCompleted}
        />
      </div>
      <div className="d-flex flex-column mb-2">
        <label className="info-label">Actual end</label>
        <TimePicker
          value={secsSinceMidnightToDayjs(actualEnd || 0)}
          needConfirm={false}
          showNow={false}
          format={"HH:mm"}
          onChange={(time) => {
            setActualEnd(dayjsToSecsSinceMidnight(time));
          }}
          className="w-100"
          disabled={isCompleted}
        />
      </div>
      <div className="d-flex flex-column mb-2">
        <label className="info-label">Actual pay hours</label>
        <InputNumber
          value={actualPayHours?.toFixed(2) || 0}
          onChange={(value) => {
            setActualPayHours(value);
          }}
          className="w-100"
          disabled={isCompleted}
        />
      </div>
      <div className="d-flex flex-column mb-3">
        <Flex className="w-100 align-items-center justify-content-between mt-2">
          <label className="info-label">
            <i className={`IconCheck ${isCompleted && "active"}`}>
              <Check width={12} height={8} />
            </i>{" "}
            Mark as Completed
          </label>
          <Switch
            size="small"
            className="ColorSuccess"
            onChange={(val) => {
              setIsCompleted(val);
            }}
            checked={isCompleted}
          />
        </Flex>
      </div>
      <div className="d-flex justify-content-end">
        <Button type="primary" className="primary-btn btn-small mt-0" onClick={save}>
          Update
        </Button>
      </div>
    </div>
  );
};
