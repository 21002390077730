import React, { useCallback, useMemo, useState } from "react";
import { Input, Radio, Tooltip } from "antd";
import { memoize, values } from "lodash";
import { toHrsMinsSecs } from "../../libs/formatLib";
import LoadMessage from "../../components/LoadMessage";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { EmployeeAvatar } from "./AllocationModal";
import { useRoster } from "./RosterContext";

const UNASSIGNED_EMPLOYEE = {
  employeeID: null,
  firstName: "Unassigned",
  lastName: "",
  validations: [],
};

export const employeeName = memoize((employee) => {
  return employee ? `${employee.firstName} ${employee.lastName}` : "?";
});

const SelectEmployeeView = ({ selectedEmployee, setSelectedEmployee }) => {
  const { selectedScenario: scenario, employeeData, selectedWorkItems } = useRoster();
  const [searchTerm, setSearchTerm] = useState("");

  const getSeverity = (employee) => {
    if (employee.validations.find((v) => ["unavailable", "overtime"].includes(v.type))) {
      return "error";
    }

    return "warning";
  };

  const [availableStaff, unavailableStaff] = useMemo(() => {
    if (!scenario || !employeeData) return [[], []];

    const _unavailableStaff = [];
    const _availableStaff = [];
    values(employeeData.allEmployees)
      .sort((a, b) => a.firstName.localeCompare(b.firstName))
      .forEach((employee) => {
        const emp = {
          ...employee,
          employeeName: employeeName(employee),
          hours: toHrsMinsSecs(scenario.getTotalTime("employee", employee.employeeID)),
          validations: scenario.getEmployeeValidations(employeeData, employee, selectedWorkItems),
        };
        const _isAvailable = emp.validations.every((v) => v.type !== "unavailable" && v.type !== "onLeave");
        if (!_isAvailable) {
          _unavailableStaff.push(emp);
        } else {
          _availableStaff.push(emp);
        }
      });
    return [_availableStaff, _unavailableStaff];
  }, [scenario, employeeData, selectedWorkItems]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterStaff = useCallback(
    (staffList, includeUnassigned = false) => {
      if (!searchTerm) return includeUnassigned ? [UNASSIGNED_EMPLOYEE, ...staffList] : staffList;
      const search = searchTerm.toLowerCase();
      return [
        ...(includeUnassigned ? [UNASSIGNED_EMPLOYEE] : []),
        ...staffList.filter((employee) => {
          return (
            employee.firstName?.toLowerCase().includes(search) ||
            employee.lastName?.toLowerCase().includes(search) ||
            (search === "driver" && employee.driverId)
          );
        }),
      ];
    },
    [searchTerm]
  );

  const filteredAvailableStaff = useMemo(() => filterStaff(availableStaff, true), [filterStaff, availableStaff]);
  const filteredUnavailableStaff = useMemo(() => filterStaff(unavailableStaff), [filterStaff, unavailableStaff]);

  const allStaff = useMemo(() => {
    return [...filteredAvailableStaff, ...filteredUnavailableStaff];
  }, [filteredAvailableStaff, filteredUnavailableStaff]);

  return (
    <>
      {employeeData ? (
        <>
          <div className={`item-allocation hideStaffUnavailable`}>
            <Input type="text" placeholder="Search staff" value={searchTerm} onChange={handleSearch} />
            <div className="item-list-scroll">
              <div className="item-list" style={{ height: "200px", overflowY: "scroll" }}>
                {allStaff?.length ? (
                  allStaff.map((employee) => (
                    <div
                      key={`available-staff-${employee.employeeID}`}
                      className="item-single"
                      onClick={() => {
                        if (employee.unavailableStatus !== "Unavailable") {
                          setSelectedEmployee(employee);
                        }
                      }}
                    >
                      <Radio
                        name="staff"
                        checked={selectedEmployee?.employeeID === employee.employeeID}
                        disabled={getSeverity(employee) === "error"}
                      >
                        <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                          <EmployeeAvatar {...employee} />
                          <div className="d-flex flex-column" style={{ gap: "2px" }}>
                            <span className="staff-name">{employeeName(employee)}</span>
                            {employee.hours ? <span className="staff-time">{employee.hours}</span> : ""}
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{ gap: "3px" }}>
                          {employee.validations?.length ? (
                            <Tooltip
                              trigger="hover"
                              placement="right"
                              color="#fff"
                              mouseEnterDelay={0.5}
                              overlayClassName="error-tooltip"
                              title={
                                <div className="info-content">
                                  <div className="info-content-issues">
                                    {employee.validations.map((v, i) => {
                                      return (
                                        <div key={i}>
                                          {v.type === "unavailable" && <span>{employeeName(employee)} is unavailable.</span>}
                                          {v.type === "onLeave" && <span>{employeeName(employee)} is on leave.</span>}
                                          {v.type === "overtime" && <span>{employeeName(employee)} has worked overtime.</span>}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              }
                            >
                              <Warning width={16} height={16} />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Radio>
                    </div>
                  ))
                ) : (
                  <div className="no-results">No employees found</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadMessage title="Loading staff..." />
      )}
    </>
  );
};

export default React.memo(SelectEmployeeView);
