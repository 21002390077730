import React, { useEffect, useState } from "react";
import LoaderButton from "../../components/LoaderButton";
import { Customer } from "../../model/customer";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { customerModelData } from "../../services/ModelService";
import { Trash } from "../../components/Icons";

const CustomerList = ({ customers, mergeMode }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (customers && setData) {
            setData(
                customers.map((customer) => {
                    return new Customer({
                        ...customer,
                        key: customer.customerId,
                        // warnings: customer.validateCustomer(customers),
                    });
                })
            );
        }
    }, [customers, setData]);

    const handleDelete = async (customer) => {
      await customerModelData.delete(customer.customerId, true)
    }

    return (
        <ListGroup>
            <div className="row list-group-header">
                <div className="col-lg-4">Customers</div>
                <div className="col-lg-4">Email</div>
                <div className="col-lg-3">Phone Number</div>
                <div className="col-lg-1 text-center">Actions</div>
            </div>
            {data.map((customer) => {
                return (
                    <Link key={customer.customerId} to={`/customers/${customer.customerId}`} style={{ cursor: "pointer" }}>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col-lg-4">{customer.name}</div>
                                <div className="col-lg-4">{customer.email}</div>
                                <div className="col-lg-3">{customer.phone}</div>
                                <div className="col-lg-1 d-flex justify-content-around">
                                    <LoaderButton
                                        className={`btn-icon-control btn-delete`}
                                        size="sm"
                                        // isLoading={employee.external.employeeID === isDeleting}
                                        onClick={async () => await handleDelete(customer)}
                                    >
                                        <Trash />
                                    </LoaderButton>
                                </div>
                            </div>
                        </ListGroup.Item>
                    </Link>
                );
            })}
        </ListGroup>
    );
};

export default React.memo(CustomerList);
