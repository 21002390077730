import {ulid} from "ulid";
import {BaseStop, BusRoute, Stop} from "./busRoute";
import {
    cloneDeep,
    differenceWith,
    findLastIndex,
    flatten,
    intersection, intersectionWith,
    last,
    uniq,
    uniqBy,
    uniqWith,
    values
} from "lodash";
import {getCachedAccurateDurations, getCachedPath} from "../libs/pathLib";
import {TimeFilter, TimeFilterType} from "./timeFilter";
import {FEATURE} from "./features";
import {getSecondsSinceMidnightAsDayjs, toMins} from "../libs/formatLib";
import util from "util";
import {find, keyBy} from "lodash/collection";
import log from "loglevel";
import {createUsage, getPublicRoutes} from "../services/routeService";
import dayjs from "../dayjs";
import {
    getDepartureTimeAsDayjs,
    getDepartureTimeAsSecondsSinceMidnight, getDistanceInMetres,
    getSecondsTilNextDeparture, publicStopFilter
} from "../libs/routes-lib";
import {CONNECTIONS} from "./prefs";
import {stopDistCache} from "./stopDistCache";

const logger = log.getLogger('JP');


export const getCompositeRouteId = (tripPlan, leg) => {
    return tripPlan.departureTime + '|' + leg.route.startStopIdx + '|' + leg.route.endStopIdx + '|' + leg.route.routeId + (leg.merged ? '|' + leg.merged.join('|') : '')
}

const getNextDepartureDate = (tripPlan, schedules, timeFilter) => {
    const routeLegs = tripPlan.legs.filter(l => l.type === 'route' && l.route?.services)
    // logger.debug('next departure data. Leg count: %d', routeLegs.length, routeLegs)
    const firstLegOfService = tripPlan.legs[tripPlan.legs.findIndex(l => l?.route?.services?.length)];
    for (let i = 0; i < routeLegs.length; i++) {
        const routeLeg = routeLegs[i]
        logger.debug(routeLeg)
        routeLeg.nextDepartureDates = []
        logger.debug(routeLeg.route.services)
        for (let j = 0; j < routeLeg.route.services.length; j++) {
            routeLeg.route.services[j].scheduleIds.forEach(sId => {
                const scheduleForTrip = schedules[sId]
                if (scheduleForTrip) {
                    const nextDepartureDates = scheduleForTrip.getNext7DepartureDates(timeFilter.startTime)
                    // logger.debug(routeLeg.route.routeNumber, ' -> ', nextDepartureDates)
                    const tripStartTime = getDepartureTimeAsSecondsSinceMidnight(routeLeg.route.services[j], routeLeg.route.services[j].getStopTime(firstLegOfService.route.getStartStop()));
                    if (nextDepartureDates?.[0]?.clone().startOf("d").add(tripStartTime, "s").isBefore(dayjs())) {
                        nextDepartureDates.splice(0, 1);
                    }
                    routeLeg.nextDepartureDates = routeLeg.nextDepartureDates.concat(nextDepartureDates)
                } else {
                    logger.debug('No schedule for sId: ', sId)
                }
            })
        }
        if(timeFilter.anyDate) {
            routeLeg.nextDepartureDates = routeLeg.nextDepartureDates.filter(d => {
                const dayOfWeek = d.isoWeekday();
                return [1,2,3,4,5].includes(dayOfWeek)
            })
        }
        routeLeg.nextDepartureDates.sort((d1, d2) => d1.unix() - d2.unix())
    }

    let nextDepartureDates;
    if (routeLegs.length === 1) {
        nextDepartureDates = routeLegs[0].nextDepartureDates
    } else if (routeLegs.length === 2) {
        nextDepartureDates = intersectionWith(routeLegs[0]?.nextDepartureDates, routeLegs[1]?.nextDepartureDates, (d1, d2) => {
            return d1.isSame(d2, 'date')
        })
    } else if (routeLegs.length === 3) {
        nextDepartureDates = intersectionWith(routeLegs[0]?.nextDepartureDates, routeLegs[1]?.nextDepartureDates, routeLegs[2]?.nextDepartureDates, (d1, d2) => {
            return d1.isSame(d2, 'date')
        })
    }
    logger.debug('next departure dates', nextDepartureDates);
    return nextDepartureDates
}


const getNextTrip = (timeFilter, route, nextDepartureDate, schedules) => {
    if (timeFilter.type === TimeFilterType.ARRIVING) {
        return route.getNextTripBeforeDate(nextDepartureDate, timeFilter.anyDate, schedules);
    } else {
        return route.getNextTripAfterDate(nextDepartureDate, timeFilter.anyDate, schedules);
    }
}

const getRoutes = (stopsNear, possibleDirections = [], possibleRouteTypes = [], timeFilter, schedules) => {
    logger.debug(stopsNear, possibleDirections, possibleRouteTypes, timeFilter);
    return flatten(stopsNear.map(s => {

        return s.routes ? s.routes
            .filter(r => !possibleDirections.length || possibleDirections.indexOf(r.direction) > -1)
            .filter(r => !possibleRouteTypes.length || possibleRouteTypes.indexOf(r.routeType || 'Regular') > -1)
            .map(r => cloneDeep(r))
            .filter(route => {
                if (!timeFilter) {
                    delete route.services
                    return s.routes.findIndex(r => r.routeId === route.routeId) > -1
                }
                route.services = s.getPassingTimes(r => r.routeId === route.routeId, {
                    date: timeFilter.startTime,
                    school: timeFilter.anyDate,
                    time: timeFilter.getStartTime(),
                    window: timeFilter.type === TimeFilterType.ARRIVING ? 48 * 3600 : timeFilter.windowInSecs
                }, schedules)
                return route.services.length
            }) : []
    }));
};

const getIntersectingRoutes = (stopsNearFrom, stopsNearTo, possibleDirections, possibleRouteTypes, timeFilter, schedules) => {
    const log = true;
    let routesFrom = uniqBy(getRoutes(stopsNearFrom, possibleDirections, possibleRouteTypes, [TimeFilterType.LEAVING, TimeFilterType.NOW, TimeFilterType.ALL].includes(timeFilter.type) ? timeFilter : null, schedules), 'routeId');
    log && logger.debug("routesFrom: ", routesFrom);
    let routesTo = uniqBy(getRoutes(stopsNearTo, possibleDirections, possibleRouteTypes, [TimeFilterType.ARRIVING, TimeFilterType.ALL].includes(timeFilter.type) ? timeFilter : null, schedules), 'routeId');
    log && logger.debug("routesTo: ", routesTo);
    let directRouteIds = uniq(intersection(routesFrom.map(r => r.routeId), routesTo.map(r => r.routeId)));
    log && logger.debug("directRouteIds: ", directRouteIds);

    return {routesFrom, routesTo, directRouteIds};
}

const createWp = (route, stopsNearPoint, startLat, startLon, fromIdx = 0, end = false) => {
    const log = route.routeNumber === '913'
    log && logger.debug('all stops near', stopsNearPoint, route.stops);
    const stopsNear = stopsNearPoint.filter(s => route.stops.findIndex(routeStop => routeStop.stopId === s.stopId) > -1).map(nearStop => {
        const sequence = route.getPublicStopIndex(nearStop, 0, end) + 1// .stops.findIndex(routeStop => routeStop.stopId === nearStop.stopId) + 1
        return {...nearStop, sequence}
    }).sort((s1, s2) => s1.dist - s2.dist)
    log && logger.debug('filtered stops near', stopsNear);
    return {
        ...stopsNear[fromIdx],
        nearIdx: fromIdx,
        lat: startLat,
        lon: startLon
    };
}


// const createWpsOnRoute = (route, tripId, stopsNearPoint, coord, timeFilter, fromIdx = 0, end = false) => {
//     const log = route.routeNumber === 'AM09'
//     // return stopsNearPoint.filter(s => route.stops.findIndex(routeStop => routeStop.stopId === s.stopId) > -1).map(nearStop => {
//     return stopsNearPoint.filter(s => route.stopsAtStopInWindow(s, tripId, timeFilter, end)).map(nearStop => {
//         const sequence = route.getPublicStopIndex(nearStop, 0, end) + 1// .stops.findIndex(routeStop => routeStop.stopId === nearStop.stopId) + 1
//         const stop = {...nearStop, sequence, nearIdx: fromIdx, ...coord.center}
//         log && logger.debug(stop)
//         return stop;
//     })
// }

// TODO: issue with school to school when School ALL selected - AM works.
// TODO: issue with transfers
const createStopTimesOnRoute = (route, tripId, stopsNearPoint, coord, timeFilter, fromIdx = 0, end = false) => {
    const log = route.routeNumber === 'AM09'
    const trip = find(route.services, ['tripId', tripId], 0)
    if (!trip) {
        console.log('No trip')
        return []
    }
    // return stopsNearPoint.filter(s => route.stops.findIndex(routeStop => routeStop.stopId === s.stopId) > -1).map(nearStop => {
    return flatten(stopsNearPoint.map(s => route.stopToStopTimesInWindow(s, tripId, timeFilter, end))).map(nearStopTime => {
        const sequence = trip.stopTimes.findIndex(st => st.stopTimeId === nearStopTime.stopTimeId) + 1// .stops.findIndex(routeStop => routeStop.stopId === nearStop.stopId) + 1
        const stop = {...nearStopTime, sequence, nearIdx: fromIdx, ...coord.center}
        log && logger.debug(stop)
        return stop;
    });
}

/**
 * Finds the best start and end wp based on the start lat/lon and end lat/lon. This handles the edge case where the
 * stop to board/alight the bus is not the closest stop on the route to the lat/lon.
 * @param route
 * @param stopsNearFrom
 * @param stopsNearTo
 * @param startLat
 * @param startLon
 * @param endLat
 * @param endLon
 * @returns {{startWp: (*&{nearIdx: number, lon, lat}), endWp: (*&{nearIdx: number, lon, lat})}}
 */
    // eslint-disable-next-line
const findBestStartEndWp = (route, stopsNearFrom, stopsNearTo, startLat, startLon, endLat, endLon) => {
        const log = route.routeNumber === '913'

        let startWp, endWp, nearIdx = 0;
        do {
            startWp = createWp(route, stopsNearFrom, startLat, startLon, nearIdx)
            log && logger.debug('startWp', startWp);
            endWp = createWp(route, stopsNearTo, endLat, endLon, 0, true);
            log && logger.debug('endWp', endWp);
            nearIdx = nearIdx > 0 ? nearIdx + 1 : endWp.nearIdx + 1;
        } while (startWp.sequence >= endWp.sequence && nearIdx < stopsNearFrom.length);

        if (startWp.sequence >= endWp.sequence) {
            startWp = null;
            endWp = null;
            nearIdx = 0;
            do {
                startWp = createWp(route, stopsNearFrom, startLat, startLon)
                endWp = createWp(route, stopsNearTo, endLat, endLon, nearIdx);
                nearIdx = nearIdx > 0 ? nearIdx + 1 : startWp.nearIdx + 1;
            } while (startWp.sequence >= endWp.sequence && nearIdx < stopsNearTo.length);
        }

        return {startWp, endWp}
    }

const groupBySequence = (wps, prefs) => {
    const startLinks = [];
    let prevSequence = 0;
    let shortestDistInLink = -1;
    wps.forEach((wp, idx) => {
        if (idx === 0 || wp.sequence > (prevSequence + 1)) {
            // if (idx > 0 && wp.dist > shortestDistInLink * 2) {
            //     shortestDistInLink = wp.dist;
            //     return;
            // }
            startLinks.push([wp]);
            shortestDistInLink = wp.dist;
            prevSequence = wp.sequence;
            return;
        }
        if (wp.sequence === (prevSequence + 1)) {
            shortestDistInLink = wp.dist < shortestDistInLink ? wp.dist : shortestDistInLink;
            last(startLinks).push(wp);
        }
        prevSequence = wp.sequence;
    })
    return startLinks.sort((l1, l2) => l1[0].sequence - l2[0].sequence);
}

const findBestStartEndWpOnRoute = (route, tripId, stopsNearFrom, stopsNearTo, startPoi, endPoi, {
    arrivingTimeFilter,
    leavingTimeFilter
}) => {
    const log = route.routeNumber === '945'
    let startWp, endWp;
    log && logger.debug(route.routeNumber, 'stopsNearFrom', stopsNearFrom)
    log && logger.debug(route.routeNumber, 'stopsNearTo', stopsNearTo)
    log && logger.debug(route.routeNumber, 'startPoi', startPoi)
    log && logger.debug(route.routeNumber, 'endPoi', endPoi)
    let startSTsOnRoute = createStopTimesOnRoute(route, tripId, stopsNearFrom, startPoi, leavingTimeFilter).sort((wp1, wp2) => wp1.sequence - wp2.sequence);
    startSTsOnRoute = uniqWith(startSTsOnRoute, BaseStop.isEqual)

    let endSTsOnRoute = createStopTimesOnRoute(route, tripId, stopsNearTo, endPoi, arrivingTimeFilter, startSTsOnRoute[0] ? startSTsOnRoute[0].sequence - 1 : 0, true).sort((wp1, wp2) => wp1.sequence - wp2.sequence);
    endSTsOnRoute = uniqWith(endSTsOnRoute, BaseStop.isEqual)

    log && logger.debug(route.routeNumber, 'startWpsOnRoute', startSTsOnRoute)
    log && logger.debug(route.routeNumber, 'endWpsOnRoute', endSTsOnRoute)
    const exactStartStops = startSTsOnRoute.filter(wp => wp.stopId === startPoi.stopId || wp.dist === 0)
    startWp = exactStartStops.length === 1 ? exactStartStops[0] : null
    const exactEndStops = endSTsOnRoute.filter(wp => wp.stopId === endPoi.stopId || wp.dist === 0)
    endWp = exactEndStops.length === 1 ? exactEndStops[0] : null
    // for (let i = startWpsOnRoute.length - 1; i >= 0; i--) {
    // for (let i = 0; i < startWpsOnRoute.length; i++) {
    //     startWp = startWpsOnRoute[i].stopId === startPoi.stopId || startWpsOnRoute[i].dist === 0 ? startWpsOnRoute[i] : null;
    // }
    // for (let j = 0; j < endWpsOnRoute.length; j++) {
    //     endWp = endWpsOnRoute[j].stopId === endPoi.stopId || endWpsOnRoute[j].dist === 0 ? endWpsOnRoute[j] : null;
    // }

    log && logger.debug(route.routeNumber, "start/end", startWp, endWp);

    if (startWp && endWp) {
        return {startWp, endWp}
    }

    let startLink, endLink;

    // group wps on sequence links
    const startLinks = groupBySequence(startSTsOnRoute);
    log && logger.debug(route.routeNumber, 'start links', startLinks, startSTsOnRoute)
    const endLinks = groupBySequence(endSTsOnRoute);
    log && logger.debug(route.routeNumber, 'end links', endLinks, endSTsOnRoute)


    for (let j = 0; j < endLinks.length; j++) {
        for (let i = startLinks.length - 1; i >= 0; i--) {
            let maybeStartLink = differenceWith(startLinks[i], endLinks[j], (l1, l2) => l2.sequence <= l1.sequence);
            let maybeEndLink = differenceWith(endLinks[j], startLinks[i], (l1, l2) => l1.sequence <= l2.sequence);
            log && logger.debug('maybeStartLink', maybeStartLink)
            log && logger.debug('maybeEndLink', maybeEndLink)
            if (!maybeStartLink.length) {
                log && logger.debug('No startLinks after filter.')
                continue;
            }

            startLink = maybeStartLink;
            endLink = maybeEndLink;
            break;
        }
        if (startLink?.length && endLink?.length) {
            break;
        }
    }

    log && logger.debug(route.routeNumber, startWp, endWp, startLink, endLink);

    if (!startWp && startLink) {
        startWp = startLink.sort((wp1, wp2) => wp1.dist - wp2.dist)[0] // TODO: Check this really is returning the closest WP in array
    }
    if (!endWp && endLink) {
        endWp = endLink.sort((wp1, wp2) => wp1.dist - wp2.dist)[0]
    }

    return {startWp, endWp}
}

const getConnectionDuration = (profile, startCoord, coordinates, fallbackSpeed = 4.5) => {
    const exe = async (profile, startCoord, coordinates, fallbackSpeed) => {
        // if (applyAcs() && key) {
        logger.debug('Getting accurate durations...')
        return await getCachedAccurateDurations(profile, startCoord, coordinates, fallbackSpeed)
        // }
        // logger.debug('Getting simple durations...')
        // return getCachedSimpleDurations(profile, startCoord, coordinates, fallbackSpeed)
    };
    return exe(profile, startCoord, coordinates, fallbackSpeed);
}

const getNearestStopsToCoord = async (allVerifiedStopsArray, coord, maxDist, maxDuration, connectType, schoolOnly) => {
    const firstCut = allVerifiedStopsArray.filter(stop => !schoolOnly || stop.stopType === 'school').map(stop => ({
        ...stop,
        cheapDist: getDistanceInMetres(coord, stop)
    }))
        .filter(s => {
            return s.cheapDist <= maxDist
        })
        .map(s => {
            // logger.debug('Getting cached dist for %s', s.stopName)
            const {dist, duration} = stopDistCache.getData(connectType, coord, s.stopId);
            // logger.debug('Cached %d, %d for %s', dist, duration, s.stopName)
            s.dist = dist;
            s.duration = duration;
            // s.stopName === "St Johns College, Sheraton Rd" && logger.debug("Checking dist  St Johns College, Sheraton Rd", dist, duration)
            return s
        })

    const cached = firstCut.filter(s => s.dist >= 0)
    const missingDist = firstCut.filter(s => s.dist === -1 || s.dist === undefined).sort((s1, s2) => s1.cheapDist - s2.cheapDist);//.slice(0, 200);

    logger.debug('missing distances', missingDist.length)
    let newlyCached = []
    if (missingDist.length) {

        const {durations, distances} = await getConnectionDuration(connectType, coord, missingDist);

        newlyCached = missingDist.map((s, idx) => ({...s, dist: distances[idx], duration: durations[idx]}));
        newlyCached.forEach(s => {
            stopDistCache.setData(connectType, coord, s.stopId, s.dist, s.duration);
        })
        stopDistCache.write();
    }

    return cached.concat(newlyCached)
        .filter(s => s.duration <= maxDuration)
        .sort((s1, s2) => s1.duration - s2.duration)
        .map(s => {
            s = new Stop(s);
            s.setLinkedStops(allVerifiedStopsArray);
            return s;
        });
}

const getNearestStops = async (allVerifiedStopsArray, maxDist, maxDuration, connectType, poi, features) => {
    logger.debug('getting stops near', maxDist, maxDuration, connectType, poi)
    // if (poi.type === 'Feature') {
    //     // Is an address or other poi
    //     const lat = poi.center[1];
    //     const lon = poi.center[0];
    //     return getNearestStopsToCoord(allStops, {lat, lon}, maxDist)
    // } else {
    //     // is a specific stop selected by user
    //     if (poi.stopType === 'school') {
    //         // if school stop, look for other school stops
    //         const lat = poi.center[1];
    //         const lon = poi.center[0];
    //         return getNearestStopsToCoord(allStops, {lat, lon}, maxDist, true);
    //     } else {
    //         // Is a bus stop of some kind
    //         return [{...poi, dist: 0}];
    //     }
    // }
    // if school stop, look for other school stops
    const lat = poi.center[1];
    const lon = poi.center[0];
    let stops = await getNearestStopsToCoord(allVerifiedStopsArray, {
        lat,
        lon
    }, maxDist, maxDuration, connectType, poi.stopType === 'school');
    if (features.access(FEATURE.lnks)) {
        stops.filter(stop => stop.hasLinkedStops()).forEach(stop => {
            logger.debug('near stop has links')
            stop.linkedStops.forEach(linkedStop => {
                const ls = new Stop({
                    ...linkedStop.stop,
                    dist: stop.dist
                })
                if (stop.stopType === 'school') {
                    ls.startBell = stop.startBell;
                    ls.startBellWindow = stop.startBellWindow
                    ls.endBell = stop.endBell
                    ls.endBellWindow = stop.endBellWindow
                }
                stops.push(ls);
            })
        });
    }
    return stops;
}

export class JourneyPlanFilter {
    constructor({filter, timeFilter, prefs, reverse, features, transfers, allStops, schedules, allRoutes, key}) {
        Object.assign(this, {
            filter,
            timeFilter,
            prefs,
            reverse,
            features,
            transfers,
            allStops,
            schedules,
            allRoutes,
            key
        })
    }

    async lookup() {

        const {filter, timeFilter, prefs, reverse, features, transfers, allStops, schedules, allRoutes, key} = this
        const allVerifiedStopsArray = values(allStops).filter(s => publicStopFilter(s) && s.verified).map(s => new Stop(s));

        let tripPlans = [];
        const [startLon, startLat] = filter.from.center
        const [endLon, endLat] = filter.to.center

        let stopsNearFrom = await getNearestStops(allVerifiedStopsArray, prefs.getMaxDistanceConnectStart(reverse), prefs.getMaxDurationConnectStart(reverse), prefs.connectStart, filter.from, features);
        logger.debug("Stops near from: ", stopsNearFrom)
        let stopsNearTo = await getNearestStops(allVerifiedStopsArray, prefs.getMaxDistanceConnectEnd(reverse), prefs.getMaxDurationConnectEnd(reverse), prefs.connectEnd, filter.to, features);
        logger.debug("Stops near to: ", stopsNearTo)

        let routeTypes = []
        let directions = filter.directions || [];
        directions = uniq(directions);
        if (filter.regular) routeTypes.push('Regular')
        if (filter.school) routeTypes.push('School')
        routeTypes = uniq(routeTypes);
        if (prefs.avoidSchool) {
            routeTypes = ["Regular"]
        }

        // logger.debug('stops near from:', stopsNearFrom.map(s => s.stopName))
        // logger.debug('stops near to:', stopsNearTo.map(s => s.stopName))

        let {
            routesFrom,
            routesTo,
            directRouteIds
        } = getIntersectingRoutes(stopsNearFrom, stopsNearTo, directions, routeTypes, timeFilter, schedules);
        logger.debug('Intersecting routeIds: ', directRouteIds)

        let directConnection = await getCachedPath(prefs.connectStart, {
            lat: startLat,
            lon: startLon
        }, {lat: endLat, lon: endLon}, prefs.getConnectStartSpeed(reverse))

        if (directConnection?.duration < (prefs.getMaxDurationConnectStart(reverse) + prefs.getMaxDurationConnectEnd(reverse))) {
            tripPlans.push({
                legs: [{
                    startStop: filter.from,
                    endStop: filter.to,
                    type: prefs.connectStart,
                    ...directConnection,
                    route: new BusRoute({
                        routeId: 'direct',
                        waypoints: directConnection.waypoints
                    })
                }],
                duration: directConnection.duration,
                secondsTilDeparture: 0,
                type: 'direct'
            })
        }

        timeFilter.trips = {}
        let stops = timeFilter.type === TimeFilterType.ARRIVING ? stopsNearTo : stopsNearFrom

        directRouteIds.forEach(rId => {
            stops.forEach(stop => {
                // const validTripIds = getValidTripsAtStopForRoute(rId, stop, timeFilter.anyDate, timeFilter.startTime, timeFilter.toWindowArray())
                const validTripIds = stop.getPassingTimes(r => r.routeId === rId, {
                    date: timeFilter.startTime,
                    school: timeFilter.anyDate,
                    time: timeFilter.getStartTime(),
                    window: timeFilter.type === TimeFilterType.ARRIVING ? 48 * 3600 : timeFilter.windowInSecs
                }, schedules).map(pt => pt.tripId)
                validTripIds.forEach(tripId => {
                    tripPlans = tripPlans.concat({
                        legs: [{
                            // startStop: timeFilter.type === TimeFilterType.LEAVING ? stop : null,
                            // endStop: timeFilter.type === TimeFilterType.ARRIVING ? stop : null,
                            type: 'route',
                            routeId: rId,
                            tripId
                        }]
                    })
                })
            })
        })


        logger.debug('tripPlans: ', tripPlans)
        const timeFilters = [];

        if (features.access(FEATURE.trnsfrs) && transfers?.length) {

            logger.debug('Looking fro transfers...', transfers)
            logger.debug(timeFilter)

            // Find routesFrom from stopsUpstream from nearStops to look for transfers
            routesFrom.filter(r => directRouteIds.indexOf(r.routeId) === -1).forEach(routeFrom => {

                logger.debug('Looking for transfer from Route ', routeFrom.routeNumber, routeFrom.routeId)
                const stopsOnFromRoute = values(allStops).filter(s => {
                    if (!s.routes) {
                        return false;
                    }
                    const stopOnRoute = s.routes[s.routes.findIndex(r => r.routeId === routeFrom.routeId)];
                    if (!stopOnRoute) {
                        return false;
                    }
                    return stopOnRoute.maxSequence >= routeFrom.minSequence;
                })
                stopsOnFromRoute.forEach(stopOnFromRoute => {

                    const validTransfers = transfers.filter(t => !t.invalid && (!prefs.avoidTransfers || t.inSeat)).map(transfer => {
                        const log = false //transfer.fromRouteNumber === '914' && transfer.toRouteNumber === '912A'

                        // TODO: Ensure routesTo has a stop on the stopsNear
                        if (transfer.isValidTransfer(routeFrom, stopOnFromRoute, routesTo, timeFilter, log)) {
                            return {...transfer, legCount: 1}
                        }

                        if (transfer.isValidTransfer(routeFrom, stopOnFromRoute, null, timeFilter, log)) {
                            const idx = transfer._trx.findIndex(leg2 => {
                                const routeTo = routesTo[routesTo.findIndex(rTo => rTo.routeId === leg2.toRouteId)]
                                if (!routeTo) {
                                    return false
                                }
                                return leg2.isValidTransfer(null, null, routesTo, timeFilter, log);
                            });
                            if (idx > -1) {
                                const leg2 = transfer._trx[idx]
                                logger.debug('Found multi leg transfer from %s -> %s ->  %s @ %s', transfer.fromRouteNumber, leg2.fromRouteNumber, leg2.toRouteNumber, leg2.fromStopId)
                                return {...transfer, next: leg2, legCount: 2}
                            }
                        }

                        // return transfer._trx.some(leg2 => {
                        //     return leg2._trx.some(leg3 => routesTo.findIndex(rTo => rTo.routeNumber === leg3.toRouteNumber) > -1)
                        // })
                        log && logger.debug('no multi leg transfer.')
                        return null;

                    }).filter(t => !!t)

                    logger.debug("Found valid transfers: ", validTransfers.length)
                    if (validTransfers?.length) {

                        validTransfers.forEach(transfer => {
                            logger.debug('appending tripplan...', transfer)

                            logger.debug('1st leg: %s, rId: %s', transfer.fromRouteNumber, routeFrom.routeId)
                            let legs = [{
                                type: 'route',
                                routeId: routeFrom.routeId,
                                // startStop: filter.from.stopId ? filter.from : null,
                                tripId: transfer.fromTripId,
                                endStop: allStops[transfer.fromStopId],
                                arrivingTimeFilter: new TimeFilter({
                                    anyDate: false,
                                    type: TimeFilterType.ARRIVING,
                                    startTime: getSecondsSinceMidnightAsDayjs(transfer.time + transfer.window),
                                    window: transfer.window
                                })
                            }, {
                                type: 'transfer',
                                route: new BusRoute({
                                    routeId: routeFrom.routeId + '_' + transfer.toRouteId,
                                    waypoints: transfer.waypoints || []
                                }),
                                inSeat: transfer.inSeat,
                                dist: transfer.distance,
                                duration: Math.round(transfer.duration || 0)
                            }]

                            if (transfer.legCount === 1) {
                                logger.debug(' to : ', transfer.toRouteNumber)
                                legs.push({
                                    type: 'route',
                                    routeId: transfer.toRouteId || undefined,
                                    startStop: allStops[transfer.toStopId],
                                    tripId: transfer.toTripId,
                                    minDepart: transfer.time + transfer.window,
                                    leavingTimeFilter: new TimeFilter({
                                        anyDate: false,
                                        type: TimeFilterType.LEAVING,
                                        startTime: getSecondsSinceMidnightAsDayjs(transfer.time - transfer.window),
                                        window: transfer.window
                                    })
                                    // endStop: filter.to.stopId ? filter.to : null,
                                })
                            } else if (transfer.legCount === 2) {
                                // const idx = transfer._trx.findIndex(leg2 => routesTo.findIndex(rTo => rTo.routeNumber === leg2.toRouteNumber) > -1)
                                // if (idx > -1) {
                                logger.debug('2nd leg')
                                logger.debug(' to : ', transfer.toRouteNumber)
                                const leg2 = transfer.next
                                logger.debug(' to : ', leg2.toRouteNumber)
                                legs.push({
                                    type: 'route',
                                    routeId: transfer.toRouteId,
                                    // startStop: filter.from.stopId ? filter.from : null,
                                    startStop: allStops[transfer.toStopId],
                                    endStop: allStops[leg2.toStopId],
                                    tripId: transfer.toTripId,
                                    arrivingTimeFilter: new TimeFilter({
                                        anyDate: false,
                                        type: TimeFilterType.ARRIVING,
                                        startTime: getSecondsSinceMidnightAsDayjs(leg2.time + leg2.window),
                                        window: leg2.window
                                    }),
                                    leavingTimeFilter: new TimeFilter({
                                        anyDate: timeFilter.anyDate,
                                        type: TimeFilterType.LEAVING,
                                        startTime: getSecondsSinceMidnightAsDayjs(transfer.time - transfer.window),
                                        window: transfer.window
                                    })
                                });
                                legs.push({
                                    type: 'transfer',
                                    route: new BusRoute({
                                        routeId: transfer.toRouteId + '_' + leg2.toRouteId,
                                        waypoints: leg2.waypoints || []
                                    }),
                                    inSeat: leg2.inSeat,
                                    dist: leg2.distance,
                                    duration: Math.round(leg2.duration || 0)
                                })
                                legs.push({
                                    type: 'route',
                                    routeId: leg2.toRouteId || undefined,
                                    startStop: allStops[leg2.toStopId],
                                    tripId: leg2.toTripId,
                                    leavingTimeFilter: new TimeFilter({
                                        anyDate: timeFilter.anyDate,
                                        type: TimeFilterType.LEAVING,
                                        startTime: getSecondsSinceMidnightAsDayjs(leg2.time - leg2.window),
                                        window: leg2.window
                                    })
                                    // endStop: filter.to.stopId ? filter.to : null,
                                })
                            }

                            if (legs.filter(l => l.type === 'route').every(l => l.routeId && l.tripId)) {
                                tripPlans.push({
                                    type: 'transfer',
                                    legs: legs
                                });
                            }

                        })
                    } else {
                        logger.debug("No transfers found")
                    }
                })
            })
        }

        logger.debug('TIME FILTERs: ', timeFilters);
        timeFilters.push(timeFilter);
        tripPlans = tripPlans.filter(t => t.legs.length && t.legs.every(leg => {
            if (leg.type === "route") {
                return leg.routeId?.length && leg.routeId !== "_" && leg.tripId?.length;
            }
            return true;
        }))

        logger.debug('Before uniqueness: ', [...tripPlans])
        tripPlans = tripPlans.map(tp => new JourneyPlan(tp, allStops));
        tripPlans = uniqWith(tripPlans.sort((tp1, tp2) => tp1.legs.length - tp2.legs.length), JourneyPlan.isEqual)
        logger.debug('After uniqueness: ', [...tripPlans])

        logger.debug('tripplans1: ', util.inspect(tripPlans, {depth: 3}));
        let allPossibleRouteIds = flatten(tripPlans.map(tp => tp.legs.filter(l => l.type === 'route').map(l => ({
            routeId: l.routeId,
            tripId: l.tripId
        })))).filter(rId => !!rId)

        let routeFilter = {}
        allPossibleRouteIds.forEach(r => {
            routeFilter[r.routeId] = routeFilter[r.routeId] || {tripIds: []}
            routeFilter[r.routeId].tripIds.push(r.tripId)
        })
        logger.debug('route filter', routeFilter)
        let routes = []
        if (Object.keys(routeFilter).length) {
            logger.warn('%d routeIds to fetch', Object.keys(routeFilter).length)
            if (allRoutes) {
                routes = allRoutes.filter(r => routeFilter[r.routeId]).map(cloneDeep)
                routes.forEach(route => {
                    route.services = route.services?.filter(trip => routeFilter[route.routeId].tripIds.includes(trip.tripId))
                })
                routes = routes.filter(r => r.services?.length && r.published === 1).map(r => new BusRoute(r));
            } else {
                routes = await getPublicRoutes(key, routeFilter, {
                    shrinkRate: 0.00001
                });
            }
            logger.warn('%d routes fetched', routes.length)
        }
        routes.forEach(r => {
            r.setBaseStops(allStops)
            r.setSchedules(schedules)
        })
        logger.debug('Route count: ', routes.length)
        const routesById = keyBy(routes, 'routeId')
        logger.debug(routes)

        let tripCount = tripPlans.length
        logger.warn('Removed ', tripCount - tripPlans.length, 'trips due to no legs')
        tripCount = tripPlans.length
        logger.warn('tripplans2: ', util.inspect(tripPlans, {depth: 3}));
        tripPlans.forEach(tripPlan => {
            logger.debug('leg count1', tripPlan.legs.length)
            tripPlan.legs.filter(leg => leg.type === 'route' && leg.routeId).forEach(leg => {
                const route = cloneDeep(routesById[leg.routeId]);
                if (route) {
                    logger.debug(' Setting route', route.routeNumber, leg.routeId)
                    route.services = route.services.filter(trip => trip.tripId === leg.tripId);
                    leg.route = new BusRoute(route)
                    leg.trip = route.services[0]
                    // leg.route.setBaseStops(allStops)
                    logger.debug(leg.route)
                } else {
                    logger.warn('No route for tripplan.leg with routeId: ', leg.routeId)
                }
            })
        })

        // Remove trip plans without a route or roue without a srevice
        const tripPlanCOunt = tripPlans.length
        tripPlans = tripPlans.filter(tripPlan => tripPlan.legs.filter(leg => leg.type === 'route').every(leg => leg.route?.services?.length))
        logger.warn('%d TripPlans removed because of no route', tripPlanCOunt - tripPlans.length)

        // logger.debug('leg count2', tripPlan.legs.length)
        // logger.debug('leg routes', tripPlan.legs.map(l => l.route?.routeNumber));
        tripPlans.forEach(tripPlan => {

            logger.debug('leg count3', tripPlan.legs.length)
            tripPlan.legs.filter(leg => leg.type === 'route').forEach((leg, idx) => {
                if (tripPlan.invalid) {
                    return
                }
                const {
                    startWp,
                    endWp
                } = findBestStartEndWpOnRoute(leg.route, leg.tripId, leg.startStop ? [leg.startStop] : stopsNearFrom,
                    leg.endStop ? [leg.endStop] : stopsNearTo,
                    leg.startStop || filter.from, leg.endStop || filter.to, {
                        arrivingTimeFilter: leg.arrivingTimeFilter ||
                            (
                                timeFilter?.checkArrivalTime(leg.route.direction) ? timeFilter : undefined
                            ),
                        leavingTimeFilter: leg.leavingTimeFilter ||
                            (
                                timeFilter?.checkDepartureTime(leg.route.direction) ? timeFilter : undefined
                            )

                    });
                logger.debug('startwp', startWp, 'endwp', endWp)
                if (!startWp || !endWp || startWp.sequence >= endWp.sequence) {
                    logger.warn('Could not find startWp/endWp')
                    tripPlan.invalid = true
                    return;
                }
                leg.route.calculateStartEnd({firstStop: startWp, lastStop: endWp, shortestRoute: true});
                leg.duration = leg.trip.getDuration(leg.route);
                logger.debug(leg.route.routeNumber, 'route duration', leg.duration);
            });
            logger.debug('leg durations', tripPlan.legs.map(l => l.duration));
            logger.debug('leg count4', tripPlan.legs.length)
            tripPlan.legs = tripPlan.legs.filter(l => l.type !== 'route' || l.duration);
            tripPlan.duration = tripPlan.legs.filter(l => l.type !== 'direct').map(leg => leg.duration).reduce((prev, next) => prev + next, 0);
            logger.debug('leg count5: ', tripPlan.legs.filter(l => l.route).map(l => l.route?.routeNumber).join('->'))
        })

        tripPlans = tripPlans.filter(tripPlan => {
            const filter = !tripPlan.invalid
            !filter && logger.warn('Removing trip plan of as we could not find a start/end for all sections.')
            return filter
        })

        // TODO: check legs in transfer don't start and end at the same stop
        tripPlans = tripPlans.filter(tripPlan => {
            const filter = tripPlan.type !== 'transfer' || tripPlan.legs.filter(l => l.type === 'route').every(l => l.route.getStartStop().stopId !== l.route.getEndStop().stopId)
            !filter && logger.warn('Removing trip plan of type', tripPlan.type, ' ', tripPlan.legs?.filter(l => l.route).map(l => l.route.routeNumber), ' due to start and end stop being the same.')
            return filter
        })

        tripPlans = tripPlans.filter(tripPlan => {
            const filter = tripPlan.type !== 'transfer' || tripPlan.legs.length >= 3
            !filter && logger.warn('Removing trip plan of type', tripPlan.type, ' ', tripPlan.legs?.filter(l => l.route).map(l => l.route.routeNumber), ' due to some routes missing.')
            return filter
        })

        tripPlans = tripPlans.filter(tripPlan => {
            const filter = tripPlan.duration > 0
            !filter && logger.warn('Removing ', tripPlan.legs[0] && tripPlan.legs[0].route.routeNumber, 'due to no duration')
            return filter
        })

        logger.warn('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due to no duration')
        tripCount = tripPlans.length
        if (!tripPlans.length) {
            return []
        }
        logger.debug('tripplans3: ', util.inspect(tripPlans, {depth: 3}));


        // let connectStartDurations = await getConnectionDuration(prefs.connectStart, {
        //     lat: startLat,
        //     lon: startLon
        // }, tripPlans.map(tripPlan => tripPlan.legs[0].route.getStartStop()).concat({
        //     lat: endLat,
        //     lon: endLon
        // }), prefs.getConnectStartSpeed(reverse));


        await Promise.all(tripPlans.filter(tripPlan => tripPlan.type !== 'direct').map(async (tripPlan, idx) => {
            const firstLegOfService = tripPlan.legs[tripPlan.legs.findIndex(l => l?.route?.services?.length)];
            logger.debug('Connecting start of %s @ %s with %d legs', firstLegOfService?.route?.routeNumber, firstLegOfService?.route?.getStartStop().stopName, tripPlan.legs.length);

            let connectStartDirections = await getCachedPath(prefs.connectStart, {
                lat: startLat,
                lon: startLon
            }, firstLegOfService.route.getStartStop(), prefs.getConnectStartSpeed(reverse))

            const connectType = firstLegOfService.route.getStartStop().isLinkedStop(filter.from) ? 'direct' : !reverse ? prefs.connectStart : prefs.connectEnd;
            tripPlan.legs.unshift(new Leg({
                type: connectType,
                ...connectStartDirections,
                route: new BusRoute({
                    routeId: '_' + firstLegOfService.route.routeId,
                    waypoints: connectStartDirections?.waypoints || []
                })
            }))
            let nextDepartureDates = getNextDepartureDate(tripPlan, schedules, timeFilter);
            let i = 0
            let nextDepartureDate = nextDepartureDates[i++]
            let nextTrip = getNextTrip(timeFilter, firstLegOfService.route, nextDepartureDate, schedules)
            if (!nextTrip || !nextDepartureDate) {
                tripPlan.invalid = true
                return
            }
            let tripStartTimeAsDayjs = getDepartureTimeAsDayjs(nextTrip, nextTrip.getStopTime(firstLegOfService.route.getStartStop()), null, nextDepartureDate);
            while (!timeFilter.anyDate && i <= nextDepartureDates.length && timeFilter.type !== TimeFilterType.ARRIVING && tripPlan.diffDays === 0 && tripStartTimeAsDayjs.isBefore(timeFilter.startTime)) {
                nextDepartureDate = nextDepartureDates[i++]
                nextTrip = getNextTrip(timeFilter, firstLegOfService.route, nextDepartureDate, schedules)
                if (!nextTrip) {
                    tripPlan.invalid = true
                    return
                }
                tripStartTimeAsDayjs = getDepartureTimeAsDayjs(nextTrip, nextTrip.getStopTime(firstLegOfService.route.getStartStop()), null, nextDepartureDate);
            }

            let diffDays = dayjs(nextDepartureDate).startOf('d').diff(dayjs(timeFilter.startTime).startOf('d'), 'd');
            if (!timeFilter.anyDate && diffDays > nextDepartureDates.length) {
                tripPlan.invalid = true
                return
            }
            // let nextTrip = getNextTrip(timeFilter, firstLegOfService.route, nextDepartureDate)

            let tripStartTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(firstLegOfService.route.getStartStop()));

            tripPlan.diffDays = diffDays;
            tripPlan.timeFilter = timeFilter;
            tripPlan.departureTime = tripStartTimeAsSecsSinceMidnight - (!reverse ? (tripPlan.legs[0].type === 'direct' ? 0 : tripPlan.legs[0].duration) : (last(tripPlan.legs).type === 'direct' ? -last(tripPlan.legs).duration : 0));
            tripPlan.nextDepartureDate = nextDepartureDate
            tripPlan.nextDepartureDates = nextDepartureDates
            tripPlan.nextDepartureTime = dayjs.unix(nextDepartureDate.unix() + tripStartTimeAsSecsSinceMidnight)
            // tripPlan.nextArrivalTime = dayjs.unix(nextDepartureDate.unix() + tripStartTimeAsSecsSinceMidnight + tripPlan.duration)
            tripPlan.secondsTilDeparture = getSecondsTilNextDeparture(tripPlan.departureTime, !timeFilter.anyDate ? timeFilter.startTime : tripPlan.nextDepartureDate);
            // tripPlan.secondsTilArrival = tripPlan.secondsTilDeparture + tripPlan.duration
            tripPlan.estimated = !nextTrip.getStopTime(firstLegOfService.route.getStartStop())?.timingPoint
        }))

        tripPlans = tripPlans.filter(tripPlan => {
            const filter = !tripPlan.invalid
            !filter && logger.warn('Removing trip plan as we could not find a next trip.')
            return filter
        })

        tripPlans = tripPlans.filter(tripPlan => {
            // If the start is a linked stop, don't check distance.
            const firstLeg = tripPlan.legs[0]
            if (firstLeg.type === 'direct') return true;
            const duration = Math.round(firstLeg.duration / 60) * 60;
            if (duration <= prefs.getMaxDurationConnectStart(reverse)) return true
            logger.warn('Removing trip with route', tripPlan.legs.filter(l => l.route).map(l => l.r?.routeNumber).join('->'), 'trips due start connection longer than allowed. Connect start', firstLeg?.duration);
            return false
        })
        tripCount = tripPlans.length

        logger.debug('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due start connection longer than allowed')

        tripPlans = tripPlans.filter(tripPlan => {
            // If the start is a linked stop, don't check distance.
            if (tripPlan.type === 'direct' || tripPlan.nextDepartureDate) return true
            // const firstLegOfService = tripPlan.legs[tripPlan.legs.findIndex(l => l?.route?.trips && Object.keys(l.route.trips).length)];
            logger.debug('Removing trip with route', tripPlan.legs.filter(l => l.route).map(l => l.r?.routeNumber).join('->'), 'trips due no departure date.');
            return false
        })
        tripCount = tripPlans.length

        logger.warn('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due to no departure date')

        // let connectEndDurations = await getConnectionDuration(prefs.connectEnd, {
        //     lat: endLat,
        //     lon: endLon
        // }, tripPlans.map(trip => last(trip.legs).route.getEndStop()), prefs.getConnectEndSpeed(reverse));

        await Promise.all(tripPlans.filter(tripPlan => tripPlan.type !== 'direct').map(async (tripPlan, idx) => {
            const lastLegOfService = tripPlan.legs[findLastIndex(tripPlan.legs, l => l.route?.services?.length)];
            logger.debug('Connecting end of %s @ %s with %d legs', lastLegOfService?.route?.routeNumber, lastLegOfService.route.getEndStop().stopName, tripPlan.legs.length);

            let connectEndDirections = await getCachedPath(prefs.connectEnd, {
                lat: endLat,
                lon: endLon
            }, lastLegOfService.route.getEndStop(), prefs.getConnectEndSpeed(reverse))

            const connectType = lastLegOfService.route.getEndStop().isLinkedStop(filter.to) ? 'direct' : !reverse ? prefs.connectEnd : prefs.connectStart;
            tripPlan.legs.push(new Leg({
                type: connectType,
                ...connectEndDirections,
                route: new BusRoute({
                    routeId: lastLegOfService.route.routeId + '_',
                    waypoints: connectEndDirections?.waypoints || []
                })
            }))

            let nextTrip = getNextTrip(timeFilter, lastLegOfService.route, tripPlan.nextDepartureDate, schedules)

            if (!nextTrip) {
                tripPlan.invalid = true
                return
            }
            let tripEndTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(lastLegOfService.route.getEndStop()))
            if (!timeFilter.anyDate && timeFilter.type === TimeFilterType.ARRIVING && tripPlan.diffDays === 0 && tripEndTimeAsSecsSinceMidnight > timeFilter.getEndTime()) {
                tripPlan.invalid = true
            }
            // if the next departuer is before now, then show tomorrows.
            // if (timeFilter.type === TimeFilterType.ARRIVING && tripEndTimeAsDayjs.isAfter(timeFilter.startTime)) {
            //     tripPlan.nextDepartureDate = tripPlan.nextDepartureDates[1]
            //     const diffDays = dayjs(tripPlan.nextDepartureDate).startOf('d').diff(dayjs(timeFilter.startTime).startOf('d'), 'd')
            //     if (!timeFilter.anyDate && diffDays > tripPlan.nextDepartureDates.length) {
            //         tripPlan.invalid = true
            //         return
            //     }
            //     nextTrip = getNextTrip(timeFilter, lastLegOfService.route, tripPlan.nextDepartureDate)
            //     if (!nextTrip) {
            //         tripPlan.invalid = true
            //         return
            //     }
            //     tripEndTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(lastLegOfService.route.getEndStop()))
            //
            // }
            tripPlan.arrivalTime = tripEndTimeAsSecsSinceMidnight + (last(tripPlan.legs).type !== 'direct' ? (!reverse ? last(tripPlan.legs).duration : -last(tripPlan.legs).duration) : 0);
            tripPlan.nextArrivalTime = tripPlan.nextDepartureDate.clone().add(tripPlan.arrivalTime, 's')

        }))

        tripPlans = tripPlans.filter(tripPlan => {
            const filter = !tripPlan.invalid
            !filter && logger.warn('Removing trip plan as we could not find a next trip.')
            return filter
        })

        tripPlans.forEach(tripPlan => {
            logger.debug('Adding up tripPlan duration....')
            // tripPlan.legs.filter(l => l?.route?.services?.length).forEach(l => {
            //
            //     let nextTrip = getNextTrip(timeFilter, l.route)
            //     const tripStartTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(l.route.getStartStop()))
            //     const tripEndTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(l.route.getEndStop()))
            //     l.duration = tripEndTimeAsSecsSinceMidnight - tripStartTimeAsSecsSinceMidnight;
            //     logger.debug('leg duration: ', l.duration)
            // })
            logger.debug('leg durations: ', tripPlan.legs.map(l => l.duration));
            tripPlan.duration = tripPlan.legs.filter(l => l.type !== 'direct').map(leg => leg.duration).reduce((prev, next) => prev + next, 0);
            logger.debug('DURATION: ', tripPlan.duration);
        })
        logger.debug('tripplans4: ', util.inspect(tripPlans, {depth: 3}));

        tripPlans = tripPlans.filter(tripPlan => {
            const filter = tripPlan.duration > 0
            !filter && logger.debug('Removing ', tripPlan.legs[0] && tripPlan.legs[0].route.routeNumber, 'due to no duration')
            return filter
        })
        tripCount = tripPlans.length
        logger.warn('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due to no duration')

        tripPlans = tripPlans.filter(tripPlan => {
            // If the destination is a linked stop, don't check distance.
            const lastLeg = last(tripPlan.legs)
            if (lastLeg.type === 'direct') return true;
            const duration = Math.round(lastLeg.duration / 60) * 60;
            if (duration <= prefs.getMaxDurationConnectEnd(reverse)) return true;
            logger.warn('Removing trip with route', tripPlan.legs.filter(l => l.route).map(l => l.r?.routeNumber).join('->'), ' due to end connection longer than allowed');
            return false
        });

        if (!tripPlans.length && (prefs.connectStart === CONNECTIONS.drive || prefs.connectEnd === CONNECTIONS.drive)) {
            prefs.set('connectFrom', CONNECTIONS.walk)
            prefs.set('connectTo', CONNECTIONS.walk)
            // setPrefs(new Prefs(prefs, true))
            return
        }

        // Update any tripPlan transfers for inSeat transfers
        logger.debug('TripPlans before inseat: ', util.inspect(tripPlans, {depth: 3}))
        // eslint-disable-next-line
        for (const _ in [0, 1]) { // Look for in-seat transfers twice, in case 2nd leg is also one.

            tripPlans.filter(tp => tp.type === 'transfer' && tp.legs.some(l => l.type === 'transfer' && l.inSeat === true)).forEach(tripPlan => {
                logger.debug('In seat transfer: ', {...tripPlan})
                tripPlan.legs = tripPlan.legs.filter((l, idx) => {
                    if (idx === 0 || idx > 3) {
                        return true
                    }
                    const prevLeg = tripPlan.legs[idx - 1];
                    const nextLeg = tripPlan.legs[idx + 1]
                    if (prevLeg.type === 'transfer' && prevLeg.inSeat === true) {
                        return false;
                    }
                    if (l.type === 'transfer' && l.inSeat === true && prevLeg?.trip && nextLeg?.trip) {

                        const nextLegStops = nextLeg.route.getStopsBetween(nextLeg.trip).concat(nextLeg.route.getEndStop());
                        nextLegStops.unshift(nextLeg.route.getStartStop());

                        // const endTimeOfPrevLeg = getDepartureTimeAsSecondsSinceMidnight(prevLeg.trip, prevLeg.trip.stopTimes[prevLeg.route.endStopIdx])
                        // const startTimeOfNextLeg = nextLeg.trip.getStartTimeAsSecondsSinceMidnight();
                        // const diff = startTimeOfNextLeg - endTimeOfPrevLeg

                        // const additionalDelta = prevLeg.trip.stopTimes[prevLeg.route.endStopIdx].delta + diff
                        // nextLegStops.forEach(stop => {
                        //     stop.arriveSecs += diff
                        //     stop.departSecs += diff
                        // })
                        prevLeg.trip.stopTimes = prevLeg.trip.stopTimes.slice(0, prevLeg.route.endStopIdx).concat(nextLegStops)
                        prevLeg.route.stopTimes = cloneDeep(prevLeg.trip.stopTimes)
                        prevLeg.route.stops = prevLeg.route.stops.slice(0, prevLeg.route.endStopIdx).concat(nextLegStops);
                        prevLeg.route.endStopIdx = prevLeg.route.stops.length - 1;
                        prevLeg.merged = prevLeg.merged || []
                        prevLeg.merged.push(nextLeg.route.routeId);

                        const nextLegWps = nextLeg.route.getActiveWaypoints()
                        prevLeg.route.waypoints = prevLeg.route.waypoints.slice(0, prevLeg.route.endWpIdx).concat(nextLegWps)
                        prevLeg.route.endWpIdx = prevLeg.route.waypoints.length - 1;
                        const startTimeOfPrevLeg = getDepartureTimeAsSecondsSinceMidnight(prevLeg.trip, prevLeg.trip.stopTimes[prevLeg.route.startStopIdx])
                        const endTimeOfNextLeg = getDepartureTimeAsSecondsSinceMidnight(nextLeg.trip, nextLeg.trip.stopTimes[nextLeg.route.endStopIdx])
                        prevLeg.duration = endTimeOfNextLeg - startTimeOfPrevLeg
                        return false;
                    }
                    return true;
                })
                tripPlan.duration = tripPlan.legs.filter(l => l.type !== 'direct').map(leg => leg.duration).reduce((prev, next) => prev + next, 0);
            })
        }

        // tripPlans.sort((t1, t2) => sortTrips(t1, t2, rankBy))
        // logger.debug('TripPlans after inseat: ', util.inspect(tripPlans, {depth: 3}))
        tripPlans.forEach(tripPlan => tripPlan.legs.filter(leg => leg.route).forEach(leg => {
            leg.route.routeId = getCompositeRouteId(tripPlan, leg);
        }));

        logger.debug('Before uniqueness: ', [...tripPlans])
        tripPlans = uniqWith(tripPlans, JourneyPlan.isEqual)
        logger.debug('After uniqueness: ', [...tripPlans])

        return tripPlans
    }
}

export class JourneyPlan {


    static getJPConnectionDuration = (tripPlan) => {
        const connectStartLeg = tripPlan.legs[0]
        const connectEndLeg = last(tripPlan.legs)
        return (connectStartLeg.type !== 'direct' ? connectStartLeg.duration : 0) + (connectEndLeg.type !== 'direct' ? connectEndLeg.duration : 0)
    }

    static sortByShortestTime = (a, b) => {
        const diff = toMins(a.duration) - toMins(b.duration);
        if (Math.abs(diff) >= 1) {
            return diff
        }

        return JourneyPlan.getJPConnectionDuration(a) - JourneyPlan.getJPConnectionDuration(b);
    }
    static sortByNextService = (a, b) => {
        if (!a.nextDepartureDate || !b.nextDepartureDate || !a.timeFilter || !b.timeFilter) {
            return JourneyPlan.sortByShortestTime(a, b)
        }
        let diff = 0
        if (a.timeFilter.type === TimeFilterType.ARRIVING) {
            const aTTS = Math.abs(a.timeFilter.startTime.diff(a.nextArrivalTime))
            const bTTS = Math.abs(b.timeFilter.startTime.diff(b.nextArrivalTime))
            diff = aTTS - bTTS
        } else {
            diff = a.nextDepartureTime - b.nextDepartureTime;
        }
        if (Math.abs(diff) > 90) {
            return diff
        }

        return JourneyPlan.sortByShortestTime(a, b);
    }

    static sortByLeastWalking = (a, b) => {
        const diff = JourneyPlan.getJPConnectionDuration(a) - JourneyPlan.getJPConnectionDuration(b);
        if (Math.abs(diff) > 90) {
            return diff
        }
        return JourneyPlan.sortByNextService(a, b);
    }

    constructor(data, allStops) {
        this.jpId = ulid();
        this.departureTime = null;
        this.secondsTilDeparture = null;
        this.duration = null;
        this.type = null;
        this.legs = [];
        Object.assign(this, data);
        this.legs = this.legs.map(l => new Leg(l, allStops));
    }

    static isEqual(tp1, tp2) {
        return tp1.isEqual(tp2)
    }

    isEqual(otherTripPlan) {
        const tpResult = this.type === otherTripPlan.type && this.departureTime === otherTripPlan.departureTime &&
            this.duration === otherTripPlan.duration && this.secondsTilDeparture === otherTripPlan.secondsTilDeparture;

        let legsResult = this.legs?.length === otherTripPlan.legs?.length
        if (this.legs?.length && otherTripPlan.legs?.length) {
            legsResult = this.legs.every((leg, idx) => leg.isEqual(otherTripPlan.legs[idx])) ||
                (this.legs[0].isEqual(otherTripPlan.legs[0]) && last(this.legs).isEqual(last(otherTripPlan.legs)))
        }

        return tpResult && legsResult
    }

    getFirstRoute() {
        return this.legs.filter(l => l.type === 'route' && l.route)?.[0]?.route
    }

}

export class Leg {

    constructor(data, allStops) {
        this.duration = null;
        this.type = null;
        this.routeId = null;
        this.tripId = null;
        this.route = null;
        Object.assign(this, data);
        if (this.route && this.route.stopTimes && allStops) {
            this.route = new BusRoute(cloneDeep(this.route));
            this.route.setBaseStops(allStops)
        }
    }

    static isEqual(l1, l2) {
        return l1.isEqual(l2)
    }

    isEqual(otherLeg) {
        return this.type === otherLeg.type && this.routeId === otherLeg.routeId &&
            this.route?.routeId === otherLeg.route?.routeId && this.tripId === otherLeg.tripId &&
            this.duration === otherLeg.duration
    }
}
