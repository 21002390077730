import React, { useState } from "react";
import { Button } from "antd";
import SelectVehicleView from "./SelectVehicleView";
import { useRoster } from "./RosterContext";

export const VehicleAllocation = ({ close }) => {
  const { handleVehicleAllocation } = useRoster();
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  return (
    <>
      <SelectVehicleView selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} />
      <div className="d-flex justify-content-end">
        <Button
          key="employee-save-btn"
          type="primary"
          className="primary-btn btn-small mt-1"
          onClick={() => {
            if (selectedVehicle) {
              handleVehicleAllocation(selectedVehicle);
            }
            close();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};
