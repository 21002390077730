import React from "react";
import SecondaryMenu from "../../components/SecondaryMenu";
import { AddRoster, Auto, Bus, Close, Copy, Export, Graph, Plus, Rostering, Timer, User, Check } from "../../components/Icons";
import { Actuals } from "./Actuals";
import { VehicleAllocation } from "./VehicleAllocation";
import { DriverAllocation } from "./DriverAllocation";
import { Button, Input, TimePicker } from "antd";
import { useRoster } from "./RosterContext";

const RosteringSecondaryMenu = () => {
  const {
    showDashboard,
    setShowDashboard,
    showUnallocated,
    setShowUnallocated,
    showImportScenarios,
    weeklyScenarios,
    selectedWorkItems,
    handleUnallocate,
    copyScenario,
    autoAllocate,
    publishScenario,
    addRoster,
    exportScenario,
    filteredScenario,
    
  } = useRoster();

  const isSingleSelectedWorkItemCompleted = selectedWorkItems?.length === 1 && selectedWorkItems[0].isCompleted;

  const secondaryMenuConfig = [
    {
      items: [
        {
          type: "switch",
          icon: Graph,
          iconProps: { width: 18 },
          tooltip: `${showDashboard ? "Hide" : "Show"} Dashboard`,
          switch: {
            size: "small",
            onChange: (val) => setShowDashboard(val),
            checked: showDashboard,
          },
        },
        {
          icon: Copy,
          iconProps: { width: 14 },
          tooltip: "Copy Scenario",
          onClick: copyScenario,
          disabled: (weeklyScenarios?.length || 0) === 0 || showImportScenarios,
        },
        {
          icon: Auto,
          iconProps: { width: 16 },
          tooltip: "Auto Allocate (AI)",
          onClick: autoAllocate,
          disabled: (weeklyScenarios?.length || 0) === 0 || showImportScenarios,
        },
        {
          icon: Check,
          iconProps: { width: 16, height: 12 },
          tooltip: "Publish Scenario",
          className: "no-fill",
          onClick: publishScenario,
          disabled: (weeklyScenarios?.length || 0) === 0 || showImportScenarios,
        },
      ],
    },
    {
      items: [
        {
          type: "switch",
          icon: Rostering,
          iconProps: { width: 14 },
          tooltip: `${showUnallocated ? "Hide" : "Show"} Unallocated`,
          switch: {
            size: "small",
            onChange: (val) => setShowUnallocated(val),
            checked: showUnallocated,
          },
          badge: {
            size: "small",
            count: filteredScenario?.getTotalUnallocatedWorkItems() || 0,
          },
        },
        // {
        //   icon: AddRoster,
        //   iconProps: { width: 16, height: 18 },
        //   tooltip: "Create Ad hoc",
        //   popover: {
        //     title: "Create Ad hoc",
        //     content: (close) => (
        //       <div className="SecondaryMenuPopup">
        //         <div className="d-flex flex-column mb-2">
        //           <label className="info-label">Title</label>
        //           <Input placeholder="Enter title" />
        //         </div>
        //         <div className="d-flex flex-column mb-2">
        //           <label className="info-label">Start time</label>
        //           <TimePicker needConfirm={false} showNow={false} format={"HH:mm"} />
        //         </div>
        //         <div className="d-flex flex-column mb-3">
        //           <label className="info-label">End time</label>
        //           <TimePicker needConfirm={false} showNow={false} format={"HH:mm"} />
        //         </div>
        //         <div className="d-flex justify-content-end">
        //           <Button type="primary" className="primary-btn btn-small mt-0" onClick={close}>
        //             Create
        //           </Button>
        //         </div>
        //       </div>
        //     ),
        //     trigger: "click",
        //     placement: "rightTop",
        //   },
        //   separator: true,
        //   disabled: (weeklyScenarios?.length || 0) === 0 || showImportScenarios,
        // },
        {
          icon: Plus,
          iconProps: { width: 16 },
          tooltip: "Add Roster",
          onClick: addRoster,
          disabled: (weeklyScenarios?.length || 0) === 0 || showImportScenarios,
        },
        {
          icon: User,
          iconProps: { width: 14 },
          tooltip: "Driver allocation",
          popover: {
            title: "Driver allocation",
            content: (close) => <DriverAllocation close={close} />,
            trigger: "click",
            placement: "rightTop",
          },
          disabled: (selectedWorkItems?.length || 0) === 0 || isSingleSelectedWorkItemCompleted,
        },
        {
          icon: Bus,
          iconProps: { width: 16 },
          tooltip: "Vehicle allocation",
          popover: {
            title: "Vehicle allocation",
            content: (close) => <VehicleAllocation close={close} />,
            trigger: "click",
            placement: "rightTop",
          },
          disabled: (selectedWorkItems?.length || 0) === 0 || isSingleSelectedWorkItemCompleted,
        },
        {
          icon: Timer,
          iconProps: { width: 14 },
          tooltip: "Log Actuals",
          popover: {
            title: "Actuals",
            content: (close) => <Actuals close={close} />,
            trigger: "click",
            placement: "rightTop",
          },
          disabled: (selectedWorkItems?.length || 0) !== 1,
        },
        {
          icon: Close,
          iconProps: { width: 16 },
          tooltip: "Unallocate Item",
          onClick: handleUnallocate,
          disabled: (selectedWorkItems?.length || 0) === 0 || isSingleSelectedWorkItemCompleted,
        },
        {
          icon: Export,
          iconProps: { width: 13 },
          tooltip: "Export",
          onClick: () => exportScenario(),
          disabled: (weeklyScenarios?.length || 0) === 0 || showImportScenarios,
        },
      ],
    },
  ];

  return <SecondaryMenu menuGroups={secondaryMenuConfig} />;
};

export default React.memo(RosteringSecondaryMenu);
