import React, {useCallback, useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import ErrorBoundary from './components/ErrorBoundary';
import {AppContext} from './libs/contextLib';
import RoutesComponent from './RoutesComponent';
import './App.css';
import config from './config';
import ExportModal from './components/Export';
import {getPubOperator, setUserOperator, startFreeTrial} from './services/routeService';
import logoImage from './assets/images/busable-logo-1.svg';
import logoImage2 from './assets/images/busable-logo-2.svg';
import {ReactComponent as Import} from './assets/icons/Import.svg';
import {
    driverModelData,
    driverShiftModelData,
    operatorModelData,
    routeModelData,
    routesModelExpiryService,
    scheduleModelData,
    stopModelData,
    vehicleModelData
} from './services/ModelService';
import {GetFullUrl, getKey} from './libs/hooksLib';
import {Button, Image} from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import util from 'util';
import {MenuOutlined, PlusOutlined} from '@ant-design/icons';
import {Alert, Breadcrumb, Flex, Layout, Menu, message, notification, Typography} from 'antd';
import {ReactComponent as Marketplace} from './assets/icons/Marketplace.svg';
import {ReactComponent as Globe} from './assets/icons/Globe.svg';
import {ReactComponent as Explore} from './assets/icons/Explore.svg';
import {ReactComponent as SMS} from './assets/icons/SMS.svg';
import {ReactComponent as Services} from './assets/icons/Services.svg';
import {ReactComponent as Pin} from './assets/icons/Pin.svg';
import {ReactComponent as Calendar} from './assets/icons/Calendar.svg';
import {ReactComponent as Transfers, ReactComponent as Trackable} from './assets/icons/Transfers.svg';
import {ReactComponent as Rostering} from './assets/icons/Rostering.svg';
import {ReactComponent as HR} from './assets/icons/HR.svg';
import imgCharterable, {ReactComponent as BusSM} from './assets/icons/Bus_sm.svg';
import {ReactComponent as Timetable} from './assets/icons/Timetable.svg';
import {ReactComponent as Export} from './assets/icons/Export.svg';
import {ReactComponent as Settings} from './assets/icons/Settings.svg';
import {ReactComponent as Info} from './assets/icons/Info.svg';
import {ReactComponent as Help} from './assets/icons/Help.svg';
import {ReactComponent as Logout} from './assets/icons/Logout.svg';
import {ReactComponent as Switch} from './assets/icons/Switch.svg';
import {ReactComponent as User} from './assets/icons/user.svg';
import imgPlanable from './assets/icons/Planable.png';
import imgRosterable from './assets/icons/Rosterable.png';
import imgPublishable from './assets/icons/Publishable.png';
import imgTrackable from './assets/icons/Mapable.png';
import Hotjar from '@hotjar/browser';
import ReactGA from 'react-ga4';
import FaultModal from './components/FaultModal';
import store from 'store2';
import Features from './model/features';
import {values} from 'lodash/object';
import AdminModal from './components/Admin';
import {ReactComponent as ResetIcon} from './assets/icons/ResetCache.svg';
import {getEditor} from './hooks/getEditor';
import {checkOnline} from './libs/errorLib';
import dayjs from './dayjs';
import useEditor from './hooks/useEditor';
import useAllComments from './hooks/useAllComments';
import {ChatContext} from './model/Comment';
import GtfsImportModal from './features/Import/GtfsImportModal';

const {Header, Content, Sider} = Layout;

export const checkAuth = async (setFaultState) => {
    console.log('Checking fault lines...');
    try {
        if (process.env.REACT_APP_STAGE !== 'local') {
            if (!await checkOnline()) {
                return setFaultState(state => state.type === 'offline' ? state : {type: 'offline'});
            }

            const user = await Auth.currentUserInfo();
            if (user?.attributes) {
                // if (user.attributes["custom:operatorId"] !== operatorId) {
                //     console.log('Region has changed from %s to %s', operatorId, user.attributes["custom:operatorId"])
                //     // region changed in session
                //     setFaultState({type: 'refresh'})
                // }
                return setFaultState(state => state.type === 'none' ? state : {type: 'none'});
            } else {
                console.log('User has been logged out. User: ', user);
                // Not logged in, show logged out modal.
                return setFaultState(state => state.type === 'loggedOut' ? state : {type: 'loggedOut'});
            }
        }

    } catch (e) {
        console.log('Fault check error: ', e);
        return setFaultState(state => state.type === 'offline' ? state : {type: 'offline'});
    }
};

export const checkFeatureAuth = async (setFaultState, operator, feature, onCancel) => {

    await checkAuth(setFaultState);
    try {
        const user = await Auth.currentUserInfo();
        if (user?.attributes?.['custom:isAdmin'] === 'true') {
            return true;
        }
        // if logged in, then check feature access
        if (!operator?.features || !operator.features.access(feature)) {
            if (feature.trial && !operator.features.isInTrialPeriod(feature.key)) {
                // Offer trial period modal
                setFaultState({
                    type: 'trial', feature, operator, onClick: async () => {
                        operator.features = new Features(await startFreeTrial(operator, feature.key));
                    }, onCancel
                });
            } else {
                // no access and trial over show subscription modal.
                setFaultState({type: 'subscription', feature, onClick: onCancel, onCancel});
            }
            return false;
        }
        // if access granted, then proceed
        return true;
    } catch (e) {
        console.log('Action error: ', e);
        return false;
    }
};

function getItem(label, key, icon, className, children) {
    return {
        key,
        icon,
        children,
        label,
        className
    };
}

function getFeatureFlaggedItem(operator, label, key, icon, className, children) {
    if (!operator) return;

    if (operator.features.all || operator.features.beta || operator.features[key.toLowerCase()])
        return {
            key,
            icon,
            children,
            label,
            className
        };
}

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <Button variant={'link'} className="region-toggle" ref={ref} onClick={(e) => {
        e.preventDefault();
        onClick(e);
    }}>
        {children}
    </Button>
));
const CustomMenu = React.forwardRef(
    ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
        // eslint-disable-next-line
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                {/* <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Find Region..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          /> */}
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const getSubMenu = (pathname, operator, isAdmin) => {
    if (!pathname?.length || !pathname.split('/').length) {
        return 'Planable';
    }

    // for optibus operators, show charterable only
    if (!isAdmin && operator?.opts?.external?.optibus?.apiKey){
      return "Charterable"
    }

    switch (pathname.split('/')[1]) {
        case 'services':
        case 'points':
        case 'calendars':
        case 'transfers':
            return 'Planable';
        case 'charters':
        case 'customers':
            return 'Charterable';
        case 'shiftbats':
        case 'rostering':
            return 'Rosterable';
        case 'trip_planner':
        case 'timetables':
        case 'school_list':
        case 'venue_list':
            return 'Publishable';
        case 'hrm':
        case 'fleet':
        case 'students':
            return 'Manageable';
        case 'marketplace':
        case 'sms':
            return 'Apps';
        case 'settings':
            return 'Settings';
        case 'fleet_monitoring':
            return 'Trackable';
        case 'rostering':
        default:
            return null;
    }
};

const getMenu = (pathname) => {
    if (!pathname?.length) {
        return 'Services';
    }
    switch (pathname.split('/')[1]) {
        case 'services':
            return 'Services';
        case 'points':
            return 'Points';
        case 'calendars':
            return 'Calendars';
        case 'transfers':
            return 'Transfers';
        case 'charters':
            return 'Charters';
        case 'customers':
            return 'Customers';
        case 'shiftbats':
            return 'Driver Duties';
        case 'trip_planner':
            return 'Journey Planner';
        case 'timetables':
            return 'Service Timetables';
        case 'school_list':
            return 'School Timetables';
        case 'venue_list':
            return 'Venue Timetables';
        case 'hrm':
            return 'Staff';
        case 'fleet':
            return 'Fleet';
        case 'vehicleTypes':
            return 'Vehicle Types';
        case 'students':
            return 'Students';
        case 'marketplace':
            return 'Marketplace';
        case 'sms':
            return 'SMS';
        case 'rostering':
            return 'Rosters';
        case 'settings':
            return 'Settings';
        case 'jobs':
            return 'Jobs';
        case 'fleet_monitoring':
            return 'Real-Time Fleet Tracking';
        default:
            return 'Services';
    }
};

export const APP_VERSION = '3.10.2';

export default function App(props) {

    const version = APP_VERSION;
    const release_notes = 'https://help.busable.app/whats-new';
    const history = useHistory();
    const location = useLocation();
    const fullUrl = GetFullUrl();
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState(null);
    const [givenName, setGivenName] = useState(null);
    const [familyName, setFamilyName] = useState(null);
    const [schedules, setSchedules] = useState(null);
    const [driverShifts, setDriverShifts] = useState(null);
    // const [drivers, setDrivers] = useState({})
    const [vehicles, setVehicles] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [operator, setOperator] = useState(null);
    const [allowedOperators, setAllowedOperators] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [features, setFeatures] = useState({});
    //const [noLogo, setNoLogo] = useState(false)
    const [isPublic] = useState(location.pathname.includes('/timetable') ||
        location.pathname.includes('/trip_planner') || location.pathname.includes('/school_list') ||
        location.pathname.includes('/venue_list') || location.pathname.includes('/p_sb'));

    const [pageTitle, setPageTitle] = useState(getMenu(location.pathname));
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState(null);
    const [items, setItems] = useState(null);

    const [collapsed, setCollapsed] = useState(false);

    const [faultState, setFaultState] = useState({type: 'none', feature: null});

    const [messageApi, contextHolder] = message.useMessage();

    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [faultModalVisible, setFaultModalVisible] = useState(false);

    const [newVersion, setNewVersion] = useState(false);
    const [jwtToken, setJwtToken] = useState(null);

    const [editor, setEditor] = useEditor({user});
    const [chatOpen, setChatOpen] = useState(false);
    const [chatContext, setChatContext] = useState(new ChatContext({type: 'all'}));
    const {allRouteComments, allStopComments} = useAllComments();

    const handleLogout = useCallback(() => {
        Auth.signOut().then(() => {
            routesModelExpiryService.deinit();

            routeModelData.clear();
            stopModelData.clear();
            scheduleModelData.clear();
            vehicleModelData.clear();
            driverModelData.clear();
            userHasAuthenticated(false);
            setSchedules(null);
            setApiKey(null);
            setEmail(null);
            setUser(null);
            setGivenName(null);
            setFamilyName(null);
            setFeatures(features => {
                features?.revoke && features.revoke();
                return null;
            });
            setOperator(null);
            setAllowedOperators(null);
            setIsAdmin(false);
        }).then(() => {
            console.log('Logged out.');
            routesModelExpiryService.deleteAllDbs().then(() => {
                window.location.reload();
            });
        });
    }, [userHasAuthenticated, setSchedules, setApiKey, setUser, setEmail, setGivenName, setFamilyName, setFeatures,
        setOperator, setAllowedOperators, setIsAdmin]);

    useEffect(() => {
        const getTokenAndRedirect = async () => {
            try {
                const session = await Auth.currentSession();
                const idToken = session.getIdToken().getJwtToken();
                setJwtToken(idToken);

            } catch (error) {
                console.error('Error getting token:', error);
            }
        };

        // Example: Redirect on some condition or event
        getTokenAndRedirect();
    }, []);

    useEffect(() => {
        document.body.classList.add('busable-app');

        async function handleRefresh() {
            window.location.reload();
        }

        if (!schedules || !operator || !setFaultState || !handleLogout) {
            if (isAdmin) {
                setItems([getItem(<AdminModal allowedOperators={allowedOperators} isAdmin={isAdmin}
                                              apiKey={apiKey} messageApi={messageApi}/>, 'Admin', <Settings/>),
                    getItem(<Nav.Link onClick={handleLogout}>{givenName || 'Logout'}
                        <Logout/></Nav.Link>, 'Logging out...', <div
                        className="user-image"><User/></div>)]);
            }
            return;
        }

        // Check if operator has Optibus integration
        const hasOptibusIntegration = operator?.opts?.external?.optibus?.apiKey;

        if (hasOptibusIntegration && !isAdmin) {
            // Show only Charterable menu and essential items for Optibus-integrated non-admin users
            setItems([
                getItem('Charterable', 'Charterable', <img src={imgCharterable} alt=""/>, '', [
                    getItem(<LinkContainer to="/charters"
                                           className="badge-beta"><Nav.Link>Charters</Nav.Link></LinkContainer>, 'Charters',
                        <BusSM/>),
                    getItem(<LinkContainer to="/customers"
                                           className="badge-beta"><Nav.Link>Customers</Nav.Link></LinkContainer>, 'Customers',
                        <HR/>),
                    getItem(<LinkContainer to="/points"><Nav.Link>Points</Nav.Link></LinkContainer>, 'Points', <Pin/>),
                ]),
                getItem(<Nav.Link onClick={handleRefresh}>Refresh</Nav.Link>, 'Refresh', <ResetIcon/>, 'MenuBottom'),
                getItem(<a href="https://help.busable.app/" target="_blank" rel="noreferrer noopener">
                        <span className="menu-version">Help Centre<span>Learn Busable</span></span></a>, 'Help',
                    <Help/>, 'MenuBottom'),
                getItem(<a href={release_notes} target="_blank" rel="noreferrer noopener">
                        <span className="menu-version">What's New<span>version {version}</span></span></a>, 'What\'s New',
                    <Info/>, 'MenuBottom'),
                getItem(<Nav.Link onClick={handleLogout}>{givenName || 'Logout'} <Logout/></Nav.Link>, 'Logging out...',
                    <div className="user-image"><User/></div>, 'MenuBottom'),
            ]);
        } else {
            // Show all menu items for non-Optibus operators or admin users
            setItems([
                getItem('Planable', 'Planable', <img src={imgPlanable} alt=""/>, '', [
                    getItem(<LinkContainer to="/services"><Nav.Link>Services</Nav.Link></LinkContainer>, 'Services',
                        <BusSM/>),
                    getItem(<LinkContainer to="/points"><Nav.Link>Points</Nav.Link></LinkContainer>, 'Points', <Pin/>),
                    getItem(<LinkContainer to="/calendars"><Nav.Link>Calendars</Nav.Link></LinkContainer>, 'Calendars',
                        <Calendar/>),
                    getItem(<LinkContainer to="/transfers"><Nav.Link>Transfers</Nav.Link></LinkContainer>, 'Transfers',
                        <Transfers/>),
                ]),
                getItem('Charterable', 'Charterable', <img src={imgCharterable} alt=""/>, '', [
                    getItem(<LinkContainer to="/charters"
                                           className="badge-beta"><Nav.Link>Charters</Nav.Link></LinkContainer>, 'Charters',
                        <BusSM/>),
                    getItem(<LinkContainer to="/customers"
                                           className="badge-beta"><Nav.Link>Customers</Nav.Link></LinkContainer>, 'Customers',
                        <HR/>),
                ]),
                getItem('Rosterable', 'Rosterable', <img src={imgRosterable} alt=""/>, '', [
                    getItem(<LinkContainer to="/shiftbats"><Nav.Link>Driver
                            Duties</Nav.Link></LinkContainer>, 'Driver Duties',
                        <Rostering/>),
                    getItem(<LinkContainer
                            to="/rostering"
                            className="badge-beta"><Nav.Link>Allocations</Nav.Link></LinkContainer>, 'Rosters',
                        <Calendar/>),
                ]),
                getItem('Trackable', 'Trackable', <img src={imgTrackable} alt=""/>, '', [
                    getItem(<LinkContainer to="/fleet_monitoring" className="badge-beta"><Nav.Link>Fleet Tracking
                        </Nav.Link></LinkContainer>, 'Fleet Monitoring',
                        <Trackable/>),
                ]),
                getItem('Publishable', 'Publishable', <img src={imgPublishable} alt=""/>, '', [
                    getItem(<LinkContainer to={`/trip_planner`}><Nav.Link>Journey
                        Planner</Nav.Link></LinkContainer>, 'Journey Planner', <Services/>),
                    getItem(<LinkContainer to={`/timetables`}><Nav.Link>Service
                        Timetables</Nav.Link></LinkContainer>, 'Service Timetables', <BusSM/>),
                    getItem(<LinkContainer to={`/school_list`}><Nav.Link>School
                        Timetables</Nav.Link></LinkContainer>, 'School Timetables', <Timetable/>),
                    getItem(<LinkContainer to={`/venue_list`}><Nav.Link>Venue
                        Timetables</Nav.Link></LinkContainer>, 'Venue Timetables', <Timetable/>),
                ]),
                (operator.features.beta || operator.features.all || operator.features.manageable) && getItem('Manageable', 'Manageable',
                    <img
                        src={imgRosterable} alt=""/>, '', [
                        getItem(<LinkContainer to="/hrm"><Nav.Link>Staff</Nav.Link></LinkContainer>, 'Staff', <HR/>),
                        getItem(<LinkContainer to="/vehicleTypes" className="badge-beta"><Nav.Link>Vehicle
                            Types</Nav.Link></LinkContainer>, 'Vehicle TYpes', <BusSM/>),
                        getItem(<LinkContainer to="/fleet"
                                               className="badge-beta"><Nav.Link>Fleet</Nav.Link></LinkContainer>, 'Fleet',
                            <BusSM/>),
                        getItem(<LinkContainer to="/hrm"
                                               className="badge-beta"><Nav.Link>Depot</Nav.Link></LinkContainer>, 'Depot',
                            <Pin/>),
                        getItem(<LinkContainer to="/students"><Nav.Link>Students</Nav.Link></LinkContainer>, 'Students',
                            <HR/>),
                    ]),
                (operator.features.beta || operator.features.all || operator.features.apps) && getItem('Apps', 'Apps',
                    <Marketplace/>, '', [
                        getItem(<LinkContainer
                                to="/marketplace"><Nav.Link>Explore</Nav.Link></LinkContainer>, 'Explore',
                            <Explore/>),
                        getItem(<LinkContainer to="/sms"><Nav.Link>SMS</Nav.Link></LinkContainer>, 'SMS', <SMS/>),
                        getItem(<a
                            href={`${config.cms.URL}/sso/cognito/callback?token=${jwtToken}&firstName=${operator.operatorName}&companyId=${operator.companyId}`}
                            target="_blank" rel="noreferrer">
                            <span className="menu-version">Website CMS</span>
                        </a>, 'CMS', <Globe/>),
                    ]),
                getItem('Settings', 'Settings', <Settings/>, 'MenuBottom', [
                    getItem(<LinkContainer to="/settings"><Nav.Link>Account</Nav.Link></LinkContainer>, 'Account',
                        <Settings/>),
                    getItem(<GtfsImportModal operator={operator} messageApi={messageApi}/>, 'Import', <Import/>),
                    getItem(<ExportModal schedules={schedules} operator={operator}
                                         setFaultState={setFaultState} notificationApi={notificationApi}
                                         messageApi={messageApi} isAdmin={isAdmin}/>, 'Export', <Export/>),
                    (isAdmin) && getItem(<AdminModal allowedOperators={allowedOperators} isAdmin={isAdmin}
                                                     apiKey={apiKey} messageApi={messageApi}/>, 'Admin', <Settings/>),
                ]),
                getItem(<Nav.Link onClick={handleRefresh}>Refresh</Nav.Link>, 'Refresh', <ResetIcon/>, 'MenuBottom'),
                getItem(<a href="https://help.busable.app/" target="_blank" rel="noreferrer noopener">
                        <span className="menu-version">Help Centre<span>Learn Busable</span></span></a>, 'Help',
                    <Help/>, 'MenuBottom'),
                getItem(<a href={release_notes} target="_blank" rel="noreferrer noopener">
                        <span className="menu-version">What's New<span>version {version}</span></span></a>, 'What\'s New',
                    <Info/>, 'MenuBottom'),
                getItem(<Nav.Link onClick={handleLogout}>{givenName || 'Logout'} <Logout/></Nav.Link>, 'Logging out...',
                    <div className="user-image"><User/></div>, 'MenuBottom'),
            ]);
        }
    }, [setItems, schedules, operator, setFaultState, handleLogout, isAdmin, messageApi, allowedOperators, apiKey,
        givenName, version, jwtToken]);

    const rootSubmenuKeys = ['Planable', 'Charterable', 'Rosterable', 'Publishable', 'Manageable', 'Apps', 'Settings'];

    const [openKeys, setOpenKeys] = useState(['Planable']);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onMenuSelect = useCallback((item) => {
        console.log('MENU SLELECT: ', item);
        setBreadcrumbs(breadcrumbs => ['Export', 'What\'s New', 'Help', 'Logging out...'].includes(item.key) ? breadcrumbs : [...item.keyPath].reverse());
        setBreadcrumb(null);
        setPageTitle(pageTitle => ['Export', 'What\'s New', 'Help', 'Logging out...'].includes(item.key) ? pageTitle : item.key);
    }, [setBreadcrumbs, setBreadcrumb, setPageTitle]);

    useEffect(() => {
        setBreadcrumbs([getSubMenu(location.pathname, operator, isAdmin),
            <Link to={`/${location.pathname.split('/')[1]}`}>{getMenu(location.pathname)}</Link>]);
        setBreadcrumb(null);
        setOpenKeys([getSubMenu(location.pathname, operator, isAdmin)]);
        setPageTitle(getMenu(location.pathname));
    }, [location, setBreadcrumb, setBreadcrumbs, setOpenKeys, setPageTitle, operator, isAdmin]);

    useEffect(() => {
        if (isAuthenticating) {
            return;
        }
        if (isAuthenticated) {
            const interval = setInterval(() => {
                if (!faultModalVisible) {
                    checkAuth(setFaultState, apiKey).then(() => console.log('Fault lines checked.'));
                }
            }, 30 * 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [setFaultState, faultState, apiKey, isAuthenticating, isAuthenticated, faultModalVisible]);

    useEffect(() => {

        // console.log('isAuthenticating: %s, isPublic: %s, isAuthenticated: %s, local: %s', isAuthenticating, isPublic, isAuthenticated, config.local)

        async function onLoad() {

            console.log('Loading Busable v' + version);
            const currentVersion = store.local.get('busable-version');
            if (!isPublic && !location.pathname.includes('/login') && !location.pathname.includes('/logout') && currentVersion !== version) {
                setNewVersion(true);
                store.local.set('busable-version', version);
            }
            try {

                let user = {attributes: {email: 'local@local.com', given_name: 'Local', family_name: 'Dog'}};
                let isAdmin = config.local, authenticated = config.local;
                if (!config.local) {
                    await Auth.currentSession();
                    user = await Auth.currentUserInfo();
                    if (!user?.attributes) {
                        setIsAuthenticating(false);
                        return;
                    }
                    authenticated = true;
                    isAdmin = user.attributes['custom:isAdmin'] === 'true';
                }

                let _key = await getKey();
                if (!config.local && user?.attributes['custom:operatorId'] !== _key &&
                    (!user?.attributes['custom:allowedOperators'] || (user?.attributes['custom:allowedOperators'] !== 'all' &&
                        !user?.attributes['custom:allowedOperators'].split(',').includes(_key)))) {
                    store.session.remove('busable-operator-id');
                    _key = user?.attributes['custom:operatorId'];
                }

                const {editor} = getEditor({user});

                await routesModelExpiryService.init({apiKey: _key, user, editor, messageApi, setFaultState});
                const _setOperator = operator => {
                    setOperator(operator);
                    setFeatures(operator.features);
                    if (isAdmin) {
                        operator.features.all = true;
                    }

                    if (operator?.opts?.snappy?.optOut) {
                        console.log('Opting out of snappy mode.');
                        routesModelExpiryService.optOutSuperFast();
                    }

                    console.log('Super snappy: ', !operator?.opts?.snappy?.optOut);

                    config.operator = operator;
                    console.log(`OPERATOR: `, operator);
                    console.log(`FEATURES: `, util.inspect(operator.features));
                    if (!config.local && user?.attributes['custom:operatorId'] !== _key) {
                        setUserOperator(operator).then(() => console.log('User operator set to ', _key));
                    }
                };
                let operator;
                if (!authenticated) {
                    console.log('Unauthenticated.. get public op.');
                    operator = await operatorModelData.get(_key);
                } else {
                    console.log('Authenticated.. get ops.');
                    operatorModelData.addListener({
                        id: 'appOperatorListener', loaded: operators => {
                            console.log('Allowed operators: ', operators);
                            setAllowedOperators(values(operators).sort((a, b) => (a.operatorName || '').localeCompare(b.operatorName || '')));
                            operator = operators[_key];
                            try {
                                _setOperator(operator);
                            } catch (e) {
                                console.log(e, e);
                                // if (user?.attributes['custom:allowedOperators']?.length) {
                                //     _key = user?.attributes['custom:allowedOperators'].split(',')[0];
                                //     getOperator(_key).then(_setOperator);
                                // }
                            }
                        }
                    });
                    // const operators = await operatorModelData.getAll(_key)
                    // setAllowedOperators(values(operators).sort((o1, o2) => o1.operatorName.localeCompare(o2.operatorName)));
                    // console.log('All operators: ', operators)
                    // try {
                    //     operator = operators[_key];
                    // } catch (e) {
                    //     if (user?.attributes['custom:allowedOperators']?.length) {
                    //         _key = user?.attributes['custom:allowedOperators'].split(',')[0];
                    //         operator = await getOperator(_key);
                    //     }
                    // }
                }

                console.log(`Found key: ${_key}`);
                setApiKey(_key);

                store.session.set('busable-operator-id', _key);

                // console.log(`OPERATOR: `, operator);
                // console.log(`FEATURES: `, util.inspect(operator.features));

                // setOperator(operator)
                // setFeatures(operator.features);
                // operator.features.all = isAdmin


                // dutyModelExpiryService.init(_key).then(() => console.log(`dutyModelExpiryService started.`))
                // routeModelData.getAll(key).then(() => console.log(`RouteModelData ready.`))
                // stopModelData.getAll(key).then(() => console.log(`StopModelData ready.`))
                // await Promise.all([await modelExpiryService.init(), await routeModelData.get(key), await stopModelData.get(key)])

                scheduleModelData.addListener({
                    id: 'appScheduleListener', setterFn: setSchedules, loaded: schedules => {
                        setSchedules(schedules);
                    }
                });
                // let schedules = await scheduleModelData.getAll(_key)
                // if (schedules) {
                //     console.log('schedule count:', Object.keys(schedules).length);
                //     setSchedules(schedules)
                // }

                // let user = {attributes: {email: 'graham@cortexus.com.au'}}
                if (!config.local) {
                    setUser(user);
                    setEmail(user.attributes['email']);
                    setGivenName(user.attributes['given_name']);
                    setFamilyName(user.attributes['family_name']);
                    driverShiftModelData.addListener({id: 'appDriverShiftListener', loaded: setDriverShifts});
                    vehicleModelData.addListener({id: 'appVehicleModelData', loaded: setVehicles});
                    // setDriverShifts(keyBy(await driverShiftModelData.getAll(_key), 'shiftId'))
                    // setDrivers(keyBy(await driverModelData.getAll(_key), 'driverId'))
                    // setVehicles(keyBy(await vehicleModelData.getAll(_key), 'vehicleId'))
                    if (!isAdmin) {
                        await startAnalytics();
                    }
                }
                setIsAdmin(isAdmin);
                userHasAuthenticated(authenticated);

            } catch (e) {
                if (!isPublic) {
                    console.log(`error loading app... ${e}`);
                    if (e.message?.includes('No Operator')) {
                        const sessionOpId = store.session.get('busable-operator-id');
                        if (sessionOpId?.length) {
                            store.session.remove('busable-operator-id');
                            return window.location.reload();
                        }
                        Auth.signOut().then(() => console.log('Signed out due to ', e));
                        messageApi.error('There is something wrong with your user account. Please contact us for help.', 60);
                    }

                    await Auth.signOut();
                    history.push("/login");
                } else {
                    let _key = await getKey();
                    // if (_key && location.pathname.includes('/timetables')) {
                    //     await routesModelExpiryService.init({
                    //         apiKey: _key,
                    //         messageApi,
                    //         setFaultState,
                    //         modelFilter: ['RouteComment', 'StopComment', 'Stop', 'Transfer', 'Route', 'Schedule']
                    //     })
                    if (_key) {
                        const operator = await getPubOperator(_key);
                        setOperator(operator);
                        config.operator = operator;
                    }
                }
                // history.push("/login");
                // throw e
                // if (isAdmin) {
                //     onError(e);
                // }
                // handleLogout();
            }
            setIsAuthenticating(false);

        }

        onLoad().then(() => console.log('App loaded...')).catch(e => {
            console.error(e)
            history.push('/login');
        });

        return () => {
            routesModelExpiryService.deinit();
            scheduleModelData.removeListener('appScheduleListener');
        };
    }, [setSchedules, setFeatures, isPublic, setApiKey, setIsAuthenticating, setVehicles, setUser, setEmail, setGivenName,
        setFamilyName, setDriverShifts, history, handleLogout, messageApi, version]);

    async function handleSwitchRegion(operator) {
        console.log('Setting operator: ', operator.operatorId);
        try {
            window.location.replace(`/switch/${operator.companyId}/${operator.operatorId}`);
        } catch (e) {
            console.log(e);
        }
    }

    async function startAnalytics() {
        console.log('Starting analytics...');
        /* -- Initialise Hotjar behaviour analytics -- */
        const siteId = 3470107;
        const hotjarVersion = 6;

        Hotjar.init(siteId, hotjarVersion);

        // Initializing with `debug` option:
        // Hotjar.init(siteId, hotjarVersion, {
        //     debug: true
        // });

        let user = await Auth.currentUserInfo();
        let userId = user.attributes['email'];

        Hotjar.identify(userId, {
            email: userId,
        });

        /* -- Initialise Google Analytics GA4 -- */
        ReactGA.initialize('G-B0S037Y0L9');
    }

    const hideMenu = useCallback(() => {
        return isPublic && !isAuthenticated;
    }, [isPublic, isAuthenticated]);

    const showHeader = useCallback(() => {
        return () => {
            location.pathname.includes('/login');
            location.pathname.includes('/trial_14d');
            location.pathname.includes('/trial_30d');
        };
    }, [location]);

    useEffect(function ensureCharterOnlyForOptibus() {
        if (!isAdmin && operator?.opts?.external?.optibus?.apiKey &&
            !window.location.pathname.includes('charters') &&
            !window.location.pathname.includes('customers') &&
            !window.location.pathname.includes('points')) {
            window.location.replace('/charters');
        }
    }, [isAdmin, operator]);

    return (
        // !isAuthenticating ? (
        <div>
            {contextHolder}
            {notificationContextHolder}
            <FaultModal {...faultState} visible={faultModalVisible} setVisible={setFaultModalVisible}/>
            <Layout hasSider={!hideMenu()} className="app-main">
                {!hideMenu() && ((features && operator) || isAdmin) ? (
                    <>
                        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
                               className="app-sider"
                               collapsedWidth="66" width="235">
                            <div className="sider-logo"><Navbar.Brand>
                                <Image
                                    src={logoImage}
                                    height="28"
                                    className="d-inline-block align-center"
                                /><Image
                                src={logoImage2}
                                height="18"
                                className="d-inline-block align-center"
                            />{' '}
                            </Navbar.Brand></div>
                            <Menu theme="dark" defaultSelectedKeys={[pageTitle]} mode="inline" items={items}
                                  openKeys={openKeys} onOpenChange={onOpenChange} onSelect={onMenuSelect}/>
                            <Dropdown className="region-dropdown">
                                <Dropdown.Toggle
                                    as={CustomToggle}><Switch/><span>{operator?.operatorName || 'No region'}</span><i
                                    className="menu-icon-right"></i></Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    {isAdmin &&
                                        <Dropdown.Item href="/new_operator" className="new-region"
                                                       title="New Region"><PlusOutlined/> Add
                                            Region</Dropdown.Item>
                                    }

                                    {allowedOperators?.length > 1 && allowedOperators.filter(operator => operator.operatorId !== apiKey).map(operator => (
                                        <Dropdown.Item key={`OpRegionDropdown-${operator.operatorId}`}
                                                       onClick={() => handleSwitchRegion(operator)}
                                                       variant="light">{operator.operatorName}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Sider>
                    </>
                ) : <></>}
                <Layout className="app-layout">
                    {newVersion ? <Flex justify={'space-around'}><Alert
                        message={<Typography.Text>New version <a href={release_notes} target="_blank"
                                                                 rel="noreferrer noopener">
                            {version}</a> available. Click <a href={'/refresh'}>here</a> to update. Please contact <a
                            href={`mailto:support@busable.app?subject=Busable%20version%20issue%20${version}%20${encodeURIComponent(dayjs().format('lll'))}`}
                        >support</a> for any issues.</Typography.Text>} type="success" closable/></Flex> : <></>}
                    <Header className="d-flex app-header">
                        {!hideMenu() && features && operator ? (
                                <>
                                    <div className="mr-4">
                                        <h1 className="page-title">{pageTitle}</h1>
                                        <Breadcrumb className="app-breadcrumb">
                                            {process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE !== 'prod' &&
                                                <Breadcrumb.Item
                                                    key={'Op_Stage_bc'}>
                                                    <span style={{color: 'red'}}>
                                                    <strong>{process.env.REACT_APP_STAGE}</strong></span>
                                                </Breadcrumb.Item>
                                            }
                                            <Breadcrumb.Item
                                                key={'Op_Name_bc'}>{operator.operatorName}</Breadcrumb.Item>
                                            {breadcrumbs.map(title => (
                                                <Breadcrumb.Item key={`${title}_bc`}>{title}</Breadcrumb.Item>))}
                                            {breadcrumb && <Breadcrumb.Item
                                                key={`${breadcrumb}_bc`}>{breadcrumb}</Breadcrumb.Item>}
                                        </Breadcrumb>
                                    </div>
                                    <Button
                                        className={`menu-sm-open ${collapsed ? 'menu-collapsed' : 'menu-expanded'}`}
                                        type="primary"
                                        onClick={() => setCollapsed(!collapsed)}
                                    ><MenuOutlined/></Button>
                                </>
                            )
                            : !hideMenu() && showHeader() ?
                                <div className="public-header">
                                    <Image src={logoImage} height="28" className="d-inline-block align-center"/>
                                    <Image src={logoImage2} height="18" className="d-inline-block align-center"/>
                                </div>
                                : <></>
                        }
                    </Header>

                    <Content className="app-content">
                        <div className={`App ${hideMenu() ? 'publicContainer' : 'container'}`}>
                            <ErrorBoundary>
                                <AppContext.Provider
                                    value={{
                                        user,
                                        email,
                                        givenName,
                                        familyName,
                                        isAuthenticated,
                                        userHasAuthenticated,
                                        schedules,
                                        setSchedules,
                                        apiKey,
                                        operator,
                                        setOperator,
                                        setApiKey,
                                        fullUrl,
                                        features,
                                        setFeatures,
                                        setAllowedOperators,
                                        isAdmin,
                                        driverShifts,
                                        vehicles,
                                        setPageTitle,
                                        setBreadcrumb,
                                        setFaultState,
                                        handleLogout,
                                        messageApi,
                                        notificationApi,
                                        isPublic,
                                        allRouteComments,
                                        allStopComments,
                                        editor,
                                        setEditor,
                                        chatContext,
                                        setChatContext,
                                        setChatOpen
                                    }}>

                                    {/*<ChatModal visible={chatOpen} setVisible={setChatOpen} />*/}
                                    <RoutesComponent isAuthenticating={isAuthenticating} setChatContext={setChatContext}
                                                     setChatOpen={setChatOpen} operator={operator}/>
                                </AppContext.Provider>
                            </ErrorBoundary>
                            {!hideMenu() &&
                                <CookieConsent
                                    location="bottom"
                                    buttonText="Accept"
                                    cookieName="acceptCookiesCookie"
                                    style={{
                                        backgroundColor: '#6c5cf6', fontFamily: 'Arial, Helvetica, sans-serif',
                                        textShadow: '1px 1px #333'
                                    }}
                                    buttonStyle={{
                                        color: '#333',
                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                        fontSize: '13px',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        border: '1px solid #333'
                                    }}
                                    expires={150}>This website uses cookies to improve the site and enhance the user
                                    experience.</CookieConsent>}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
        // ) : <div>
        //     {/*<div className="lander">*/}
        //     {/*    <h1>Bus Transport Software</h1>*/}
        //     {/*    <h5>Flexible end-to-end bus transport software.</h5>*/}
        //     {/*    <h5>Plan, schedule, publish and enable your bus services easily anywhere, anytime.</h5>*/}
        //     {/*    <div className="pt-3">*/}
        //     {/*        /!* <Link to="/login" className="btn btn-primary btn-lg mr-3">*/}
        //     {/*            Login*/}
        //     {/*        </Link> *!/*/}
        //     {/*        /!*<Link to="/signup" className="btn btn-success btn-lg">*!/*/}
        //     {/*        /!*  Signup*!/*/}
        //     {/*        /!*</Link>*!/*/}
        //     {/*    </div>*/}
        // <LoadMessage size={"lg"}/>
        // {/*</div>*/}
        // </div>
    );
}
