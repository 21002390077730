import dayjs from "../dayjs";

export const TimeFilterType = {NOW: 'Now', LEAVING: 'Departing', ARRIVING: 'Arriving', ALL: 'All'}

export const dayjsToSecsSinceMidnight = (m) => m ? m.diff(m.clone().startOf('day'), 'seconds') : null
// eslint-disable-next-line
export const secsSinceMidnightToDayjs = (s, date = dayjs()) => {
  const validDate = dayjs.isDayjs(date) ? date : dayjs(date);
  return Number.isFinite(s) && validDate && validDate?.startOf ? validDate?.startOf?.('day')?.add?.(s, 'seconds') : null;
};

export class TimeFilter {
    constructor(data) {
        this.type = TimeFilterType.NOW
        this.anyDate = false;
        this.startTime = dayjs();
        this.windowInSecs = 3600 * 24 * 7;
        this.stopIds = {};
        this.stops = {};
        Object.assign(this, data);
        this.startTime = this.startTime.clone();

    }

    clone() {
        return new TimeFilter(this);
    }

    setSchool() {
        this.type = TimeFilterType.ALL;
        this.anyDate = true;
        this.startTime = dayjs().startOf('day').hour(4);
        this.windowInSecs = 16 * 3600;
    }

    setSchoolAM(stopIds) {
        this.type = TimeFilterType.ARRIVING;
        this.anyDate = true;
        this.startTime = dayjs().startOf('day').hour(9).minute(30);
        this.windowInSecs = 3600 * 2; // two hour buffer
        // this.stopIds = stopIds;
    }

    setSchoolPM(stopIds) {
        this.type = TimeFilterType.LEAVING;
        this.anyDate = true;
        this.startTime = dayjs().startOf('day').hour(14).minute(30);
        this.windowInSecs = 3600 * 2; // two hour buffer
        // this.stopIds = stopIds;
    }

    toString() {
        if (this.anyDate) {
            return this.toAnyDateString();
        } else if (this.type === TimeFilterType.NOW) {
            return 'Departing now'
        } else {
            return this.toFutureString();
        }
    }

    toAnyDateString() {
        return `School days ${this.type === TimeFilterType.ARRIVING ? '(am)' : this.type === TimeFilterType.ALL ? '(all)' : '(pm)'}`
    }

    toFutureString() {
        const daysDiff = this.startTime.clone().startOf('day').diff(dayjs().startOf('day'), 'days');
        const startTimeStr = this.startTime.format('HH:mm');
        const dateStr = daysDiff === 0 ? this.startTime.format('[today] (ddd)') : daysDiff === 1 ? this.startTime.format('[tomorrow] (ddd)') : this.startTime.format('Do MMM (ddd)');
        const result = `${this.type === TimeFilterType.ARRIVING ? 'Arr' : 'Dep'} ${startTimeStr}, ${dateStr}`
        console.log('Getting string: ', result, this.startTime.clone());
        return result
    }


    isValid(tripPlan) {

    }

    toWindow(anyDate) {
        switch (this.type) {
            case TimeFilterType.ARRIVING:
                return `${this.startTime.clone().subtract(this.windowInSecs, 's').format()}_${this.startTime.format()}`;
            case TimeFilterType.LEAVING:
            case TimeFilterType.NOW:
            default:
                return `${this.startTime.format()}_${this.startTime.clone().add(this.windowInSecs, 's').format()}`;
        }
    }

    toWindowArray() {
        return [this.getStartTime(), this.getEndTime()];
    }

    getEndTime() {
        switch (this.type) {
            case TimeFilterType.ARRIVING:
                return dayjsToSecsSinceMidnight(this.startTime);
            case TimeFilterType.LEAVING:
            case TimeFilterType.NOW:
            default:
                return dayjsToSecsSinceMidnight(this.startTime) + this.windowInSecs;

        }
    }

    getEndTimeAsDayJs() {
        switch (this.type) {
            case TimeFilterType.ARRIVING:
                return this.startTime.clone().add(1, 'd');
            case TimeFilterType.LEAVING:
            case TimeFilterType.NOW:
            default:
                return this.startTime.clone().add(this.windowInSecs, 's').clone();

        }
    }

    getStartTime() {
        switch (this.type) {
            case TimeFilterType.ARRIVING:
                return dayjsToSecsSinceMidnight(this.startTime) - this.windowInSecs;
            case TimeFilterType.LEAVING:
            case TimeFilterType.NOW:
            default:
                return dayjsToSecsSinceMidnight(this.startTime);
        }
    }

    getStartTimeAsDayJs() {
        switch (this.type) {
            case TimeFilterType.ARRIVING:
                return this.startTime.clone().subtract(this.windowInSecs, 's').clone();
            case TimeFilterType.LEAVING:
            case TimeFilterType.NOW:
            default:
                return this.startTime.clone();
        }
    }

    toParams() {
        return Object.keys(this.stopIds).map(rId => ({
            rId,
            stopId: this.stopIds[rId],
            school: this.anyDate,
            w: this.toWindow(),
            type: this.type
        }));
    }

    toStopParams() {
        return Object.keys(this.stops).map(rId => ({
            rId,
            stop: {
                stopId: this.stops[rId].stopId,
                startBell: this.stops[rId].startBell,
                startBellWindow: this.stops[rId].startBellWindow,
                endBell: this.stops[rId].endBell,
                endBellWindow: this.stops[rId].endBellWindow
            },
            school: this.anyDate,
            w: this.toWindow(),
            type: this.type
        }));
    }

    toTripParams() {
        return Object.keys(this.trips).map(rId => ({
            rId,
            t: this.trips[rId],
            school: this.anyDate,
            w: this.toWindow(),
            type: this.type
        }));
    }

    toQueryParams() {
        if (this.anyDate) {
            return `school|${this.startTime.hour() === 4 ? 'all' : this.startTime.hour() === 9 ? 'am' : 'pm'}`
        }
        return `${this.type.toLowerCase()}|${this.startTime.toISOString()}`
    }

    checkArrivalTime(direction) {
        return this.type === TimeFilterType.ARRIVING || (this.type === TimeFilterType.ALL && (this.anyDate ? ['AM'] : ['AM', 'Inbound']).includes(direction))
    }

    checkDepartureTime(direction) {
        return [TimeFilterType.LEAVING, TimeFilterType.NOW].includes(this.type) || (this.type === TimeFilterType.ALL && (this.anyDate ? ['PM'] : ['PM', 'Outbound']).includes(direction))
    }
}
