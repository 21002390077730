import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Charters.css';
import {charterModelData} from '../../services/ModelService';
import {Charter, CHARTER_STATUS} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import {Button, Card, Col, DatePicker, Flex, Input, InputNumber, Row, Select, Typography} from 'antd';
import CharterMenu from './CharterMenu';
import {isEmpty, startCase} from 'lodash';
import LoaderButton from '../../components/LoaderButton';
import {RefreshIcon, TrashIcon} from '../../components/Icons';
import {ReactComponent as Copy} from '../../assets/icons/Copy.svg';
import {secsSinceMidnightToDayjs} from '../../model/timeFilter';
import {DATE_STRING} from '../../model/schedule';
import {useAppContext} from '../../libs/contextLib';
import config from '../../config';
import {FilterTags} from '../../containers/FilterTag';
import {filterItems} from './Charters';
import {DEFAULT_VEHICLE_RATE} from '../../model/vehicleType';
import {DEFAULT_DRIVER_RATE} from '../../model/hrm/employee';
import useAllSchedules from '../../hooks/useAllSchedules';
import RouteTitle from '../../components/RouteTitle';
import LoadMessage from '../../components/LoadMessage';
import dayjs from '../../dayjs';
import CharterQuoteTemplate from './CharterQuoteTemplate';
import ReactDOM from 'react-dom';

const {TextArea} = Input;
const {Paragraph} = Typography;
const GST = 0.1;

const DEFAULT_LOADING = 30;
const DEFAULT_LINE_ITEM_DESCRIPTION = 'Vehicle + Driver (incl. fuel and maintenance)';

const editOnLoad = charter => !charter?.quote;

function CharterQuote() {
    const {operator} = useAppContext();
    const {allSchedules} = useAllSchedules();
    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({charterId: '_'}),
        editOnLoad
    });

    useEffect(() => {
        if(!charter.address1) {
            charter.address1 = charter.customer?.addressLine1;
        }
        if(!charter.address2) {
            charter.address2 = charter.customer?.addressLine2;
        }
        if (!charter.addressContact) {
            charter.addressContact = charter.customer?.email || charter.customer?.phone;
        }
        if (!charter.quoteDescription) {
            charter.quoteDescription = charter.note;
        }
    }, [charter]);

    const updateCharterStatus = useCallback(() => {
        const _charter = new Charter({...charter, status: 'sent'});
        charterModelData.save(_charter).then(() => {
            console.log('Charter saved.');
        });
    }, [charter]);

    const contentRef = useRef();
    const [mailData, setMailData] = useState({
        to: [],
        attachments: [],
        subject: `Invoice: Charter Request`,
        template: 'Charter: Invoice',
    });

    // Function to create a PDF file from the content
    //const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const createPDF = useCallback(async () => {
        //setIsGeneratingPdf(true);
        const content = contentRef.current;
        // Create a temporary container for the PDF template
        const tempContainer = document.createElement('div');
        // tempContainer.style.position = 'absolute';
        // tempContainer.style.left = '-9999px';
        tempContainer.style.width = '210mm';
        tempContainer.style.minHeight = '297mm';
        tempContainer.style.background = 'white';
        tempContainer.style.boxSizing = 'border-box';
        tempContainer.style.fontFamily = 'Arial, sans-serif';
        document.body.appendChild(tempContainer);
        
        // Render the template into the temporary container
        ReactDOM.render(<CharterQuoteTemplate charter={charter} allSchedules={allSchedules} operator={operator} />, tempContainer);
        
        // Wait for the next tick to ensure the template is rendered
        await new Promise(resolve => setTimeout(resolve, 1000)); // Increased wait time for better rendering
        
        try {
            // Remove all images from the container to avoid CORS issues
            const images = tempContainer.getElementsByTagName('img');
            Array.from(images).forEach(img => {
                img.remove();
            });

            const canvas = await html2canvas(tempContainer, {
                scale: 2, // Increased scale for better quality
                useCORS: true,
                logging: false,
                backgroundColor: '#ffffff',
                windowWidth: 793.92, // 210mm in pixels
                windowHeight: 1122.52, // 297mm in pixels
            });
            
            ReactDOM.unmountComponentAtNode(tempContainer);
            document.body.removeChild(tempContainer);
            
            const imgData = canvas.toDataURL('image/png', 1.0);
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            
            // Use A4 dimensions in mm
            const pageWidth = 210;
            const pageHeight = 297;
            
            // Calculate scaling to fit content while maintaining quality
            const scale = Math.min(pageWidth / (canvas.width / 3.78), pageHeight / (canvas.height / 3.78));
            const scaledWidth = (canvas.width / 3.78) * scale;
            const scaledHeight = (canvas.height / 3.78) * scale;
            
            // Center the content
            const x = (pageWidth - scaledWidth) / 2;
            const y = (pageHeight - scaledHeight) / 2;
            
            // Add the image to the PDF with centering
            pdf.addImage(
                imgData,
                'PNG',
                x,
                y,
                scaledWidth,
                scaledHeight
            );

            // Convert PDF to blob to attach to emails or handle as needed
            const pdfBlob = pdf.output('blob');
            const pdfFile = new File([pdfBlob], `quote.pdf`, {type: 'application/pdf'});
            console.log('PDF created: ', pdfFile.size, 'bytes');

            setMailData((mail) => ({
                ...mail,
                attachments: [pdfFile],
            }));
            return pdfFile;
        } catch (error) {
            console.error('Error generating PDF:', error);
            ReactDOM.unmountComponentAtNode(tempContainer);
            document.body.removeChild(tempContainer);
            throw error;
        }
    }, [charter, allSchedules]);

    const onDownloadClicked = useCallback(async () => [await createPDF()], [createPDF]);

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({...charter});
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    useEffect(() => {
        if (!allSchedules || charter.quote || !charter.duties?.length) {
            return;
        }
        charter.quote = {};
        charter.duties.forEach(duty => {
            charter.quote[duty.shiftBatId] = {
                distance: Math.ceil(duty.getShiftDistance() / 1000),
                duration: Math.ceil(duty.getShiftTime() / 3600),
                driverRate: DEFAULT_DRIVER_RATE,
                vehicleRate: duty?.vehicleType?.rate || DEFAULT_VEHICLE_RATE,
                loading: DEFAULT_LOADING,
                days: duty.getRunningDates(allSchedules, dayjs()).length,
            };
        });

        if (!charter.quoteLineItems?.length) {
            charter.quoteLineItems = [{
                name: charter.name,
                description: DEFAULT_LINE_ITEM_DESCRIPTION,
                unitPrice: charter.calculateQuote(),
                quantity: 1,
                discount: 0,
            }];
        }
        setCharterWith(['quote', 'quoteLineItems'], [charter.quote, charter.quoteLineItems]);
    }, [charter, setCharterWith, allSchedules]);

    useEffect(() => {
        let to = [];
        let cc = [];
        if (charter.customer?.email) {
          to = [charter.customer.email];
        }

        charter.customer?.additionalContacts?.map(contact => {
          if (contact.email) {
            cc.push(contact.email);
          }
        })

        setMailData((mail) => ({
          ...mail,
          to,
          cc,
          bcc: [],
          onMailComplete: () => {
              setCharterWith('status', 'sent');
              updateCharterStatus();
          },
          onMailOpen: () => {
              createPDF().then(() => {
                  console.log('Created PDF');
              });
          },
      }));
    }, [charter, updateCharterStatus, setCharterWith, createPDF]);

    const addLine = useCallback(
        (
            lineItem = {
                name: null,
                description: null,
                unitPrice: null,
                quantity: null,
                discount: null,
                amount: null,
            }
        ) => {
            setCharter((charter) => {
                return new Charter({
                    ...charter,
                    quoteLineItems: [...(charter.quoteLineItems || []), lineItem],
                });
            });
            // setLineItems(lineItems => [
            //     ...(lineItems || []),
            //     lineItem,
            // ]);
        },
        [setCharter]
    );

    const cloneLine = (index) => {
        const clone = {...charter.quoteLineItems[index]};
        setCharterWith('quoteLineItems', [...charter.quoteLineItems, clone]);
        // setLineItems([...lineItems, clone]);
    };

    const deleteLine = (index) => {
        if (charter.quoteLineItems.length <= 1) {
            return;
        }
        setCharterWith(
            'quoteLineItems',
            charter.quoteLineItems.filter((e, i) => i !== index)
        );
        // setLineItems(lineItems.filter((e, i) => i !== index));
    };

    const refreshQuote = () => {
        charter.quoteLineItems[0].unitPrice = charter.calculateQuote();
        setCharterWith('quoteLineItems', [...charter.quoteLineItems]);
    };

    const updateLine = useCallback((index, key, value) => {
        setCharterWith(
            'quoteLineItems',
            charter.quoteLineItems.map((e, i) => {
                if (i === index) {
                    return {
                        ...e,
                        [key]: value,
                    };
                }
                return e;
            })
        );
        // setLineItems(lineItems => lineItems.map((e, i) => {
        //     if (i === index) {
        //         return {
        //             ...e,
        //             [key]: value,
        //         };
        //     }
        //     return e;
        // }));
    }, [setCharterWith, charter]);

    const calculateAmount = useCallback((line) => {
        const discount = line.discount || 0;
        if (line.unitPrice && line.quantity) {
            const total = line.unitPrice * line.quantity;
            const discountedAmount = total * (discount / 100);
            const finalAmount = total - discountedAmount;
            if (finalAmount < 0) return 0;
            return finalAmount;
        }
        return 0;
    }, []);

    // Memoize the calculateSubTotal function
    const calculateSubTotal = useMemo(() => {
        return (
            charter.quoteLineItems.reduce((acc, current) => {
                return acc + parseFloat(calculateAmount(current));
            }, 0) || 0
        ).toFixed(2);

        // return (
        //     lineItems.reduce((acc, current) => {
        //         return acc + parseFloat(calculateAmount(current));
        //     }, 0) || 0
        // );
    }, [charter.quoteLineItems, calculateAmount]);

    // Memoize the calculateGST function
    const calculateGST = useMemo(() => {
        return (parseFloat(calculateSubTotal) * GST || 0).toFixed(2);
    }, [calculateSubTotal]);

    // Memoize the calculateTotal function
    const calculateTotal = useMemo(() => {
        const subTotal = parseFloat(calculateSubTotal) || 0;
        const gst = parseFloat(calculateGST) || 0;
        return (subTotal + gst).toFixed(2);
    }, [calculateSubTotal, calculateGST]);

    // useEffect(() => {
    //     if (charter?.quote && !charter.quoteLineItems?.length) {
    //         addLine({
    //             name: charter.name,
    //             description: 'Vehicle + Driver (incl. fuel and maintenance)',
    //             unitPrice: charter.calculateQuote(),
    //             quantity: 1,
    //             discount: 0,
    //         });
    //     }
    // }, [charter, addLine]);

    const QuoteLineItem = useCallback((line, index) => {
        return (<Row
            key={`qline-${index}`}
            className="card-row-content border-full align-items-center"
            style={{cursor: 'pointer'}}
        >
            <Col xs={6}>
                {mode.edit ? (
                    <Input
                        placeholder="Name"
                        status={!line.name?.length ? 'error' : ''}
                        value={line.name}
                        onChange={({target: {value}}) => updateLine(index, 'name', value)}
                    />
                ) : (
                    <strong>{startCase(line.name)}</strong>
                )}
            </Col>
            <Col xs={mode.edit ? 5 : 8} className="text-left">
                {mode.edit ? (
                    <Input
                        placeholder="Line description"
                        value={line.description}
                        onChange={({target: {value}}) => updateLine(index, 'description', value)}
                    />
                ) : (
                    <span>{line.description}</span>
                )}
            </Col>
            <Col xs={3}>
                {mode.edit ? (
                    <InputNumber
                        placeholder="Unit Price"
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        value={line.unitPrice}
                        onChange={(value) => updateLine(index, 'unitPrice', value)}
                    />
                ) : (
                    <span>${String(line.unitPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                )}
            </Col>
            <Col xs={2}>
                {mode.edit ? (
                    <InputNumber
                        placeholder="Quantity"
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        value={line.quantity}
                        onChange={(value) => updateLine(index, 'quantity', value)}
                    />
                ) : (
                    <span>{line.quantity}</span>
                )}
            </Col>
            <Col xs={2}>
                {mode.edit ? (
                    <InputNumber
                        placeholder="Discount"
                        max={100}
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        value={line.discount}
                        onChange={(value) => updateLine(index, 'discount', value)}
                    />
                ) : (
                    <span>{line.discount}</span>
                )}
            </Col>
            <Col xs={3} className="text-right">
                ${calculateAmount(line).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Col>
            {mode.edit && (
                <Col xs={3} className="row-col-actions">
                    <LoaderButton className="btn-icon-control"
                                  onClick={() => cloneLine(index)}>
                        <Copy style={{width: 14}}/>
                    </LoaderButton>
                    {index > 0 ? <LoaderButton
                        className="btn-icon-control btn-delete"
                        onClick={() => deleteLine(index)}
                    >
                        <TrashIcon/>
                    </LoaderButton> : <LoaderButton
                        className="btn-icon-control btn-refresh"
                        onClick={() => refreshQuote()}
                    >
                        <RefreshIcon/>
                    </LoaderButton>}
                </Col>
            )}
        </Row>);
    }, [mode.edit, updateLine, calculateAmount, cloneLine, deleteLine]);

    const saveCharter = async () => {
        // charter.quoteLineItems = lineItems;
        // setCharter(new Charter({...charter}));
        await charterModelData.save(charter);
        console.log('Charter saved.');
        setMode({edit: false});
    }

    const isCharterQuoteValid = () => {
        // check if all required fields are filled
        return charter.isValid();
    }

    return (
        <div className="charter-details charter-quote w-secondary-menu">
            <h2 className="print-title">{startCase(charter.name)}</h2>
            {charter?.duties?.length && operator && allSchedules ? (
                <>
                    <SaveMenu
                        save={saveCharter}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => isCharterQuoteValid()}
                        closeView={true}
                        showPrint={true}
                        showSend={true}
                        mailData={mailData}
                        onDownloadClicked={onDownloadClicked}
                    />


                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={4}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: mode.edit,
                                    duty: mode.edit,
                                    quote: false,
                                }}
                                save={saveCharter}
                                validFn={() => isCharterQuoteValid()}
                                editMode={mode.edit}
                                updated={controls.updated}
                            />
                        )}
                        <Col xs={24} lg={24} ref={contentRef} className="print-area">
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Summary</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={6}>
                                                <label>To (Address)</label>
                                                {/* <strong>Picton High School</strong>
                                                <small>480 Argyle St, Picton NSW 2571</small>
                                                <small>(02) 4677 1242</small> */}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Line 1"
                                                        status={!charter.address1?.length && charter?.customer?.addressLine1?.length ? 'error' : ''}
                                                        value={charter.address1}
                                                        onChange={({target: {value}}) => setCharterWith('address1', value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.address1)}</strong>
                                                )}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Line 2"
                                                        value={charter.address2}
                                                        onChange={({target: {value}}) => setCharterWith('address2', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.address2)}</small>
                                                )}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Contact"
                                                        status={!charter.addressContact?.length ? 'error' : ''}
                                                        value={charter.addressContact}
                                                        onChange={({target: {value}}) => setCharterWith('addressContact', value)}
                                                    />
                                                ) : (
                                                    <small>{charter.addressContact}</small>
                                                )}
                                            </Col>
                                            <Col xs={12}>
                                                <label>Quote Description</label>
                                                {/* <strong>Picton HS Swimming Carnival</strong> */}
                                                {mode.edit ? (
                                                    <TextArea
                                                        autoSize={{minRows: 4}}
                                                        placeholder="Enter quote description"
                                                        value={charter.quoteDescription}
                                                        onChange={({target: {value}}) => setCharterWith('quoteDescription', value)}
                                                    />
                                                ) : (<small>{(charter.quoteDescription || '').split('\n').map(p =>
                                                    <Paragraph>{p}</Paragraph>)}</small>)}
                                            </Col>
                                            <Col xs={6} className="d-flex align-items-end">
                                                <img
                                                    width={150}
                                                    src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${
                                                        operator?.operatorId
                                                    }/logo.png?${Date.now()}`}
                                                    onError={(e) => {
                                                        e.nativeEvent.target.style.width = 0;
                                                        e.nativeEvent.target.style.height = 0;
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <label>Quote Date</label>
                                                {/* <strong>27/06/2024</strong> */}
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Quote Date"
                                                        {...(!isEmpty(charter.quoteDate) ? {value: charter.quoteDate} : {})}
                                                        status={!charter.quoteDate ? 'error' : ''}
                                                        format={{
                                                            format: DATE_STRING,
                                                            type: 'mask',
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith('quoteDate', value);
                                                            if (!charter.paymentDue) {
                                                                setCharterWith('paymentDue', value.add(2, 'w'));
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.quoteDate?.format ? charter.quoteDate.format(DATE_STRING) : 'TBC'}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={6}>
                                                <label>Payment Due</label>
                                                {/* <strong>27/08/2024</strong> */}
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Payment Due"
                                                        {...(!isEmpty(charter.paymentDue) ? {value: charter.paymentDue} : {})}
                                                        status={!charter.paymentDue ? 'error' : ''}
                                                        format={{
                                                            format: DATE_STRING,
                                                            type: 'mask',
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith('paymentDue', value);
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.paymentDue?.format ? charter.paymentDue.format(DATE_STRING) : 'TBC'}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={6}>
                                                <label>Start Date & Time</label>
                                                <strong>
                                                    {charter.duties[0].getRunningDates(allSchedules, dayjs())?.[0]?.format(DATE_STRING) || ''} -{' '}
                                                    {secsSinceMidnightToDayjs(charter.duties[0].getStartTime()).format('HH:mm')}
                                                </strong>
                                            </Col>
                                            {/*<Col xs={24} lg={6}>*/}
                                            {/*    <label>End Date & Time</label>*/}
                                            {/*    <strong>*/}
                                            {/*        {charter.endDate}*/}
                                            {/*    </strong>*/}
                                            {/*</Col>*/}

                                            <Col xs={6}>
                                                <label># Passengers</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter # Passengers"
                                                        formatter={(value) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} pax`}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        value={charter.passengerCount}
                                                        onChange={(value) => setCharterWith('passengerCount', value)}
                                                        className="w-100"
                                                    />
                                                ) : (
                                                    <strong>{charter.passengerCount}</strong>
                                                )}
                                            </Col>
                                            <Col xs={6}>
                                                <label>Ref</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Ref"
                                                        status={!charter.ref?.length ? 'error' : ''}
                                                        value={charter.ref}
                                                        onChange={({target: {value}}) => setCharterWith('ref', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.ref)}</small>
                                                )}
                                            </Col>
                                            <Col xs={6}>
                                                <label>Invoice No</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Invoice Number"
                                                        status={!charter.invoiceNumber?.length ? 'error' : ''}
                                                        value={charter.invoiceNumber}
                                                        onChange={({target: {value}}) => setCharterWith('invoiceNumber', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.invoiceNumber)}</small>
                                                )}
                                            </Col>
                                            <Col xs={6}>
                                                <label>Quote Status</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.status}
                                                        status={!charter.status ? 'error' : ''}
                                                        options={CHARTER_STATUS}
                                                        onChange={(value) => setCharterWith('status', value)}
                                                        defaultValue={'new'}
                                                    />
                                                ) : (
                                                    <div className="simple-tags">
                                                        <FilterTags filterItems={filterItems} model={charter}/>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col data-html2canvas-ignore="true" xs={24}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Duties</div>
                                            </Flex>
                                        }
                                        extra={<strong style={{fontSize: 16}}>Total
                                            $ {String(charter.calculateQuote()).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>}
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        {charter.duties.map(duty => {
                                            return (<Card key={'c-' + duty.shiftBatId} title={<RouteTitle route={{
                                                routeNumber: duty.shiftBatNumber,
                                                routeName: duty.shiftBatName,
                                                routeDetails: duty.shiftBatDetails,
                                                routeLogo: duty.shiftBatLogo,
                                                colour: duty.shiftBatColour
                                            }}/>}
                                                          extra={<strong
                                                              style={{fontSize: 14}}>$ {String(charter.calculateQuoteForDuty(charter.quote?.[duty.shiftBatId])).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>}
                                                          className="card-info mb-3"><Row gutter={[30, 20]}>
                                                <Col xs={6}>
                                                    <label>Distance</label>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            status={!charter.quote?.[duty.shiftBatId]?.distance ? 'error' : ''}
                                                            placeholder="Enter Distance"
                                                            formatter={(value) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} km`}
                                                            parser={(value) => value.replace(/\$\s?|(,*) km/g, '')}
                                                            value={charter.quote?.[duty.shiftBatId]?.distance || 0}
                                                            onChange={(value) => {
                                                                setCharterWith('quote', {
                                                                    ...charter.quote,
                                                                    [duty.shiftBatId]: {
                                                                        ...charter.quote?.[duty.shiftBatId],
                                                                        distance: value
                                                                    }
                                                                });
                                                            }}
                                                            className="w-100"
                                                        />
                                                    ) : (
                                                        <strong>{charter.quote?.[duty.shiftBatId]?.distance} km</strong>
                                                    )}
                                                </Col>
                                                <Col xs={6}>
                                                    <label>Duration</label>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            status={!charter.quote?.[duty.shiftBatId]?.duration ? 'error' : ''}
                                                            placeholder="Enter Duration"
                                                            formatter={(value) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} hrs`}
                                                            parser={(value) => value.replace(/\$\s?|(,*) hrs/g, '')}
                                                            value={charter.quote?.[duty.shiftBatId]?.duration}
                                                            onChange={(value) => setCharterWith('quote', {
                                                                ...charter.quote,
                                                                [duty.shiftBatId]: {
                                                                    ...charter.quote?.[duty.shiftBatId],
                                                                    duration: value
                                                                }
                                                            })}
                                                            className="w-100"
                                                        />
                                                    ) : (
                                                        <strong>{charter.quote?.[duty.shiftBatId]?.duration} hrs</strong>
                                                    )}
                                                </Col>
                                                <Col xs={6}>
                                                    <label>Total days</label>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            status={!charter.quote?.[duty.shiftBatId]?.days ? 'error' : ''}
                                                            placeholder="Enter days"
                                                            formatter={(value) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} days`}
                                                            parser={(value) => value.replace(/\$\s?|(,*) days/g, '')}
                                                            value={charter.quote?.[duty.shiftBatId]?.days}
                                                            onChange={(value) => setCharterWith('quote', {
                                                                ...charter.quote,
                                                                [duty.shiftBatId]: {
                                                                    ...charter.quote?.[duty.shiftBatId],
                                                                    days: value
                                                                }
                                                            })}
                                                            className="w-100"
                                                        />
                                                    ) : (
                                                        <strong>{charter.quote?.[duty.shiftBatId]?.days} days</strong>
                                                    )}
                                                </Col>
                                                <Col xs={6}>
                                                    <label>Driver rate</label>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            status={!charter.quote?.[duty.shiftBatId]?.driverRate ? 'error' : ''}
                                                            placeholder="Enter driver rate"
                                                            formatter={(value) => `$ ${value} / hr`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*) \/ hr/g, '')}
                                                            value={charter.quote?.[duty.shiftBatId]?.driverRate}
                                                            onChange={(value) => setCharterWith('quote', {
                                                                ...charter.quote,
                                                                [duty.shiftBatId]: {
                                                                    ...charter.quote?.[duty.shiftBatId],
                                                                    driverRate: value
                                                                }
                                                            })}
                                                            className="w-100"
                                                        />
                                                    ) : (
                                                        <strong>$ {charter.quote?.[duty.shiftBatId]?.driverRate} /
                                                            hr</strong>
                                                    )}
                                                </Col>
                                                <Col xs={6}>
                                                    <label>Vehicle rate</label>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            status={!charter.quote?.[duty.shiftBatId]?.vehicleRate ? 'error' : ''}
                                                            placeholder="Enter vehicle rate"
                                                            formatter={(value) => `$ ${value} / km`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*) \/ km/g, '')}
                                                            value={charter.quote?.[duty.shiftBatId]?.vehicleRate}
                                                            onChange={(value) => setCharterWith('quote', {
                                                                ...charter.quote,
                                                                [duty.shiftBatId]: {
                                                                    ...charter.quote?.[duty.shiftBatId],
                                                                    vehicleRate: value
                                                                }
                                                            })}
                                                            className="w-100"
                                                        />
                                                    ) : (
                                                        <strong>$ {charter.quote?.[duty.shiftBatId]?.vehicleRate} /
                                                            km</strong>
                                                    )}
                                                </Col>
                                                <Col xs={6}>
                                                    <label>Loading</label>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Enter loading"
                                                            formatter={(value) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`}
                                                            parser={(value) => value.replace(/\$\s?|(,*) %/g, '')}
                                                            value={charter.quote?.[duty.shiftBatId]?.loading}
                                                            onChange={(value) => setCharterWith('quote', {
                                                                ...charter.quote,
                                                                [duty.shiftBatId]: {
                                                                    ...charter.quote?.[duty.shiftBatId],
                                                                    loading: value
                                                                }
                                                            })}
                                                            className="w-100"
                                                        />
                                                    ) : (
                                                        <strong>{charter.quote?.[duty.shiftBatId]?.loading} %</strong>
                                                    )}
                                                </Col>
                                            </Row>
                                                {/* <Row>
                                                <Col
                                                    lg={24}>$ {`${String(charter.calculateQuoteForDuty(charter.quote?.[duty.shiftBatId])).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</Col>
                                            </Row> */}
                                            </Card>);
                                        })}
                                    </Card>
                                </Col>

                                <Col xs={24} lg={24}>
                                    <Card bordered={false} className="card-main">
                                        <Row className="card-row-header align-items-center">
                                            <Col xs={6} className="d-flex align-items-center">
                                                <h3>Charter Quote</h3>
                                                {mode.edit && (
                                                    <Button className="icon-button btn-sm" type="primary"
                                                            onClick={() => addLine()}>
                                                        Add
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col xs={mode.edit ? 5 : 8} className="text-left">
                                                <span>Description</span>
                                            </Col>
                                            <Col xs={3}>
                                                <span>Unit price</span>
                                            </Col>
                                            <Col xs={2}>
                                                <span>Qty</span>
                                            </Col>
                                            <Col xs={2}>
                                                <span>Disc(%)</span>
                                            </Col>
                                            <Col xs={3} className="text-right">
                                                <span>Amount</span>
                                            </Col>
                                            {mode.edit && (
                                                <Col xs={3}>
                                                    <span>Actions</span>
                                                </Col>
                                            )}
                                        </Row>
                                        {charter.quoteLineItems?.map((line, index) => {
                                            return QuoteLineItem(line, index);
                                        })}
                                        <Row className="card-row-content border-full align-items-center">
                                            <Col xs={18}>
                                                <strong>Subtotal</strong>
                                            </Col>
                                            <Col xs={mode.edit ? 3 : 6} className="text-right">
                                                <strong
                                                    style={{fontSize: '15px'}}>${calculateSubTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                                            </Col>
                                        </Row>
                                        <Row className="card-row-content border-full align-items-center no-border">
                                            <Col xs={18}>
                                                <strong>Plus GST</strong>
                                            </Col>
                                            <Col xs={mode.edit ? 3 : 6} className="text-right">
                                                <strong
                                                    style={{fontSize: '15px'}}>${calculateGST.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                                            </Col>
                                        </Row>
                                        <Row className="card-row-content border-full align-items-center">
                                            <Col xs={18}>
                                                <strong style={{fontSize: '18px'}}>Total</strong>
                                            </Col>
                                            <Col xs={mode.edit ? 3 : 6} className="text-right">
                                                <strong
                                                    style={{fontSize: '18px'}}>${calculateTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..."/>
            )}
        </div>
    );
}

export default React.memo(CharterQuote);
