import React, { memo, useCallback, useMemo } from "react";
import { Checkbox, Table } from "antd";
import "./Rosters.css";
import { WeeklyRoster } from "../../model/roster";
import { values } from "lodash";
import { WorkItemForDay } from "./WorkItemForDay";
import { getWorkItemColumns } from "./WeeklyRosterTable";
import { useRoster } from "./RosterContext";

export const WeeklyUnallocatedTable = memo(() => {
  const {
    setPrintWorkItems,
    selectedWorkItems,
    onSelectWorkItems,
    employeeData,
    filteredScenario: scenario,
  } = useRoster();

  const onSelectWorkItem = useCallback(
    (workItem, _selectedWorkItems) => {
      if (!workItem) {
        onSelectWorkItems([]);
        return;
      }
      if (selectedWorkItems?.find((wi) => wi.workItemId === workItem?.workItemId)) {
        onSelectWorkItems(selectedWorkItems.filter((wi) => wi.workItemId !== workItem.workItemId));
      } else if (_selectedWorkItems?.find((wi) => wi.workItemId === workItem?.workItemId)) {
        onSelectWorkItems(_selectedWorkItems.filter((wi) => wi.workItemId !== workItem.workItemId));
      } else {
        onSelectWorkItems([..._selectedWorkItems, workItem]);
      }
    },
    [selectedWorkItems, onSelectWorkItems]
  );

  const workItemRenderer = useCallback(
    (workItems, roster) => {
      return (
        <WorkItemForDay
          roster={roster}
          workItems={workItems}
          onSelectWorkItem={onSelectWorkItem}
          unallocated={true}
        />
      );
    },
    [onSelectWorkItem]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "Roster",
        dataIndex: "rosterName",
        render: (_, roster) => {
          let allSelected = roster.workItems
            .flat()
            .every((wi) => selectedWorkItems.find((selectedWi) => selectedWi.workItemId === wi.workItemId));
          return (
            <div className="roster-cell-main d-flex justify-content-between">
              <div className="roster-name">{roster.rosterName}</div>
              <Checkbox
                indeterminate={
                  !allSelected &&
                  roster.workItems.flat().some((wi) => selectedWorkItems.find((selectedWi) => selectedWi.workItemId === wi.workItemId))
                }
                checked={allSelected}
                onChange={(e) => {
                  onSelectWorkItems(e.target.checked ? roster.workItems.flat() : []);
                }}
              />
            </div>
          );
        },
      },
    ].concat(
      getWorkItemColumns({
        table: "unallocated",
        workItemRenderer,
        selectedWorkItems,
        employeeData,
        scenario,
        setPrintWorkItems,
      })
    );
  }, [workItemRenderer, selectedWorkItems, employeeData, scenario, setPrintWorkItems, onSelectWorkItems]);

  const unallocatedRosters = useMemo(() => {
    let time = Date.now();
    const weeklyRosters = {};
    scenario?.unallocatedRoster?.workItems.forEach((workItemsForDay, day) => {
      workItemsForDay.forEach((workItem) => {
        const rosterId = workItem.getDuty().shiftBatId;
        let roster = weeklyRosters[rosterId];
        if (!roster) {
          roster = WeeklyRoster.from({
            rosterId,
            key: rosterId,
            startTime: workItem.getDuty().getStartTime(),
            rosterName: workItem.getDuty().shiftBatNumber,
          });
          weeklyRosters[rosterId] = roster;
        }
        const isAlreadyAllocated = scenario.isWorkItemAlreadyAllocated(workItem);
        if (!isAlreadyAllocated) roster.workItems[day].push(workItem);
      });
    });

    console.log("WeeklyRoster unallocated data: ", Date.now() - time, "ms");
    return values(weeklyRosters)
    .filter((roster) => {
      return roster.workItems.flat().length > 0; // filter out empty unallocated rosters
    })
    .sort((a, b) => a.startTime - b.startTime);
  }, [scenario]);

  return (
    <>
      {unallocatedRosters?.length ? (
        <div className={"weekly-roster-table unallocated-roster-table"}>
          <Table
            dataSource={unallocatedRosters}
            columns={columns}
            pagination={false}
            rowClassName={() => "unallocated-roster"}
            sticky={true}
          />
        </div>
      ) : (
        <><div className="NotFound text-center text-info-md d-flex align-items-center justify-content-center" style={{height: '100%'}}>No unallocated items</div></>
      )}
    </>
  );
});
