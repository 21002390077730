import React, { useEffect, useMemo } from "react";
import "./Rosters.css";
import { Button, Flex, Tabs } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import DateSelector from "./DateSelector";
import LoadMessage from "../../components/LoadMessage";
import dayjs from "../../dayjs";
import { find } from "lodash";
import WeeklyScenarioView from "./WeeklyScenarioView";
import EditPopover from "../../components/EditPopover";
import { ComplianceDisclaimerModal } from "./ComplianceDisclaimerModal";
import { useAppContext } from "../../libs/contextLib";
import { Prompt } from "react-router-dom";
import UpdateTitle from "../../components/UpdateTitle";
import weekOfYear from "dayjs/plugin/weekOfYear";
import ShareModal from "../../components/ShareModal";
import { RosteringFilters } from "./RosteringFilters";
import { Check, Print } from "../../components/Icons";
import { RosterProvider, useRoster } from "./RosterContext";
import RosteringSecondaryMenu from "./RosteringSecondaryMenu";
import { ImportScenarios } from "./ImportScenarios";

dayjs.extend(weekOfYear);

function Rosters() {
  const { setBreadcrumb, setPageTitle, operator } = useAppContext();
  const {
    view,
    mode,
    selectedDate,
    setSelectedDate,
    weeklyScenarios,
    selectedScenario,
    setSelectedScenario,
    showImportScenarios,
    setShowImportScenarios,
    printWorkItems,
    setPrintWorkItems,
    updated,
    savingScenario,
    allShiftBatsArray,
    showImportButton,
    removeList,
    setRemoveList,
    save,
    setScenario,
    add,
    remove,
    onSelectWorkItems,
  } = useRoster();

  const activeTabKey = useMemo(() => {
    return selectedScenario?.scenarioId || weeklyScenarios?.[0]?.scenarioId;
  }, [selectedScenario, weeklyScenarios]);

  useEffect(() => {
    const selectedWeek = dayjs(selectedDate).week();
    const title = `Week ${selectedWeek}`;
    setPageTitle(title);
  }, [selectedDate, setPageTitle]);

  useEffect(() => {
    const beforeunload = (e) => {
      if (updated) {
        e.returnValue = "unloading";
        return "unloading";
      }
    };
    window.addEventListener("beforeunload", beforeunload);
    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [updated]);

  useEffect(() => {
    const prefix = view === "weekly" ? "Weekly" : "Daily";
    const breadcrumb = mode === "vehicle" ? "Vehicle Roster" : mode === "staff" ? "Staff Roster" : "Rosters";
    setBreadcrumb(`${prefix} ${breadcrumb}`);
  }, [mode, setBreadcrumb, setPageTitle, view]);

  const onEdit = (selectedScenario, action) => {
    if (action === "add") {
      add();
    } else {
      setRemoveList([...removeList, selectedScenario]);
      remove(selectedScenario);
    }
  };

  return (
    <>
      <Prompt when={updated} message="You have unsaved changes. Are you sure you want to leave?" />

      {allShiftBatsArray ? (
        <>
          <RosteringSecondaryMenu />

          <div className="top-float-title">
            <DateSelector
              initialDate={selectedDate}
              onChange={(date) => {
                save(weeklyScenarios);
                setSelectedDate(date);
              }}
              view={view}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end filter-options-main top-float">
            <div className="d-flex justify-content-end w-separator" style={{ gap: "10px" }}>
              {weeklyScenarios?.length && showImportButton ? (
                <Button
                  onClick={() => setShowImportScenarios(!showImportScenarios)}
                  type="primary"
                  disabled={!weeklyScenarios?.length}
                  className={`icon-button ${showImportScenarios ? "active" : ""}`}
                >
                  Import
                </Button>
              ) : null}
              <Button
                disabled={!weeklyScenarios?.length}
                onClick={() => setPrintWorkItems(!printWorkItems)}
                type="primary"
                className="icon-button btn-filled"
                icon={<Print />}
              >
                Print
              </Button>
              <ShareModal />
            </div>
            <div className="d-flex justify-content-end">
              <ComplianceDisclaimerModal />
              <Button
                disabled={!weeklyScenarios?.length}
                onClick={() => save(weeklyScenarios, null, true)}
                type="primary"
                className="icon-button btn-filled btn-success"
                icon={savingScenario ? <SyncOutlined spin={true} /> : <Check />}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="dispatch-list">
            {weeklyScenarios?.length && !showImportScenarios ? (
              <Tabs
                size={"middle"}
                onChange={(scenarioId) => {
                  const scenario = find(weeklyScenarios, { scenarioId });
                  setSelectedScenario(scenario);
                  onSelectWorkItems([]);
                }}
                activeKey={activeTabKey}
                type="editable-card"
                onEdit={onEdit}
                tabBarExtraContent={
                  <div className="d-flex align-items-center">
                    <RosteringFilters />
                  </div>
                }
                items={weeklyScenarios
                  .filter((s) => !removeList.includes(s.scenarioId))
                  .map((scenario) => {
                    return {
                      key: scenario.scenarioId,
                      label: (
                        <Flex justify={"space-between"} align={"center"} style={{ gap: 6 }}>
                          {selectedScenario?.scenarioId === scenario.scenarioId ? (
                            <EditPopover
                              save={(value) => {
                                setScenario({
                                  ...scenario,
                                  name: value,
                                });
                              }}
                              value={scenario.name}
                              title={"Edit scenario name"}
                            />
                          ) : (
                            <div>{scenario.name}</div>
                          )}
                          {scenario.active && (
                            <span className="icon-success">
                              <Check />
                            </span>
                          )}
                        </Flex>
                      ),
                      children: <WeeklyScenarioView scenario={scenario} operator={operator} />,
                    };
                  })}
              />
            ) : (
              <ImportScenarios />
            )}
          </div>
        </>
      ) : (
        <LoadMessage message={"Loading Rosters..."} />
      )}
    </>
  );
}

const RosterContainer = () => {
  return (
    <RosterProvider>
      <Rosters />
    </RosterProvider>
  );
};

export default React.memo(RosterContainer);
