import React, {useCallback, useEffect, useRef, useState} from 'react';
import DeckGL from '@deck.gl/react';
import {_MapContext, FlyToInterpolator, Marker, NavigationControl, StaticMap, WebMercatorViewport} from 'react-map-gl';
import config from '../config';
import {getViewportForStops, toGeoJsonPtLineCollection, toGeoJsonStopCollection} from '../libs/mapLib';
import './RouteMap.css';
import {debounce, flatten, keyBy, uniq, values} from 'lodash';
import {getRhumbLineBearing} from 'geolib';
import {EditableGeoJsonLayer, SelectionLayer} from '@nebula.gl/layers';
import {BitmapLayer, GeoJsonLayer} from '@deck.gl/layers';
import {ModifyMode, ViewMode} from '@nebula.gl/edit-modes';
import MapLayerToolbar from './MapLayerToolbar';
import MapToolbar from './MapToolbar';
import {PathStyleExtension} from '@deck.gl/extensions';
import StopsMapEditorToolbar from './StopsMapEditorToolbar';
import log from 'loglevel';
import {getDistanceInMetres} from '../libs/routes-lib';
import {toKmMs} from '../libs/formatLib';
import proj4 from 'proj4';
import {useAppContext} from '../libs/contextLib';
import LoadMessage from './LoadMessage';
import {localGeocoder, renderSuggestions} from '../libs/geocoderLib';
import {Button, Image} from 'react-bootstrap';
import ControlPanel from './ControlPanel';
import StopInfo from './StopInfo';
import {Popover, Tooltip} from 'antd';
import {toggleStrInArray} from '../libs/dataStructs';
import ASLLogo from '../assets/images/asl-logo.png';
import {CopyOutlined} from '@ant-design/icons';
import {ReactComponent as TfNSW} from '../assets/icons/TfNSW.svg';
import {Stop} from '../model/busRoute';
import Geocoder from 'react-map-gl-geocoder';
import {SHARED_STOP_MIN_ZOOM} from '../containers/StopManager';
import MapPopup from './MapPopup';

const MAPBOX_ACCESS_TOKEN = config.maps.mabBox;
export const MAPBOX_STYLES = {
    satellite: 'mapbox://styles/gjrwebber/cks05z5mi23ph18mls3s8uf4h',
    light: 'mapbox://styles/gjrwebber/cks05wz5p1osg18q2q8qbzrfa',
    dark: 'mapbox://styles/gjrwebber/cks05ynib0z5z17lexp7x3735',
    street: 'mapbox://styles/gjrwebber/ckryi2wsg02e517pkuyl35emh',
    six: '/sixmaps.json'
};

const navStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px'
};

const logger = log.getLogger('PointManager');

const isCtrlClick = e => e.ctrlKey || e.metaKey || e.srcEvent?.ctrlKey || e.srcEvent?.metaKey;

// Function to convert bounding box from lon/lat to UTM format
function convertBoundingBox(lonLatBoundingBox) {
    // Define the lon/lat projection
    const sourceProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs';

    // Calculate the UTM zone based on the center of the bounding box
    const centerLonLat = [
        (lonLatBoundingBox[0] + lonLatBoundingBox[2]) / 2, // Calculating center longitude
        (lonLatBoundingBox[1] + lonLatBoundingBox[3]) / 2  // Calculating center latitude
    ];
    const utmZone = Math.floor((centerLonLat[0] + 180) / 6) + 1; // Calculating UTM zone

    // Define the UTM projection
    const utmProjection = `+proj=utm +datum=WGS84 +zone=${utmZone} +units=m +no_defs`;

    // Project the bounding box to UTM
    const min = proj4(sourceProjection, utmProjection, [lonLatBoundingBox[0], lonLatBoundingBox[1]]);
    const max = proj4(sourceProjection, utmProjection, [lonLatBoundingBox[2], lonLatBoundingBox[3]]);
    return [min[0], min[1], max[0], max[1]];
}

const DEFAULT_VIEWSTATE = {
    width: 798,
    height: 610,
    latitude: -26.089768592081594,
    longitude: 136.253744512621,
    zoom: 3,
    bearing: 0,
    pitch: 0,
    altitude: 1.5,
    maxZoom: 20,
    minZoom: 0,
    maxPitch: 60,
    minPitch: 0
};

export default function StopsMapViewer({
                                           allStops,
                                           setBoundedStops,
                                           stopsToShow,
                                           onFocusStop,
                                           handleStopClick,
                                           handleSelectedStops,
                                           clearSelectedStops,
                                           selectedStop,
                                           selectedStops,
                                           focusStop,
                                           zoomStop,
                                           editStop,
                                           handleViewStateChange,
                                           handleStopMoved,
                                           distanceToCursor, setDistanceToCursor,
                                           loadingmasterStops,
                                           showmasterStops,
                                           setShowmasterStops,
                                           masterStops,
                                           handleImportStops,
                                           hideContextMenu,
                                           deselectStop,
                                           busLastUpdated, schoolLastUpdated,
                                           ...props
                                       }) {

    const {operatorId} = useAppContext();
    const deckRef = useRef(null);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const [layers, setLayers] = useState({});
    const [data, setData] = useState(null);

    const [viewState, setViewState] = useState(DEFAULT_VIEWSTATE);
    const [viewport, setViewport] = useState(null);
    const [bounds, setBounds] = useState(null);

    const guideLayer = useRef(null);
    const [hoverInfo, setHoverInfo] = useState(null);
    const [measureTool, setMeasureTool] = useState(false);
    const [geocoder, setGeocoder] = useState(false);

    const [poiMarker, setPoiMarker] = useState(null);
    const [style, setStyle] = useState('light');
    const geocoderContainerRef = useRef(null);
    const [showRadius, setShowRadius] = useState(false);
    const [selectType, setSelectType] = useState('none');
    const [popupData, setPopupData] = useState(null);

    const sixLayers = useRef([new BitmapLayer({id: 'sixmaps_address'}),
        new BitmapLayer({id: 'sixmaps_poi'}), new BitmapLayer({id: 'sixmaps_label'})]);


    useEffect(() => {

        const handleViewStateChangedDebounce = debounce(handleViewStateChange, 500);
        const setBoundsDebounce = debounce(setBounds, 500);
        const setViewportDebounce = debounce(setViewport, 500);

        const viewport = new WebMercatorViewport(viewState);
        setViewportDebounce(viewport);
        setBoundsDebounce(viewport.getBoundingRegion());
        handleViewStateChangedDebounce(viewState);

        return () => {
            handleViewStateChangedDebounce.cancel();
            setBoundsDebounce.cancel();
            setViewportDebounce.cancel();
        };
    }, [viewState, setViewport, setBounds, handleViewStateChange]);

    useEffect(() => {

        logger.debug('setting up key maps...');

        function downHandler({key}) {
            console.log('key down', key);
            if (key === 'Shift') {
                setSelectType('rectangle');
            }
        }

        function upHandler({key}) {
            console.log('key up', key);
            if (key === 'Shift') {
                setSelectType('none');
            }
        }

        mapContainerRef.current.addEventListener('keydown', downHandler);
        mapContainerRef.current.addEventListener('keyup', upHandler);
        return () => {
            mapContainerRef.current.removeEventListener('keydown', downHandler);
            mapContainerRef.current.removeEventListener('keyup', upHandler);
            setSelectType('none');
        };
    }, [setSelectType, mapContainerRef]);

    useEffect(() => {

        logger.debug('setting up stop view...');

        if (stopsToShow?.length) {

            setViewState(viewState => {
                if (viewState.latitude === DEFAULT_VIEWSTATE.latitude && viewState.longitude === DEFAULT_VIEWSTATE.longitude) {
                    console.log('getting viewport for stops');
                    return getViewportForStops(stopsToShow);
                }
                return viewState;
            });

            let data = showmasterStops?.length && masterStops?.length ? masterStops : [];
            data = data.concat(stopsToShow.map(stop => ({
                ...stop,
                focused: focusStop && stop.stopId === focusStop.stopId,
                selected: stop.selected || selectedStop?.stopId === stop.stopId || selectedStops?.[stop.stopId],
                editing: editStop && stop.stopId === editStop.stopId,
                lat: editStop && editStop.stopId === stop.stopId ? editStop.lat : stop.lat,
                lon: editStop && editStop.stopId === stop.stopId ? editStop.lon : stop.lon,
            })));
            setData(toGeoJsonStopCollection(data));
        }

    }, [setViewState, stopsToShow, selectedStops, focusStop, selectedStop, editStop, showmasterStops, masterStops, setBoundedStops]);

    const createLinkedLayer = (fromStop, stops) => {
        console.log('creating linked layers for %d stops.', stops.length);
        const data = toGeoJsonPtLineCollection(fromStop, stops);
        return new GeoJsonLayer({
            id: 'linked-stops-layer-' + fromStop.stopId,
            data,
            lineWidthMinPixels: 2,
            // getLineColor: d => ([0,0,0]),
            getDashArray: (feature) => {
                return [4, 3];
            },
            dashJustified: false,
            extensions: [new PathStyleExtension({highPrecisionDash: true})]
        });
    };

    useEffect(() => {
        console.log('build points layer...');

        if (!stopsToShow?.length || !data?.features?.length) {
            setLayers(layers => {
                delete layers.pointsLayer;
                return {...layers};
            });
            return;
        }

        const selectedIdx = !editStop ? -1 : data.features.findIndex(feature => editStop.stopId === feature.stop.stopId);

        setLayers(layers => ({
            ...layers, pointsLayer: new EditableGeoJsonLayer({
                id: 'pointsLayer',
                data,
                mode: editStop ? ModifyMode : ViewMode,
                selectedFeatureIndexes: selectedIdx >= 0 ? [selectedIdx] : [],
                pickingRadius: 10,
                stroked: true,
                filled: true,
                // radiusScale: 6,
                radiusUnits: editStop && showRadius ? 'metres' : 'pixels',
                pointRadiusMinPixels: 7,
                pointRadiusMaxPixels: editStop && showRadius ? 10000 : 15,
                // lineWidthMinPixels: 3,
                lineWidthMaxPixels: 10,
                _subLayerProps: {
                    geojson: {
                        getLineColor: (d) => {
                            return selectedStops[d.stop.stopId] || editStop?.stopId === d.stop?.stopId ? [95, 75, 242] :
                                (d.stop.focused || d.stop.selected || d.stop.editing) ? [95, 75, 242] : [255, 255, 255];
                            // return d.stop.focused || d.stop.selected || d.stop.editing ? [0, 123, 255] : [255, 255, 255]
                        },
                        getFillColor: (d) => {
                            return editStop?.stopId === d.stop?.stopId ? [220, 53, 69, 75] :
                                selectedStops[d.stop.stopId] ? [95, 75, 242, 150] :
                                    (d.stop.master && d.stop.stopType === 'school') ? [240, 173, 78, 80] :
                                        d.stop.master ? [6, 112, 249, 80] :
                                            !d.stop.verified ? [220, 53, 69] :
                                                d.stop.stopType === 'nonpub' ? [167, 165, 165] :
                                                    d.stop.stopType === 'school' ? [240, 173, 78] :
                                                        d.stop.stopType === 'depot' ? [79, 79, 79] :
                                                            d.stop.stopType === 'venue' ? [64, 144, 162] :
                                                                d.stop.stopType === 'restricted' ? [255, 77, 79] : [0, 123, 255];
                        },
                        getPointRadius: (d) => {
                            return editStop?.stopId === d.stop?.stopId ? (editStop.radius || 25) : 10;
                        }
                    }
                },
                onEdit: ({updatedData, editType, editContext}) => {
                    const latitude = editContext && editContext.position.length === 2 && editContext.position[1];
                    const longitude = editContext && editContext.position.length === 2 && editContext.position[0];
                    let movedStop = stopsToShow[selectedIdx];
                    handleStopMoved({stop: movedStop, lat: latitude, lon: longitude, radius: editStop.radius});
                },
                onHover: info => {
                    if (geocoder) {
                        return;
                    }
                    onFocusStop(info.object && info.object.stop ? info.object.stop.stopId : null);
                },
                onClick: info => {
                },
            })
        }));
    }, [setLayers, data, editStop, selectedStops, stopsToShow, handleStopMoved, showRadius, selectedStop, focusStop, geocoder]);

    useEffect(() => {

        if (selectType === 'none') {
            setLayers(layers => {
                delete layers.selectionLayer;
                return {...layers};
            });
            return;
        }
        logger.debug('adding selection layer...');

        setLayers(layers => ({
            ...layers, selectionLayer: new SelectionLayer({
                id: 'selectionLayer',
                selectionType: selectType,
                onSelect: (data) => {
                    console.log('picked');

                    let _selectedStops = data.pickingInfos.map(pi => pi.object.stop);
                    if (_selectedStops.length === 0) {
                        return;
                    }

                    if (handleSelectedStops) {
                        _selectedStops = uniq(flatten(_selectedStops));
                        handleSelectedStops(_selectedStops);
                    }

                },
                layerIds: ['pointsLayer'],
                getTentativeFillColor: () => [200, 200, 200, 100],
                getTentativeLineColor: () => [0, 0, 0, 255],
                getTentativeLineDashArray: () => [0, 0],
                lineWidthMinPixels: 1,
            })
        }));
    }, [setLayers, selectType, handleSelectedStops, viewState, stopsToShow]);

    useEffect(() => {

        logger.debug('setting link layers...');

        if (selectedStops && Object.keys(selectedStops).length) {
            setLayers(layers => {
                Object.keys(selectedStops).forEach(stopId => {
                    const stop = selectedStops[stopId];
                    if (stop.linkedStops?.length) {
                        //             stop.setLinkedStops(stops, true)
                        const linkedStops = stop.linkedStops.map(ls => ls.stop).filter(ls => !!ls);
                        const linkLayer = createLinkedLayer(stop, linkedStops);
                        layers[linkLayer.id] = linkLayer;
                        //             stop.unsetLinkedStops();
                    }
                });
                return {...layers};
            });
        } else {
            setLayers(layers => {
                // remove layers with key starting with link
                Object.keys(layers).forEach(key => {
                    if (key.startsWith('linked-stops-layer')) {
                        delete layers[key];
                    }
                });
                return {...layers};
            });
        }

    }, [setLayers, selectedStops, stopsToShow]);

    useEffect(() => {
        if (style === 'six' && viewport.zoom > 17) {

            logger.debug('six maps layer update');

            const bbox = flatten(viewport.getBounds());
            const exportQuery = `dpi=96&transparent=true&format=png8&layers=show:1,2,3&bbox=${bbox.join(',')}&bboxSR=4326&imageSR=3857&size=${viewport.width},${viewport.height}&f=image`;
            // const lotUrl = `https://maps.six.nsw.gov.au/arcgis/rest/services/sixmaps/Plan_Section_Lot/MapServer/export?${exportQuery}`
            //dpi=96&transparent=true&format=png32&layers=show%3A0&bbox=16882542.740219396%2C-3878213.7490962096%2C16883335.77438796%2C-3877863.2136617596&bboxSR=102100&imageSR=102100&size=1328%2C587&f=image
            const labelQuery = `dpi=96&transparent=true&format=png8&bbox=${bbox.join(',')}&bboxSR=4326&imageSR=3857&size=${viewport.width},${viewport.height}&f=image`;
            const labelUrl = `https://maps.six.nsw.gov.au/arcgis/rest/services/sixmaps/LPI_RasterLabels_1/MapServer/export?${labelQuery}`;
            // const addressUrl = `https://maps.six.nsw.gov.au/arcgis/rest/services/public/StrataAddress/MapServer/export?dpi=96&transparent=true&format=png32&layers=show%3A0&bbox=${bbox.join(',')}&bboxSR=4326&imageSR=3857&size=${viewport.width},${viewport.height}&f=image`
            const poiUrl = `https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_POI/MapServer/export?dpi=96&transparent=true&format=png32&layers=show%3A0&bbox=${bbox.join(',')}&bboxSR=4326&imageSR=3857&size=${viewport.width},${viewport.height}&f=image`;

            const addressUrl = `https://portal.spatial.nsw.gov.au/server/rest/services/NSW_Geocoded_Addressing_Theme/FeatureServer/1/query?f=geojson&spatialRel=esriSpatialRelIntersects&geometry={"xmin":${bbox[0]},"ymin":${bbox[1]},"xmax":${bbox[2]},"ymax":${bbox[3]},"spatialReference":{"wkid":4326}}&geometryType=esriGeometryEnvelope&inSR=102100&outFields=address&outSR=4326&resultType=tile`;
            const sixLayers = [new GeoJsonLayer({
                id: 'sixmaps_address',
                data: addressUrl,
                pointType: 'text',
                getText: f => f.properties.address,
                getTextSize: 12,
                getTextColor: [255, 255, 255],
                getTextBorderWidth: 2,
                textOutlineWidth: 2,
                textBackgroundPadding: [5, 5],
                textBackground: true,
                getTextBackgroundColor: [0, 0, 0]
            }), new BitmapLayer({id: 'sixmaps_poi', bounds: bbox, image: poiUrl}),
                new BitmapLayer({id: 'sixmaps_label', bounds: bbox, image: labelUrl})];
            setLayers(layers => ({
                ...layers, ...keyBy(sixLayers, 'id')
            }));
        } else {
            setLayers(layers => {
                Object.keys(layers).forEach(key => {
                    if (key.startsWith('sixmaps')) {
                        delete layers[key];
                    }
                });
                return {...layers};
            });
        }
    }, [setLayers, style, viewport]);

    useEffect(() => {

        const primaryStop = editStop ? editStop : selectedStop ? selectedStop : Object.keys(selectedStops)?.length === 1 ? values(selectedStops)[0] : null;
        if (!measureTool || !primaryStop || !hoverInfo?.coordinate || distanceToCursor < 0) {
            setLayers(layers => {
                delete layers.measureToolLayer;
                return {...layers};
            });
            return;
        }
        console.log('build measure tool layer...');
        const stopCoord = [primaryStop.lon, primaryStop.lat];
        const data = {
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: [stopCoord, hoverInfo.coordinate]
                }
            }]
        };
        const bearing = getRhumbLineBearing(hoverInfo.coordinate, stopCoord);
        let coord;
        if (bearing > 90 && bearing < 270) {
            // show distance below cursor
            coord = viewport.unproject([hoverInfo.pixel[0], hoverInfo.pixel[1] - 25]);
        } else {
            // show label above cursor
            coord = viewport.unproject([hoverInfo.pixel[0], hoverInfo.pixel[1] + 25]);
        }
        data.features.push({
            type: 'Feature',
            properties: {text: toKmMs(distanceToCursor, 0)},
            geometry: {type: 'Point', coordinates: coord}
        });
        setLayers(layers => ({
            ...layers, measureToolLayer: new GeoJsonLayer({
                id: 'measureToolLayer',
                data,
                stroked: false,
                filled: true,
                extruded: true,
                pointType: 'circle+text',
                lineWidthScale: 1,
                lineWidthMinPixels: 2,
                lineWidthMaxPixels: 5,
                getFillColor: [160, 160, 180, 200],
                getLineColor: d => [255, 0, 0],
                getPointRadius: 0,
                getLineWidth: 1,
                getElevation: 30,
                getText: f => f?.properties?.text,
                getTextColor: [255, 0, 0],
                getTextSize: 12,
                // props added by PathStyleExtension
                getDashArray: (feature) => {
                    return [4, 3];
                },
                dashJustified: false,
                extensions: [new PathStyleExtension({highPrecisionDash: true})],
            })
        }));
    }, [setLayers, editStop, selectedStop, selectedStops, measureTool, hoverInfo, distanceToCursor]);

    useEffect(() => {
        if (selectedStop) {
            logger.debug('selected stop changed');
            setViewState(viewState => {
                if (Number.isFinite(selectedStop.lat) && Number.isFinite(selectedStop.lon)) {
                    return {
                        ...viewState,
                        latitude: selectedStop.lat,
                        longitude: selectedStop.lon,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionDuration: 500
                    };
                }
                return viewState;
            });
        }
    }, [selectedStop]);

    useEffect(() => {
        if (selectedStops && zoomStop) {
            logger.debug('zooming to stop');
            const _stops = values(selectedStops);
            if (_stops && _stops.length) {
                const viewport = getViewportForStops(_stops);
                setViewState(viewState => ({
                    ...viewport,
                    zoom: viewState.zoom > 16 ? viewState.zoom : 16,
                    transitionInterpolator: new FlyToInterpolator(),
                    transitionDuration: 500
                }));
            }
        }
    }, [selectedStops, zoomStop]);

    // useEffect(() => {
    //     logger.debug('stopsToShow changed')
    // if (zoomStop) {
    //     debouncedViewChange(viewState, [zoomStop]);
    // } else {
    // debouncedViewChange(viewState, stopsToShow);
    // }


    // }, [debouncedViewChange, viewState, stopsToShow]);

    const onResult = useCallback((event) => {
        if (event.result.place_type.indexOf('stop') > -1) {
            const stop = event.result.properties.stop;
            handleStopClick({stop, zoom: true});
            setPoiMarker(null);
        } else {
            setPoiMarker(event.result);
        }
        return event;
    }, [props.setSelectedStop, setPoiMarker]);

    const theLocalGeoCoder = useCallback((query) => {
        return localGeocoder({
            query,
            allStops: allStops,
            verifiedOnly: false
        });
    }, [allStops]);

    const handleGeocoderViewportChange = useCallback((newViewport) => {
        const geocoderDefaultOverrides = {transitionDuration: 1000};
        setViewState({
            ...newViewport, ...geocoderDefaultOverrides
        });
    }, [setViewState]);

    logger.debug('Refreshing DOM: StopMapViewer ', viewState);
    return (
        <div ref={mapContainerRef}
        >
            {viewState && layers && (
                <>
                    <div className="ControlPanelContainer">
                        {!hideContextMenu && Object.keys(selectedStops).length ? (
                            Object.values(selectedStops).sort(Stop.sort).map((selectedMarker) => (
                                <ControlPanel
                                    key={`CtrlPnl-${selectedMarker.stopId}`}
                                    immutable={false}
                                    editing={false}
                                    closeControlFn={() => {
                                        if (deselectStop) {
                                            deselectStop(selectedMarker);
                                        }
                                    }}
                                >
                                    <StopInfo
                                        idx={allStops[selectedMarker.stopId]}
                                        stopCount={Object.keys(allStops).length}
                                        stop={selectedMarker}
                                    />
                                </ControlPanel>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                    <DeckGL
                        ref={deckRef}
                        style={{overflow: 'hidden', borderRadius: '8px 0 0 8px'}}
                        // initialViewState={viewState}
                        viewState={viewState}
                        controller={{
                            // dragPan: !overCtrlPanel,
                            dragRotate: false,
                            doubleClickZoom: false,
                        }}
                        onViewStateChange={(_viewState) => {
                            setViewState(_viewState.viewState);
                        }}
                        ContextProvider={_MapContext.Provider}
                        layers={values(layers)}
                        pickingRadius={20}
                        // getTooltip={(data) => {
                        //     return data.layer && data.layer.id === 'scatter-plot' && data.object && `Verified: ${data.object.verified}, Search: ${data.object.search}, Closest: ${data.object.closestStop}}`
                        // }}
                        onDragStart={(info, e) => {
                            if (!e.shiftKey) {
                                setSelectType(null);
                            }
                        }}
                        onClick={(info, e) => {
                            if (info.object && info.object.stop) {
                                handleStopClick({stop: info.object.stop, zoom: false, shift: isCtrlClick(e)});
                            } else {
                                clearSelectedStops();
                            }
                        }}
                        onDblClick={(info, e) => {
                            if (info.object && info.object.stop) {
                                handleStopClick({stop: info.object.stop, zoom: true, shift: isCtrlClick(e)});
                            } else {
                                clearSelectedStops();
                            }
                        }}
                        onHover={(info) => {
                            if (!measureTool) {
                                return;
                            }
                            const primaryStop = editStop
                                ? editStop
                                : selectedStop
                                    ? selectedStop
                                    : Object.keys(selectedStops)?.length === 1
                                        ? values(selectedStops)[0]
                                        : null;
                            // if no selected stop then return
                            if (!primaryStop || !info?.coordinate?.length) {
                                setDistanceToCursor(-1);
                                setHoverInfo(null);
                                return;
                            }
                            setDistanceToCursor(
                                getDistanceInMetres(primaryStop, {
                                    lat: info.coordinate[1],
                                    lon: info.coordinate[0],
                                })
                            );
                            setHoverInfo(info);
                        }}
                    >
                        <StaticMap
                            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                            mapStyle={MAPBOX_STYLES[style]}
                            ref={mapRef}
                        />

                        <>
                            {poiMarker ? (
                                <Marker
                                    key={`POIMarker`}
                                    offsetTop={-40}
                                    offsetLeft={-20}
                                    latitude={poiMarker.lat || poiMarker.center[1]}
                                    longitude={poiMarker.lon || poiMarker.center[0]}>
                                    <Image width={'40px'}
                                           src="https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/location-pin.png"/>
                                </Marker>
                            ) : (
                                <></>
                            )}
                        </>
                        <div className="nav" style={navStyle}>
                            <NavigationControl/>
                        </div>
                        <MapToolbar style={{position: 'absolute', top: 110, left: 10}}>
                            <MapLayerToolbar setLayer={setStyle}/>

                            <Popover placement="right" trigger="hover" title={<span>Point Data Layers</span>}
                                     content={<div className="data-layers-wrap">
                                         <Tooltip placement="bottom"
                                                  title={<div>
                                                      <div>Source System: TfNSW Transit Stop Management System</div>
                                                      <div>Last
                                                          Updated: {busLastUpdated?.format('lll') || 'Unknown'}</div>
                                                  </div>}>
                                             <Button variant="light"
                                                     onClick={() => setShowmasterStops(s => toggleStrInArray(s, 'bus'))}
                                                     className={showmasterStops.includes('bus') ? 'active' : ''}>
                                                 <TfNSW/>TfNSW Stops
                                             </Button>
                                         </Tooltip>
                                         <Tooltip placement="bottom"
                                                  title={<div>
                                                      <div>Source System: ACARA Australian School List</div>
                                                      <div>Last
                                                          Updated: {schoolLastUpdated?.format('lll') || 'Unknown'}</div>
                                                  </div>}>
                                             <Button variant="light"
                                                     onClick={() => setShowmasterStops(s => toggleStrInArray(s, 'school'))}
                                                     className={showmasterStops.includes('school') ? 'active' : ''}>
                                                 <img src={ASLLogo} alt={'Australian Schools'} width={100}/>Australian
                                                 Schools
                                             </Button>
                                         </Tooltip>
                                     </div>}>
                                <Button className="mapbox-ctrl-icon" variant="light" disabled={editStop}><CopyOutlined/></Button>
                            </Popover>
                            <MapPopup popupData={popupData} setPopupData={setPopupData} {...props}/>
                            <div className="MapSearch PointsMap">
                                <div ref={geocoderContainerRef}/>
                            </div>
                            <Geocoder
                                mapRef={mapRef}
                                containerRef={geocoderContainerRef}
                                onViewportChange={handleGeocoderViewportChange}
                                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                                zoom={17}
                                limit={100}
                                clearAndBlurOnEsc={true}
                                clearOnBlur={true}
                                countries={'AU'}
                                placeholder={'Search ...'}
                                collapsed={false}
                                localGeocoder={theLocalGeoCoder}
                                render={renderSuggestions}
                                onResult={onResult}
                                onInit={geocoder => {
                                    geocoder.setInput('');
                                    if (!geocoder._inputEl.geocoderListener) {
                                        geocoder._inputEl.style.backgroundColor = '#d6d6d6';
                                        geocoder._inputEl.style.opacity = '0.5';
                                        geocoder._inputEl.disabled = true;
                                        if (!loadingmasterStops) {
                                            debounce(() => {
                                                geocoder._inputEl.style.backgroundColor = '#fefefe';
                                                geocoder._inputEl.style.opacity = '1.0';
                                                geocoder._inputEl.disabled = false;
                                            }, 500)();
                                        }
                                        geocoder._inputEl.addEventListener('keyup', e => {
                                            e.stopPropagation();
                                        });
                                        geocoder.container.addEventListener('mousemove', e => {
                                            e.stopPropagation();
                                        });
                                        geocoder._inputEl.geocoderListener = true;
                                    }
                                }}
                            />
                        </MapToolbar>
                        {
                            showmasterStops && loadingmasterStops && (
                                <div style={{position: 'absolute', bottom: -40, right: 10}}>
                                    <LoadMessage title={'Loading source points...'} height={32}/>
                                </div>
                            )
                        }
                        {
                            showmasterStops?.length && !masterStops?.length && viewport?.zoom < SHARED_STOP_MIN_ZOOM && (
                                <div style={{position: 'absolute', bottom: 20, right: 10}}>
                                    <span>Please zoom in to see shared {showmasterStops.join(' and ')} points</span>
                                </div>
                            )
                        }
                        <StopsMapEditorToolbar
                            style={{position: 'absolute', top: 182, left: 10}}
                            mapContainerRef={mapContainerRef}
                            editStop={editStop}
                            measureTool={measureTool}
                            setMeasureTool={setMeasureTool}
                            handleImportStops={handleImportStops}
                            showRadius={showRadius}
                            setShowRadius={setShowRadius}
                            {...props}
                        />
                        {/*<MapToolbar style={{position: 'absolute', bottom: 110, left: 10}}>*/
                        }
                        {/*    {distance > -1 &&*/
                        }
                        {/*        <div style={{zIndex: 10000}}>{toKmMs(distance, 1)}</div>*/
                        }
                        {/*    }*/
                        }
                        {/*</MapToolbar>*/
                        }
                    </DeckGL>
                </>
            )}
        </div>
    );
};
