import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadMessage from './LoadMessage';

function SplashPage({ operator }) {
  const history = useHistory();

  useEffect(() => {
    // Add a small delay before redirecting to show the splash page
    const timer = setTimeout(() => {
      if(!operator) return;
      const redirectPath = operator?.opts?.external?.optibus?.apiKey ? "/charters" : "/services";
      history.push(redirectPath);
    }, 500); // 2 second delay

    return () => clearTimeout(timer);
  }, [operator, history]);

  return (
    <LoadMessage message='Loading busable workspace...' size={"lg"}/> 
  );
}

export default SplashPage; 