import { Avatar, Tooltip } from "antd";
import { createAcronym } from "../Charters/CharterItinerary";
import { toHrsMinsSecs } from "../../libs/formatLib";
import { employeeName } from "./SelectEmployeeView";
import { useRoster } from "./RosterContext";
import { useAppContext } from "../../libs/contextLib";

export const AllocationAvatar = ({ employeeId, scenario, roster, rosterData, handleOnUserClick }) => {
  const { operator } = useAppContext();
  const { employeeData } = useRoster();

  const complianceForEmployee = [];
  if (operator?.opts?.compliance?.accept) {
    rosterData.forEach((r) => {
      r.getWorkItemsForEmployee(employeeId).forEach((wi) => {
        if (wi.compliance) {
          complianceForEmployee.push(...wi.compliance);
        }
      });
    });
  }
  const employee = employeeData.getEmployee(employeeId);
  const totalTime = toHrsMinsSecs(scenario.getTotalTime("employee", employeeId));
  const rosterTime = toHrsMinsSecs(roster.getTotalTimeForEmployee(employeeId));
  const name = employeeName(employee) || "Unknown";

  return (
    <div className={`allocation-item`} onClick={() => handleOnUserClick(employeeId, roster)}>
      <Tooltip
        overlayClassName="fixed-tooltip"
        mouseEnterDelay={0.9}
        title={
          <div>
            <div>
              {name} {rosterTime}
            </div>
            <div>Weekly total: {totalTime}</div>
            <div>
              {complianceForEmployee.map((c) => (
                <div className="mt-3">{c}</div>
              ))}
            </div>
          </div>
        }
        placement="bottom"
      >
        <Avatar
          size={24}
          style={{
            backgroundColor: complianceForEmployee?.length ? "#FFE6E6" : "#C1C5CE",
            color: complianceForEmployee?.length ? "#FF4D4D" : null,
            borderColor: complianceForEmployee?.length ? "#FDCCC9" : null,
          }}
        >
          {createAcronym(name)}
        </Avatar>
        <div>
          <div className="driver-name">{name}</div>
          <div className="roster-time">{rosterTime}</div>
        </div>
      </Tooltip>
    </div>
  );
};
