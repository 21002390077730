import React, { memo } from "react";
import { Avatar } from "antd";
import { createAcronym } from "../Charters/CharterItinerary";

export const EmployeeAvatar = memo(({ employeeId, employeeName, employeeAvatarUrl, statusColour = "#C1C5CE", size = 28 }) => {
  return (
    <Avatar
      key={`employee-avatar-${employeeId}`}
      style={{ backgroundColor: statusColour, verticalAlign: "middle" }}
      size={size}
      src={employeeAvatarUrl}
    >
      {createAcronym(employeeName)}
    </Avatar>
  );
});

export const Allocation = memo(({ employeeId, employeeName, employeeAvatarUrl, vehicleName, statusColour, onClick }) => (
  <div className={`allocation-item ${employeeName === "?" ? "driver-error" : ""} ${vehicleName ? "" : "vehicle-error"}`} onClick={onClick}>
    <div className="row RouteTitle flex-nowrap align-items-center">
      <div className={`RouteLogo`}>
        <EmployeeAvatar employeeId={employeeId} employeeName={employeeName} employeeAvatarUrl={employeeAvatarUrl} />
        <div className="driver-name">{employeeName}</div>
      </div>

      <span className="RouteNumber" style={{ backgroundColor: statusColour }}>
        {vehicleName}
      </span>
    </div>
  </div>
));
