import {ColorPicker, Input, Popconfirm, Row} from 'antd';
import React, {useState} from 'react';

const EditPopover = ({save, cancel, value, title, child, color, ...props}) => {
    const [newValue, setNewValue] = useState(value);
    const [selectedColor, setSelectedColor] = useState(color?.value);

    const handleCancel = () => {
        cancel && cancel();
        setNewValue(value);
        setSelectedColor(color?.value);
    }

    return (
        <Popconfirm
            title={title || 'Edit'} icon=""
            description={
              <Row className='flex-nowrap'>
                {color && 
                  <ColorPicker
                    value={selectedColor}
                    onChangeComplete={async (color) => {
                      setSelectedColor(color.toHexString());
                    }}
                />}
                <Input value={newValue} onChange={e => setNewValue(e.target.value)} onKeyDown={e => e.stopPropagation()}/>
              </Row>
          }
            onConfirm={() => save(newValue, selectedColor)}
            onCancel={handleCancel}
            destroyTooltipOnHide={true}
            {...props}
        >
            {child || <span
                style={{cursor: 'pointer'}}>{value}</span>}
        </Popconfirm>
    );
};

export default React.memo(EditPopover);