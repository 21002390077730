import React, { useState } from "react";
import "./SecondaryMenu.css";
import { Button, Popover, Switch, Tooltip, Badge } from "antd";
import UpdateBodyCSS from "./UpdateBodyCSS";

function SecondaryMenu({ menuGroups = [], className }) {
  const [tooltipStates, setTooltipStates] = useState({});
  const [popoverStates, setPopoverStates] = useState({});

  const getItemKey = (groupIndex, itemIndex) => `${groupIndex}-${itemIndex}`;

  const handlePopoverChange = (groupIndex, itemIndex, open) => {
    const key = getItemKey(groupIndex, itemIndex);
    setPopoverStates((prev) => ({ ...prev, [key]: open }));
    if (open) {
      setTooltipStates((prev) => ({ ...prev, [key]: false }));
    }
  };

  return (
    <>
      <UpdateBodyCSS bodyClass="ShowSecondaryMenu" />
      <div className={`SecondaryMenu ${className || ""}`}>
        {menuGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="SecondaryMenuWrap">
            {group.items.map((item, itemIndex) => {
              const {
                type,
                icon: Icon,
                iconProps = {},
                tooltip,
                onClick,
                popover,
                badge,
                disabled = false,
                switch: switchProps,
                className: itemClassName = "",
              } = item;

              const itemKey = getItemKey(groupIndex, itemIndex);
              const showTooltip = tooltipStates[itemKey] && !popoverStates[itemKey];

              let Component = (
                <Tooltip
                  key={itemIndex}
                  title={tooltip}
                  placement="right"
                  mouseEnterDelay={0.2}
                  open={showTooltip}
                  {...(popover?.tooltipProps || {})}
                >
                  {type === "switch" ? (
                    <div className={`icon-switch icon-button ${switchProps?.checked ? "active" : ""} ${itemClassName}`}>
                      <Icon width={iconProps.width || 18} {...iconProps} />
                      <Switch size="small" style={{ height: "34px", width: "34px" }} {...switchProps} />
                    </div>
                  ) : (
                    <Button
                      className={`icon-button ${itemClassName}`}
                      onClick={onClick}
                      onMouseEnter={() => setTooltipStates((prev) => ({ ...prev, [itemKey]: true }))}
                      onMouseLeave={() => setTooltipStates((prev) => ({ ...prev, [itemKey]: false }))}
                      disabled={disabled}
                      {...item.buttonProps}
                    >
                      <Icon width={iconProps.width || 16} {...iconProps} />
                    </Button>
                  )}
                </Tooltip>
              );

              if (popover) {
                Component = (
                  <Popover
                    key={itemIndex}
                    placement="rightTop"
                    trigger="click"
                    open={popoverStates[itemKey]}
                    onOpenChange={(open) => handlePopoverChange(groupIndex, itemIndex, open)}
                    overlayClassName="SecondaryMenuOverlay"
                    content={popover.content(() => handlePopoverChange(groupIndex, itemIndex, false), true)} // Pass `close` function
                  >
                    {Component}
                  </Popover>
                );
              }

              if (badge) {
                Component = (
                  <Badge key={itemIndex} {...badge}>
                    {Component}
                  </Badge>
                );
              }

              if (item.separator) {
                return (
                  <React.Fragment key={itemIndex}>
                    {Component}
                    <div className="Separator" />
                  </React.Fragment>
                );
              }

              return Component;
            })}
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(SecondaryMenu);
