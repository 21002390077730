import {useCallback, useEffect, useState} from 'react';
import {employeeModelData} from '../services/ModelService';
import {pickBy, values} from 'lodash';


const useAllEmployees = ({filter = null} = {}) => {
    const [allEmployees, setAllEmployees] = useState(null);
    const [allLeaves, setAllLeaves] = useState(null);
    const [filteredEmployees, setFilteredEmployees] = useState([]);

    useEffect(() => {

        const setEmployees = (employees) => {
          employeeModelData.getAll().then((allEmployees) => {
            setAllEmployees(pickBy(allEmployees, (v, k) => (v.firstName && v.lastName && !v.isDeleted)));
            setAllLeaves(pickBy(allEmployees, (v, k) => v.leaveTypeID));
          })
        };

        const employeesListener = employeeModelData.addListener({
            setterFn: setEmployees, loaded: employees => {
                setEmployees(employees);
            }
        });

        return () => {
            if (employeesListener)
                employeeModelData.removeListener(employeesListener);
        };
    }, []);

    useEffect(() => {
        if (allEmployees && filter) {
            setFilteredEmployees(values(allEmployees).filter(filter));
        }
    }, [setFilteredEmployees, allEmployees, filter]);

    const getEmployee = useCallback(id => id ? (allEmployees?.[`#EMPLOYEE#${id}#EXTERNAL`] || null) : null, [allEmployees]);
    
    const isEmployeeOnLeave = useCallback((employeeId, date) => {
        if(!date || !employeeId) return false
        const formattedDate = date?.toISOString()?.split('T')[0];
        const employeeLeaves = values(allLeaves).filter(e => e.employeeID === employeeId);
        return employeeLeaves.find(leave => {
          const startDate = new Date(leave.startDate);
          const endDate = new Date(leave.endDate);
          const dateToCheck = new Date(formattedDate);
          return dateToCheck >= startDate && dateToCheck <= endDate;
      });
    }, [allLeaves]);

    return {
        allEmployees, filteredEmployees, getEmployee,
        isEmployeeOnLeave
    };
};

export default useAllEmployees;

