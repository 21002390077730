import * as React from 'react';

// const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
//   c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
//   C20.1,15.8,20.2,15.8,20.2,15.7z`;

const stopStyle = {
    fill: '#FFFFFF'
};

// const timingStyle = {
//     stroke: '#111',
//     fill: '#64c466'
// };

const focusedStyle = {
    fill: '#007bff',
};

const selectedStyle = {
    fill: '#007bff',
};

const verifiedStyle = {
    fill: '#fff'
};

export const shiftStyle = {
    fill: '#1cab23'
};

export const venueStyle = {
    fill: '#0292a4'
};

export const busStopStyle = {
    fill: '#0275D8'
};

export const schoolStopStyle = {
    fill: '#F0AD4E'
};

export const depotStopStyle = {
    fill: '#4f4f4f'
};

export const nonPubStopStyle = {
    fill: '#a7a5a5'
};

export const muteStopStyle = {
    fill: '#d3d3d3'
};

export const restrictedStopStyle = {
    fill: '#FF4D4F'
};

function Pin(props) {
    const {size = 20, type, sequence, onClick, selected, focused, verified, mute} = props;
    const style = focused ? focusedStyle : selected ? selectedStyle : verified ? verifiedStyle : stopStyle;
    const stopTypeStyle = mute ? muteStopStyle : type === 'restricted' ? restrictedStopStyle : type === 'school' ? schoolStopStyle : type === 'depot' ? depotStopStyle : type === 'nonpub' ? nonPubStopStyle : type === 'shift' ? shiftStyle : type === 'venue' ? venueStyle : busStopStyle;
    const stopDisplayName = sequence  ? sequence : type === 'restricted' ? 'x' : type === 'school' ? "S" : type === 'depot' ? "D" : type === 'nonpub' ? "N" : type === 'venue' ? "V" : "B";
    const stopTitle = type === 'restricted' ? "Restricted" : type === 'school' ? "School stop" : type === 'depot' ? "Depot stop" : type === 'nonpub' ? "Non public stop" : type === 'venue' ? "Venue" : "Bus stop";

    return (
        <div title={stopTitle}>
            <svg width={size} height={size} onClick={onClick}>
                <g id="Symbols">
                    <g id="icon-stop/bus">
                        <circle id="circle-outer" fill={style.fill} cx={size / 2} cy={size / 2} r={size / 2}></circle>
                        <circle id="circle-inner" fill={stopTypeStyle.fill} cx={size / 2} cy={size / 2}
                                r={size / 2.5}></circle>
                        <text id={stopDisplayName} fontFamily="HelveticaNeue, Helvetica Neue" fontSize="10"
                              fontWeight="bold" fill="#FFFFFF" x="50%" y="50%" textAnchor="middle"
                              alignmentBaseline="middle">
                            {stopDisplayName}
                        </text>
                    </g>
                </g>
            </svg>
        </div>
    );

}

export default React.memo(Pin);
