import React, { useCallback, useMemo, useState } from "react";
import { Input, Radio, Tooltip, Avatar } from "antd";
import { values } from "lodash";
import { toHrsMinsSecs } from "../../libs/formatLib";
import LoadMessage from "../../components/LoadMessage";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { useRoster } from "./RosterContext";

const UNASSIGNED_VEHICLE = {
  vehicleId: null,
  vehicleName: "Unassigned",
  vehicleRego: "",
  validations: [],
};

const SelectVehicleView = ({ selectedVehicle, setSelectedVehicle }) => {
  const { selectedScenario: scenario, allVehicles, selectedWorkItems } = useRoster();
  const [searchTerm, setSearchTerm] = useState("");

  const getSeverity = (vehicle) => {
    if (vehicle.validations.length > 0) {
      return "error";
    }

    return "warning";
  };

  const [availableVehicles, unavailableVehicles] = useMemo(() => {
    if (!scenario || !allVehicles) return [[], []];
    const _availableVehicles = [];
    const _unavailableVehicles = [];
    values(allVehicles).forEach((vehicle) => {
      const _veh = {
        ...vehicle,
        hours: toHrsMinsSecs(scenario.getTotalTime("vehicle", vehicle.vehicleId)),
        validations: scenario.getVehicleValidations(vehicle, selectedWorkItems),
      };
      if (getSeverity(_veh) === "error") {
        _unavailableVehicles.push(_veh);
      } else {
        _availableVehicles.push(_veh);
      }
    });
    return [_availableVehicles, _unavailableVehicles];
  }, [scenario, allVehicles, selectedWorkItems]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterVehicles = useCallback(
    (vehicleList, includeUnassigned = false) => {
      if (!searchTerm) return includeUnassigned ? [UNASSIGNED_VEHICLE, ...vehicleList] : vehicleList;
      const search = searchTerm.toLowerCase();
      return [
        ...(includeUnassigned ? [UNASSIGNED_VEHICLE] : []),
        ...vehicleList.filter((vehicle) => {
          return (
            vehicle.vehicleName?.toLowerCase().includes(search) ||
            vehicle.vehicleRego?.toLowerCase().includes(search)
          );
        }),
      ];
    },
    [searchTerm]
  );

  const filteredAvailableVehicles = useMemo(() => filterVehicles(availableVehicles, true), [filterVehicles, availableVehicles]);
  const filteredUnavailableVehicles = useMemo(() => filterVehicles(unavailableVehicles), [filterVehicles, unavailableVehicles]);

  const filteredAllVehicles = useMemo(() => {
    return [...filteredAvailableVehicles, ...filteredUnavailableVehicles];
  }, [filteredAvailableVehicles, filteredUnavailableVehicles]);

  return (
    <>
      {allVehicles ? (
        <>
          <div className={`item-allocation hideVehicleUnavailable`}>
            <Input type="text" placeholder="Search vehicle" value={searchTerm} onChange={handleSearch} />
            <div className="item-list-scroll">
              <div className="item-list" style={{ height: "200px", overflowY: "scroll" }}>
                {filteredAllVehicles?.length ? (
                  filteredAllVehicles.map((vehicle) => (
                    <div
                      key={"available-vehicle-" + vehicle.vehicleId}
                      className="item-single"
                      onClick={() => {
                        if (vehicle.vehicleStatus !== "Unavailable") setSelectedVehicle(vehicle);
                      }}
                    >
                      <Radio
                        name="vehicle"
                        checked={selectedVehicle?.vehicleId === vehicle.vehicleId}
                        disabled={getSeverity(vehicle) === "error"}
                      >
                        <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                          <Avatar size={30} icon={<Bus width={16} height={16} className="icon-light" />} />
                          <span className="staff-name">{vehicle.vehicleName}</span>
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{ gap: "3px" }}>
                          {vehicle.validations?.length ? (
                            <Tooltip
                              trigger="hover"
                              placement="right"
                              color="#fff"
                              mouseEnterDelay={0.5}
                              overlayClassName="error-tooltip"
                              title={
                                <div className="info-content">
                                  <div className="info-content-issues">
                                    {vehicle.validations.map((v, i) => {
                                      return (
                                        <div key={i}>
                                          {v.type === "repair" && <span>Vehicle is under repair</span>}
                                          {v.type === "service" && <span>Vehicle is being serviced.</span>}
                                          {v.type === "unavailable" && <span>Vehicle is unavailable</span>}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              }
                            >
                              <Warning width={16} height={16} />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Radio>
                    </div>
                  ))
                ) : (
                  <div className="no-results">No vehicles found</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadMessage title="Loading vehicles..." />
      )}
    </>
  );
};

export default React.memo(SelectVehicleView);
