import React, { useRef } from "react";
import { Card, Col, Row, Splitter } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import WeeklyRosterTable from "./WeeklyRosterTable";
import { WeeklyUnallocatedTable } from "./WeeklyUnallocatedTable";
import WorkDiaryPrintModal from "./WorkDiaryPrintModal";
import { useRoster } from "./RosterContext";
import { BusPlus, BusQuote, BusStat } from "../../components/Icons";
import "./Rosters.css";

const WeeklyScenarioView = ({ scenario, operator }) => {
  const unallocatedPanel = useRef();
  const rostersPanel = useRef();

  const {
    showDashboard,
    showUnallocated,
    setShowUnallocated,
    isLoading,
    onSelectWorkItems,
    driverHours,
    wages,
    overtime,
    overtimeHours,
    otpercentage,
    filteredScenario,
  } = useRoster();

  return (
    <div key={scenario.scenarioId}>
      {showDashboard && (
        <Row key={scenario.scenarioId} gutter={[20, 20]} className={`Dashboards mt-3 mb-3 ${showDashboard ? "show" : "hide"}`}>
          <Col
            xs={24}
            lg={6}
            onClick={() => {
              if (showUnallocated) {
                onSelectWorkItems(null);
              }
              setShowUnallocated(!showUnallocated);
            }}
          >
            <Card bordered={false} className="card-main card-stats card-stats-flex">
              <BusPlus />
              <div>
                <h4>Unallocated</h4>
                <div className="text-lg">{isLoading ? <SyncOutlined spin /> : filteredScenario?.getTotalUnallocatedWorkItems() || 0}</div>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card bordered={false} className="card-main card-stats card-stats-profit card-stats-flex">
              <BusStat />
              <div>
                <h4>Driver Hours</h4>
                <div className="text-lg">{driverHours}</div>
              </div>
              <div>
                <h4>Overtime</h4>
                <div className="text-lg">{overtimeHours}</div>
              </div>
              <div>
                <h4>Overtime %</h4>
                <div className="text-lg">{otpercentage?.toFixed(0)}%</div>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={6}>
            <Card bordered={false} className="card-main card-stats card-stats-flex">
              <BusQuote />
              <div>
                <h4>Total Wages</h4>
                <div className="text-lg">${wages.toFixed(2)}</div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
      <WorkDiaryPrintModal key={"work-diary-print"} />
      <div className="rosters-grid">
        <div className="rosters-grid-wrap">
          {isLoading ? (
            <div className="weekly-roster-table header-roster-table">...Loading</div>
          ) : (
            <>
              <div className={"weekly-roster-table header-roster-table"}></div>
              {showUnallocated ? (
                <Splitter layout="vertical" style={{ height: showDashboard ? "68vh" : "82vh" }}>
                  <Splitter.Panel defaultSize={"30%"} min={"20%"} ref={unallocatedPanel}>
                    <WeeklyUnallocatedTable />
                  </Splitter.Panel>
                  <Splitter.Panel min={"20%"} ref={rostersPanel}>
                    <WeeklyRosterTable scroll={{ y: rostersPanel.current?.clientHeight || 900 }} hideHeader={true} />
                  </Splitter.Panel>
                </Splitter>
              ) : (
                <WeeklyRosterTable />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(WeeklyScenarioView);
