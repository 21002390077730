import {clone, find, uniq, uniqBy, some} from 'lodash';
import {ulid} from 'ulid';
import dayjs from '../dayjs';
import {DATE_STRING} from './schedule';
import {WorkItem} from './shiftBat';
import { DEFAULT_MAX_DRIVER_HRS } from './hrm/employee';
import { VEHICLE_STATUS } from './vehicle';

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export class WeeklyRoster {

    static from(data) {
        return new WeeklyRoster(data);
    }

    static new() {
        return new WeeklyRoster({});
    }

    constructor(data) {
        this.date = dayjs().startOf('week');
        this.rosterId = this.date.format('YYYYMMDD') + '#' + ulid();
        this.rosterName = 'NEW';
        this.defaultEmployeeId = null;
        this.defaultEmployee = null;
        this.defaultEmployeeName = null;
        this.defaultVehicleId = null;
        this.defaultVehicleName = null;
        this.defaultEmployee = null;
        this.defaultVehicle = null;
        this.workItems = [[], [], [], [], [], [], []]; // Array of WorkItems indexed by the locale aware day of week (workItems[0] = [Monday rosters])
        Object.assign(this, data);

        if (this.workItems.length) {
            this.workItems = this.workItems.map(workItems => workItems?.map(workItem => WorkItem.from({
                ...workItem, roster: this
            })));
        }
    }

    // allocate(unallocatedShiftBats, schedulesById) {
    //     this.getDaysOfWeek().forEach(date => {
    //         const dailyRosters = [];
    //         unallocatedShiftBats.forEach(sb => {
    //             console.log('Allocating SB', sb.shiftBatNumber, sb.getStartTime({asDayJs: true}).format('HH:mm'), sb.getEndTime({asDayJs: true}).format('HH:mm'));
    //             if (!sb.getActualStartTime) {
    //                 sb = new ShiftBat(sb);
    //             }
    //             let dailyRoster = find(dailyRosters, r => r.isDutyPossible(sb));
    //             if (!dailyRoster) {
    //                 dailyRoster = DailyRoster.from({date});
    //                 dailyRosters.push(dailyRoster);
    //             }
    //
    //             if (dailyRoster) {
    //                 dailyRoster.duties.push(sb.shiftBatId);
    //             }
    //         });
    //         this.rosters.push(dailyRosters);
    //     });
    //     return jobSet;
    // };
    //
    // allocate(unallocatedDuties, schedulesById) {
    //     this.getDaysOfWeek().forEach(date => {
    //         this.duties[date.weekday()] = this.duties[date.weekday()] || [];
    //         const dailyRosters = [];
    //         let unallocatedDutiesForDate = unallocatedDuties.filter(duty => duty.isRunning(date, schedulesById));
    //         while (unallocatedDutiesForDate?.length) {
    //             const dailyRoster = DailyRoster.fromDate(date);
    //             dailyRosters.push(dailyRoster);
    //             unallocatedDutiesForDate = dailyRoster.allocate(unallocatedDutiesForDate);
    //         }
    //         // Rosters keyed by day of week locale aware (0 = Monday in Australia)
    //         this.duties[date.weekday()].push(dailyRosters);
    //     });
    // };

    setDate(date) {
        this.date = date;
        this.workItems.forEach(workItemsForDay => workItemsForDay.forEach(workItem => workItem.setDate(date)));
    }

    /**
     * Set the default employee on the roster and add the employee to all work items that do not have an employee
     * @param employee
     */
    setDefaultEmployee(employee, assignToAllUnassigned = true) {
        if (!employee) return;
        this.defaultEmployee = employee;
        this.defaultEmployeeId = employee.employeeID;
        this.defaultEmployeeName = employee.firstName + ' ' + employee.lastName;
        if(assignToAllUnassigned){
          this.workItems.forEach(workItemsForDay => workItemsForDay
              .filter(workItem => !workItem.employeeId)
              .forEach(workItem => {
                  workItem.employeeId = employee.employeeID;
                  workItem.employeeName = employee.firstName + ' ' + employee.lastName;
              }));
        }
    }

    /**
     * Set the default vehicle on the roster and add the vehicle to all work items that do not have a vehicle
     * @param vehicle
     */
    setDefaultVehicle(vehicle, assignToAllUnassigned = true) {
        if (!vehicle) return;
        this.defaultVehicle = vehicle;
        this.defaultVehicleId = vehicle.vehicleId;
        this.defaultVehicleName = vehicle.vehicleName;
        if(assignToAllUnassigned){
        this.workItems.forEach(workItemsForDay => workItemsForDay
            .filter(workItem => !workItem.vehicleId)
            .forEach(workItem => {
                workItem.vehicleId = vehicle.vehicleId;
                workItem.vehicleName = vehicle.vehicleName;
            }));
        }
    }

    clearDefaultEmployee() {
        this.defaultEmployee = null;
        this.defaultEmployeeId = null;
        this.defaultEmployeeName = null;
    }

    clearDefaultVehicle() {
        this.defaultVehicle = null;
        this.defaultVehicleId = null;
        this.defaultVehicleName = null;
    }

    /**
     * Replace the employee on the roster and all work items with the new employee
     * @param {*} employeeId - The employee to replace
     * @param {*} employee - The new employee
     */
    replaceEmployee(employeeId, employee, scenario) {
        if (!employee) return;
        this.workItems.forEach(workItemsForDay => workItemsForDay
            .filter(workItem => workItem.employeeId === employeeId)
            .forEach(workItem => {
                const isEmployeeUnavailable = scenario?.isEmployeeUnavailable(employee.employeeID, workItem);
                workItem.employeeId = isEmployeeUnavailable ? null : employee.employeeID;
                workItem.employeeName = isEmployeeUnavailable ? null : (employee.firstName + ' ' + employee.lastName);
            }));
    }

    /**
     * Replace the vehicle on the roster and all work items with the new vehicle
     * @param {*} vehicleId - The vehicle to replace
     * @param {*} vehicle - The new vehicle
     */
    replaceVehicle(vehicleId, vehicle, scenario) {
        if (!vehicle) return;
        this.workItems.forEach(workItemsForDay => workItemsForDay
            .filter(workItem => workItem.vehicleId === vehicleId)
            .forEach(workItem => {
                const isVehicleUnavailable = scenario?.isVehicleUnavailable(vehicle.vehicleId, workItem);
                workItem.vehicleId = isVehicleUnavailable ? null : vehicle.vehicleId;
                workItem.vehicleName = isVehicleUnavailable ? null : vehicle.vehicleName;
            }));
    }

    addWorkItem(workItem, scenario) {
        const isDefaultEmployeeUnavailable = scenario?.isEmployeeUnavailable(this.defaultEmployeeId, workItem);
        const isDefaultVehicleUnavailable = scenario?.isVehicleUnavailable(this.defaultVehicleId, workItem);
        this.workItems[workItem.dayOfWeek].push(WorkItem.from({
            ...workItem,
            employeeId: isDefaultEmployeeUnavailable ? workItem.employeeId : (workItem.employeeId || this.defaultEmployeeId),
            employeeName: isDefaultEmployeeUnavailable ? workItem.employeeName : (workItem.employeeName || this.defaultEmployeeName),
            vehicleId: isDefaultVehicleUnavailable ? workItem.vehicleId : (workItem.vehicleId || this.defaultVehicleId),
            vehicleName: isDefaultVehicleUnavailable ? workItem.vehicleName : (workItem.vehicleName || this.defaultVehicleName),
        }));
    }

    removeWorkItem(workItem) {
        this.workItems[workItem.dayOfWeek] = this.workItems[workItem.dayOfWeek].filter(wi => wi.workItemId !== workItem.workItemId).map(wi => {
            delete wi.roster;
            return wi;
        });
    }

    removeAllWorkItemsFromEmployee(employeeId) {
        this.workItems.forEach(workItemsForDay => workItemsForDay
            .filter(workItem => workItem.employeeId === employeeId)
            .forEach(workItem => {
                this.removeWorkItem(workItem);
            }));
    }

    removeAllWorkItems() {
      this.workItems.forEach((_, i) => this.workItems[i] = []);
    }

    addDuty(dayOfWeek, duty, vehicleId = this.defaultVehicleId, employeeId = this.defaultEmployeeId) {
        this.workItems[dayOfWeek].push(WorkItem.from({date: this.date, duty, vehicleId, employeeId, dayOfWeek}));
    }

    getVehicleIds() {
        return uniq(this.workItems.flat().filter(workItem => !workItem.optional).map(workItem => workItem.vehicleId));
    }

    getEmployeeIds() {
        return uniq(this.workItems.flat().filter(workItem => !workItem.optional).map(workItem => workItem.employeeId));
    }

    // jobSummary(max = 2) {
    //     const truncatedJobs = [];
    //     for (const day in this.jobs) {
    //         if (Array.isArray(this.jobs[day]) && this.jobs[day].length > max) {
    //             truncatedJobs[day] = this.jobs[day].slice(0, max);
    //         } else {
    //             truncatedJobs[day] = [...this.jobs[day]];
    //         }
    //     }
    //     return truncatedJobs;
    // };

    isEmpty() {
        return this.getTotalWorkItems() === 0;
    }

    getTotalWorkItems() {
        return this.workItems.flat().length;
    }

    getTotalWages() {
        return this.workItems.flat().filter(workItem => workItem.employeeId).reduce((acc, workItem) => {
            return acc + workItem.getWage();
        }, 0);
    }

    getTotalTime() {
        return this.workItems.flat().reduce((acc, workItem) => {
            return acc + workItem.getActualDuration();
        }, 0);
    }

    getTotalTimeForDay(day) {
        return this.workItems[day]?.reduce((acc, workItem) => {
            return acc + workItem.getActualDuration();
        }, 0);
    }

    getTotalDriverTime() {
        return this.workItems.flat().filter(workItem => workItem.employeeId)
            .reduce((acc, workItem) => {
                return acc + workItem.getActualDuration();
            }, 0);
    }

    getTotalTimeForEmployee(employeeId) {
        return this.getWorkItemsForEmployee(employeeId).reduce((acc, workItem) => {
            return acc + workItem.getActualDuration();
        }, 0);
    }

    getTotalTimeForEmployeeForDay(employeeId, day) {
        return this.getWorkItemsForEmployeeForDay(employeeId, day).reduce((acc, workItem) => {
            return acc + workItem.getActualDuration();
        }, 0);
    }

    newRoster() {
        this.rosters.push(WeeklyRoster.new());
    }

    deleteRoster(rosterId) {
        this.rosters = this.rosters.filter(roster => roster.rosterId !== rosterId);
    }

    getDaysOfWeek() {
        return Array(7).fill(null).map((_, i) => this.date.add(i, 'day'));
    }

    getAllVehicleIds() {
        return uniq(this.workItems.flat().map(workItem => workItem.vehicleId));
    }

    getAllEmployeesId() {
        return uniq(this.workItems.flat().map(workItem => workItem.employeeId));
    }

    getAllEmployeesIdForDay(day) {
        return uniq(this.workItems[day]?.map(workItem => workItem.employeeId));
    }

    getWorkItemsForDay(day) {
        return uniqBy(this.workItems[day], 'workItemId');
    }

    getAllWorkItems() {
        return uniqBy(this.workItems.flat(), 'workItemId');
    }

    getWorkItemsForEmployeeForDay(employeeId, day) {
        return uniqBy(this.workItems[day]?.filter(workItem => workItem.employeeId === employeeId && !workItem.optional), 'workItemId');
    }

    getWorkItemsForEmployee(employeeId) {
        return uniqBy(this.workItems.flat().filter(workItem => workItem.employeeId === employeeId && !workItem.optional), 'workItemId');
    }

    getWorkItemsForVehicle(vehicleId) {
        return uniqBy(this.workItems.flat().filter(workItem => workItem.vehicleId === vehicleId && !workItem.optional), 'workItemId');
    }

    contains(workItem) {
        return this.workItems[workItem.dayOfWeek].some(wi => wi.workItemId === workItem.workItemId);
    }

    containsOptional() {
        return this.workItems.flat().some(wi => wi.optional);
    }

    getOptionalWorkItems() {
        return this.workItems.flat().filter(wi => wi.optional);
    }

    addOptionalWorkItems(workItems, scenario) {
        workItems.filter(workItem => this.isDutyPossible(workItem.duty, workItem.dayOfWeek))
            .forEach(workItem => {
              if(workItem.isCompleted) return
              this.addWorkItem({
                  ...workItem, optional: true, prevRoster: workItem.roster
              }, scenario);
            });
    }

    saveOptionalWorkItems(scenario) {
        this.workItems.forEach(workItemsForDay => workItemsForDay.filter(workItem => workItem.optional).forEach(workItem => {
            delete workItem.optional;
            delete workItem.prevRoster;
            const isDefaultEmployeeUnavailable = scenario?.isEmployeeUnavailable(this.defaultEmployeeId, workItem);
            const isDefaultVehicleUnavailable = scenario?.isVehicleUnavailable(this.defaultVehicleId, workItem);
            workItem.employeeId = isDefaultEmployeeUnavailable ? workItem.employeeId : (workItem.employeeId || this.defaultEmployeeId);
            workItem.employee = isDefaultEmployeeUnavailable ? workItem.employee : (workItem.employee || this.defaultEmployee);
            workItem.vehicleId = isDefaultVehicleUnavailable ? workItem.vehicleId : (workItem.vehicleId || this.defaultVehicleId);
            workItem.vehicle = isDefaultVehicleUnavailable ? workItem.vehicle : (workItem.vehicle || this.defaultVehicle);
        }));
    }

    saveOptionalWorkItem(workItem, scenario) {
        const isDefaultEmployeeUnavailable = scenario?.isEmployeeUnavailable(this.defaultEmployeeId, workItem);
        const isDefaultVehicleUnavailable = scenario?.isVehicleUnavailable(this.defaultVehicleId, workItem);
        workItem = WorkItem.from({
            ...workItem,
            employeeId: isDefaultEmployeeUnavailable ? workItem.employeeId : (workItem.employeeId || this.defaultEmployeeId),
            employeeName: isDefaultEmployeeUnavailable ? workItem.employeeName : (workItem.employeeName || this.defaultEmployeeName),
            vehicleId: isDefaultVehicleUnavailable ? workItem.vehicleId : (workItem.vehicleId || this.defaultVehicleId),
            vehicleName: isDefaultVehicleUnavailable ? workItem.vehicleName : (workItem.vehicleName || this.defaultVehicleName),
        });
        delete workItem.optional;
        delete workItem.prevRoster;
        this.workItems[workItem.dayOfWeek] = this.workItems[workItem.dayOfWeek].map(wi => {
            return wi.workItemId === workItem.workItemId ? workItem : wi;
        });
    }

    removeOptionalWorkItems() {
        this.workItems = this.workItems.map(workItemsForDay => workItemsForDay.filter(wi => !wi.optional));
    }

    sanitizeWorkItems() {
        // Temporary fix for missing days
        while (this.workItems.length < 7) {
            this.workItems.push([]);
        }
    }

    isDutyPossible(duty, dayOfWeek, {actual = false} = {}) {
        if(!duty) return 0;
        this.sanitizeWorkItems(); // temporary fix for missing days
        const duties = this.workItems[dayOfWeek]?.filter(wi => wi.duty)?.map(wi => wi.duty);
        // Check each job to see if the startTime and endTime overlap with the duty's startTime and endTime
        const dutyStartTime = actual ? duty.getActualStartTime({asDayJs: true}) : duty.getStartTime({asDayJs: true});
        const dutyEndTime = actual ? duty.getActualEndTime({asDayJs: true}) : duty.getEndTime({asDayJs: true});
        const possible = duties?.every(rosterDuty => {
            const jobStartTime = actual ? rosterDuty.getActualStartTime({asDayJs: true}) : rosterDuty.getStartTime({asDayJs: true});
            const jobEndTime = actual ? rosterDuty.getActualEndTime({asDayJs: true}) : rosterDuty.getEndTime({asDayJs: true});
            return (jobEndTime.isBefore(dutyStartTime) && dutyEndTime.isAfter(jobStartTime)) || 
                    (dutyEndTime.isBefore(jobStartTime) && jobEndTime.isAfter(dutyStartTime)) || 
                    jobEndTime.isSame(dutyStartTime) || 
                    dutyEndTime.isSame(jobStartTime);   
        });
        return possible ? 1 : 0;
    }

    hasLinkedDuty(duty, dayOfWeek) {
        return this.workItems[dayOfWeek].some(wi => {
            const existingDuty = wi.duty;
            return existingDuty.isLinkedTo(duty);
        });
    }

    setBaseData({allVehicles, employeeData}) {
        this.workItems.forEach(workItemsForDay => workItemsForDay.forEach(workItem => {
            workItem.vehicle = allVehicles[workItem.vehicleId];
            workItem.employee = employeeData.getEmployee(workItem.employeeId);
        }));
    }

    toJson() {
        const json = {...this};
        delete json.defaultEmployee;
        delete json.defaultEmployeeName;
        delete json.defaultVehicle;
        delete json.defaultVehicleName;
        json.date = typeof this.date === 'string' ? this.date : this.date.format(DATE_STRING);
        json.workItems = [];
        this.workItems.forEach((workItems, day) => json.workItems[day] = workItems.map(workItem => WorkItem.from(workItem).toJson()));
        return json;
    }

    fromJson(shiftBatsById, employeesById, vehiclesById) {
        if (typeof this.date === 'string') {
            this.date = dayjs(this.date, DATE_STRING).startOf('day');
        }

        if (this.defaultEmployeeId) {
            const employee = employeesById[`#EMPLOYEE#${this.defaultEmployeeId}#DETAILS`];
            if (employee) {
                this.defaultEmployeeName = employee.firstName + ' ' + employee.lastName;
            }
        }

        if (this.defaultVehicleId) {
            this.defaultVehicleName = vehiclesById[this.defaultVehicleId];
        }
        this.workItems = this.workItems.map(workItems => workItems.map(workItem => WorkItem.from(workItem).fromJson(shiftBatsById, employeesById, vehiclesById)));
        return this;
    }

    clone() {
        return WeeklyRoster.from(this);
    }
}


export class WeeklyScenario {

    static async smartAllocate(unallocatedDuties, schedulesById, {date, name, ignore = () => false} = {}) {
        const scenario = new WeeklyScenario({date, name});
        scenario.smartAllocate(unallocatedDuties, schedulesById, {ignore});
        return scenario;
    }

    constructor(data) {
        this.scenarioId = ulid();
        this.date = dayjs().startOf('week');
        this.name = 'NEW SCENARIO';
        this.weeklyRosters = [];
        this.unallocatedRoster = WeeklyRoster.from({date: this.date, name: 'Unallocated', rosterId: 'unallocated'});
        Object.assign(this, data);

        if (this.weeklyRosters?.length) {
            this.weeklyRosters = this.weeklyRosters.map(roster => WeeklyRoster.from(roster));
        }
    }

    setDate(date) {
        this.date = date;
        this.weeklyRosters.forEach(roster => roster.setDate(date));
    }

    addDuties(duties, schedulesById, {ignore = () => false} = {}) {
        duties.forEach(duty => {
            this.getDaysOfWeek().forEach(date => {
                const dayOfWeek = date.weekday();
                if (schedulesById && !duty.isRunning(date, schedulesById)) {
                    return;
                }
                if (ignore(duty)) {
                    this.unallocatedRoster.addDuty(dayOfWeek, duty);
                    return;
                }
                let weeklyRoster = find(this.weeklyRosters, wr => wr.hasLinkedDuty(duty, dayOfWeek) && wr.isDutyPossible(duty, dayOfWeek));
                if (!weeklyRoster) {
                    weeklyRoster = find(this.weeklyRosters, wr => wr.isDutyPossible(duty, dayOfWeek));
                    if (!weeklyRoster) {
                        weeklyRoster = WeeklyRoster.from({
                            date: this.date, rosterName: (this.weeklyRosters.length + 1).toString().padStart(2, '0')
                        });
                        this.weeklyRosters.push(weeklyRoster);
                    }
                }
                weeklyRoster.addDuty(dayOfWeek, duty);
            });
        });
    };

    smartAllocate(unallocatedDuties, schedulesById, {ignore = () => false} = {}) {
        const sortedSchoolDuties = unallocatedDuties.filter(d => d.isSchoolServiceOnly() && !d.charter).sort((a, b) => {
            const aSchool = a.isSchoolServiceOnly();
            const bSchool = b.isSchoolServiceOnly();
            if (aSchool !== bSchool) {
                return aSchool ? -1 : 1;
            }
        });
        this.addDuties(sortedSchoolDuties, schedulesById, {ignore});
        const sortedRegularDuties = unallocatedDuties.filter(d => !d.isSchoolServiceOnly() && !d.charter).sort((a, b) => {
            const aOperatingDays = a.getOperatingDays(schedulesById).numberOfDays();
            const bOperatingDays = b.getOperatingDays(schedulesById).numberOfDays();
            if (aOperatingDays !== bOperatingDays) {
                return bOperatingDays - aOperatingDays;
            }
            return a.shiftBatNumber?.localeCompare(b.shiftBatNumber);
            // const aRunning = a.getRunningDates(schedulesById, scenario.date).length;
            // const bRunning = b.getRunningDates(schedulesById, scenario.date).length;
            // return aRunning - bRunning;
        });
        this.addDuties(sortedRegularDuties, schedulesById, {ignore});

        const charterDuties = unallocatedDuties.filter(d => d.charter);
        this.addDuties(charterDuties, schedulesById, {ignore});
    }


    autoAllocate({charter = false} = {}) {
        for (const day in this.unallocatedRoster.workItems) {
            this.unallocatedRoster.workItems[day] = this.unallocatedRoster.workItems[day]?.filter(workItem => {
                if (!charter && workItem.getDuty().charter) {
                    return true;
                }
                let weeklyRoster = find(this.weeklyRosters, wr => wr.isDutyPossible(workItem.getDuty(), day));
                if (!weeklyRoster) {
                    weeklyRoster = WeeklyRoster.from({date: this.date});
                    this.weeklyRosters.push(weeklyRoster);
                }
                weeklyRoster.addDuty(day, workItem.duty);
                return false;
            });
        }
    }

    updateUnallocatedRoster(allUnallocated) {
        const unallocated = allUnallocated?.clone() || this.unallocatedRoster;
        unallocated.workItems.forEach((unallocatedWorkItemsForDay, day) => {
            unallocated.workItems[day] = unallocatedWorkItemsForDay.filter(unallocatedWorkItem => {
                // Filter out the unallocated work item if it is allocated in any of the weekly rosters
                const notContains = !this.weeklyRosters.some(roster => {
                    const containsWorkItem = roster.workItems[day]?.some(workItem => {
                        return !workItem.optional && workItem.workItemId === unallocatedWorkItem.workItemId;
                    });
                    return containsWorkItem;
                });
                return notContains;
            });
        });
        this.unallocatedRoster = unallocated;
    }

    removeOptionalWorkItems() {
        this.weeklyRosters.forEach(roster => {
            roster.removeOptionalWorkItems();
        });

        this.unallocatedRoster.removeOptionalWorkItems();
    }

    removeOutOfDateWorkItems(allSchedules) {
        this.weeklyRosters.forEach(roster => {
            roster.workItems = roster.workItems.map(workItemsForDay => {
                return workItemsForDay.filter(workItem => {
                    const _date = this.date.add(workItem.dayOfWeek, 'day');
                    return workItem.duty?.isRunning(_date, allSchedules);
                });
            })
        });
    }

    // same as updateUnallocatedRoster but only checks for duplicate duties when imported
    removeAlreadyAllocatedWorkItemsFromImport(allUnallocated) {
        const unallocated = allUnallocated?.clone() || this.unallocatedRoster;
        unallocated.workItems.forEach((unallocatedWorkItemsForDay, day) => {
            unallocated.workItems[day] = unallocatedWorkItemsForDay.filter(unallocatedWorkItem => {
                const notContains = !this.weeklyRosters.some(roster => {
                    const containsWorkItem = roster.workItems[day]?.some(workItem => {
                        return !workItem.optional && workItem.dutyId === unallocatedWorkItem.dutyId;
                    });
                    return containsWorkItem;
                });
                return notContains;
            });
        });
        this.unallocatedRoster = unallocated;
    }

    removeWorkItems(workItems) {
      // remove work items from any roster in scenario
      this.weeklyRosters.forEach(roster => {
        roster.workItems.forEach(workItemsForDay => {
          workItemsForDay.forEach(workItem => {
            if(workItems.includes(workItem.workItemId)) {
              roster.removeWorkItem(workItem);
            }
          });
        });
      })
    }

    isWorkItemAlreadyAllocated(workItem) {
        return this.weeklyRosters.some(roster => {
            return roster.workItems[workItem.dayOfWeek]?.some(wi => {
                return !wi.optional && wi.dutyId === workItem.dutyId;
            });
        });
    }

    saveOptionalWorkItemsOnRoster(rosterId) {
        const roster = this.weeklyRosters.find(roster => roster.rosterId === rosterId);
        roster.saveOptionalWorkItems(this);
        this.removeOptionalWorkItems();
        this.updateUnallocatedRoster();
    }

    getAllVehicleIds() {
        return uniq(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getAllVehicleIds());
        }, []));
    }

    getAllEmployeesId() {
        return uniq(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getAllEmployeesId());
        }, []));
    }

    getWorkItemsForEmployee(employeeId) {
        return uniqBy(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getWorkItemsForEmployee(employeeId));
        }, []), 'workItemId');
    }

    getWorkItemsForVehicle(vehicleId) {
        return uniqBy(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getWorkItemsForVehicle(vehicleId));
        }, []), 'workItemId');
    }

    getAllEmployeesIdForDay(day) {
        return uniq(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getAllEmployeesIdForDay(day));
        }, []));
    }

    getWorkItemsForEmployeeForDay(employeeId, day) {
        return uniqBy(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getWorkItemsForEmployeeForDay(employeeId, day));
        }, []), 'workItemId');
    }

    getWorkItemsForDay(day) {
        return uniqBy(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getWorkItemsForDay(day));
        }, []), 'workItemId');
    }

    getAllWorkItems() {
        return uniqBy(this.weeklyRosters.reduce((acc, roster) => {
            return acc.concat(roster.getAllWorkItems());
        }, []), 'workItemId');
    }

    getAllUnallocatedWorkItems() {
        return this.unallocatedRoster.workItems.flat();
    }

    addOptionalWorkItems(workItems) {
        this.weeklyRosters.forEach(roster => {
            roster.removeOptionalWorkItems();
            if (workItems) {
                roster.addOptionalWorkItems(workItems, this);
            }
        });

        // const employeeIds = this.getAllEmployeesId();
        // employeeIds.forEach(employeeId => {
        //     const workItems = this.getWorkItemsForEmployee(employeeId);
        //     if (workItems?.length) {
        //         const compliance = DriverCompliance.checkCompliance({employeeId, workItems});
        //         keys(compliance).forEach(workItemId => {
        //             const workItem = find(workItems, wi => wi.workItemId === workItemId);
        //             workItem.compliance = compliance[workItemId];
        //         });
        //     }
        // });
    }

    getTotalUnallocatedWorkItems() {
        this.removeAlreadyAllocatedWorkItemsFromImport(this.unallocatedRoster)
        return this.unallocatedRoster.getTotalWorkItems();
    }

    getTotalJobs() {
        return this.weeklyRosters.reduce((acc, roster) => {
            return acc + roster.workItems.flat().length;
        }, 0);
    }

    getTotalTime(type, id) {
        return this.weeklyRosters.reduce((acc, roster) => {
            if (type && id) {
                return acc + roster.workItems.flat().filter(workItem => type === 'vehicle' ? workItem.vehicleId === id : workItem.employeeId === id).reduce((acc, workItem) => {
                    return acc + workItem.getActualDuration();
                }, 0);
            } else {
                return acc + roster.getTotalTime();
            }
        }, 0);
    }

    getTotalTimeForDay(type, id, day) {
        return this.weeklyRosters.reduce((acc, roster) => {
            if (type && id) {
                return acc + roster.workItems[day]?.filter(workItem => type === 'vehicle' ? workItem.vehicleId === id : workItem.employeeId === id).reduce((acc, workItem) => {
                    return acc + workItem.getActualDuration();
                }, 0);
            } else {
                return acc + roster.getTotalTimeForDay();
            }
        }, 0);
    }

    getDaysOfWeek() {
        return Array(7).fill(null).map((_, i) => this.date.add(i, 'day'));
    }

    isEmployeeUnavailable(employeeId, selectedWorkItem) {
        if (!selectedWorkItem) return false;
        const dayOfTheWeek = selectedWorkItem.dayOfWeek;
        const startTime = selectedWorkItem.actualStart;
        const endTime = selectedWorkItem.actualEnd;
        
        return some(this.weeklyRosters, weeklyRoster => {
            const workItemsForDay = weeklyRoster.workItems[dayOfTheWeek];
            return some(workItemsForDay, workItem =>
                workItem.employeeId === employeeId &&
                workItem.workItemId !== selectedWorkItem.workItemId &&
                (
                    (startTime >= workItem.actualStart && startTime < workItem.actualEnd) ||
                    (endTime > workItem.actualStart && endTime <= workItem.actualEnd) ||
                    (startTime <= workItem.actualStart && endTime >= workItem.actualEnd)
                )
            );
        });
    }

    isVehicleUnavailable(vehicleId, selectedWorkItem) {
        if (!selectedWorkItem) return false;
        const dayOfTheWeek = selectedWorkItem.dayOfWeek;
        const startTime = selectedWorkItem.actualStart;
        const endTime = selectedWorkItem.actualEnd;

        return some(this.weeklyRosters, weeklyRoster => {
            const workItemsForDay = weeklyRoster.workItems[dayOfTheWeek];
            return some(workItemsForDay, workItem =>
                workItem.vehicleId === vehicleId &&
                workItem.workItemId !== selectedWorkItem.workItemId &&
                (
                    (startTime >= workItem.actualStart && startTime < workItem.actualEnd) ||
                    (endTime > workItem.actualStart && endTime <= workItem.actualEnd) ||
                    (startTime <= workItem.actualStart && endTime >= workItem.actualEnd)
                )
            );
        });
    }

    getEmployeeValidations(employeeData, employee, selectedWorkItems) {
      const validations = [];

      if((this.getTotalTime('employee', employee.employeeID) / 3600) >= DEFAULT_MAX_DRIVER_HRS) {
        validations.push({
          type: 'overtime',
          workItems: selectedWorkItems,
        });
      }

      selectedWorkItems.forEach(selectedWorkItem => {
        if(this.isEmployeeUnavailable(employee.employeeID, selectedWorkItem)) {
          const unavailableValidation = validations.find(validation => validation.type === 'unavailable');
          if(unavailableValidation) {
            unavailableValidation.workItems.push(selectedWorkItem);
          } else {
            validations.push({
              type: 'unavailable',
              workItems: [selectedWorkItem],
            });
          }
        }

        if(employeeData?.isEmployeeOnLeave(employee.employeeID, selectedWorkItem.workItemDate)) {
          const leaveValidation = validations.find(validation => validation.type === 'onLeave');
          if(leaveValidation) {
            leaveValidation.workItems.push(selectedWorkItem);
          } else {
            validations.push({
              type: 'onLeave',
              workItems: [selectedWorkItem],
            });
          }
        }
      })

      return validations;
    }

    getVehicleValidations(vehicle, selectedWorkItems) {
      const validations = [];

      if(vehicle.vehicleStatus === VEHICLE_STATUS.repair) {
        validations.push({
          type: 'repair',
          workItems: selectedWorkItems,
        });
      } else if(vehicle.vehicleStatus === VEHICLE_STATUS.service) {
        validations.push({
          type: 'service',
          workItems: selectedWorkItems,
        });
      }

      selectedWorkItems.forEach(selectedWorkItem => {
        if(this.isVehicleUnavailable(vehicle.vehicleId, selectedWorkItem)) {
          const unavailableValidation = validations.find(validation => validation.type === 'unavailable');
          if(unavailableValidation) {
            unavailableValidation.workItems.push(selectedWorkItem);
          } else {
            validations.push({
              type: 'unavailable',
              workItems: [selectedWorkItem],
            });
          }
        }
      })

      return validations;
    }

    assignDriverToWorkItems(employee, selectedWorkItems) {
      this.weeklyRosters.forEach(roster => {
        roster.workItems.forEach(workItemsForDay => {
          workItemsForDay.forEach(workItem => {
            if(selectedWorkItems.includes(workItem.workItemId) && !workItem.isCompleted && !workItem.optional) {
              workItem.employeeId = employee.employeeID;
              workItem.employeeName = employee.firstName + ' ' + employee.lastName;
              
              // if the roster has no default employee set, set the default employee to the assigned employee
              if(!roster.defaultEmployeeId) {
                roster.setDefaultEmployee(employee, false);
              }
            }
          });
        })
      })
    }
    
    assignVehicleToWorkItems(vehicle, selectedWorkItems) {
      this.weeklyRosters.forEach(roster => {
        roster.workItems.forEach(workItemsForDay => {
          workItemsForDay.forEach(workItem => {
            if(selectedWorkItems.includes(workItem.workItemId) && !workItem.isCompleted && !workItem.optional) {
              workItem.vehicleId = vehicle.vehicleId;
              workItem.vehicleName = vehicle.vehicleName;

              // if the roster has no default vehicle set, set the default vehicle to the assigned vehicle
              if(!roster.defaultVehicleId) {
                roster.setDefaultVehicle(vehicle, false);
              }
            }
          });
        })
      })
    }

    updateWorkItem(workItem) {
        this.weeklyRosters.forEach(roster => {
            roster.workItems.forEach(workItemsForDay => {
                workItemsForDay.forEach(wi => {
                    if (wi.workItemId === workItem.workItemId &&
                      !(wi.isCompleted && workItem.isCompleted)
                    ) {
                      wi.actualStart = workItem.actualStart;
                      wi.actualEnd = workItem.actualEnd;
                      wi.actualPayHours = workItem.actualPayHours;
                      wi.isCompleted = workItem.isCompleted;
                    }
                });
            });
        });
    }

    unallocateWorkItems(workItems) {
        // move work items from weekly rosters to unallocated roster and remove them from the weekly rosters
        this.weeklyRosters.forEach(roster => {
            roster.workItems.forEach(workItemsForDay => {
                workItemsForDay.forEach(workItem => {
                    if(workItems.includes(workItem.workItemId)) {
                      workItem.clean()
                      roster.removeWorkItem(workItem);
                      this.unallocatedRoster.workItems[workItem.dayOfWeek].push(workItem);
                    }
                });
            });
        });
    }

    newRoster() {
        this.weeklyRosters.push(WeeklyRoster.new());
    }

    removeRoster(rosterToRemove) {
        this.weeklyRosters = this.weeklyRosters.filter(roster => roster.rosterId !== rosterToRemove.rosterId);
        rosterToRemove.workItems.forEach((workItemsForDay, dayOfWeek) => {
            // remove employee,vehicle,validations from work items
            workItemsForDay.forEach(workItem => {
              workItem.clean();
              this.unallocatedRoster.workItems[dayOfWeek].push(workItem);
            });
        });
    }

    setBaseData({allVehicles, employeeData}) {
        this.weeklyRosters.forEach(roster => roster.setBaseData({allVehicles, employeeData}));
    }

    toJson() {
        const json = {...this};
        json.weeklyRosters = this.weeklyRosters.map(roster => roster.toJson());
        delete json.unallocatedRoster;
        json.date = json.date.format(DATE_STRING);
        return json;
    }

    fromJson(shiftBatsById, employeesById, vehiclesById) {
        if (typeof this.date === 'string') {
            this.date = dayjs(this.date, DATE_STRING).startOf('day');
        }
        this.weeklyRosters = this.weeklyRosters.map(roster => WeeklyRoster.from(roster).fromJson(shiftBatsById, employeesById, vehiclesById));
        return this;
    }

    static from(data) {
        return new WeeklyScenario(clone(data));
    }

    static new() {
        return new WeeklyScenario({});
    }

    clone() {
        return WeeklyScenario.from(this);
    }

}
