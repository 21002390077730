import React, { useCallback, useMemo, useEffect, useRef } from "react";
import { Button, Empty, Table, Tooltip } from "antd";
import "./Rosters.css";
import { toHrsMinsSecs } from "../../libs/formatLib";
import { WeeklyRoster } from "../../model/roster";
import { DeleteOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import dayjs from "../../dayjs";
import { WorkItemForDay } from "./WorkItemForDay";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import EditRosterPopover from "./EditRosterPopover";
import { useRoster } from "./RosterContext";
import { AllocationAvatar } from "./AllocationUser";
import { useAppContext } from "../../libs/contextLib";

export const getWorkItemColumns = ({ table, workItemRenderer, selectedWorkItems, scenario }) => {
  const startDate = scenario?.date;
  return Array(7)
    .fill()
    .map((_, day) => ({
      title: (
        <div>
          <div className="day-column">
            {capitalize(dayjs().weekday(day).format("dddd"))}
            {startDate && <span>{dayjs(startDate).add(day, "day").format("DD/MM/YYYY")}</span>}
          </div>
        </div>
      ),
      dataIndex: table + "_" + dayjs().weekday(day).format("dddd"),
      render: (_, roster) => {
        if (!roster) return <div>Loading...</div>;
        return workItemRenderer(roster.workItems[day], roster, selectedWorkItems);
      },
    }));
};

function WeeklyRosterTable({ hideHeader }) {
  const { operator } = useAppContext();
  const {
    setPrintWorkItems,
    selectedWorkItems,
    setSelectedWorkItems,
    onSelectWorkItems,
    previousRoster,
    setScenario,
    employeeData,
    allVehicles,
    newRoster,
    saveRoster,
    filteredScenario: scenario,
    runCompliance,
    runValidations,
    registerCallback,
    unregisterCallback,
  } = useRoster();

  // Create a ref for the table container
  const tableContainerRef = useRef(null);

  const doSelectWorkItem = useCallback(
    (workItem, _selectedWorkItems, roster) => {
      if (!workItem) {
        onSelectWorkItems([]);
        return;
      }

      if (workItem?.optional) {
        workItem.prevRoster?.removeWorkItem(workItem);
        roster.saveOptionalWorkItem(workItem, scenario);
        saveRoster([roster, workItem.prevRoster]);
        onSelectWorkItems(_selectedWorkItems.filter((wi) => wi.workItemId !== workItem.workItemId));
      } else {
        if (selectedWorkItems?.find((wi) => wi.workItemId === workItem?.workItemId)) {
          onSelectWorkItems(selectedWorkItems.filter((wi) => wi.workItemId !== workItem.workItemId));
        } else if (_selectedWorkItems?.find((wi) => wi.workItemId === workItem?.workItemId)) {
          onSelectWorkItems(_selectedWorkItems.filter((wi) => wi.workItemId !== workItem.workItemId));
        } else {
          onSelectWorkItems([..._selectedWorkItems, workItem]);
        }
      }
    },
    [selectedWorkItems, onSelectWorkItems, saveRoster, scenario]
  );

  const rosterData = useMemo(() => {
    let time = Date.now();
    let data = (scenario?.weeklyRosters || []).map((roster) => {
      return WeeklyRoster.from({
        key: roster.rosterId,
        ...roster,
      });
    });

    if (operator?.opts?.compliance?.accept) {
      data = runCompliance(data, previousRoster);
    }

    // check if user is on leave, vehicle is in service, etc
    runValidations(scenario, employeeData, allVehicles);

    console.log("WeeklyRoster data: ", Date.now() - time, "ms");
    return data;
  }, [scenario, operator?.opts?.compliance?.accept, runValidations, employeeData, allVehicles, runCompliance, previousRoster]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (tableContainerRef.current) {
        const lastRow = tableContainerRef.current.querySelector(".ant-table-row:last-child");
        if (lastRow) {
          lastRow.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    }, 100)
  };

  useEffect(() => {
    registerCallback("addRoster", scrollToBottom);
    return () => {
      unregisterCallback("addRoster", scrollToBottom);
    };
  }, [registerCallback, unregisterCallback]);

  const handleOnUserClick = useCallback(
    (employeeId, roster) => {
      // select all workItems in this roster for the employee. if already selected, deselect
      const workItems = roster.getWorkItemsForEmployee(employeeId);
      const selected = selectedWorkItems.find((wi) => wi.employeeId === employeeId);
      setSelectedWorkItems(selected ? [] : workItems);
      onSelectWorkItems(selected ? [] : workItems);
    },
    [onSelectWorkItems, selectedWorkItems, setSelectedWorkItems]
  );

  const handleOnVehicleClick = useCallback(
    (vehicleId, roster) => {
      // select all workItems for the vehicle. if already selected, deselect
      const workItems = roster.getAllWorkItems().filter((wi) => wi.vehicleId === vehicleId);
      const selected = selectedWorkItems.find((wi) => wi.vehicleId === vehicleId);
      setSelectedWorkItems(selected ? [] : workItems);
      onSelectWorkItems(selected ? [] : workItems);
    },
    [onSelectWorkItems, selectedWorkItems, setSelectedWorkItems]
  );

  const handlePopoverSave = useCallback(
    (roster, value, color, employee, vehicle) => {
      roster.rosterName = value;
      roster.color = color;
      if (employee) {
        roster.setDefaultEmployee(employee);
      } else {
        roster.clearDefaultEmployee();
      }

      if (vehicle) {
        roster.setDefaultVehicle(vehicle);
      } else {
        roster.clearDefaultVehicle();
      }
      saveRoster(roster);
    },
    [saveRoster]
  );

  const getEmployeeIds = (roster) => {
    let employeeIds = roster?.getEmployeeIds() || [];
    if (employeeIds.length === 0) {
      employeeIds.push(roster.defaultEmployeeId);
    }
    // sort to bring defaultEmployeeId to top
    employeeIds = employeeIds.sort((a, b) => {
      if (a === roster.defaultEmployeeId) return -1;
      if (b === roster.defaultEmployeeId) return 1;
      return 0;
    });

    return employeeIds;
  };

  const rosterRender = useCallback(
    (roster) => {
      let employeeIds = getEmployeeIds(roster);

      if (!roster) return <div>Loading...</div>;
      const d = (
        <div key={"roster-row-" + roster.rosterId} span={3} className="d-flex flex-row roster-cell-main" style={{ gap: 6 }}>
          <div className="roster-name">
            <div className="d-flex align-items-center justify-content-between">
              <EditRosterPopover
                title={"Edit roster name"}
                save={(value, color, employee, vehicle) => handlePopoverSave(roster, value, color, employee, vehicle)}
                value={roster.rosterName}
                color={{
                  value: roster.color,
                }}
                roster={roster}
              />
              <Button
                className={"icon-button btn-filled btn-error btn-delete"}
                icon={<DeleteOutlined />}
                onClick={() => {
                  setScenario((scenario) => {
                    scenario.removeRoster(roster);
                    return scenario.clone();
                  });
                }}
              />
            </div>
            <div className="roster-time mt-1">{toHrsMinsSecs(roster.getTotalTime())}</div>
            <div className="roster-row-color" style={{ backgroundColor: roster.color }}></div>
          </div>
          <div>
            <div className="d-flex flex-column" style={{ gap: 8, paddingBottom: 8, marginBottom: 8, borderBottom: "1px solid #f0f0f0" }}>
              {employeeIds &&
                employeeIds.map((employeeId) => (
                  <div key={roster.rosterId + "_employee_" + employeeId} className={`roster-employee`}>
                    <AllocationAvatar
                      roster={roster}
                      rosterData={rosterData}
                      scenario={scenario}
                      employeeId={employeeId}
                      handleOnUserClick={handleOnUserClick}
                    />
                  </div>
                ))}
            </div>

            <div className="d-flex flex-wrap" style={{ gap: 6 }}>
              {roster?.getVehicleIds &&
                roster.getVehicleIds().map((vehicleId) => {
                  const vehicle = allVehicles[vehicleId];
                  const vehicleName = vehicle?.vehicleName || "Unknown";
                  return (
                    <Tooltip
                      className="vehicle-name"
                      mouseEnterDelay={0.9}
                      key={roster.rosterId + "_tt_vehicle_" + vehicleId}
                      title={`${vehicleName} (${vehicle?.vehicleRego ? vehicle?.vehicleRego: 'Unallocated'})`}
                      placement="bottom"
                      onClick={() => handleOnVehicleClick(vehicleId, roster)}
                    >
                      {vehicleName}
                    </Tooltip>
                  );
                })}
            </div>
          </div>
          {roster.containsOptional() ? (
            <Tooltip title={`Allocate all options in roster ${roster.rosterName}`} mouseEnterDelay={0.9}>
              <span
                className="icon-success"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const optionalWorkItems = roster.getOptionalWorkItems().map((wi) => wi.workItemId);
                  roster.saveOptionalWorkItems(scenario);
                  saveRoster([roster], optionalWorkItems);
                  setSelectedWorkItems(selectedWorkItems.filter((wi) => !optionalWorkItems.includes(wi.workItemId)));
                }}
              >
                <Check />
              </span>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      );
      return d;
    },
    [
      allVehicles,
      rosterData,
      scenario,
      saveRoster,
      handleOnUserClick,
      handlePopoverSave,
      setScenario,
      handleOnVehicleClick,
      setSelectedWorkItems,
      selectedWorkItems,
    ]
  );

  const workItemRenderer = useCallback(
    (workItems, roster, selectedWorkItems, statusColour = "#C1C5CE") => {
      const w = (
        <WorkItemForDay
          roster={roster}
          workItems={workItems}
          onSelectWorkItem={doSelectWorkItem}
          statusColour={statusColour}
          operator={operator}
        />
      );
      return w;
    },
    [doSelectWorkItem, operator]
  );

  const columns = useMemo(() => {
    const columns = [
      {
        title: "Roster",
        render: (_, roster) => rosterRender(roster),
      },
    ].concat(
      getWorkItemColumns({
        table: "rosters",
        workItemRenderer,
        selectedWorkItems,
        employeeData,
        scenario,
        setPrintWorkItems,
      })
    );
    return columns;
  }, [workItemRenderer, selectedWorkItems, employeeData, scenario, setPrintWorkItems, rosterRender]);

  return (
    <>
      <div className={"weekly-roster-table allocated-roster-table"} ref={tableContainerRef}>
        <Table
          dataSource={rosterData}
          columns={columns}
          pagination={false}
          locale={{
            emptyText: (
              <Empty description="No Data">
                <Button onClick={newRoster}>New roster</Button>
              </Empty>
            ),
          }}
          onHeaderRow={() => (hideHeader ? { style: { display: "none" } } : {})}
        />
      </div>
    </>
  );
}

export default React.memo(WeeklyRosterTable);
