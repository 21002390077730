import { Button, DatePicker, Flex } from "antd";
import { WeeklyRoster, WeeklyScenario } from "../../model/roster";
import { useRoster } from "./RosterContext";
import { useAppContext } from "../../libs/contextLib";

export const ImportScenarios = () => {
  const { messageApi } = useAppContext();
  const { selectedDate, unallocatedRoster, importScenarios, cloneAndImport, setSelectedScenario, allRosters } = useRoster();

  return (
    <Flex justify={"center"} className="info-box mb-4">
      <div className="d-flex align-items-center" style={{ gap: 30 }}>
        <Button
          type="primary"
          className="btn-primary"
          onClick={() => {
            const scenario = WeeklyScenario.from({
              date: selectedDate,
              name: "Scenario 1",
              weeklyRosters: [
                WeeklyRoster.from({
                  date: selectedDate,
                  rosterName: "01",
                }),
              ],
              unallocatedRoster,
            });
            importScenarios([scenario]);
            setSelectedScenario(scenario);
          }}
        >
          Create new weekly roster
        </Button>
        <Button
          className="btn-secondary"
          disabled={!allRosters?.[selectedDate?.subtract(1, "week").format("YYYYMMDD")]?.scenarios?.length}
          onClick={() => {
            const weeklyScenarios = allRosters?.[selectedDate?.subtract(1, "week").format("YYYYMMDD")]?.scenarios || [];
            if (!weeklyScenarios?.length) {
              messageApi.warning("No scenarios available");
            }
            cloneAndImport(weeklyScenarios);
          }}
        >
          Import last weeks scenarios
        </Button>
      </div>
      <div className="d-flex align-items-center" style={{ gap: 10 }}>
        <label className="mb-0">Choose week to import</label>
        <DatePicker
          picker={"week"}
          onChange={(date) => {
            if (!date) return;
            const weeklyScenarios = allRosters?.[date.startOf("week").format("YYYYMMDD")]?.scenarios || [];
            if (!weeklyScenarios?.length) {
              messageApi.warning("No scenarios available");
            }
            cloneAndImport(weeklyScenarios);
          }}
        />
      </div>
    </Flex>
  );
};
