import React, { memo } from "react";
import { useRoster } from "./RosterContext";
import { WorkItemView } from "./WorkItemView";

export const WorkItemForDay = memo(({ roster, unallocated, workItems = [], onSelectWorkItem, statusColour }) => {
  const { selectedWorkItems } = useRoster();

  const rosterId = roster?.rosterId;
  return (
    <div key={"roster-jobs-" + rosterId} className={`${unallocated ? "unallocated-" : ""}roster-cell`}>
      {workItems
        .sort((a, b) => (a.duty?.getStartTime() || 0) - (b.duty?.getStartTime() || 0))
        .map((workItem) => {
          return (
            <WorkItemView
              key={rosterId + "-work-item-card-" + workItem.workItemId}
              roster={roster}
              workItem={workItem}
              selected={selectedWorkItems.find((wi) => wi?.workItemId === workItem.workItemId)}
              unallocated={unallocated}
              onSelectWorkItem={(workItem, selected = selectedWorkItems) => {
                onSelectWorkItem(workItem, selected, roster);
              }}
              statusColour={statusColour}
            />
          );
        })}
    </div>
  );
});
