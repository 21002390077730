import React, { useEffect, useState } from "react";
import dayjs from "../../dayjs";
import { ReactComponent as Right } from "../../assets/icons/Right.svg";

export function getNextMonday(date) {
  const dayOfWeek = date.day();
  const daysUntilMonday = (8 - dayOfWeek) % 7;
  return date.add(daysUntilMonday, "day");
}

const DateSelector = ({ initialDate, onChange, view }) => {
  const [selectedDate, setSelectedDate] = useState(view === "weekly" ? initialDate.startOf("week") : initialDate);

  const getDateLabel = (date) => {
    const today = dayjs();

    if (view === "weekly") {
      const weekStart = date.startOf("week"); // Start of the week
      const weekEnd = date.endOf("week"); // End of the week
      return `${weekStart.format("DD/MM/YYYY")} - ${weekEnd.format("DD/MM/YYYY")}`;
    }

    if (today.isSame(date, "day")) {
      const tomorrow = today.add(1, "days");
      return `Today - ${tomorrow.format("DD/MM/YYYY")}`;
    }
    const yesterday = today.subtract(1, "days");

    if (yesterday.isSame(date, "day")) {
      return `${yesterday.format("DD/MM/YYYY")} - Today`;
    }
    const nextDay = date.add(1, "days");
    return `${date.format("DD/MM/YYYY")} - ${nextDay.format("DD/MM/YYYY")}`;
  };

  const onSetSelectedDate = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  const handlePrevious = () => {
    if (view === "weekly") {
      const newDate = selectedDate.subtract(1, "week").startOf("week");
      onSetSelectedDate(newDate);
    } else {
      onSetSelectedDate(selectedDate.subtract(1, "day"));
    }
  };

  const handleNext = () => {
    if (view === "weekly") {
      const newDate = selectedDate.add(1, "week").startOf("week");
      onSetSelectedDate(newDate);
    } else {
      onSetSelectedDate(selectedDate.add(1, "day"));
    }
  };

  return (
    <div className="DateSelector">
      <button onClick={handlePrevious}>
        <Right />
      </button>
      <strong>{getDateLabel(selectedDate)}</strong>
      <button onClick={handleNext}>
        <Right />
      </button>
    </div>
  );
};

export default DateSelector;
