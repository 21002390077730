import React, { memo, useEffect, useMemo, useState } from "react";
import { useRoster } from "./RosterContext";
import { Avatar, Checkbox, Popover, Tooltip } from "antd";
import { WorkItemCard } from "./WorkItemCard";
import { Bus, Income, Time, Warning } from "../../components/Icons";
import { Allocation } from "./AllocationModal";
import { employeeName } from "./SelectEmployeeView";

export const WorkItemView = memo(({ roster, workItem, selected, unallocated, onSelectWorkItem, statusColour = "#C1C5CE" }) => {
  const { employeeData, allVehicles, allCharters, saveRoster, selectedWorkItems } = useRoster();

  const [showMenu] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!!selected);
  }, [selected]);

  const onMultiSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // check if user is pressing Ctrl or Cmd
    if (e.ctrlKey || e.metaKey) {
      onSelectWorkItem(workItem);
    } else {
      onSelectWorkItem(workItem, []);
    }
  };

  const onWorkItemSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setChecked(!checked);
    onSelectWorkItem(workItem);
  };

  const [isCharter, charter] = useMemo(() => {
    const _isCharter = workItem?.duty?.charter;
    let charter;

    if (_isCharter) {
      charter = Object.values(allCharters).find((c) => c.duties.some((d) => d.shiftBatId === workItem.duty.shiftBatId));
    }

    return [_isCharter, charter];
  }, [workItem, allCharters]);

  const handleNavigate = (workItem) => {
    if (isCharter) {
      window.open(`/charters/${charter.charterId}/duty`, "_blank");
    } else {
      window.open(`/shiftbats/${workItem.dutyId}`, "_blank");
    }
  };

  const allIssues = useMemo(() => {
    return [...(workItem.compliance ?? []), ...(workItem.validations ?? [])];
  }, [workItem]);

  const allocatedVehicle = workItem.vehicleId && allVehicles ? allVehicles[workItem.vehicleId] : null;
  const allocatedEmployee = workItem.employeeId && employeeData ? employeeData.getEmployee(workItem.employeeId) : null;

  const showOptional = useMemo(() => {
    return selectedWorkItems.find((wi) => wi.workItemId === workItem.workItemId);
  }, [workItem, selectedWorkItems]);

  const workItemBackgroundColor = useMemo(() => {
    // TODO: add logic for other work item types (rail, emergency, tours)
    const isCharter = workItem?.duty?.charter;
    return isCharter ? "#007BFF" : "#7AB32E";
  }, [workItem]);

  return (
    <>
      {workItem.optional ? (
        <Tooltip title={`Allocate ${workItem.workItemName} to roster ${roster.rosterName}.`}>
          {showOptional && (
            <WorkItemCard
              onSelectWorkItem={onSelectWorkItem}
              unallocated={unallocated}
              selected={selected}
              statusColour={statusColour}
              showMenu={showMenu}
              isCharter={isCharter}
              charter={charter}
              saveRoster={saveRoster}
              roster={roster}
              workItem={workItem}
            />
          )}
        </Tooltip>
      ) : (
        <div>
          <div
            key={`work-item-div-${workItem.workItemId}`}
            className={`roster-item ${unallocated ? "item-unallocated" : ""} ${selected ? "item-selected" : ""} ${
              workItem?.optional ? "item-optional" : ""
            } ${workItem.compliance?.length ? "item-compliance" : ""} ${workItem.isCompleted ? "item-completed" : ""}`}
          >
            <Popover
              trigger="hover"
              placement="rightTop"
              mouseEnterDelay="0.6"
              overlayClassName="roster-item-popover"
              title={
                <div className="roster-name">
                  <span onClick={() => handleNavigate(workItem)}>{workItem.workItemName}</span>
                  {isCharter && charter?.name && `${charter?.name}`}
                  {!isCharter && workItem?.duty?.shiftBatName && `${workItem?.duty?.shiftBatName}`}
                </div>
              }
              content={
                <div className="info-content">
                  <div>
                    <label>
                      <Time width={14} height={14} />
                      Start Time
                    </label>{" "}
                    : <div>{workItem.startTimeFormatted}</div>
                  </div>
                  <div>
                    <label>
                      <Time width={14} height={14} />
                      End Time
                    </label>{" "}
                    : {workItem.endTimeFormatted}
                  </div>
                  <div>
                    <label>
                      <Time width={14} height={14} />
                      Duration
                    </label>{" "}
                    : {workItem.scheduledHours?.toFixed(2)} hours
                  </div>
                  <div>
                    <label>
                      <Income width={14} height={18} />
                      Paid Time
                    </label>{" "}
                    : {workItem.actualPayHours?.toFixed(2) || (workItem.getActualDuration()/3600)?.toFixed(2) || 0} hours
                  </div>
                  {allocatedVehicle && (
                    <div>
                      <label>
                        <Bus className="icon-dark" width={14} height={14} />
                        Vehicle Cost
                      </label>{" "}
                      : ${allocatedVehicle.rate || 0}
                    </div>
                  )}
                  <div className="info-content-allocation">
                    {allocatedEmployee && (
                      <div>
                        <Avatar size={28} style={{ backgroundColor: "#C1C5CE", verticalAlign: "middle" }}>
                          {allocatedEmployee.firstName[0]}
                          {allocatedEmployee.lastName[0]}
                        </Avatar>
                        <strong>
                          {allocatedEmployee.firstName} {allocatedEmployee.lastName}
                        </strong>
                      </div>
                    )}
                    {allocatedVehicle && (
                      <div>
                        <Avatar size={28} style={{ backgroundColor: "#C1C5CE", verticalAlign: "middle" }}>
                          <Bus width={16} height={16} />
                        </Avatar>
                        <strong>{allocatedVehicle.vehicleRego}</strong>
                      </div>
                    )}
                  </div>
                  {allIssues.length ? (
                    <div className="info-content-issues">
                      <strong>
                        Warnings <Warning width={15} height={15} />
                      </strong>
                      {allIssues.map((c) => (
                        <div>{c}</div>
                      ))}
                    </div>
                  ) : null}
                </div>
              }
            >
              <div className="roster-item-wrap" onClick={onMultiSelect}>
                <Checkbox checked={checked} onClick={onWorkItemSelect} />
                <div className="roster-type" style={{ backgroundColor: workItemBackgroundColor }}></div>
                <div className="d-flex flex-column" style={{ gap: 6 }}>
                  <div className="roster-name">
                    {workItem.workItemName}
                    {isCharter && charter?.name && `: ${charter?.name}`}
                    {!isCharter && workItem?.duty?.shiftBatName && `: ${workItem?.duty?.shiftBatName}`}
                  </div>
                  <div className="roster-time">
                    <Time width={14} height={14} />
                    {workItem.startTimeFormatted}-{workItem.endTimeFormatted}
                  </div>
                  {!unallocated ? (
                    <div className="roster-pay">
                      <Income width={14} height={18} />
                      {workItem.actualPayHours?.toFixed(2) || (workItem.getActualDuration()/3600)?.toFixed(2) || 0}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {!unallocated ? (
                  <Allocation
                    employeeId={employeeData.getEmployee(workItem.employeeId)?.employeeID}
                    employeeName={employeeName(employeeData.getEmployee(workItem.employeeId))}
                    vehicleName={allVehicles[workItem.vehicleId]?.vehicleName}
                    statusColour={statusColour}
                  />
                ) : (
                  <></>
                )}
                {allIssues.length ? (
                  <span className="icon-warning">
                    <Warning />
                  </span>
                ) : null}
              </div>
            </Popover>
          </div>
        </div>
      )}
    </>
  );
});
