import { Button, Input, Popover, Select, Switch } from "antd";
import { useRoster } from "./RosterContext";
import { useDebounce } from "rooks";
import { Close, Refine } from "../../components/Icons";

const { Search } = Input;

export const rosteringFilterItems = {
  charter: {
    name: "Charters",
    tagColor: "#007BFF",
    color: "success",
    showFn: (wi) => wi?.duty?.charter,
  },
  services: {
    name: "Services",
    tagColor: "#7AB32E",
    color: "success",
    showFn: (wi) => !wi?.duty?.charter,
  },
  tours: {
    name: "Tours",
    tagColor: "#F0AD4E",
    color: "success",
    showFn: (wi) => wi?.duty?.type === "tour",
  },
  emergency: {
    name: "Emergency Bus",
    tagColor: "#BE0B0B",
    color: "success",
    showFn: (wi) => wi?.duty?.type === "emergency",
  },
  rail: {
    name: "Rail Replacement",
    tagColor: "#C0811C",
    color: "success",
    showFn: (wi) => wi?.duty?.type === "rail", 
  },
};

export const RosteringFilters = () => {
  const {
    selectedWorkItems,
    selectedDrivers,
    setSelectedDrivers,
    selectedVehicles,
    setSelectedVehicles,
    filterBy,
    setFilterBy,
    filter,
    setFilter,
    onSelectWorkItems,
    rosteredVehicles,
    rosteredDrivers,
  } = useRoster();
  const setFilterDebounced = useDebounce(setFilter, 250);

  return (
    <div className="d-flex align-items-center justify-content-between filter-options-main mb-0">
      <div>
        <div className="d-flex align-items-center" style={{ gap: 0 }}>
          {selectedWorkItems?.length > 0 && (
            <div className="d-flex justify-content-end w-separator">
              <Button type="primary" className={`icon-button`} onClick={() => onSelectWorkItems()}>
                Un-select
              </Button>
            </div>
          )}
          <div className="justify-content-end mr-4">
            <Search
              id="basic-addon1"
              style={{
                width: 180,
              }}
              value={filter}
              allowClear={true}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <Popover
            placement="bottomRight"
            trigger="click"
            overlayClassName="filters-popover"
            title={
              <div className="d-flex justify-content-between align-items-center">
                Filter settings
                {filter || filterBy?.length || selectedVehicles?.length || selectedDrivers?.length ? (
                  <Button
                    onClick={() => {
                      setFilter("");
                      setFilterBy([]);
                      setSelectedVehicles([]);
                      setSelectedDrivers([]);
                    }}
                    type="primary"
                    className="icon-button btn-filled icon-10 btn-xs"
                  >
                    <Close /> Clear
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            }
            content={
              <div className="filter-wrap">
                <div className="d-flex align-items-center justify-content-between" style={{ marginBottom: "10px" }}>
                  <label>Driver</label>
                  <Select
                    mode="multiple"
                    className={"multi-select rounded"}
                    style={{ width: 150 }}
                    showSearch
                    optionFilterProp="label"
                    placeholder="Select driver"
                    multiple={true}
                    value={selectedDrivers}
                    onChange={setSelectedDrivers}
                    options={rosteredDrivers.map((driver) => ({
                      label: driver ? driver?.firstName + " " + driver?.lastName : "Unassigned",
                      value: driver ? driver?.employeeID : null,
                    }))}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <label>Vehicle</label>
                  <Select
                    mode="multiple"
                    className={"multi-select rounded"}
                    style={{ width: 150 }}
                    showSearch
                    optionFilterProp="label"
                    placeholder="Select vehicle"
                    multiple={true}
                    value={selectedVehicles}
                    onChange={setSelectedVehicles}
                    options={rosteredVehicles.map((vehicle) => ({
                      label: vehicle ? vehicle?.vehicleName || vehicle?.vehicleRego : "Unallocated",
                      value: vehicle ? vehicle?.vehicleId : null,
                    }))}
                  />
                </div>
                <div className="filter-switches-sm">
                  {Object.keys(rosteringFilterItems).map((key) => {
                    const filterItem = rosteringFilterItems[key];
                    return (
                      <div key={`filter-switch-${filterItem.name}`}>
                        <span>
                          <i style={{ background: filterItem.tagColor }} /> {filterItem.name}
                        </span>{" "}
                        <Switch
                          className="default-switch"
                          size="small"
                          checked={filterBy.includes(key)}
                          onChange={(checked) =>
                            setFilterBy((filterBy) => (checked ? [...filterBy, key] : filterBy.filter((f) => f !== key)))
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          >
            <Button type="primary" className={`icon-button inline-icon`} onClick={() => onSelectWorkItems()}>
              <Refine />
              Filter
            </Button>
          </Popover>
        </div>
      </div>
    </div>
  );
};
