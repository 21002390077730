import { Card, Col, Row, Button, Modal, Switch, Tag, Collapse, List, Tooltip, TreeSelect, Input, Checkbox } from "antd";
import "./SMS.css";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { employeeModelData, smsModelData } from "../../services/ModelService";
import { useAppContext } from "../../libs/contextLib";
import { ulid } from "ulid";
import { PlusOutlined } from "@ant-design/icons";
import LoaderButton from "../../components/LoaderButton";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { ReactComponent as Trash } from "../../assets/icons/Trash.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/Minus.svg";
import { Auth } from "aws-amplify";
import { sendEmailViaTemplate } from "../../libs/mailer";
import { values } from "lodash";
import dayjs from "../../dayjs";

const PRICE_PER_UNIT = 0.09;
const DEFAULT_UNITS = 1000;

const DO_NOT_REPLY = "Do not reply to this SMS.";

function SMS() {
    const { operator, email } = useAppContext();
    const [history, setHistory] = useState([]);

    const [showAddCredits, setShowAddCredits] = useState(false);
    const [showSendMessage, setShowSendMessage] = useState(false);

    const [custom, setCustom] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [staffGroups, setStaffGroups] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [students, setStudents] = useState([]);
    const [schools, setSchools] = useState([]);
    const [showOptions, setShowOptions] = useState({
        staff: true,
        customers: false,
        schools: false,
    });
    const treeRef = useRef(null);
    const [searchKey, setSearchKey] = useState("");
    const [treeReceivers, setTreeReceivers] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [filterTreeReceivers, setFilterTreeReceivers] = useState([]);

    const [includeDoNotReply, setIncludeDoNotReply] = useState(true);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [openTemplates, setOpenTemplates] = useState(false);

    const [selectedRecord, setSelectedRecord] = useState(null);
    const [openHistory, setOpenHistory] = useState(false);

    const [openQuotaRequest, setOpenQuotaRequest] = useState(false);
    const [buyNoUnits, setBuyNoUnits] = useState(DEFAULT_UNITS);
    const [buyAmount, setBuyAmount] = useState(PRICE_PER_UNIT * DEFAULT_UNITS);

    const [records, setRecords] = useState([]);
    const [quota, setQuota] = useState({
        quota: 0,
        used: 0,
        remaining: 0,
    });
    const [templates, setTemplates] = useState([]);
    const [addTemplate, setAddTemplate] = useState(false);
    const [quotaRecords, setQuotaRecords] = useState(true);
    const [current, setCurrent] = useState({});
    const [smsUnits, setSmsUnits] = useState(0);
    const [cost, setCost] = useState(0);
    const [sending, setSending] = useState(false);
    const [purchasing, setPurchasing] = useState(false);

    const setBasicEmployeeData = (employees) => {
        const keys = Object.keys(employees);
        const staffData = [];
        keys.forEach((key) => {
            if (key.includes("DETAILS")) {
                const emp = employees[key.replace("DETAILS", "EXTERNAL")];
                const staffGroup = employees[key.replace("DETAILS", "EMPLOYMENT")]?.staffGroups ?? [];

                if (!emp || !emp.mobile) return;
                staffData.push({
                    key: `EMPLOYEE:${key}`,
                    label: `${emp.firstName} ${emp.lastName} - ${emp.mobile}`,
                    value: employees[key].sk,
                    type: "staff",
                    extra: {
                        phone: emp.mobile,
                        staffGroup,
                    },
                });
            }
        });
        setEmployees(staffData);
    };

    useEffect(() => {
        const load = async () => {
            const canSendSMS = (await Auth.currentUserInfo()).attributes["custom:sms:send"] ?? false;
            setShowSendMessage(canSendSMS === "true");

            const isAdmin = (await Auth.currentUserInfo()).attributes["custom:sms:admin"] ?? false;
            setShowAddCredits(isAdmin === "true");
            !canSendSMS && setShowSendMessage(isAdmin === "true");

            smsModelData.addListener({
                setterFn: setRecords,
                loaded: (sms) => setRecords(sms),
            });

            const staffgroupMasterList = await employeeModelData.getAll({ summary: false }, "#STAFFGROUP");
            if (Object.keys(staffgroupMasterList || {}).length > 0)
                setStaffGroups(
                    values(staffgroupMasterList)?.map((group) => ({
                        key: group.sk,
                        label: group.name,
                        value: group.name,
                        type: "staffGroup",
                    }))
                );

            // set employees and staff groups
            employeeModelData.addListener({
                loaded: (employees) => setBasicEmployeeData(employees),
                setterFn: setBasicEmployeeData,
            });
        };
        if (operator) {
            load().then(() => {
                console.log("Data loaded.");
            });
        }

        return () => {
            smsModelData.removeListener();
            employeeModelData.removeListener();
        };
    }, [operator]);

    useEffect(() => {
        if (!records) return;
        // tempoary fix to filter records by companyId
        const recordsArray = Object.values(records).filter(r => r.companyId === operator?.companyId);

        const quotaRecordItems = recordsArray.filter((record) => record.type === "quota#sms");
        if (quotaRecordItems.length > 0) setQuota(quotaRecordItems[0]);

        const historyRecords = recordsArray.filter((record) => record.type === "history#sms");
        setHistory(historyRecords.reverse());

        const templateRecords = recordsArray.filter((record) => record.type === "template#sms");
        setTemplates(templateRecords);
    }, [records]);

    useEffect(() => {
        let treeReceiverOptions = [];
        if (showOptions.staff && employees.length) {
            treeReceiverOptions = [
                ...treeReceiverOptions,
                {
                    // key: "staff-key",
                    key: "staff",
                    value: "staff",
                    title: "All Staff Members",
                    // value: "staff",
                    label: "All Staff Members",
                    children: employees.map((emp) => {
                        return {
                            key: emp.key,
                            value: emp.key,
                            title: emp.label,
                            label: emp.label,
                            extra: emp.extra,
                        };
                    }),
                },
            ];
        }
        if (showOptions.staff && staffGroups) {
            treeReceiverOptions = [
                ...treeReceiverOptions,
                ...staffGroups.map((group) => {
                    return {
                        key: group.key,
                        value: group.key,
                        title: group.label,
                        // value: group.key,
                        label: group.label,
                        children: employees
                            .filter((emp) => emp.extra.staffGroup.includes(group.key))
                            .map((emp) => ({
                                // ...emp,
                                // prevent duplicate employees across tree data
                                key: `${group.value}-group:${emp.value}`,
                                value: `${group.value}-group:${emp.value}`,
                                title: emp.label,
                                label: emp.label,
                                extra: emp.extra,
                                // value: `${group.value}-group:${emp.value}`,
                            })),
                    };
                }),
            ];
        }
        if (custom) {
            treeReceiverOptions = [
                ...treeReceiverOptions,
                ...custom,
                // {
                //     key: "custom",
                //     value: "custom",
                //     title: "Custom",
                //     label: "Custom",
                //     children: custom,
                // },
            ];
        }
        // TODO: update logic below
        // if (showOptions.customers && customers) {
        //     treeReceiverOptions = [...treeReceiverOptions, ...customers];
        // }
        // if (showOptions.students && students) {
        //     treeReceiverOptions = [...treeReceiverOptions, ...students];
        // }
        // if (showOptions.schools && schools) {
        //     treeReceiverOptions = [...treeReceiverOptions, ...schools];
        // }
        setTreeReceivers(treeReceiverOptions);
        // setExpandedKeys(treeReceiverOptions.map((option) => option.key));
    }, [custom, employees, staffGroups, students, schools, customers, showOptions]);

    const addQuota = useCallback(async () => {
        if (!buyNoUnits || !buyAmount) return;
        setPurchasing(true);
        if (quota.history) {
            const newQuota = {
                ...quota,
                remaining: (Number(quota.remaining) ?? 0) + Number(buyNoUnits),
                quota: (Number(quota.quota) ?? 0) + Number(buyNoUnits),
                history: [
                    ...quota.history,
                    {
                        date: new Date(),
                        message: `Added ${buyNoUnits} units`,
                        smsUnits: buyNoUnits,
                        cost: buyAmount,
                        requestedBy: email,
                    },
                ],
            };
            await smsModelData.save(newQuota);
            setQuota(newQuota);
        } else {
            const id = ulid();
            const quota = {
                id,
                companyId: operator?.companyId,
                sk: "#QUOTA#",
                type: "quota#sms",
                quota: Number(buyNoUnits),
                used: 0,
                remaining: Number(buyNoUnits),
                history: [
                    {
                        date: new Date(),
                        message: `Initial: Added ${buyNoUnits} units`,
                        smsUnits: buyNoUnits,
                        cost: buyAmount,
                        requestedBy: email,
                    },
                ],
            };
            await smsModelData.save(quota);
            setQuota(quota);
        }

        // inform Roly of the purchase
        const toEmail = process.env.NODE_ENV === "production" ? "hello@busable.com.au,heroly.chour@gmail.com" : "avindu@busable.com.au,avinduhewa@gmail.com";
        await sendEmailViaTemplate({
            to: [toEmail],
            subject: "SMS Quota Purchase",
            merge: {
                operator: `${email} from ${operator?.operatorId} - ${operator?.companyId}`,
                amount: buyAmount,
                units: buyNoUnits,
                date: new Date().toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }),
            },
            template: "Invoice - SMS: quota added (busable)",
        });

        setPurchasing(false);
        setOpenQuotaRequest(false);
        setBuyAmount(DEFAULT_UNITS * PRICE_PER_UNIT);
        setBuyNoUnits(DEFAULT_UNITS);
    }, [quota, operator, buyAmount, buyNoUnits]);

    const handleChangeTree = (value, type) => {
        const selectedPhones = new Set();
        const getSelectedNodes = (nodes, values) => {
            let selectedNodesFlat = [];
            let selectedNodesTree = [];

            for (let node of nodes) {
                if (values.includes(node.value)) {
                    if (node.children) {
                        selectedNodesFlat = [...selectedNodesFlat, ...node.children];
                        selectedNodesTree.push({ ...node }); // Copy the node
                    } else {
                        selectedNodesFlat.push(node);
                        selectedNodesTree.push(node);
                    }
                } else if (node.children) {
                    const [childSelectedNodesFlat, childSelectedNodesTree] = getSelectedNodes(node.children, values);
                    selectedNodesFlat = [...selectedNodesFlat, ...childSelectedNodesFlat];
                    if (childSelectedNodesTree.length > 0) {
                        selectedNodesTree.push({ ...node, children: childSelectedNodesTree });
                    }
                }
            }

            return [selectedNodesFlat, selectedNodesTree];
        };

        const [selectedRecordsFlat, selectedRecordsTree] = getSelectedNodes(treeReceivers, value);
        selectedRecordsFlat.forEach((record) => {
            const phone = record.extra.phone?.replace(/\s+/g, ''); // Remove all whitespaces
            selectedPhones.add(phone);
        });
        
        setCurrent({
            ...current,
            selected: selectedRecordsFlat,
            selectedTree: selectedRecordsTree,
            phones: Array.from(selectedPhones),
        });
    };

    const handleMessageChange = (event) => {
        setCurrent({
            ...current,
            message: event.target.value,
        });
    };

    const handleSendMessage = async (values) => {
        try {
            const message = includeDoNotReply ? `${current.message}\n${DO_NOT_REPLY}` : current.message;
            setSending(true);
            const id = ulid();
            const object = {
                companyId: operator?.companyId,
                sk: `#SMS#${id}`,
                id: id,
                type: "history#sms",
                message: message,
                phones: current.phones,
                users: current.selectedTree,
                date: new Date().toString(),
                taskStatus: "PENDING",
                cost: {
                    units: current.smsUnits,
                    amount: current.cost,
                    currency: "AUD",
                },
            };
            const saved = await smsModelData.save(object);
            const { sms: updatedRecord } = await smsModelData.sendSMS(saved);
            await smsModelData.save(updatedRecord);

            // Updates the total quota if a purchase has been exhausted
            const usedAmount = quota.used + current.smsUnits;
            const remainingAmount = quota.remaining - current.smsUnits;
            let difference = quota.quota - remainingAmount;
            let total = quota.quota;
            const history = quota.history.map((history) => {
                if (history.exhausted) return history;
                if (difference >= history.smsUnits) {
                    difference -= history.smsUnits;
                    total -= history.smsUnits;
                    return {
                        ...history,
                        exhausted: true,
                    };
                }
                return history;
            });

            // update quota
            const newQuota = {
                ...quota,
                used: usedAmount,
                remaining: remainingAmount,
                quota: total,
                history: history,
            };
            await smsModelData.save(newQuota);
            setQuota(newQuota);
            setSending(false);

            // setHistory(history.map((record) => (record.id === updatedRecord.id ? updatedRecord : record)));
            setSelectedRecord(updatedRecord);
            setOpenHistory(true);

            setCurrent({});
        } catch (err) {
            console.error(err);
            setSending(false);
        }
    };

    const calculateSmsUnits = (message) => {
        if (!message) return 0;

        const isUnicode = [...message].some((char) => char.charCodeAt(0) > 127);
        const singleSegmentSize = isUnicode ? 70 : 160;
        const multiSegmentSize = isUnicode ? 67 : 153;

        const messageLength = message.length;

        if (messageLength <= singleSegmentSize) {
            return 1;
        } else {
            return Math.ceil(messageLength / multiSegmentSize);
        }
    };

    useEffect(() => {
        const numberOfReceivers = current.phones ? current.phones.length : 0;
        const newSmsUnits = calculateSmsUnits(current.message) * numberOfReceivers;
        const newCost = (newSmsUnits * PRICE_PER_UNIT).toFixed(2);

        setSmsUnits(newSmsUnits);
        setCost(newCost);
    }, [current]);

    useEffect(() => {
        setCurrent({
            ...current,
            smsUnits,
            cost,
        });
    }, [smsUnits, cost]);

    function isValidAustralianPhoneNumber(number) {
        const regex = /^(?:\+?61|0)?(?:4\d{2}|\(04\)\d{2})[-.\s]?\d{3}[-.\s]?\d{3}$/;
        return regex.test(number);
    }

    function formatAustralianPhoneNumber(number) {
        const cleaned = number.replace(/[^\d+]/g, "");
        let match = cleaned.match(/^(?:\+?61|0)?(4\d{2})(\d{3})(\d{3})$/);
        if (match) {
            return `+61 ${match[1]} ${match[2]} ${match[3]}`;
        }
        return number;
    }

    const onSearchKeyEnter = (e) => {
        const isEnter = e.key === "Enter";
        const isSearchKeyValidAusNumber = isValidAustralianPhoneNumber(searchKey);
        const number = searchKey;

        const alreadyInCustom = custom.find((c) => c.key === number);

        if (isEnter && isSearchKeyValidAusNumber) {
            const customNode = {
                label: `${number}`,
                key: number,
                value: number,
                type: "custom",
                extra: {
                    phone: number,
                },
            };

            if (!alreadyInCustom) {
                setCustom([...custom, customNode]);
            }

            setSearchKey("");
            // force treeselect to clear search key
            treeRef.current?.blur();
            setCurrent({
                ...current,
                selected: [...(current.selected || []), customNode],
                phones: [...(current.phones || []), number],
            });
        }
    };

    // Template managing
    const createTemplate = async (message) => {
        const id = ulid();
        const template = {
            id,
            companyId: operator?.companyId,
            sk: `#TEMPLATE#${id}`,
            type: "template#sms",
            message: selectedTemplate.message,
            author: operator.id,
        };
        console.log("Creating template", template);
        await smsModelData.save(template);
        setTemplates([...templates, template]);
        setSelectedTemplate(null);
        setAddTemplate(false);
    };

    // TODO: fix delete not removing record
    const deleteTemplate = async (id) => {
        const template = templates.find((template) => template.id === id);
        await smsModelData.delete(template.smsId, true);
        setTemplates(templates.filter((template) => template.id !== id));
        setSelectedTemplate(null);
    };

    const updateTemplate = async () => {
        const sk = selectedTemplate.sk;
        const template = templates.find((template) => template.sk === sk);
        const updatedTemplate = {
            ...template,
            message: selectedTemplate.message,
        };
        console.log("Updating template", updatedTemplate);
        await smsModelData.save(updatedTemplate);
        setTemplates(templates.map((template) => (template.sk === sk ? updatedTemplate : template)));
        setSelectedTemplate(null);
        setAddTemplate(false);
    };

    const applyTemplate = (id) => {
        const template = templates.find((template) => template.id === id);
        setCurrent({
            ...current,
            message: template.message,
        });
        setSelectedTemplate(null);
        setOpenTemplates(false);
    };

    useEffect(() => {
        setBuyNoUnits(Number((buyAmount / PRICE_PER_UNIT).toFixed(0)));
    }, [buyAmount]);

    useEffect(() => {
        setBuyAmount(Number((buyNoUnits * PRICE_PER_UNIT).toFixed(0)));
    }, [buyNoUnits]);

    const tagError = () => (
        <div className="simple-tags">
            <Tag color="error">Error</Tag>
        </div>
    );
    const tagPending = () => (
        <div className="simple-tags">
            <Tag color="warning">Pending</Tag>
        </div>
    );
    const tagSuccess = () => (
        <div className="simple-tags">
            <Tag color="success">Success</Tag>
        </div>
    );

    const calculateRemainderAsPercentage = useCallback(() => {
        return (quota.remaining / quota.quota) * 100;
    }, [quota]);

    const getTaskStatus = (status) => {
        switch (status) {
            case "PENDING":
                return <Tag color="warning">Pending</Tag>;
            case "SENT":
                return <Tag color="success">Sent</Tag>;
            case "ERROR":
                return <Tag color="error">Error</Tag>;
            default:
                return <Tag color="default">Unknown</Tag>;
        }
    };

    const getReceiversAsText = (record) => {
        if (record.users) {
            return record.users?.map((user) => `${user.label} (${user.children?.length})`).join(", ");
        }
        return record.phones?.join(", ");
    };

    const generateItemHistory = useCallback(() => {
        const getStatusTag = (status) => {
            switch (status) {
                case "Pending":
                    return tagPending();
                case "Success":
                    return tagSuccess();
                case "Failed":
                    return tagError();
                default:
                    return tagPending();
            }
        };
        const records = [];
        if (selectedRecord.users) {
            records.push(
                ...selectedRecord.users?.map((item) => {
                    let childrenStatuses = [];
                    if (item.children) {
                        childrenStatuses = item.children.map((child) => {
                            const result = selectedRecord?.results?.find((result) => result.phoneNumber === child?.extra?.phone);
                            const status = result?.status ?? "Pending";
                            const reason = (result?.message || result?.reason) ?? null;
                            return { status, reason };
                        });
                    } else {
                        const result = selectedRecord?.results?.find((result) => result.phoneNumber === item?.extra?.phone);
                        const status = result?.status ?? "Pending";
                        const reason = (result?.message || result?.reason) ?? null;
                        childrenStatuses = [{ status, reason }];
                    }

                    let childStatus = "Success";
                    if (childrenStatuses.some(({ status }) => status === "Failed")) {
                        childStatus = "Error";
                    } else if (childrenStatuses.some(({ status }) => status === "Pending")) {
                        childStatus = "Pending";
                    }

                    const object = {
                        key: item.key,
                        label: item.label,
                        children: (
                            <div className="modal-info-rows">
                                {childrenStatuses.map(({ status, reason }, index) => {
                                    const child = item.children ? item.children[index] : item;
                                    return (
                                        <Row key={child.key}>
                                            <Col span={12}>{child.label}</Col>
                                            <Col span={12} className={`info-col-status ${status?.toLowerCase()}`}>
                                                {reason} - {getStatusTag(status)}
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        ),
                        extra: childStatus,
                    };
                    return object;
                })
            );
        }
        if (selectedRecord.phones) {
            // get phone numbers that arent in the users list
            const customPhones = selectedRecord?.phones.filter(
                (phone) =>
                    !selectedRecord?.users?.some((user) => {
                        const isChild = user.children?.some((child) => child.extra.phone === phone);
                        return user.extra?.phone === phone || isChild;
                    })
            );
            if (customPhones.length > 0) {
                records.push({
                    key: "Custom",
                    label: "Custom Numbers",
                    children: (
                        <div className="modal-info-rows">
                            {customPhones.map((phone) => {
                                const result = selectedRecord?.results?.find((result) => result.phoneNumber === phone);
                                const status = result?.status ?? "Pending";
                                const reason = (result?.message || result?.reason) ?? null;
                                return (
                                    <Row key={phone}>
                                        <Col span={12}>{phone}</Col>
                                        <Col span={12} className={`info-col-status ${status?.toLowerCase()}`}>
                                            {reason ? `${reason} - ` : ""}
                                            {getStatusTag(status)}
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    ),
                });
            }
        }

        return records;
    }, [selectedRecord]);

    const incrementRequest = () => {
        setBuyNoUnits(buyNoUnits + DEFAULT_UNITS);
    };

    const decrementRequest = () => {
        if (buyNoUnits > 0) {
            setBuyNoUnits(buyNoUnits - DEFAULT_UNITS);
        }
    };

    const characterCount = useMemo(() => {
        const _newline = 1;
        const msgCount = current?.message?.length || 0;
        const doNotReplyCount = includeDoNotReply ? DO_NOT_REPLY.length + _newline || 0 : 0;
        return msgCount + doNotReplyCount;
    }, [current?.message?.length, includeDoNotReply]);

    const MAX_SMS_LENGTH = useMemo(() => {
      const _newline = 1;
      const doNotReplyCount = includeDoNotReply ? DO_NOT_REPLY.length + _newline || 0 : 0;
      return 400 - doNotReplyCount;
    }, [includeDoNotReply]);

    const isMessageTooLong = useMemo(() => {
      const _newline = 1;
      const doNotReplyCount = includeDoNotReply ? DO_NOT_REPLY.length + _newline || 0 : 0;
      return characterCount > (MAX_SMS_LENGTH + doNotReplyCount);
    }, [includeDoNotReply, characterCount, MAX_SMS_LENGTH]);

    if (!employees || !history || !quota) {
        return "Loading";
    }

    return (
        <div className="Marketplace AppSMS">
            <Row gutter={[30, 30]}>
                {showSendMessage ? (
                    <>
                        <Col xs={24} md={16} lg={18}>
                            <Card title="Send to" bordered={false} className="card-main">
                                <Row gutter={[0, 30]}>
                                    {/* TODO: enable when we want to support external source control */}
                                    {/* <Col className="SwitchGroup pt-2">
                                        <div>
                                            <Switch
                                                className="default-switch"
                                                checked={showOptions.staff}
                                                onChange={() => setShowOptions({ ...showOptions, staff: !showOptions.staff })}
                                            />{" "}
                                            Staff
                                        </div>
                                        <div>
                                            <Switch
                                                className="default-switch"
                                                checked={showOptions.customers}
                                                onChange={() => setShowOptions({ ...showOptions, customers: !showOptions.customers })}
                                            />{" "}
                                            Customers
                                        </div>
                                        <div>
                                            <Switch
                                                className="default-switch"
                                                checked={showOptions.schools}
                                                onChange={() => setShowOptions({ ...showOptions, schools: !showOptions.schools })}
                                            />{" "}
                                            Schools
                                        </div>
                                    </Col> */}
                                    <Col xs={24}>
                                        <TreeSelect
                                            ref={treeRef}
                                            size="large"
                                            className="SelectLarge"
                                            style={{ width: "100%" }}
                                            placeholder="Select recipients"
                                            onChange={(values) => handleChangeTree(values)}
                                            value={current.selected}
                                            treeCheckable={true}
                                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                            treeData={treeReceivers}
                                            showSearch
                                            treeExpandedKeys={expandedKeys}
                                            onTreeExpand={(keys) => setExpandedKeys(keys)}
                                            autoClearSearchValue
                                            allowClear
                                            filterTreeNode={(input, node) => {
                                                return node.label.toLowerCase().includes(input.toLowerCase());
                                            }}
                                            onSearch={(value) => setSearchKey(value)}
                                            onKeyUp={onSearchKeyEnter}
                                        />
                                        <span>For custom numbers please enter as 04XXXXXXXX</span>
                                    </Col>
                                    <Col xs={24} className="d-flex flex-column align-items-end">
                                        <Row gutter={[20, 0]}>
                                            <Button className="icon-button mb-4" onClick={() => setOpenTemplates(true)}>
                                                View Templates
                                            </Button>
                                        </Row>
                                        <div className="w-100">
                                            <TextArea
                                                size="large"
                                                maxLength={MAX_SMS_LENGTH}
                                                placeholder="Type your Message"
                                                style={{
                                                    height: 120,
                                                    resize: "none",
                                                }}
                                                onChange={handleMessageChange}
                                                value={current.message}
                                            />
                                            <Row className="mt-2">
                                                <Col xs={12} md={12} lg={12}>
                                                    <Checkbox
                                                        value={includeDoNotReply}
                                                        checked={includeDoNotReply}
                                                        onChange={({ target: { checked } }) => {
                                                            setIncludeDoNotReply(checked);
                                                        }}
                                                    >
                                                        Include "No Reply" in the message
                                                    </Checkbox>
                                                </Col>
                                                <Col className="d-flex justify-content-end" xs={12} md={12} lg={12}>
                                                  <span>Total character count (Max: 400) - {characterCount}</span>
                                                </Col>
                                            </Row>
                                            {/* {current.smsUnits ? (
                                                <span className="text-info-md">Using {current.smsUnits} credit per sms</span>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[20, 0]} className="mt-5">
                                    <Col xs={16} md={16} lg={18} className="d-flex align-items-center">
                                        {current?.phones?.length > 0 && (
                                            <span className="text-info-lg">
                                                {/* Sending these <strong>{current?.phones?.length}</strong> SMS messages will cost{" "}
                                            <strong>${current?.cost}</strong> AUD */}
                                                Sending message to <strong>{current?.phones?.length}</strong> unique numbers (members).
                                                Total units consumed: <strong>{current?.smsUnits}</strong>
                                            </span>
                                        )}
                                    </Col>
                                    <Col xs={8} md={8} lg={6} className="d-flex align-items-center justify-content-end">
                                        <Button
                                            type="primary"
                                            className="primary-btn"
                                            size="large"
                                            onClick={() => handleSendMessage()}
                                            disabled={
                                                sending ||
                                                !current?.phones?.length ||
                                                !current?.message ||
                                                quota.remaining < current.smsUnits ||
                                                isMessageTooLong
                                            }
                                        >
                                            {sending ? "Sending..." : "Send SMS"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                            <Card title="SMS Quota" bordered={false} className="card-main card-quota">
                                <div className="d-flex flex-column align-items-center" style={{ width: "100%" }}>
                                    <div className="pie animate no-round" style={{ "--p": calculateRemainderAsPercentage() }}>
                                        {quota.remaining}
                                    </div>
                                    <h4 className="title mt-3">{quota.remaining} Messages Left</h4>
                                    <span className="text-info mt-1 mb-4">Out of {quota.quota}</span>
                                    {showAddCredits && (
                                        <Button className="btn-outline-lg" onClick={() => setOpenQuotaRequest(true)}>
                                            Buy More
                                        </Button>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    </>
                ) : null}

                <Col xs={24} lg={24}>
                    <Card bordered={false} className="card-main">
                        <Row className="card-row-header align-items-center">
                            <Col xs={9} className="d-flex align-items-center">
                                <h3>SMS History</h3>
                            </Col>
                            <Col xs={6} className="text-left">
                                <span>Sent to</span>
                            </Col>
                            <Col xs={3} className="text-left">
                                <span>Sent by</span>
                            </Col>
                            <Col xs={3}>
                                <span>Sent Date</span>
                            </Col>
                            <Col xs={3}>
                                <span>Status</span>
                            </Col>
                        </Row>
                        {history.map((record) => (
                            <Row
                                onClick={() => {
                                    setSelectedRecord(record);
                                    setOpenHistory(true);
                                }}
                                className="card-row-content border-full align-items-center"
                                style={{ cursor: "pointer" }}
                                key={record.id}
                            >
                                <Col xs={9}>{record.message}</Col>
                                <Col xs={6} className="text-left">
                                    {record.phones.length} {record.phones.length > 1 ? "members" : "member"}
                                </Col>
                                <Col xs={3} className="text-left">
                                    {record.author}
                                </Col>
                                <Col xs={3}>{new Date(record?.date).toLocaleDateString("en-GB")}</Col>
                                <Col xs={3} className="simple-tags">
                                    {getTaskStatus(record.taskStatus)}
                                </Col>
                            </Row>
                        ))}
                    </Card>
                </Col>
            </Row>
            {/* Template Modal */}
            <Modal
                open={openTemplates}
                onCancel={() => {
                    setOpenTemplates(false);
                    setAddTemplate(false);
                }}
                width={700}
                destroyOnClose
                footer={[
                    <Button
                        className="btn-secondary"
                        onClick={() => {
                            setOpenTemplates(false);
                            setAddTemplate(false);
                        }}
                    >
                        Close
                    </Button>,
                ]}
            >
                <div class="ant-modal-header d-flex" style={{ gap: "20px" }}>
                    <div class="ant-modal-title">SMS Templates</div>
                    <Button onClick={() => setAddTemplate(true)} type="primary" className="icon-button" icon={<PlusOutlined />}>
                        Add Template
                    </Button>
                </div>
                <div class="modal-info pt-3 pb-2">
                    {addTemplate ? (
                        <Row gutter={[30, 30]} className="mb-5">
                            <Col xs={24} lg={24}>
                                <label>SMS Message Template</label>
                                <div className="w-100">
                                    <TextArea
                                        size="large"
                                        showCount
                                        // maxLength={160}
                                        placeholder="Type your Message"
                                        style={{
                                            height: 120,
                                            resize: "none",
                                        }}
                                        value={selectedTemplate?.message}
                                        onChange={(template) =>
                                            setSelectedTemplate({
                                                ...selectedTemplate,
                                                message: template.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </Col>
                            <Col xs={24} lg={24} className="d-flex flex-row">
                                {selectedTemplate?.id ? (
                                    <Button type="primary" className="btn-primary" onClick={updateTemplate}>
                                        Update Template
                                    </Button>
                                ) : (
                                    <Button type="primary" className="btn-primary" onClick={createTemplate}>
                                        Create Template
                                    </Button>
                                )}
                                <Button
                                    className="btn-secondary"
                                    onClick={() => {
                                        setAddTemplate(false);
                                        setSelectedTemplate(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}

                    <List
                        bordered
                        dataSource={templates}
                        renderItem={(item) => (
                            <List.Item
                                key={item.id}
                                onClick={() => applyTemplate(item.id)}
                                actions={[
                                    <Tooltip placement="bottom" title="Edit">
                                        <LoaderButton
                                            className="btn-icon-control"
                                            onClick={() => {
                                                setAddTemplate(true);
                                                setSelectedTemplate(item);
                                            }}
                                        >
                                            <Edit style={{ width: 14 }} />
                                        </LoaderButton>
                                    </Tooltip>,
                                    <Tooltip placement="bottom" title="Delete">
                                        <LoaderButton className="btn-icon-control btn-delete" onClick={() => deleteTemplate(item.id)}>
                                            <Trash style={{ width: 12 }} />
                                        </LoaderButton>
                                    </Tooltip>,
                                ]}
                            >
                                <div style={{ cursor: "pointer" }}>{item.message}</div>
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
            {/* SMS history detail Modal */}
            <Modal
                open={openHistory}
                title="SMS History"
                onCancel={() => {
                    setOpenHistory(false);
                    setSelectedRecord(null);
                }}
                width={700}
                destroyOnClose
                footer={[
                    <Button
                        className="btn-secondary"
                        onClick={() => {
                            setOpenHistory(false);
                            setSelectedRecord(null);
                        }}
                    >
                        Close
                    </Button>,
                ]}
            >
                {selectedRecord && (
                    <div class="ant-modal-body modal-info text-light pt-3 pb-2">
                        <Row gutter={[30, 30]}>
                            <Col xs={24} lg={12}>
                                <label>Sent to</label>
                                {/* <strong>Clerical Staff (23), Dubbo Drivers (31), John Smith</strong> */}
                                <strong>{getReceiversAsText(selectedRecord)}</strong>
                            </Col>
                            <Col xs={24} lg={6}>
                                <label>Sent date</label>
                                <strong>{dayjs(selectedRecord.date).format("DD/MM/YYYY HH:mm") || "-"}</strong>
                            </Col>
                            <Col xs={24} lg={6}>
                                <label>Sent by</label>
                                <strong>{selectedRecord.author}</strong>
                            </Col>
                            <Col xs={24} lg={12}>
                                <label>Cost</label>
                                <strong>${selectedRecord.cost.amount}</strong>
                            </Col>
                            <Col xs={24} lg={12}>
                                <label>Status</label>
                                <div className="simple-tags">{getTaskStatus(selectedRecord.taskStatus)}</div>
                            </Col>
                            <Col xs={24} lg={24}>
                                <label>Message</label>
                                <strong>{selectedRecord.message}</strong>
                            </Col>
                            <Col xs={24} lg={24}>
                                <label>Status Log</label>
                                <Collapse accordion className="w-100 collapse-body-0" items={generateItemHistory()} />
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
            {/* Request SMS quota Modal */}
            <Modal
                open={openQuotaRequest}
                title="Request SMS Quota"
                onCancel={() => setOpenQuotaRequest(false)}
                footer={[
                    <Button className="btn-secondary" onClick={() => setOpenQuotaRequest(false)}>
                        Cancel
                    </Button>,
                    <Button type="primary" className="btn-primary" onClick={addQuota} disabled={purchasing}>
                        {purchasing ? "Requesting..." : "Request Quota"}
                    </Button>,
                ]}
            >
                <div class="ant-modal-body modal-info pt-3 pb-4">
                    {/* <Row gutter={[30, 30]}>
                        <Col xs={24} lg={12}>
                            <label>Request by no. of SMS</label>
                            <Input placeholder="1000" value={buyNoUnits} onChange={(e) => setBuyNoUnits(e.target.value)} type="number" />
                        </Col>
                        <Col xs={24} lg={12}>
                            <label>Request by amount $</label>
                            <Input placeholder="12.00" value={buyAmount} onChange={(e) => setBuyAmount(e.target.value)} />
                        </Col>
                    </Row> */}
                    <Row className="mb-4">
                        <Col xs={24}>
                            <Row className="mb-1">
                                <Col xs={13}>
                                    <label>No. of SMS</label>
                                </Col>
                                <Col xs={11} className="text-center">
                                    <label>Amount</label>
                                </Col>
                            </Row>
                            <div className="RequestQuota">
                                <Row>
                                    <Col xs={13} className="d-flex align-items-center justify-content-between">
                                        <span style={{ fontSize: "20px" }}>{buyNoUnits}</span>
                                        <div className="d-flex" style={{ gap: "6px" }}>
                                            <Button
                                                type="primary"
                                                className="icon-button"
                                                icon={<Minus />}
                                                onClick={decrementRequest}
                                            ></Button>
                                            <Button
                                                type="primary"
                                                className="icon-button"
                                                icon={<Plus />}
                                                onClick={incrementRequest}
                                            ></Button>
                                        </div>
                                    </Col>
                                    <Col xs={11} className="d-flex align-items-center justify-content-center" style={{ fontSize: "32px" }}>
                                        <b style={{ fontSize: "32px" }}>${buyAmount}</b>
                                    </Col>
                                </Row>
                            </div>
                            <Row className="mt-1">
                                <Col xs={13}></Col>
                                <Col xs={11} className="text-center">
                                    <label>(exc GST)</label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
}

export default React.memo(SMS);
