import { ColorPicker, Input, Popconfirm, Row, Select, Button } from "antd";
import { values } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useRoster } from "./RosterContext";

const EditRosterPopover = ({ save, cancel, value, title, child, color, roster, ...props }) => {
  const { employeeData, allVehicles } = useRoster();
  const employees = useMemo(() => values(employeeData.allEmployees), [employeeData]);
  const vehicles = useMemo(() => values(allVehicles), [allVehicles]);

  const [newValue, setNewValue] = useState(value);
  const [selectedColor, setSelectedColor] = useState(color?.value);
  const [selectedEmployee, setSelectedEmployee] = useState(roster.defaultEmployeeId);
  const [selectedVehicle, setSelectedVehicle] = useState(roster.defaultVehicleId);

  const [vehicleSearchTerm, setVehicleSearchTerm] = useState("");
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState("");

  useEffect(() => {
    setNewValue(value);
    setSelectedColor(color?.value);
    setSelectedEmployee(roster.defaultEmployeeId);
    setSelectedVehicle(roster.defaultVehicleId);
  }, [color?.value, roster, value])

  const filteredVehicles = useMemo(() => {
    if (!vehicleSearchTerm) return vehicles;
    const search = vehicleSearchTerm.toLowerCase();
    return (
      vehicles?.filter((vehicle) => {
        return vehicle.registration?.toLowerCase().includes(search);
      }) || []
    );
  }, [vehicles, vehicleSearchTerm]);

  const filteredEmployees = useMemo(() => {
    if (!employeeSearchTerm) return employees;
    const search = employeeSearchTerm.toLowerCase();
    return (
      employees?.filter((employee) => {
        return employee.firstName?.toLowerCase().includes(search) || employee.lastName?.toLowerCase().includes(search);
      }) || []
    );
  }, [employees, employeeSearchTerm]);

  const handleCancel = () => {
    cancel && cancel();
    setNewValue(value);
    setSelectedColor(color?.value);
  };

  const handleSave = () => {
    const employee = selectedEmployee ? employees.find((e) => e.employeeID === selectedEmployee) : null;
    const vehicle = selectedVehicle ? vehicles.find((v) => v.vehicleId === selectedVehicle) : null;
    save(newValue, selectedColor, employee, vehicle);
  };

  return (
    <Popconfirm
      title={title || "Edit"}
      icon=""
      description={
        <>
          <Row className="flex-nowrap mb-2" style={{ gap: 8 }}>
            <Input value={newValue} onChange={(e) => setNewValue(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />
            {color && (
              <ColorPicker
                value={selectedColor}
                onChangeComplete={async (color) => {
                  setSelectedColor(color.toHexString());
                }}
              />
            )}
          </Row>
          <Row className="flex-nowrap mb-2">
            <Select
              className="w-100"
              showSearch
              placeholder="Default Employee"
              optionFilterProp="label"
              value={selectedEmployee}
              onChange={(value) => setSelectedEmployee(value)}
              onSearch={(value) => setEmployeeSearchTerm(value)}
              options={filteredEmployees.map((employee) => {
                return {
                  value: employee.employeeID,
                  label: `${employee.firstName} ${employee.lastName}`,
                };
              })}
            />
          </Row>
          <Row className="flex-nowrap">
            <Select
              className="w-100"
              showSearch
              placeholder="Default Vehicle"
              optionFilterProp="label"
              value={selectedVehicle}
              onChange={(value) => setSelectedVehicle(value)}
              onSearch={(value) => setVehicleSearchTerm(value)}
              options={filteredVehicles.map((vehicle) => {
                return {
                  value: vehicle.vehicleId,
                  label: vehicle.vehicleRego,
                };
              })}
            />
          </Row>
        </>
      }
      onConfirm={handleSave}
      onCancel={handleCancel}
      okButtonProps={{ type: "primary", className: "primary-btn btn-small" }}
      cancelButtonProps={{ type: "primary", className: "btn-secondary btn-small" }}
      destroyTooltipOnHide={true}
      {...props}
    >
      {child || <span style={{ cursor: "pointer" }}>{value}</span>}
    </Popconfirm>
  );
};

export default React.memo(EditRosterPopover);
