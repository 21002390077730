import React, {useCallback, useEffect, useState} from 'react';
import {Prompt, useHistory, useParams} from 'react-router-dom';
import {onError} from '../libs/errorLib';
import './Stops.css';
import './StopManager.css';
import LoadMessage from '../components/LoadMessage';
import {useAppContext} from '../libs/contextLib';
import FilteredStopList from '../components/FilteredStopList';
import StopsMapViewer from '../components/StopsMapViewer';
import {stopModelData, transferModelData} from '../services/ModelService';
import {find, flatten, isEqual, keyBy, sortBy, uniqBy, values} from 'lodash';
import {createStopVerification, fetchMasterStopsInBounds, getCurrentLog, updateStop} from '../services/routeService';
import {FormControl, InputGroup} from 'react-bootstrap';
// eslint-disable-next-line
import util from 'util';
import {useScroll} from '../libs/hooksLib';
import {getCachedPath,} from '../libs/pathLib';
import {Stop} from '../model/busRoute';
import {PlusOutlined, SearchOutlined, WarningTwoTone} from '@ant-design/icons';
import ReactGA from 'react-ga4';
import {Button, Dropdown, Flex, Select, Switch} from 'antd';
import {DUPLICATE_SOURCE_ID_TEXT, getStops, toCsv, validateStop} from '../libs/exportLib';
import fileDownload from 'js-file-download';
import dayjs from '../dayjs';
import {ReactComponent as Export} from '../assets/icons/Export.svg';
import {STOP_TYPES_OPTIONS} from '../components/EditStop';
import log from 'loglevel';
import ImportModal from '../features/Import/ImportModal';
import {WebMercatorViewport} from 'react-map-gl';
import {debounce} from 'lodash/function';
import {isPointInPolygon} from 'geolib';
import {ulid} from 'ulid';
import {getEditor} from '../hooks/getEditor';
import {toggleStrInArray} from '../libs/dataStructs';
import {ReactComponent as Close} from '../assets/icons/Close.svg';
import {getDistanceInMetres} from '../libs/routes-lib';

export const SHARED_STOP_MIN_ZOOM = 14;
export const GEOHASH_DUPLICATE_LENGTH = 11;

const setDuplicateStops = (stopsById, distance) => {
    if (stopsById) {
        Object.keys(stopsById).forEach(stopId => {
            const stop = stopsById[stopId];
            stop.duplicates = [];
            Object.keys(stopsById).forEach(otherStopId => {
                const otherStop = stopsById[otherStopId];
                if (stopId === otherStopId) {
                    return;
                }
                if (getDistanceInMetres(stop, otherStop) <= distance) {
                    // if (stop.geohash?.slice(0, geohashLength) === otherStop.geohash?.slice(0, geohashLength)) {
                    stop.duplicates.push(otherStop);
                }
            });
            if (!stop.duplicates.length) {
                delete stop.duplicates;
            }
        });
    }
    return stopsById;
};

const setDuplicateStopsDebounced = debounce(setDuplicateStops, 100);

const evaluate = (filter, stop) => {
    return (!filter.stopTypeValue?.length || filter.stopTypeValue.includes(stop.stopType || 'bus'))
        && (!filter.shared || stop.authorityId?.length)
        && (!filter.outOfSync || stop.outOfSync?.length || stop.master)
        && (!filter.duplicateSourceId || stop.warnings?.includes(DUPLICATE_SOURCE_ID_TEXT))
        && (!filter.noStopCode || !stop.stopCode?.length)
        && (!filter.unserviced || (!stop.routes?.length && (!stop.stopType?.length || stop.stopType === 'bus')))
        && ((!filter.duplicates && !filter.byDistance) || stop.duplicates)
        && (!filter.verified || stop.verified)
        && (!filter.unverified || !stop.verified)
        && (!filter.linked || stop.linkedStops?.length)
        && (stop.master || !filter.ownerValues?.length || filter.ownerValues.some(filterOwner => {
            return stop.authorityId ? (filterOwner.toLowerCase() === stop.authorityId?.split('#')[1].toLowerCase() || filterOwner.toLowerCase() === stop.authorityId) : filterOwner === stop.author;
        }))
        && (!filter.transfers || stop.routes?.some(r => r.transfers?.length) || stop.transferWindow > 0)
        && (!filter.search || !filter.search.length || ((stop.stopName || '').toLowerCase().indexOf(filter.search) > -1)
            || ((stop.stopCode || '').toLowerCase().indexOf(filter.search) > -1)
            || ((stop.stopId || '').toLowerCase().indexOf(filter.search) > -1)
            || ((stop.owner || '').toLowerCase().indexOf(filter.search) > -1)
            || ((stop.lastEditor || '').toLowerCase().indexOf(filter.search) > -1)
            || (stop.routes || []).some(r => {
                return r.routeNumber.toLowerCase().includes(filter.search);
            }));
};

const filterStops = (stops, filter) => {
    logger.debug('Filtering stops.');
    return values(stops).filter(stop => !stop.merged).filter(stop => evaluate(filter, stop));
};

const removeDeletedStopFromLinks = (deletedStopIds = [], stops) => {
    stops.filter(stop => stop.linkedStops?.length).forEach(stop => {
        const idx = stop.linkedStops.findIndex(linkedStop => deletedStopIds.includes(linkedStop.stopId));
        if (idx > -1) {
            stop.linkedStops.splice(idx, 1);
        }
    });
};

// const stopTypeOptions = [
//     {value: "bus", label: "Bus stop"},
//     {value: "school", label: "School"},
//     {value: "depot", label: "Depot"},
//     {value: "nonpub", label: "Non public"},
// ]

const marks = {
    0: '0m',
    1: '1m',
    2: '5m',
    3: '10m',
};

const items = [
    {
        key: '1',
        label: (
            <a target="_blank" rel="noopener noreferrer" href=""></a>
        ),
    }
];

const updateInterchangeDirections = async (interchange) => {
    if (interchange.length > 1) {
        // for (let i = 0; i < interchange.length; i++) {
        interchange = await Promise.all(interchange.map(async startStop => {
            // for (let j = 0; j < startStop.linkedStops.length; j++) {
            //     const endStop = startStop.linkedStops[j];

            startStop.linkedStops = await Promise.all((startStop.linkedStops || []).map(async linkedStop => {
                console.log('Calculating path from %s', startStop.stopName, {...linkedStop});
                try {
                    const result = await getCachedPath('walking', startStop, linkedStop.stop, 4.5);
                    if (result) {
                        console.log('We have a result!', result);
                        linkedStop.waypoints = result.waypoints;
                        linkedStop.duration = result.duration;
                        linkedStop.distance = result.distance;
                    }
                } catch (e) {
                    console.log(e);
                }

                return linkedStop;
            }));
            return startStop;
        }));
    }
    return interchange;
};

const logger = log.getLogger('PointManager');

const DEFAULT_FILTER_STATE = {
    outOfSync: false,
    shared: false,
    duplicates: false,
    unserviced: false,
    verified: false,
    unverified: false,
    noStopCode: false,
    linked: false,
    transfers: false,
    byDistance: false,
    byDistanceValue: -1,
    search: null,
    stopTypeValue: null,
    ownerValues: null,
};

function StopManager() {
    const {isAuthenticated, apiKey, features, isAdmin, messageApi, operator, user} = useAppContext();
    const {stopId} = useParams();
    const {editor} = getEditor({user});

    const history = useHistory();
    const [filteredStops, setFilteredStops] = useState(null);
    const [masterStops, setMasterStops] = useState(null);
    const [stopsToShowInMap, setStopsToShowInMap] = useState([]);
    const [stops, setStops] = useState(null);
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);
    const [zoomStop, setZoomStop] = useState(null);
    const [editStop, setEditStop] = useState(null);
    const [transferStop, setTransferStop] = useState(null);
    const [verifiedStops, setVerifiedStops] = useState([]);
    const [selected, setSelected] = useState({});
    // eslint-disable-next-line
    const [viewport, setViewport] = useState(null);
    const [bounds, setBounds] = useState(null);

    const [isVerifying, setIsVerifying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isMerging, setIsMerging] = useState(false);
    const [isLinking, setIsLinking] = useState(false);

    const [transfersModalOpen, setTransfersModalOpen] = useState(false);

    const [executeScroll, scrollRef] = useScroll({block: 'start'});
    const [updated, setUpdated] = useState(false);
    const [filter, setFilter] = useState({...DEFAULT_FILTER_STATE});

    const [ownerOptions, setOwnerOptions] = useState([]);

    const setFilterDebounced = debounce(setFilter, 250);

    const [allTransfers, setAllTransfers] = useState(null);

    const [distanceToCursor, setDistanceToCursor] = useState(-1);

    const [loadingmasterStops, setLoadingmasterStops] = useState(false);

    const [showmasterStops, setShowmasterStops] = useState(['bus', 'school']);
    const [fetchMasterStopsFn, setFetchMasterStopsFn] = useState(null);
    const [busLastUpdated, setBusLastUpdated] = useState(null);
    const [schoolLastUpdated, setSchoolLastUpdated] = useState(null);

    const [search, setSearch] = useState('');

    useEffect(() => {
        logger.debug('Updating scroll...');
        executeScroll();
    }, [selected, executeScroll]);

    const updateSharedStopsLastUpdated = useCallback(() => {
        getCurrentLog('shared_stops#import#school#current', 'australia#tfnsw').then(log => {
            if (log?.updatedAt > 0) {
                setSchoolLastUpdated(dayjs(log.updatedAt));
            }
        });
        getCurrentLog('shared_stops#import#bus#current', 'australia#tfnsw').then(log => {
            if (log?.updatedAt > 0) {
                setBusLastUpdated(dayjs(log.updatedAt));
            }
        });
    }, [setSchoolLastUpdated, setBusLastUpdated]);

    useEffect(() => {
        updateSharedStopsLastUpdated();
    }, []);

    const handleViewStateChange = useCallback((viewState) => {
        const viewport = new WebMercatorViewport(viewState);
        setViewport(viewport);
        setBounds(viewport.getBoundingRegion());
    }, [setViewport, setBounds]);

    const cancelDebounceFetchMasterStops = useCallback(() => {
        if (fetchMasterStopsFn?.cancel) {
            fetchMasterStopsFn.cancel();
        }
        setLoadingmasterStops(false);
    }, [fetchMasterStopsFn, setLoadingmasterStops]);

    const handleTransfersModalOpen = useCallback((open) => {
        if (open) {
            cancelDebounceFetchMasterStops();
        }
        setTransfersModalOpen(open);
    }, [cancelDebounceFetchMasterStops, setTransfersModalOpen]);

    const fetchMasterStops = useCallback((bounds, types) => {

        const debounceFetchMasterStops = debounce(bounds => {
            let minLat = Math.min(...bounds.map((p) => p[1]));
            let minLon = Math.min(...bounds.map((p) => p[0]));
            let maxLat = Math.max(...bounds.map((p) => p[1]));
            let maxLon = Math.max(...bounds.map((p) => p[0]));

            console.log('bounds: ', minLat, minLon, maxLat, maxLon);
            setLoadingmasterStops(true);
            updateSharedStopsLastUpdated();
            fetchMasterStopsInBounds({
                bounds: {minLat, minLon, maxLat, maxLon},
                excluding: [],
                types
            }).then(masterStops => {
                let filteredMasterStops = filterStops(masterStops, filter);
                filteredMasterStops = filteredMasterStops.filter(stop => {
                    return isPointInPolygon({latitude: stop.lat, longitude: stop.lon}, bounds);
                });
                setMasterStops(filteredMasterStops);
                setLoadingmasterStops(false);
            });
        }, 500);
        setFetchMasterStopsFn(() => debounceFetchMasterStops);
        debounceFetchMasterStops(bounds);

    }, [setLoadingmasterStops, setMasterStops, filter, setFetchMasterStopsFn, updateSharedStopsLastUpdated]);

    useEffect(() => {
        logger.debug('Filtering stops...');

        if (editStop || transfersModalOpen) {
            return;
        }

        let filteredStops = filterStops(stops, filter);
        logger.debug('Done filtering stops.');

        setStopsToShowInMap(mapStops => isEqual(sortBy(mapStops, 'stopId'), sortBy(filteredStops, 'stopId')) ? mapStops : filteredStops);

        setFilteredStops(keyBy(filteredStops, 'stopId'));

    }, [editStop, transfersModalOpen, stops, setFilteredStops, filter]);

    useEffect(() => {

        logger.debug('Getting shared stops...');
        if (editStop || transfersModalOpen || !showmasterStops?.length || !bounds) {
            return;
        }
        if (showmasterStops?.length && viewport.zoom >= SHARED_STOP_MIN_ZOOM) {
            fetchMasterStops(bounds, showmasterStops);
        } else {
            setMasterStops(null);
        }

    }, [editStop, transfersModalOpen, showmasterStops, bounds, fetchMasterStops, setSchoolLastUpdated, setBusLastUpdated]);

    useEffect(() => {
        logger.debug('Loading stops...');

        const transfersLoadedFn = transfers => setAllTransfers(values(transfers));
        let stopsListener;

        async function onLoad() {
            console.log('Loading points...');
            try {

                if (!isAuthenticated) {
                    return;
                }

                stopsListener = stopModelData.addListener({
                    notifyUpdate: (stops) => {
                        if (!Array.isArray(stops)) {
                            stops = [stops];
                        }
                        setStops(_stops => {
                            stops.forEach(stop => {
                                _stops[stop.stopId] = new Stop(stop);
                                _stops[stop.stopId].setLinkedStops(_stops);
                            });
                            return {..._stops};
                            // return manHandleStops(_stops);
                        });
                    },
                    notifyDelete: ids => {
                        if (!Array.isArray(ids)) {
                            ids = [ids];
                        }
                        setStops(_stops => {
                            if (!_stops) return;
                            ids.forEach(id => {
                                delete _stops[id];
                            });
                            return {..._stops};
                            // return manHandleStops(_stops);
                        });
                    },
                    loaded: stopsByStopId => {
                        console.log('Stops loaded: ', Object.keys(stopsByStopId).length);
                        Object.keys(stopsByStopId).forEach(stopId => {
                            stopsByStopId[stopId].setLinkedStops(stopsByStopId);
                            stopsByStopId[stopId].userId = apiKey;
                        });
                        setStops({...stopsByStopId});
                        // setStops(manHandleStops(stopsByStopId));

                        if (stopId) {
                            const selectedStop = stopsByStopId[stopId];
                            setSelected({[stopId]: selectedStop});
                            setZoomStop({[stopId]: selectedStop});
                        }
                    }
                });

                transferModelData.addListener({loaded: transfersLoadedFn});
                const allTransfers = await transferModelData.getAll();
                setAllTransfers(values(allTransfers));
            } catch (e) {
                console.log(`Couldn't load stops... Error: ${e}`, e);
                if (isAdmin) {
                    onError(e);
                }
            }
        }

        onLoad().then(() => console.log('Points loaded...'));
        ReactGA.send({hitType: 'pageview', page: '/', title: 'planable-points'});

        return () => {
            stopModelData.removeListener(stopsListener);
            transferModelData.removeListener(transfersLoadedFn);
        };
    }, [isAuthenticated, history, setStops, apiKey, isAdmin, stopId, setSelected, setZoomStop]);

    useEffect(() => {
        let ownerOpts = uniqBy(values(stops).filter(s => s.author), 'author').map(stop => {
            return {value: stop.author, label: stop.author};
        });
        ownerOpts = ownerOpts.concat(uniqBy(values(stops).filter(s => s.authorityId), s => s.authorityId.split('#')[1]).map(stop => {
            return {value: stop.authorityId, label: stop.authorityId.split('#')[1].toUpperCase()};
        }));

        setOwnerOptions(ownerOpts);

        // Set the warnings on the stops
        if (stops) {
            Object.keys(stops).forEach(stopId => {
                const stop = stops[stopId];
                stop.warnings = validateStop(stops, stop);
            });
        }
    }, [stops, setOwnerOptions]);

    const handleImportStops = useCallback(async selected => {
        if (!Object.keys(selected).length) {
            return;
        }

        const stopsToSave = values(selected);
        const existingStop = find(values(stops), s => {
            return s.stopCode?.length && stopsToSave.some(newStop => {
                return s.stopCode === newStop.stopCode;
            });
        });
        if (existingStop) {
            messageApi.error(`Import failed. Another point already exists with source ID '${existingStop.stopCode}'.`);
            return;
        }

        await stopModelData.save(values(selected).map(s => ({...s, stopId: ulid()})));
        setSelected({});
    }, [setFilteredStops, setStops, setSelected, stops]);

    const handleStopsInView = useCallback((stops) => {
        setFilteredStops(stops);
        setSelected(selected => {
            Object.keys(selected).forEach(selectedStopId => {
                if (!stops[selectedStopId]) {
                    delete selected[selectedStopId];
                }
            });
            return selected;
        });
        setZoomStop(null);
    }, [setFilteredStops, setZoomStop]);

    const handleSelectedStops = useCallback((selectedStops) => {
        // if (!verifiedStops || !Object.keys(verifiedStops).length) {
        //     return;
        // }
        // stops = stops.filter(stop => Object.keys(verifiedStops).indexOf(stop.stopId) === -1 && !stops.filter(_stop => _stop.stopId === stop.stopId)[0].verified)
        let _selected = {};
        selectedStops.forEach(stop => {
            _selected[stop.stopId] = stops[stop.stopId] || filteredStops[stop.stopId] || find(masterStops, ['stopCode', stop.stopCode]);
            // stop.selected = true
        });
        console.log(`Selected: ${Object.keys(_selected).length}`);
        setSelected(_selected);
        return _selected;
    }, [selected, setSelected, stops, filteredStops, masterStops]);

    const handleDeselectStop = useCallback((selectedStop) => {
        setSelected(selected => {
            delete selected[selectedStop.stopId];
            return {...selected};
        });
    }, [setSelected, selected]);

    const handleStopClick = useCallback(({stop, zoom = false, shift = false}) => {
        console.log(`Stop clicked: ${stop.stopName}`);
        // if (!Object.keys(verifiedStops).length) {
        //     stop.preVerified = true;
        //     setVerifiedStops({[stop.stopId]: []});
        //     return
        // }
        // if (Object.keys(verifiedStops).includes(stop.stopId)) {// || stops.filter(_stop => _stop.stopId === stop.stopId)[0].verified) {
        //     return
        // }

        if (zoom) {
            setZoomStop({[stop.stopId]: stops[stop.stopId]});
        } else {
            setZoomStop(null);
        }
        setSelected(_selected => {
            if (shift) {
                if (_selected[stop.stopId]) {
                    delete _selected[stop.stopId];
                    // delete stop.selected;
                } else {
                    // if (Object.keys(_selected).length < stops.length - 1) {
                    _selected[stop.stopId] = new Stop(stop);
                    // stop.selected = true;
                    // }
                }
            } else {

                if (_selected[stop.stopId]) {
                    delete _selected[stop.stopId];
                } else {
                    _selected = {};
                    _selected[stop.stopId] = new Stop(stop);
                }
            }
            return {..._selected};
        });

    }, [stops, setSelected, setZoomStop]);

    const handleUnlinkSelectedStops = useCallback(async () => {

        if (!selected || !Object.keys(selected).length) {
            return;
        }
        setIsLinking(true);

        if (!window.confirm(`Are you sure you want to unlink these ${Object.keys(selected).length} points?`)) {
            setIsLinking(false);
            return;
        }

        try {
            const stopsToUpdate = [];
            await Promise.all(Object.keys(selected).map(async stopId => {
                const stopToUnlink = selected[stopId];
                if (stopToUnlink) {
                    const interchange = stopToUnlink.linkedStops?.filter(ls => stops[ls.stopId]).map(ls => stops[ls.stopId]);
                    interchange.forEach(stop => {
                        console.log(util.inspect(stop.linkedStops));
                        stop.linkedStops = stop.linkedStops?.filter(linkedStop => linkedStop.stopId !== stopToUnlink.stopId);
                    });
                    stopToUnlink.linkedStops.length = 0;
                    interchange.push(stopToUnlink);
                    interchange.forEach(stop => {
                        stop.userId = apiKey;
                        stopsToUpdate.push(stop);
                    });
                }
            }));
            await stopModelData.save(uniqBy(stopsToUpdate, 'stopId'));

        } catch (e) {
            console.log(`Couldn't unlink selected stops. Error: ${e}`);
        }
        setSelected({});
        setIsLinking(false);

    }, [apiKey, setIsLinking, selected, setSelected, stops]);

    const handleLinkSelectedStops = useCallback(async () => {
        const time = Date.now();
        if (!selected || !Object.keys(selected).length) {
            return;
        }

        console.log('Time to show confirm on ' + Object.keys(selected).length + ' selected: ', Date.now() - time, 'ms');

        if (!window.confirm(`Are you sure you want to link these ${Object.keys(selected).length} points?`)) {
            setIsLinking(false);
            return;
        }

        setIsLinking(true);

        try {
            let interchange = Object.keys(selected).map(stopId => selected[stopId]);
            interchange.forEach(stop => {
                stop.linkedStops = stop.linkedStops || [];
                stop.linkedStops = uniqBy(flatten(stop.linkedStops.concat(interchange.filter(otherStop => otherStop.stopId !== stop.stopId))), 'stopId').map(ls => {
                    const s = stops[ls.stopId];
                    return {
                        stopId: s.stopId,
                        stop: s,
                        distance: -1,
                        duration: -1
                    };
                });
            });


            // await updateInterchangeDurations(features?.tp?.acs, interchange);
            interchange = await updateInterchangeDirections(interchange);
            await stopModelData.save(interchange);
            // await Promise.all(interchange.map(async stop => await stopModelData.save(stop)))
        } catch (e) {
            console.log(`Couldn't link selected points. Error: ${e}`);
        }
        setSelected({});
        setIsLinking(false);

    }, [setIsLinking, setSelected, selected, stops]);

    const syncStop = useCallback(async () => {

        try {
            const [stop1, stop2] = values(selected);
            const masterStop = stop1.master ? stop1 : stop2;
            let stop = stop1.master ? stop2 : stop1;
            if (!stop || !masterStop) {
                return;
            }

            const existingStop = find(values(stops), s => s.stopCode?.length && s.stopCode === stop.stopCode && s.stopId !== stop.stopId);
            if (existingStop) {
                messageApi.error(`Import failed. Another point already exists with source ID '${existingStop.stopCode}'`, 10);
                return;
            }

            if (!window.confirm(`Are you sure you want to merge to source point ${masterStop.stopName}?`)) {
                setIsMerging(false);
                return;
            }

            stop.lat = masterStop.lat;
            stop.lon = masterStop.lon;
            stop.stopCode = masterStop.stopCode;
            stop.stopName = masterStop.stopName;
            stop.authorityId = masterStop.authorityId;
            stop.suburb = masterStop.suburb;
            stop.postcode = masterStop.postcode;
            stop.website = masterStop.website;
            stop.email = masterStop.email;
            stop.phone = masterStop.phone;
            stop.street = masterStop.street;
            stop.state = masterStop.state;
            stop.duplicate = -1;
            stop.verified = 1;
            stop.outOfSync = [];
            delete stop.master;
            setIsMerging(true);
            await stopModelData.save(stop);

            setStops(stops => {
                stops[stop.stopId] = stop;
                return {...stops};
            });
        } catch (e) {
            messageApi.error(`Couldn't sync stop. Error: ${e}`);
            console.log(`Couldn't sync stop. Error: ${e}`);
        } finally {
            setIsMerging(false);
        }
    }, [selected, stops, setIsMerging, messageApi, setStops, setFilteredStops]);

    const handleMergeStops = useCallback(async () => {
        setIsMerging(true);

        if (!window.confirm(`Are you sure you want to merge these ${selected ? Object.keys(selected).length : 0} points?`)) {
            setIsMerging(false);
            return;
        }
        let _selected = {...selected};

        // First off check for stopCode
        let mergableStops = Object.keys(_selected).filter(selectedId => _selected[selectedId].stopCode?.length).map(id => _selected[id]);

        // If none have stopCode
        if (!mergableStops.length) {
            // then get verified stops
            mergableStops = Object.keys(_selected).filter(selectedId => {
                return _selected[selectedId].verified;
            }).map(verifiedId => _selected[verifiedId]);
            // If no verified stops then check all of them
            if (!mergableStops || !mergableStops.length) {
                mergableStops = values(_selected);
            }
        }

        // // All mergableStops will have stopCode at this point or none will
        const stop = mergableStops.reduce((p, c) => {
            if (!p) {
                return c;
            }
            if (c.master) {
                return c;
            }
            if (c.imported) {
                return c;
            }
            if (c.verified) {
                return c;
            }
            if ((c.updatedAt || 0) > (p.updatedAt || 0)) {
                return c;
            }
            return p;
        }, null);

        if (!stop || !stop.stopId) {
            console.log('Couldn\'t find a point to merge into.');
            setIsMerging(false);
            return;
        }

        // Remove the main stop from the selected list
        delete _selected[stop.stopId];
        let mergedRoutes = [];
        let mergedLinks = [];
        Object.keys(_selected).forEach(selectedId => {
            const selectedStop = _selected[selectedId];
            selectedStop.merged = true;
            delete selectedStop.selected;
            if (selectedStop.routes) {
                mergedRoutes = mergedRoutes.concat(selectedStop.routes);
            }
            if (selectedStop.linkedStops) {
                mergedLinks = mergedLinks.concat(selectedStop.linkedStops);
            }
        });
        mergedRoutes = mergedRoutes.filter(r => r !== undefined);
        mergedLinks = mergedLinks.filter(l => l !== undefined);
        mergedRoutes.forEach(r => r.merged = true);
        mergedLinks.forEach(l => l.merged = true);
        // if (stop.routes) {
        stop.routes = uniqBy(mergedRoutes.concat(stop.routes || []), 'routeId');
        // }
        if (stop.linkedStops?.length) {
            stop.linkedStops = stop.linkedStops.filter(ls => values(_selected).every(stop => stop.stopId !== ls.stopId));
        }
        stop.linkedStops = uniqBy(mergedLinks.concat(stop.linkedStops || []), 'stopId');

        console.log('LINKS: ', stop.linkedStops);

        try {
            let verifiedStopIds = {[stop.stopId]: Object.keys(_selected)};
            await createStopVerification({verifiedStopIds, userId: apiKey});
            stop.userId = apiKey;
            await updateStop(stop);

            setFilteredStops(filteredStops => {
                Object.keys(_selected).forEach(mergedStopId => delete filteredStops[mergedStopId]);
                filteredStops[stop.stopId] = stop;
                return {...filteredStops};
            });

            setStops(stops => {
                Object.keys(_selected).forEach(mergedStopId => delete stops[mergedStopId]);
                stops[stop.stopId] = stop;
                return {...stops};
            });
            setSelected({});

        } catch (e) {
            console.log(`Failed merging stops: ${e}`, e);
            onError(e);
        }
        setIsMerging(false);

    }, [apiKey, setIsMerging, setFilteredStops, setStops, setSelected, selected]);

    const deleteStops = useCallback(async (selected) => {
        setIsDeleting(true);

        let stopsToDelete = values(selected);
        let stopIdsToDelete = stopsToDelete.map(s => s.stopId);
        if (!window.confirm(`Are you sure you want to delete ${stopsToDelete.length === 1 ? stopsToDelete[0].stopName :
            `these ${stopsToDelete.length} stops`}? If ${stopsToDelete.length === 1 ? 'it exists' :
            `they exist`} on a route, that route's respective stop time(s) will also be removed`)) {
            setIsDeleting(false);
            return;
        }

        try {
            await stopModelData.delete(stopIdsToDelete, true);

            setStops(stops => {
                stopIdsToDelete.forEach(stopId => {
                    delete stops[stopId];
                });
                removeDeletedStopFromLinks(stopIdsToDelete, values(stops));
                return {...stops};
            });
            setSelected(_ => ({}));
        } catch (e) {
            console.log(`Failed deleting Stops: ${e}`, e);
            messageApi.error(`Failed deleting Stops: ${e}`);
        } finally {
            setIsDeleting(false);
        }

    }, [setStops, setSelected, messageApi]);

    const verifyStops = useCallback(async () => {
        setIsVerifying(true);

        let stopsToVerify = getUnverifiedOfSelected(selected);
        if (!window.confirm(`Are you sure you want to verify ${stopsToVerify.length === 1 ? stopsToVerify[0].stopName : `${stopsToVerify.length} stops`}?`)) {
            setIsVerifying(false);
            return;
        }

        try {
            await stopModelData.save(stopsToVerify.map(selectedStop => {
                    return {...selectedStop, verified: 1};
                })
            );
        } catch (e) {
            console.log(`Failed verifying Stops: ${e}`, e);
            onError(e);
        } finally {
            setIsVerifying(false);
        }
        setStops(_stops => {
            stopsToVerify.forEach(selectedStop => {
                _stops[selectedStop.stopId].verified = 1;
            });
            return {..._stops};
        });
        setSelected({});
    }, [setStops, setSelected, selected]);

    const saveStop = useCallback(async (stop) => {
        try {
            if (stop?.linkedStops?.length) {
                let interchange = stop.linkedStops.map(ls => stops[ls.stopId]).concat(stop);
                await updateInterchangeDirections(interchange);
                await stopModelData.save(interchange);
            } else {
                stop = await stopModelData.save(stop);
            }
            console.log(stop);
        } catch (e) {
            console.log(`Failed saving Stop: ${e}`, e);
            onError(e);
        }

        setStops(_stops => {
            _stops[stop.stopId] = _stops[stop.stopId] || stop;
            Object.assign(_stops[stop.stopId], stop);
            delete _stops['_'];
            return {..._stops};
        });
        setSelected({});
        setEditStop(null);
        setTransferStop(null);
    }, [setEditStop, setSelected, stops]);

    const handleStopMoved = useCallback(async (moveDetails) => {
        // const _stop = {
        //     ...moveDetails.stop,
        //     lat: moveDetails.lat,
        //     lon: moveDetails.lon
        // }
        console.log(moveDetails);
        let _stop = null;
        setEditStop(editStop => {
            _stop = editStop;
            _stop.lat = moveDetails.lat;
            _stop.lon = moveDetails.lon;
            _stop.radius = moveDetails.radius;
            _stop.moved = true;
            return {..._stop};
        });

        setUpdated(true);
    }, [setEditStop, features]);

    const newStop = useCallback(async () => {
        const newStop = new Stop({
            stopId: '_',
            lat: viewport.latitude,
            lon: viewport.longitude,
            stopName: '',
            duplicate: -1
        });
        setStops(stops => {
            stops[newStop.stopId] = newStop;
            return stops;
        });
        setStopsToShowInMap(stops => stops.concat(newStop));
        setEditStop(newStop);
    }, [viewport, setStops, setEditStop, setStopsToShowInMap]);

    useEffect(() => {
        setStopsToShowInMap(values(filteredStops));
    }, [filteredStops]);

    const getSelectedPostfix = (selected, parenthesis = true) => {
        return selected && Object.keys(selected).length > 1 ? `${parenthesis ? '(' : ''}${selected ? Object.keys(selected).length : 0}${parenthesis ? ')' : ''}` : '';
    };

    const getUnverifiedOfSelected = (selected) => {
        if (!selected) {
            return [];
        }
        return values(selected).filter(s => !s.verified);
    };
    // console.log('Rendering stops manager...')

    const exportStops = useCallback(() => {
        let filteredStops = filterStops(stops, filter);

        if (operator?.opts?.aliases?.points?.length) {
            filteredStops = filteredStops.map(s => {
                let stop = {aliases: {}, ...s};
                operator?.opts?.aliases?.points.forEach(a => {
                    stop.aliases[a.aliasId] = stop.aliases[a.aliasId] || '';
                });
                return stop;
            });
        }

        toCsv(getStops(filteredStops, true)).then(csv => {
            fileDownload(csv, `Busable_Stops_${dayjs().format('YYYY-MM-DD_HH:mm')}.csv`, 'text/csv');
        });
    }, [filter, stops, operator]);

    logger.debug('Refreshing DOM: StopManager');

    return (
        <div className="StopManagerContainer">

            <Prompt
                when={updated}
                message="You have unsaved changes. Are you sure you want to leave?"
            />

            {stops && features?.access ? (
                <div>
                    <div
                        className="d-flex align-items-center justify-content-between filter-options-main points-filter-options">
                        <div className={'d-flex align-items-left'}>
                            <Button disabled={!selected || Object.keys(selected).length > 0}
                                    type="primary" className="icon-button mr-2"
                                    onClick={newStop}><PlusOutlined/>New Point</Button>

                            <Button disabled={!filteredStops || filteredStops.length === 0}
                                    type="primary" className="icon-button mr-2"
                                    onClick={exportStops}><Export className="mr-2"/> Export</Button>
                            <ImportModal operator={operator} messageApi={messageApi} btnClassName={'icon-button'}/>
                        </div>
                        <div className="d-flex align-items-center pl-3">
                            {filter.search || filter.verified || filter.unverified || filter.linked || filter.transfers || filter.outOfSync || filter.duplicates || filter.unserviced || filter.byDistance || filter.stopTypeValue?.length || filter.ownerValues?.length ?
                                <Button onClick={() => {
                                    setFilter({...DEFAULT_FILTER_STATE});
                                    setSearch('');
                                }} type="primary" className="icon-button btn-filled icon-10 btn-xs"><Close/> Clear
                                    Filters</Button>
                                : <></>
                            }
                            <div className="justify-content-end w-separator">
                                <InputGroup className={`search-filter ${filter.search ? 'search-filter-filled' : ''}`}>
                                    <FormControl
                                        type="search"
                                        placeholder="Filter"
                                        className=""
                                        size="sm"
                                        aria-label="Filter"
                                        value={search || ''}
                                        onChange={(e) => {
                                            const val = e?.target?.value || '';
                                            setSearch(val);
                                            setFilterDebounced(filter => {
                                                filter.search = val.toLowerCase();
                                                return {...filter};
                                            });
                                        }}
                                    />
                                    <InputGroup.Text id="basic-addon1"><SearchOutlined/></InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="filter-switches">
                                <Dropdown overlayClassName="filter-dropdown"
                                          trigger={['click']}
                                          dropdownRender={(menu) => (
                                              <div className="filter-dropdown-overlay">
                                                  <Switch checkedChildren="Verified" unCheckedChildren="Verified"
                                                          disabled={filter.unverified}
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.verified = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.verified}>Verified</Switch>
                                                  <Switch checkedChildren="Unverified"
                                                          unCheckedChildren="Unverified"
                                                          disabled={filter.verified}
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.unverified = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.unverified}>Unverified</Switch>
                                                  <Switch checkedChildren="Linked" unCheckedChildren="Linked"
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.linked = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.linked}>Linked</Switch>
                                                  <Switch checkedChildren="Transfers" unCheckedChildren="Transfers"
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.transfers = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.transfers}>Transfers</Switch>
                                              </div>
                                          )}
                                >
                                    <Button type="primary"
                                            className={`icon-button ${filter.verified || filter.unverified || filter.linked || filter.transfers ? 'ant-dropdown-open' : ''}`}>Status</Button>
                                </Dropdown>

                                <Dropdown overlayClassName="filter-dropdown"
                                          trigger={['click']}
                                          dropdownRender={(menu) => (
                                              <div className="filter-dropdown-overlay">
                                                  {STOP_TYPES_OPTIONS.map((stopType, i) => (
                                                      <Switch checkedChildren={stopType.label}
                                                              unCheckedChildren={stopType.label}
                                                              onChange={(checked) => {
                                                                  setFilter(filter => {
                                                                      filter.stopTypeValue = toggleStrInArray(filter.stopTypeValue, stopType.value);
                                                                      return {...filter};
                                                                  });
                                                              }}
                                                              checked={!!filter.stopTypeValue?.includes(stopType.value)}>{stopType.label}</Switch>))}

                                              </div>
                                          )}
                                >
                                    <Button type="primary"
                                            className={`icon-button ${filter.stopTypeValue?.length ? 'ant-dropdown-open' : ''}`}>Point
                                        Type</Button>
                                </Dropdown>

                                <Dropdown overlayClassName={`filter-dropdown`}
                                          trigger={['click']}

                                          dropdownRender={(menu) => (
                                              <div className="filter-dropdown-overlay">

                                                  {ownerOptions.map((ownerOpt, i) => (
                                                      <Switch checkedChildren={ownerOpt.label}
                                                              unCheckedChildren={ownerOpt.label}
                                                              onChange={(checked) => {
                                                                  setFilter(filter => {
                                                                      filter.ownerValues = toggleStrInArray(filter.ownerValues, ownerOpt.value);
                                                                      return {...filter};
                                                                  });
                                                              }}
                                                              checked={!!filter.ownerValues?.includes(ownerOpt.value)}>{ownerOpt.label}</Switch>))}

                                              </div>
                                          )}
                                >
                                    <Button type="primary"
                                            className={`icon-button ${filter.ownerValues?.length ? 'ant-dropdown-open' : ''}`}>Owned
                                        By</Button>
                                </Dropdown>

                                <Dropdown overlayClassName="filter-dropdown"
                                          trigger={['click']}
                                          dropdownRender={(menu) => (
                                              <Flex>
                                                  {/*<Checkbox*/}
                                                  {/*    onChange={(e) => {*/}
                                                  {/*        setFilter(filter => {*/}
                                                  {/*            const checked = e.target.checked;*/}
                                                  {/*            if (checked) {*/}
                                                  {/*                setDuplicateStops(stops, filter.byDistanceValue);*/}
                                                  {/*            } else {*/}
                                                  {/*                values(stops).forEach(s => delete s.duplicates);*/}
                                                  {/*            }*/}
                                                  {/*            filter.byDistance = checked;*/}
                                                  {/*            return {...filter};*/}
                                                  {/*        });*/}
                                                  {/*    }}*/}
                                                  {/*    checked={filter.byDistance}>Filter by proximity</Checkbox>*/}
                                                  {/*<Slider*/}
                                                  {/*    min={0}*/}
                                                  {/*    max={5}*/}
                                                  {/*    defaultValue={0}*/}
                                                  {/*    value={filter.byDistanceValue}*/}
                                                  {/*    onChange={(value) => {*/}
                                                  {/*        setDuplicateStops(stops, value);*/}
                                                  {/*        setFilter(filter => {*/}
                                                  {/*            filter.byDistanceValue = value;*/}
                                                  {/*            return {...filter};*/}
                                                  {/*        });*/}
                                                  {/*    }}*/}
                                                  {/*/>*/}
                                                  <Select value={filter.byDistanceValue} style={{width: '100%'}}
                                                          onChange={(value) => {
                                                              if (value === -1) {
                                                                  values(stops).forEach(s => delete s.duplicates);
                                                                  setFilter(filter => {
                                                                      filter.byDistance = false;
                                                                      filter.byDistanceValue = value;
                                                                      return {...filter};
                                                                  });
                                                                  return;
                                                              }
                                                              setDuplicateStops(stops, value);
                                                              setFilter(filter => {
                                                                  filter.byDistance = true;
                                                                  filter.byDistanceValue = value;
                                                                  return {...filter};
                                                              });
                                                          }}
                                                          options={[{
                                                              value: -1,
                                                              label: 'Off'
                                                          }].concat([0, 2, 4, 6, 8, 10, 15, 20, 25, 50].map(v => ({
                                                              value: v,
                                                              label: v + 'm'
                                                          })))}
                                                  />

                                              </Flex>
                                          )}
                                >
                                    <Button type="primary"
                                            className={`icon-button  ${filter.byDistance ? 'ant-dropdown-open' : ''}`}>Proximity</Button>
                                </Dropdown>

                                <Dropdown overlayClassName="filter-dropdown"
                                          trigger={['click']}
                                          dropdownRender={(menu) => (
                                              <div className="filter-dropdown-overlay">
                                                  <Switch checkedChildren="Mismatched"
                                                          unCheckedChildren="Mismatched"
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.outOfSync = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.outOfSync}>Mismatched</Switch>
                                                  <Switch checkedChildren="Duplicates"
                                                          unCheckedChildren="Duplicates"
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.duplicates = checked;
                                                                  if (checked) {
                                                                      setDuplicateStops(stops, filter.byDistanceValue);
                                                                  } else {
                                                                      values(stops).forEach(s => delete s.duplicates);
                                                                  }
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.duplicates}>Duplicates <WarningTwoTone
                                                      twoToneColor="#fbc149"/></Switch>
                                                  <Switch checkedChildren="Unserviced"
                                                          unCheckedChildren="Unserviced"
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.unserviced = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.unserviced}>Unserviced</Switch>
                                                  <Switch checkedChildren="Duplicate Source ID"
                                                          unCheckedChildren="Duplicate Source ID"
                                                          onChange={(checked) => {
                                                              setFilter(filter => {
                                                                  filter.duplicateSourceId = checked;
                                                                  return {...filter};
                                                              });
                                                          }} checked={filter.duplicateSourceId}>Duplicate Source
                                                      ID</Switch>
                                              </div>
                                          )}
                                >
                                    <Button type="primary"
                                            className={`icon-button ${filter.duplicates || filter.outOfSync || filter.unserviced || filter.duplicateSourceId ? 'ant-dropdown-open' : ''}`}>Validations</Button>
                                </Dropdown>

                            </div>
                        </div>
                    </div>

                    <div className="card-main map-holder-wrap no-pad d-flex">
                        <div className="map-holder" style={{width: '70%'}}>
                            <div className="MapPlaceholder">
                                <StopsMapViewer
                                    allStops={stops}
                                    stopsToShow={stopsToShowInMap}
                                    zoomStop={zoomStop}
                                    selectedStop={selectedStop}
                                    selectedStops={selected}
                                    focusStop={focusStop}
                                    editStop={editStop}
                                    setUpdated={setUpdated}
                                    verifiedStops={verifiedStops}
                                    handleStopClick={handleStopClick}
                                    deselectStop={handleDeselectStop}
                                    handleSelectedStops={handleSelectedStops}
                                    clearSelectedStops={() => setSelected({})}
                                    onFocusStop={(stopId) => setFocusStop(stops[stopId])}
                                    handleViewStateChange={handleViewStateChange}
                                    handleStopMoved={handleStopMoved}
                                    selected={selected} setEditStop={setEditStop}
                                    isLinking={isLinking} handleUnlinkSelectedStops={handleUnlinkSelectedStops}
                                    getSelectedPostfix={getSelectedPostfix}
                                    handleLinkSelectedStops={handleLinkSelectedStops}
                                    isMerging={isMerging} handleMergeStops={handleMergeStops}
                                    getUnverifiedOfSelected={getUnverifiedOfSelected} isVerifying={isVerifying}
                                    verifyStops={verifyStops} deleteStops={deleteStops} isDeleting={isDeleting}
                                    features={features}
                                    distanceToCursor={distanceToCursor}
                                    setDistanceToCursor={setDistanceToCursor}
                                    loadingmasterStops={loadingmasterStops}
                                    showmasterStops={showmasterStops}
                                    setShowmasterStops={setShowmasterStops}
                                    busLastUpdated={busLastUpdated}
                                    schoolLastUpdated={schoolLastUpdated}
                                    masterStops={masterStops}
                                    syncStop={syncStop}
                                    handleImportStops={handleImportStops}
                                    setTransfersModalOpen={handleTransfersModalOpen}
                                />
                            </div>
                        </div>

                        <div className="map-items" style={{width: '30%', padding: '0'}}>
                            <FilteredStopList
                                bounds={bounds}
                                setSelected={setSelected}
                                transfers={allTransfers}
                                allStops={stops}
                                filteredStops={filteredStops}
                                selected={selected}
                                focusStop={focusStop}
                                editStop={editStop}
                                setEditStop={setEditStop}
                                transferStop={transferStop}
                                scrollRef={scrollRef}
                                handleDeleteStop={async (stop) => await stopModelData.delete(stop.stopId)}
                                handleMergeStops={handleMergeStops}
                                handleStopMoved={handleStopMoved}
                                verifiedStops={verifiedStops}
                                onVerifyStops={setVerifiedStops}
                                handleStopClick={handleStopClick}
                                onSelectedStops={setSelected}
                                handleSelectedStops={handleSelectedStops}
                                handleSaveStop={saveStop}
                                setUpdated={setUpdated}
                                showmasterStops={showmasterStops}
                                masterStops={masterStops}
                                handleCancelEdit={async (stop) => {

                                    setStops(stops => {
                                        delete stops['_'];
                                        return {...stops};
                                    });
                                    setFilteredStops(filteredStops => {
                                        delete filteredStops['_'];
                                        return {...filteredStops};
                                    });
                                    setEditStop(null);
                                    setDistanceToCursor(-1);
                                }}
                                handleCancelTransfer={async (stop) => {
                                    setTransferStop(null);
                                }}
                                onZoomStop={(stop) => {
                                    setZoomStop(stop);
                                    setSelectedStop(stop);
                                }}
                                onFocusStop={(stop) => {
                                    setFocusStop(stop);
                                    setSelectedStop(null);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : <LoadMessage message={'loading points...'} size={'lg'}/>}
        </div>
    );
}

export default React.memo(StopManager);
