import React from "react";
import { Modal } from "antd";
import WorkDiaryPrintView from "./WorkDiaryPrintView";
import { useRoster } from "./RosterContext";

function WorkDiaryPrintModal() {
  const { printWorkItems, setPrintWorkItems } = useRoster();
  return (
    <Modal
      width={1000}
      style={{ top: 50 }}
      open={printWorkItems}
      destroyOnClose
      okText={`Print`}
      onCancel={() => setPrintWorkItems(null)}
      onOk={() => window.print()}
    >
      <WorkDiaryPrintView />
    </Modal>
  );
}

export default React.memo(WorkDiaryPrintModal);
