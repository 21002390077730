import React, { useState, createContext, useContext, useEffect, useMemo, useCallback } from "react";
import dayjs from "../../dayjs";
import { DAYS_OF_WEEK, WeeklyRoster, WeeklyScenario } from "../../model/roster";
import { ulid } from "ulid";
import { charterModelData, rosterModelData } from "../../services/ModelService";
import useAllEmployees from "../../hooks/useAllEmployees";
import useAllVehicles from "../../hooks/useAllVehicle";
import useAllStops from "../../hooks/useAllStops";
import useAllRoutes from "../../hooks/useAllRoutes";
import useAllCharters from "../Charters/useAllCharters";
import useAllShiftBats from "../../hooks/useAllShiftBats";
import useAllSchedules from "../../hooks/useAllSchedules";
import { useAppContext } from "../../libs/contextLib";
import useAll from "../../hooks/useAll";
import { toCsv } from "../../libs/exportLib";
import fileDownload from "js-file-download";
import { rosteringFilterItems } from "./RosteringFilters";
import { Employee } from "../../model/hrm/employee";
import { compareDates, toTime } from "../../libs/formatLib";
import { DriverCompliance } from "../../model/compliance";
import { keys, filter as _filter, some, find, sortBy, values } from "lodash";

// Create the Context
const RosterContext = createContext();

// Provider Component
const RosterProvider = ({ children }) => {
  const { messageApi } = useAppContext();

  const { all: allRosters, allAsArray: allRostersAsArray } = useAll({ modelService: rosterModelData });
  const employeeData = useAllEmployees();
  const { allVehicles } = useAllVehicles();
  const { allStops } = useAllStops();
  const { allRoutes } = useAllRoutes();
  const { allCharters } = useAllCharters();
  const { allShiftBatsArray: allShiftBats } = useAllShiftBats();
  const { allSchedules } = useAllSchedules();

  const [view, setView] = useState("weekly");
  const [mode, setMode] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("week"));
  const [weeklyScenarios, setWeeklyScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showUnallocated, setShowUnallocated] = useState(false);
  const [showImportScenarios, setShowImportScenarios] = useState(false);
  const [printWorkItems, setPrintWorkItems] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [unallocatedRoster, setUnallocatedRoster] = useState(null);
  const [selectedWorkItems, setSelectedWorkItems] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [filter, setFilter] = useState("");
  const [updated, setUpdated] = useState(false);
  const [calculatingScenario, setCalculatingScenario] = useState(false);
  const [savingScenario, setSavingScenario] = useState(false);
  const [removeList, setRemoveList] = useState([]);
  const [markCompleted, setMarkCompleted] = useState(false);
  const [employeeSelectData, setEmployeeSelectData] = useState({});

  // Callbacks for events
  const [callbacks, setCallbacks] = useState({});

  // Register a callback for a specific event
  const registerCallback = useCallback((event, callback) => {
    setCallbacks((prevCallbacks) => ({
      ...prevCallbacks,
      [event]: [...(prevCallbacks[event] || []), callback],
    }));
  }, []);

  // Unregister a callback for a specific event
  const unregisterCallback = useCallback((event, callback) => {
    setCallbacks((prevCallbacks) => ({
      ...prevCallbacks,
      [event]: (prevCallbacks[event] || []).filter((cb) => cb !== callback),
    }));
  }, []);

  // Trigger callbacks for a specific event
  const triggerCallbacks = useCallback(
    (event, ...args) => {
      if (callbacks[event]) {
        callbacks[event].forEach((callback) => callback(...args));
      }
    },
    [callbacks]
  );

  // Memoized values
  const allShiftBatsArray = useMemo(() => {
    if (!allCharters || !allShiftBats) return;
    const invalidCharters = values(allCharters).filter((charter) => !["accepted", "scheduled", "allocated"].includes(charter.status));
    const invalidShiftBatIds = invalidCharters?.map((c) => c.duties?.map((d) => d.shiftBatId) || [])?.flat() || [];
    return allShiftBats.filter(
      (shiftBat) => shiftBat.published >= 0 || (shiftBat.charter && !invalidShiftBatIds.includes(shiftBat.shiftBatId))
    );
  }, [allCharters, allShiftBats]);

  const previousRoster = useMemo(() => {
    const lastWeeksRoster = allRosters?.[selectedDate?.clone().subtract(1, "week")?.format("YYYYMMDD")];
    return lastWeeksRoster?.scenarios?.filter((scenario) => scenario.active)?.[0] || lastWeeksRoster?.scenarios?.[0];
  }, [allRosters, selectedDate]);

  const rosteredVehicles = useMemo(() => {
    const vehicles = selectedScenario?.getAllVehicleIds()?.map((vehicleId) => allVehicles?.[vehicleId]);
    return sortBy(vehicles, [
      function (o) {
        return !o ? -1 : 1;
      },
      "",
    ]);
  }, [selectedScenario, allVehicles]);

  const rosteredDrivers = useMemo(() => {
    const drivers = selectedScenario?.getAllEmployeesId()?.map((employeeId) => employeeData?.getEmployee(employeeId));
    return sortBy(drivers, [
      function (o) {
        return !o ? -1 : 1;
      },
      "",
    ]);
  }, [selectedScenario, employeeData]);

  const showImportButton = useMemo(() => {
    if (selectedScenario) {
      const _workItems = selectedScenario.weeklyRosters.map((roster) => roster.workItems).flat();
      return _workItems.every((item) => item.length === 0);
    }
    return true;
  }, [selectedScenario]);

  const filteredScenario = useMemo(() => {
    const searchWorkItem = (workItem, txtFilter) => {
      if (!workItem || !txtFilter) return false;
      const emp = workItem.employeeId ? employeeData.getEmployee(workItem.employeeId) : null;
      const empName = `${emp?.firstName} ${emp?.lastName}`.toLowerCase();
      const vehicle = allVehicles[workItem.vehicleId];
      const vehicleName = vehicle?.vehicleName?.toLowerCase();

      return (
        workItem.workItemName?.toString().toLowerCase().includes(txtFilter) ||
        workItem.duty?.shiftBatName?.toString().toLowerCase().includes(txtFilter) ||
        workItem.duty?.shiftBatDetails?.toString().toLowerCase().includes(txtFilter) ||
        empName?.toString().toLowerCase().includes(txtFilter) ||
        vehicleName?.toString().toLowerCase().includes(txtFilter)
      );
    };

    if (!employeeSelectData?.selected?.length && !selectedDrivers?.length && !selectedVehicles?.length && !filterBy?.length && !filter) {
      return selectedScenario;
    }
    const txtFilter = filter?.length ? filter.toLowerCase() : null;

    const filteredScenario = selectedScenario.clone();
    filteredScenario.updateUnallocatedRoster(WeeklyRoster.from(selectedScenario.unallocatedRoster));
    filteredScenario.removeAlreadyAllocatedWorkItemsFromImport();
    filteredScenario.weeklyRosters.forEach((roster) => {
      roster.workItems.forEach((workItemsForDay, day) => {
        roster.workItems[day] = workItemsForDay.filter((workItem) => {
          const isEmployee =
            !employeeSelectData?.selected?.length ||
            find(employeeSelectData.selected, (selected) => selected && selected.extra.employeeId === workItem.employeeId);
          const isDriver = !selectedDrivers?.length || selectedDrivers.includes(workItem.employeeId);
          const isVehicle = !selectedVehicles?.length || selectedVehicles.includes(workItem.vehicleId);
          const isFilter = !filterBy?.length || filterBy.some((filter) => rosteringFilterItems[filter].showFn(workItem));
          const isTxtFilter =
            !txtFilter ||
            searchWorkItem(workItem, txtFilter) ||
            values(workItem).some((value) => value && value.toString().toLowerCase().includes(txtFilter)) ||
            DAYS_OF_WEEK.findIndex((dayOfWeek) => dayOfWeek.includes(txtFilter)) === day;

          return isEmployee && isDriver && isVehicle && isFilter && isTxtFilter;
        });
      });
    });
    filteredScenario.weeklyRosters = filteredScenario.weeklyRosters.filter((roster) => roster.getTotalWorkItems());
    filteredScenario.unallocatedRoster.workItems.forEach((workItemsForDay, day) => {
      filteredScenario.unallocatedRoster.workItems[day] = workItemsForDay.filter((workItem) => {
        const isFilter = !filterBy?.length || filterBy.some((filter) => rosteringFilterItems[filter].showFn(workItem));
        const isTxtFilter =
          !txtFilter ||
          searchWorkItem(workItem, txtFilter) ||
          values(workItem).some((value) => value && value.toString().toLowerCase().includes(txtFilter));
        return isFilter && isTxtFilter;
      });
    });
    filteredScenario.updateUnallocatedRoster(
      WeeklyRoster.from({
        ...filteredScenario.unallocatedRoster,
      })
    );
    filteredScenario.removeAlreadyAllocatedWorkItemsFromImport();
    return filteredScenario;
  }, [employeeSelectData.selected, selectedDrivers, selectedVehicles, filterBy, filter, selectedScenario, employeeData, allVehicles]);

  const workDiaries = useMemo(() => {
    const workDiaries = [];
    const scenario = selectedWorkItems.length
      ? { getAllEmployeesId: () => selectedWorkItems.map((item) => item.employeeId) }
      : filteredScenario;
    scenario?.getAllEmployeesId().forEach((employeeId) => {
      const employee = employeeData.getEmployee(employeeId);
      Array(7)
        .fill()
        .forEach((_, day) => {
          const workItemsForDay = selectedWorkItems.length
            ? selectedWorkItems.filter((item) => item.employeeId === employeeId && dayjs(item.workItemDate).weekday() === day)
            : filteredScenario.getWorkItemsForEmployeeForDay(employeeId, day);
          if (!workItemsForDay.length) return;
          const charter = workItemsForDay[0]?.duty?.charter;
          let customer = null;
          if (charter) {
            const shiftbatId = workItemsForDay[0]?.duty?.shiftBatId;
            const charterData = Object.values(allCharters).find((charter) => charter.duties.some((duty) => duty.shiftBatId === shiftbatId));
            customer = charterData?.customer;
          }
          const workDiary = {
            name: employee ? employee.firstName + " " + employee.lastName : "Unassigned",
            day: dayjs().weekday(day).format("dddd"),
            vehicle: charter ? allVehicles?.[workItemsForDay[0]?.vehicleId] : null,
            customer: charter ? customer : null,
            employee: charter ? employee : null,
            workItems: workItemsForDay,
          };
          workDiaries.push(workDiary);
        });
    });
    return workDiaries;
  }, [filteredScenario, selectedWorkItems, employeeData, allVehicles, allCharters]);

  const { wages, overtime, overtimeHours, hours, otpercentage, exportData } = useMemo(() => {
    if (!selectedScenario) return {};
    let wages = 0,
      overtime = 0,
      hours = 0,
      exportData = [];
    filteredScenario.getAllEmployeesId().forEach((employeeId) => {
      let employee = employeeData.getEmployee(employeeId);
      if (!employee) return {};
      const workItems = filteredScenario.getWorkItemsForEmployee(employeeId);
      if (!employee.getWage) {
        employee = new Employee(employee);
      }
      const employeeWage = employee.getWage(workItems);
      hours += employeeWage.hours;
      overtime += employeeWage.overtime;
      wages += employeeWage.total;
      exportData.push({ ...employee, ...employeeWage });
    });

    hours = Number.isFinite(hours) ? hours : 0;
    overtime = Number.isFinite(overtime) ? overtime : 0;
    wages = Number.isFinite(wages) ? wages : 0;

    return {
      wages,
      overtime: overtime * 3600,
      overtimeHours: toTime(overtime * 3600, false),
      hours,
      otpercentage: hours > 0 ? Math.ceil((overtime / hours) * 100) : 0,
      exportData,
    };
  }, [filteredScenario, employeeData, selectedScenario]);

  const driverHours = useMemo(() => {
    return toTime(hours * 3600, false);
  }, [hours]);

  // Actions
  const validateAllocatedCharters = async (updatedScenario) => {
    const charterArray = values(allCharters);
    const updatedWorkItems = updatedScenario?.getAllWorkItems() || [];
    const allUnallocatedWorkItems = updatedScenario?.getAllUnallocatedWorkItems() || [];
    const groupedWorkItems = [...updatedWorkItems, ...allUnallocatedWorkItems].reduce((acc, workItem) => {
      const isCharter = workItem.duty?.charter;
      if (isCharter) {
        const charterId = workItem.duty.shiftBatId;
        if (!acc[charterId]) {
          acc[charterId] = [];
        }
        acc[charterId].push(workItem);
      }
      return acc;
    }, {});

    const savePromises = Object.entries(groupedWorkItems).map(async ([shiftBatId, workItems]) => {
      const isAllWorkItemsAllocated = workItems.every((workItem) => workItem.vehicleId && workItem.employeeId);
      const charter = charterArray.find((c) => c.duties.find((d) => d.shiftBatId === shiftBatId));
      if (charter) {
        return charterModelData.save(
          charterModelData.create({
            ...charter,
            status: isAllWorkItemsAllocated ? "allocated" : "accepted",
          })
        );
      }
      return Promise.resolve();
    });

    await Promise.all(savePromises);
  };

  const save = (weeklyScenarios, updatedScenario, alert = false) => {
    if (!weeklyScenarios) return;
    setSavingScenario(true);
    rosterModelData
      .save({
        rosterId: selectedDate.format("YYYYMMDD"),
        date: selectedDate,
        scenarios: weeklyScenarios,
      })
      .then(() => {
        setUpdated(false);
        setSavingScenario(false);
        setSelectedScenario(updatedScenario ? updatedScenario : weeklyScenarios.find((s) => s.scenarioId === selectedScenario?.scenarioId));
        return validateAllocatedCharters(
          updatedScenario ? updatedScenario : weeklyScenarios.find((s) => s.scenarioId === selectedScenario?.scenarioId)
        );
      })
      .then(() => {
        console.log("All charters validated");
        if (alert) messageApi.success("Allocations saved successfully");
      })
      .catch((err) => {
        console.log("Error saving scenarios: ", err);
      });
  };

  const importScenarios = useCallback(
    (weeklyScenarios, replace = false) => {
      weeklyScenarios = weeklyScenarios.map((scenario) => {
        scenario.unallocatedRoster = unallocatedRoster.clone();
        scenario.updateUnallocatedRoster();
        scenario.setDate(selectedDate);
        if (!replace) {
          scenario.scenarioId = ulid();
          delete scenario.active;
        }

        return scenario;
      });
      setWeeklyScenarios((current) => (replace || !current ? weeklyScenarios : current.concat(weeklyScenarios)));
      setSelectedScenario((scenario) =>
        scenario && weeklyScenarios.find((s) => s.scenarioId === scenario.scenarioId)
          ? scenario
          : weeklyScenarios.find((s) => s.active) || weeklyScenarios[0]
      );
      setShowImportScenarios(false);
    },
    [selectedDate, unallocatedRoster]
  );

  const cloneAndImport = (weeklyScenarios) => {
    weeklyScenarios = weeklyScenarios.map((scenario) => {
      const clone = WeeklyScenario.from(scenario);
      clone.scenarioId = ulid();
      clone.active = false;
      clone.setDate(selectedDate);
      clone.removeOutOfDateWorkItems(allSchedules);
      clone.unallocatedRoster = unallocatedRoster.clone();
      clone.removeAlreadyAllocatedWorkItemsFromImport();
      return clone;
    });

    setWeeklyScenarios((current) => (!current ? weeklyScenarios : current.concat(weeklyScenarios)));
    setSelectedScenario((scenario) =>
      scenario && weeklyScenarios.find((s) => s.scenarioId === scenario.scenarioId)
        ? scenario
        : weeklyScenarios.find((s) => s.active) || weeklyScenarios[0]
    );
    setShowImportScenarios(false);
  };

  const setScenario = useCallback(
    (newScenario) => {
      if (typeof newScenario === "function") {
        newScenario = newScenario(selectedScenario);
      }
      setWeeklyScenarios((weeklyScenarios) =>
        weeklyScenarios?.map((s) => (s.scenarioId === newScenario.scenarioId ? WeeklyScenario.from(newScenario) : s))
      );
      setUpdated(true);
    },
    [selectedScenario]
  );

  const add = () => {
    const scenario = WeeklyScenario.from({
      name: "Scenario " + (weeklyScenarios.filter((s) => s.name.startsWith("Scenario")).length + 1),
      date: selectedDate,
      weeklyRosters: [WeeklyRoster.from({ date: selectedDate, rosterName: "01" })],
      unallocatedRoster: unallocatedRoster,
    });
    const _weeklyScenarios = [...weeklyScenarios, scenario];
    setWeeklyScenarios(_weeklyScenarios);
    setSelectedScenario(scenario);
    save(_weeklyScenarios, scenario);
  };

  const remove = (selectedScenario) => {
    console.log("Removing Scenario: ", selectedScenario);
    const idx = weeklyScenarios.findIndex((scenario) => scenario.scenarioId === selectedScenario);
    if (idx === -1) return;

    if (weeklyScenarios[idx]?.active) {
      messageApi.warning("Cannot remove published scenarios. Please unpublish first.");
      return;
    } else {
      const updatedWeeklyScenarios = weeklyScenarios.filter((scenario) => scenario.scenarioId !== selectedScenario);
      setWeeklyScenarios(updatedWeeklyScenarios);
      setSelectedScenario(updatedWeeklyScenarios[updatedWeeklyScenarios.length === 1 || idx === 0 ? 0 : idx - 1]);
      save(updatedWeeklyScenarios);
      console.log("Scenario removed: ", selectedScenario);
    }
  };

  const copyScenario = () => {
    const scenario = selectedScenario?.clone();
    scenario.scenarioId = ulid();
    scenario.active = false;
    setWeeklyScenarios([...weeklyScenarios, scenario]);
    setSelectedScenario(scenario);
    setUpdated(true);
  };

  const autoAllocate = async () => {
    setCalculatingScenario(true);
    const number = (weeklyScenarios.filter((s) => s.name.startsWith("Auto")).length + 1).toString().padStart(2, "0");
    const newScenario = await WeeklyScenario.smartAllocate(allShiftBatsArray, allSchedules, {
      date: selectedDate,
      name: "Auto " + number,
    });
    const _weeklyScenarios = [...weeklyScenarios, newScenario];
    setWeeklyScenarios(_weeklyScenarios);
    setSelectedScenario(newScenario);
    setCalculatingScenario(false);
    setUpdated(true);
    save(_weeklyScenarios, newScenario); // save the new scenario
  };

  const publishScenario = () => {
    const _weeklyScenarios = weeklyScenarios.map((scenario) =>
      WeeklyScenario.from({
        ...scenario,
        active: scenario.scenarioId === selectedScenario?.scenarioId ? !selectedScenario?.active : undefined,
      })
    );
    setWeeklyScenarios(_weeklyScenarios);
    save(_weeklyScenarios);
  };

  const addRoster = () => {
    setScenario((scenario) => {
      const roster = WeeklyRoster.from({
        date: scenario.date,
        rosterName: (scenario.weeklyRosters.length + 1).toString().padStart(2, "0"),
      });
      scenario.weeklyRosters = scenario.weeklyRosters.concat(roster);

      triggerCallbacks("addRoster", roster);
      return scenario.clone();
    });
    setWorkItemOptions(selectedWorkItems); // update optional work items
  };

  const handleDriverAllocation = (employee) => {
    const _scenario = selectedScenario.clone();
    const _selectedWorkItems = selectedWorkItems?.map((workItem) => {
      return workItem.workItemId;
    });
    _scenario.assignDriverToWorkItems(employee, _selectedWorkItems);
    setScenario(_scenario);
    setSelectedScenario(_scenario);
  };

  const handleVehicleAllocation = (vehicle) => {
    const _scenario = selectedScenario.clone();
    const _selectedWorkItems = selectedWorkItems?.map((workItem) => {
      return workItem.workItemId;
    });
    _scenario.assignVehicleToWorkItems(vehicle, _selectedWorkItems);
    setScenario(_scenario);
    setSelectedScenario(_scenario);
  };

  const handleSetActuals = (workItem) => {
    const _scenario = selectedScenario.clone();
    // update selected work items
    const updatedSelectedWorkItems = selectedWorkItems.map((item) => {
      if (item.workItemId === workItem.workItemId) {
        return { ...item, ...workItem };
      }
      return item;
    });
    setSelectedWorkItems(updatedSelectedWorkItems);
    _scenario.updateWorkItem(workItem);
    if (workItem.isCompleted) _scenario.removeOptionalWorkItems();
    else _scenario.addOptionalWorkItems(updatedSelectedWorkItems);
    setScenario(_scenario);
    setSelectedScenario(_scenario);
  };

  const setWorkItemOptions = useCallback(
    (workItems) => {
      setScenario((scenario) => {
        scenario.addOptionalWorkItems(workItems);
        scenario.setBaseData({ employeeData: employeeData, allVehicles: allVehicles });
        scenario.weeklyRosters = [...scenario.weeklyRosters];
        return scenario;
      });
    },
    [setScenario, employeeData, allVehicles]
  );

  const handleUnallocate = () => {
    const _selectedScenario = selectedScenario.clone();
    const _selectedWorkItems = selectedWorkItems?.map((workItem) => {
      return workItem.workItemId;
    });
    _selectedScenario.unallocateWorkItems(_selectedWorkItems);
    // clear any selected work items any optional work items
    setSelectedWorkItems([]);
    _selectedScenario.removeOptionalWorkItems(_selectedWorkItems);
    setScenario(_selectedScenario);
    setSelectedScenario(_selectedScenario);
  };

  const onSelectWorkItems = (workItems) => {
    if (!workItems) {
      setSelectedWorkItems([]);
      setWorkItemOptions(null);
      return;
    }
    setSelectedWorkItems(workItems);
    setWorkItemOptions(workItems);
  };

  const newRoster = useCallback(() => {
    setScenario((scenario) => {
      scenario.weeklyRosters = scenario.weeklyRosters.concat(
        WeeklyRoster.from({
          date: scenario.date,
          rosterName: (scenario.weeklyRosters.length + 1).toString().padStart(2, "0"),
        })
      );
      return scenario.clone();
    });
  }, [setScenario]);

  const saveRoster = useCallback(
    (rosters, removedWorkItems = []) => {
      rosters = Array.isArray(rosters) ? rosters : [rosters];
      setScenario((scenario) => {
        rosters
          .filter((roster) => !!roster)
          .forEach((roster) => {
            scenario.weeklyRosters = scenario.weeklyRosters.map((r) => {
              if (r.rosterId === roster.rosterId) {
                return WeeklyRoster.from(roster);
              }
              // remove any work items that were removed from the roster
              r.workItems = r.workItems.map((workItemsForDay) =>
                workItemsForDay.filter((workItem) => !removedWorkItems.includes(workItem.workItemId))
              );
              return r;
            });
            scenario.updateUnallocatedRoster(unallocatedRoster);
            scenario.removeAlreadyAllocatedWorkItemsFromImport();
            scenario.unallocatedRoster = scenario.unallocatedRoster.clone();
          });
        const newScenario = scenario.clone();
        setSelectedScenario(WeeklyScenario.from(newScenario));
        return newScenario;
      });
    },
    [setScenario, unallocatedRoster]
  );

  const exportScenario = useCallback(() => {
    toCsv(exportData).then((csv) => {
      fileDownload(csv, `Busable_Employee_Wages_${dayjs().format("YYYY-MM-DD_HH:mm")}.csv`, "text/csv");
    });
  }, [exportData]);

  const runCompliance = (data, previousRoster) => {
    if (!data) return;
    const d = [...data];
    d.forEach((keyedRoster) => {
      const employeeIds = keyedRoster?.getAllEmployeesId();
      employeeIds.forEach((employeeId) => {
        if (!employeeId) return;
        let workItems = keyedRoster?.getWorkItemsForEmployee(employeeId).concat(previousRoster?.getWorkItemsForEmployee(employeeId) || []);
        if (workItems?.length) {
          const compliance = DriverCompliance.checkCompliance({ employeeId, workItems });
          keys(compliance).forEach((workItemId) => {
            const workItem = find(workItems, (wi) => wi.workItemId === workItemId);
            workItem.compliance = compliance[workItemId];
            if (!workItem.compliance?.length) {
              delete workItem.compliance;
            }
          });
        }
      });
    });
    return d;
  };

  const runValidations = (scenario, employeeData, vehicles) => {
    if (!scenario) return;
    const allWorkItems = scenario.getAllWorkItems();

    const checkIfDoubleBooked = (workItem, id, type) => {
      const workItems = _filter(allWorkItems, [type, id]);
      return some(
        workItems,
        (wi) =>
          wi.workItemId !== workItem.workItemId &&
          wi.dayOfWeek === workItem.dayOfWeek &&
          compareDates(wi.workItemDate, workItem.workItemDate) &&
          wi.actualStart < workItem.actualEnd &&
          wi.actualEnd > workItem.actualStart
      );
    };

    allWorkItems.forEach((workItem) => {
      let validations = [];

      const employee = workItem.employeeId ? employeeData?.getEmployee(workItem.employeeId) : null;
      const isEmployeeOnLeave = employee ? employeeData?.isEmployeeOnLeave(workItem.employeeId, workItem.workItemDate) : false;
      if (isEmployeeOnLeave) {
        validations.push(`Employee is on ${isEmployeeOnLeave.title}`);
      }

      if (workItem.employeeId && checkIfDoubleBooked(workItem, workItem.employeeId, "employeeId")) {
        validations.push("Employee is double booked");
      }
      if (workItem.vehicleId && checkIfDoubleBooked(workItem, workItem.vehicleId, "vehicleId")) {
        validations.push("Vehicle is double booked");
      }

      const vehicle = workItem.vehicleId ? vehicles?.[workItem.vehicleId] : null;
      const isVehicleUnavailable = vehicle ? vehicle?.vehicleStatus !== "Active" : false;
      if (isVehicleUnavailable) {
        validations.push(vehicle?.vehicleStatus === "Service" ? "Vehicle is under service" : "Vehicle is under repair");
      }

      workItem.validations = validations;
    });
  };

  // Use Effects
  useEffect(() => {
    setIsLoading(true);
    if (!allShiftBatsArray || !selectedDate || !allSchedules || !setUnallocatedRoster || !setIsLoading) return;
    let date = selectedDate.clone();
    const unallocatedRoster = WeeklyRoster.from({ rosterId: "unallocated", rosterName: "Unallocated", date });
    for (let i = 0; i < 7; i++) {
      ((currentDate) => {
        allShiftBatsArray.forEach((duty) => {
          if (duty.isRunning(currentDate, allSchedules)) {
            unallocatedRoster.addDuty(currentDate.weekday(), duty);
          }
        });
      })(date);
      date = date.add(1, "day");
    }
    setUnallocatedRoster(unallocatedRoster);
    setIsLoading(false);
  }, [allShiftBatsArray, allCharters, setUnallocatedRoster, setIsLoading, allSchedules, selectedDate]);

  useEffect(() => {
    if (!setSelectedScenario || !setWeeklyScenarios || !unallocatedRoster || !selectedDate || !allRostersAsArray || !allRosters) return;

    const weeklyScenarios = allRosters[selectedDate.format("YYYYMMDD")]?.scenarios;
    if (!weeklyScenarios?.length) {
      const scenario = WeeklyScenario.from({
        date: selectedDate,
        name: "Scenario 1",
        weeklyRosters: [
          WeeklyRoster.from({
            date: selectedDate,
            rosterName: "01",
          }),
        ],
        unallocatedRoster,
      });
      setSelectedScenario(scenario);
      setWeeklyScenarios(null);
    } else {
      importScenarios(weeklyScenarios, true);
    }
  }, [setSelectedScenario, setWeeklyScenarios, unallocatedRoster, allRosters, allRostersAsArray, selectedDate, importScenarios]);

  return (
    <RosterContext.Provider
      value={{
        view,
        setView,
        mode,
        setMode,
        selectedDate,
        setSelectedDate,
        weeklyScenarios,
        setWeeklyScenarios,
        selectedScenario,
        setSelectedScenario,
        showDashboard,
        setShowDashboard,
        showUnallocated,
        setShowUnallocated,
        showImportScenarios,
        setShowImportScenarios,
        printWorkItems,
        setPrintWorkItems,
        isLoading,
        setIsLoading,
        unallocatedRoster,
        setUnallocatedRoster,
        selectedWorkItems,
        setSelectedWorkItems,
        selectedDrivers,
        setSelectedDrivers,
        selectedVehicles,
        setSelectedVehicles,
        filterBy,
        setFilterBy,
        filter,
        setFilter,
        updated,
        setUpdated,
        calculatingScenario,
        setCalculatingScenario,
        savingScenario,
        setSavingScenario,
        previousRoster,
        allShiftBatsArray,
        showImportButton,
        removeList,
        setRemoveList,
        markCompleted,
        setMarkCompleted,
        validateAllocatedCharters,
        save,
        importScenarios,
        cloneAndImport,
        setScenario,
        add,
        remove,
        copyScenario,
        autoAllocate,
        publishScenario,
        addRoster,
        handleDriverAllocation,
        handleVehicleAllocation,
        handleSetActuals,
        setWorkItemOptions,
        handleUnallocate,
        onSelectWorkItems,
        rosteredVehicles,
        rosteredDrivers,
        employeeData,
        allVehicles,
        allStops,
        allRoutes,
        allRosters,
        allRostersAsArray,
        allCharters,
        allSchedules,
        driverHours,
        wages,
        overtime,
        overtimeHours,
        hours,
        otpercentage,
        exportData,
        employeeSelectData,
        setEmployeeSelectData,
        workDiaries,
        newRoster,
        saveRoster,
        exportScenario,
        filteredScenario,
        runCompliance,
        runValidations,
        registerCallback,
        unregisterCallback,
      }}
    >
      {children}
    </RosterContext.Provider>
  );
};

// Consumer Hook (for functional components)
const useRoster = () => {
  const context = useContext(RosterContext);
  if (!context) {
    throw new Error("useRoster must be used within a RosterProvider");
  }
  return context;
};

export { RosterProvider, useRoster };
