import React, { useEffect } from 'react';

const UpdateBodyCSS = ({ bodyClass }) => {
    useEffect(() => {
        if (bodyClass) {
            document.body.classList.add(bodyClass);
        }

        return () => {
            if (bodyClass) {
                document.body.classList.remove(bodyClass);
            }
        };
    }, [bodyClass]);

    return null;
};

export default UpdateBodyCSS;