import React, { useState } from "react";
import SelectEmployeeView from "./SelectEmployeeView";
import { Button } from "antd";
import { useRoster } from "./RosterContext";

export const DriverAllocation = ({ close }) => {
  const { handleDriverAllocation } = useRoster();
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  return (
    <>
      <SelectEmployeeView selectedEmployee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} />
      <div className="d-flex justify-content-end">
        <Button
          key="employee-save-btn"
          type="primary"
          className="primary-btn btn-small mt-1"
          onClick={() => {
            if (selectedEmployee) {
              handleDriverAllocation(selectedEmployee);
            }
            close();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};
