import React, {useEffect, useMemo, useState} from 'react';
import LoaderButton from '../../components/LoaderButton';
import {ReactComponent as Copy} from '../../assets/icons/Copy.svg';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';
import {Charter} from '../../model/charter';
import {useHistory} from 'react-router-dom';
import {charterModelData, routeModelData, shiftBatModelData} from '../../services/ModelService';
import {FilterTags} from '../../containers/FilterTag';
import {filterItems} from './Charters';
import {Popconfirm} from 'antd/lib';
import {ulid} from 'ulid';
import {Button, Flex, Table} from 'antd';
import CharterTimesPopover from '../../components/CharterTimesPopover';
import {useAppContext} from '../../libs/contextLib';
import RouteTitle from "../../components/RouteTitle";

const CharterList = ({charters}) => {
    const {schedules: allSchedules} = useAppContext();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isDeleting, setIsDeleting] = useState({});
    const [isCloning, setIsCloning] = useState(false);

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name?.localeCompare ? a.name.localeCompare(b.name) : -1
            },
            {
                title: 'Customer Name',
                dataIndex: 'customer',
                key: 'customer',
                sorter: (a, b) => a.customer?.localeCompare ? a.customer.localeCompare(b.customer) : -1
            },
            {
                title: 'Driver Duty', dataIndex: 'duties', key: 'duties', width: 500,
                render: (_, charter) => charter.duties.filter(duty => !!duty).map(duty => <RouteTitle route={{
                    routeNumber: duty?.shiftBatNumber,
                    routeName: duty?.shiftBatName,
                    routeDetails: duty?.shiftBatDetails,
                    colour: duty?.shiftBatColour,
                    routeLogo: duty?.shiftBatLogo
                }}/>)
            },
            {
                title: 'Calendars', dataIndex: 'calendars', key: 'calendars', width: 300,
                render: (_, charter) => <CharterTimesPopover charter={charter} schedules={allSchedules}/>
            },
            {
                title: 'Status', dataIndex: 'status', key: 'status', width: 100,
                sorter: (a, b) => a.status?.localeCompare ? a.status?.localeCompare(b.status) : 1,
                render: (_, charter) => <div className="d-flex justify-content-center simple-tags">
                    <FilterTags filterItems={filterItems} model={charter}/>
                </div>
            },
            {
              title: 'Created At', dataIndex: 'createdAt', key: 'createdAt', width: 150,
              defaultSortOrder: 'descend',
              sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
              render: (_, charter) => {
                  const date = new Date(charter.createdAt);
                  const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' locale formats the date as DD/MM/YYYY
                  return <span>{formattedDate}</span>;
              }
            },
            {
                title: 'Actions', dataIndex: 'actions', key: 'actions', width: 100,
                render: (_, charter) => (
                    <Flex justify={'space-around'}>
                        <LoaderButton
                            className="btn-icon-control"
                            size="sm"
                            isLoading={isCloning}
                            onClick={ async () => {
                                setIsCloning(true);
                                console.log('Copy charter', charter.charterId);
                                const clonedCharter = charter.clone();
                                clonedCharter.charterId = ulid();
                                clonedCharter.name += '-COPY';
                                clonedCharter.status = 'new';
                                clonedCharter.createdAt = new Date().toISOString();
                                clonedCharter.updatedAt = new Date().toISOString();
                                // copy itinerary
                                clonedCharter.itinerary = clonedCharter.itinerary.map(route => {
                                    route.routeId = ulid();
                                    return route;
                                })
                                await routeModelData.save(clonedCharter.itinerary);
                                // copy duties
                                clonedCharter.duties = clonedCharter.duties.map(duty => {
                                    duty.shiftBatId = ulid();
                                    return duty;
                                })
                                await shiftBatModelData.save(clonedCharter.duties);
                                await charterModelData.save(clonedCharter)
                                console.log('Charter saved.');
                                setIsCloning(false);
                            }}
                        >
                            <Copy/>
                        </LoaderButton>
                        <Popconfirm title={`Are you sure you want to delete ${charter.name}?`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onCancel={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onConfirm={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setIsDeleting({...isDeleting, [charter.charterId]: true});
                                        console.log('Delete charter', charter.charterId);
                                        charterModelData.delete([charter.charterId, charter.itineraryId], true).then(() => {
                                            console.log('Charter deleted.');
                                            shiftBatModelData.delete(charter.duties.map(duty => duty.shiftBatId), true).then(() => {
                                                console.log(`Deleted ${charter.duties.length} duties. for charter $${charter.charterId}`);
                                            })
                                            setIsDeleting({...isDeleting, [charter.charterId]: false});
                                        }).then(() => {
                                            routeModelData.delete(charter.itinerary.map(route => route.routeId), true).then(() => {
                                                console.log(`Deleted ${charter.itinerary.length} routes. for charter $${charter.charterId}`);
                                            })
                                        });
                                    }}> <Button
                            className="btn-icon-control btn-delete"
                            size="sm"
                            loading={isDeleting[charter.charterId]}
                            icon={<Trash/>}/>
                        </Popconfirm>
                    </Flex>
                )
            },
        ];
    }, [allSchedules]);

    useEffect(() => {
        if (charters && setData) {
            setData(
                charters.map((charter) => {
                    return new Charter({
                        ...charter,
                        key: charter.charterId,
                        customer: charter.customer?.name || '',
                        // warnings: charter.validateCharter(charters),
                    });
                })
            );
        }
    }, [charters, setData]);


    return <div className="CardTable"><Table size={'middle'} key={'charter-list-table'} columns={columns} dataSource={data} pagination={false}
                  virtual={true}
                  onRow={(charter) => {
                      return {
                          onClick: e => {
                              if(e.ctrlKey || e.metaKey) {
                                  window.open(`/charters/${charter.charterId}`, '_blank');
                              } else {
                                  history.push(`/charters/${charter.charterId}`)
                              }
                          },
                      };
                  }}
                  rowClassName={() => 'charter-row'}
                //   scroll={{y: 600}}
    /></div>;

};

export default React.memo(CharterList);
