import React, { memo } from "react";
import { Check, Time } from "../../components/Icons";
import { Allocation } from "./AllocationModal";
import { useRoster } from "./RosterContext";
import { employeeName } from "./SelectEmployeeView";

export const WorkItemCard = memo(({ workItem, unallocated, selected, statusColour, isCharter, charter, onSelectWorkItem }) => {
  const { employeeData, allVehicles } = useRoster();
  return (
    <div
      key={`work-item-div-${workItem.workItemId}`}
      className={`roster-item ${unallocated ? "item-unallocated" : ""} ${selected ? "item-selected" : ""} ${
        workItem?.optional ? "item-optional" : ""
      } ${workItem.compliance?.length ? "item-compliance" : ""}`}
    >
      <div
        className="roster-item-wrap"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (workItem.optional) {
            onSelectWorkItem(workItem);
          } else {
            onSelectWorkItem(null);
          }
        }}
      >
        <div>
          <div className="roster-name">
            {workItem.workItemName}
            {isCharter && `: ${charter?.name}`}
          </div>
          <div className="roster-time">
            <Time width={14} height={14} />
            {workItem.startTimeFormatted}-{workItem.endTimeFormatted}
          </div>
        </div>
        {!unallocated ? (
          <Allocation
            employeeId={employeeData.getEmployee(workItem.employeeId)?.employeeID}
            employeeName={employeeName(employeeData.getEmployee(workItem.employeeId))}
            vehicleName={allVehicles[workItem.vehicleId]?.vehicleName}
            statusColour={statusColour}
          />
        ) : (
          <></>
        )}
        <span className="icon-success">
          <Check />
        </span>
      </div>
    </div>
  );
});
