import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Tag} from 'antd';
import {Col, Image, Row} from 'react-bootstrap';
import config from '../../config';
import {ShiftBatRowType} from '../../model/shiftBat';
import RouteTitle from '../../components/RouteTitle';
import {to12HrTime, toHrsMinsSecs, toKmMs} from '../../libs/formatLib';
import dayjs from '../../dayjs';
import {QRCode} from 'react-qrcode-logo';
import {API} from 'aws-amplify';
import html2pdf from 'html2pdf.js';
import LoadMessage from '../../components/LoadMessage';
import ShiftBatRows from './ShiftBatRows';
import {useAppContext} from '../../libs/contextLib';
import {reverse} from 'lodash';
import RouteMapViewer from '../../components/RouteMapViewer';

function ShiftBatPrint({
                           isPublic = false,
                           allStops,
                           allRoutes,
                           operatorId,
                           shiftBat,
                           schedules,
                           charter,
                           showMap,
                           printMode,
                           printLayout,
                           customer, allocation
                       }) {
    const {operator} = useAppContext();
    const [encryptedUrl, setEncryptedUrl] = useState(null);
    const [viewing, setViewing] = useState({});
    const [syncing, setSyncing] = useState(false);
    const shiftBats = useMemo(() => Array.isArray(shiftBat) ? shiftBat : [shiftBat], [shiftBat]);

    useEffect(() => {
        if (shiftBats) {
            const viewing = {};
            shiftBats.forEach(shiftBat => shiftBat.rows.filter((row) => row.type === ShiftBatRowType.service).forEach((serviceRow) => (viewing[serviceRow.id] = true)));
            setViewing(viewing);
            API.endpoint('routes').then((path) => {
                setEncryptedUrl(path + `/checkShiftBat/${operatorId || operator?.operatorId}/${shiftBats.map(shiftBat => shiftBat.shiftBatId).join(',')}?_q=${Date.now()}`);
            });
        }
    }, [shiftBats, setViewing, setEncryptedUrl, operatorId, operator, isPublic]);


    // Generates PDF file and syncs with staff portal
    const sync = useCallback(async () => {
        setSyncing(true);
        const element = document.getElementById('shiftbat-view');

        const generatePDF = async () => {
            const opt = {
                margin: 1,
                filename: 'modal-content.pdf',
                image: {type: 'jpeg', quality: 1}, // html2canvas: { scale: 2, useCors: true },
                html2canvas: {
                    scale: 4, // Increase the scale factor for better quality
                    useCORS: true, // Ensure CORS is used
                    logging: true, // Enable logging to debug issues
                },
                jsPDF: {unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']},
            };

            return new Promise((resolve, reject) => {
                html2pdf().from(element).set(opt).outputPdf('blob').then(resolve).catch(reject);
            });
        };

        if (element && shiftBat) {
            try {
                const pdfBlob = await generatePDF(element);

                if (!operator) {
                    throw new Error(`Operator not found: ${operatorId || operator?.operatorId}`);
                }

                // Uncomment to verify the file contents
                // const pdfUrl = URL.createObjectURL(pdfBlob);
                // window.open(pdfUrl, "_blank");
                // return

                const form = new FormData();
                form.append('files', pdfBlob, `${shiftBat.shiftBatId}.pdf`);

                const response = await fetch(`${config.cms.URL}/api/upload`, {
                    method: 'POST', body: form, headers: {
                        Authorization: `Bearer ${config.cms.APIKEY}`,
                    }
                });
                const uploadedFile = await response.json();
                const linkResponse = await fetch(`${config.cms.URL}/busable-inject-functions/busable/shiftbat/sync`, {
                    method: 'POST', body: JSON.stringify({
                        companyId: operator.companyId,
                        shiftbatId: shiftBat.shiftBatId,
                        shiftbatName: shiftBat.shiftBatName,
                        file: uploadedFile[0],
                    }), headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!linkResponse.ok) {
                    throw new Error(`Error uploading file: ${linkResponse.statusText}`);
                }

                const data = await linkResponse.json();
                console.log('File uploaded successfully', data);
                setSyncing(false);
            } catch (error) {
                console.error('Error uploading file', error);
                setSyncing(false);
            }
        }
    }, [operator, operatorId, operator, shiftBat]);

    return (<>
        {shiftBats.map(shiftBat => (<div
            key={shiftBat.shiftBatId}
            id="shiftbat-view"
            className={`ShiftBat PrintView PrintView-${printMode} PrintView-${printLayout} mb-4`}>
            {showMap ? <Row className="header-row align-items-center">
                <div className="MapPlaceholder" style={{height: '80vh'}}>
                    <RouteMapViewer shiftBatBuilder={true} noToolbar={true} noSearch={true}
                                    showWpSelector={false}
                                    routes={shiftBat.rows.filter(row => row.route).map(row => row.route).concat(shiftBat.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                    trips={shiftBat.rows.filter(row => row.trip).map(row => row.trip)}
                                    allStops={allStops}
                                    locations={charter ? reverse(shiftBat.rows.filter(row => [ShiftBatRowType.location, ShiftBatRowType.stop, ShiftBatRowType.service, ShiftBatRowType.charter].includes(row.type) && row.getStartLocation()).map(row => row.getStartLocation())) : null}
                                    verifiedOnly={true}
                                    filteredRoutes={shiftBat.rows.filter(row => row.route).map(row => {
                                        return row.route;
                                    }).concat(shiftBat.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                    charter={charter}
                    />
                </div>
            </Row> : <></>}
            <Row className="header-row align-items-center">
                <Col className="d-flex customer-info-text">
                    <RouteTitle
                        route={{
                            routeNumber: shiftBat.shiftBatNumber,
                            routeName: shiftBat.shiftBatName,
                            routeDetails: shiftBat.shiftBatDetails,
                            routeLogo: shiftBat.shiftBatLogo,
                            colour: shiftBat.shiftBatColour,
                        }}
                    />
                    <div className="RouteInfo">
                        <h1>{shiftBat.shiftBatName}</h1>
                        <div className="RouteDetails">{shiftBat.shiftBatDetails}</div>
                        <div className="d-flex flex-row simple-tags">
                            {shiftBat.scheduleIds.map((sId) => (
                                <Tag key={shiftBat.shiftBatId + sId}>{schedules[sId]?.scheduleName}</Tag>))}
                        </div>
                    </div>
                </Col>
                <Col xs="auto" className="customer-info-logo">
                    <Image
                        style={{maxHeight: '120px', minWidth: '120px', maxWidth: '160px'}}
                        src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${operatorId || operator?.operatorId}/logo.png`}
                        onError={(e) => {
                        }}
                    />
                    <div className="QRCode">
                        {' '}
                        {encryptedUrl ? (
                            <QRCode value={`${encryptedUrl}`} ecLevel="L" size={120} fgColor={'#000000'}
                                    qrStyle={'dots'}/>) : (<LoadMessage/>)}
                    </div>
                </Col>
            </Row>
            <div className="sb-header-info">
                <div>
                    Start Shift: <strong>{to12HrTime(shiftBat?.getStartTime() || 0)}</strong>
                </div>
                {shiftBat.getFirstStopRow()?.stop?.stopType === 'depot' && (<div>
                    Depart Depot: <strong>{to12HrTime(shiftBat.getFirstStopRow().time || 0)}</strong>
                </div>)}
                {shiftBat.getLastStopRow()?.stop?.stopType === 'depot' && (<div>
                    Arrive Depot: <strong>{to12HrTime(shiftBat.getLastStopRow().time || 0)}</strong>
                </div>)}
                <div>
                    Finish Shift: <strong>{to12HrTime(shiftBat?.getEndTime() || 0)}</strong>
                </div>
                <div>
                    Shift hours: <strong>{toHrsMinsSecs(shiftBat.getShiftTime(), false, true)}</strong>
                </div>
                <div>
                    Shift distance: <strong>{toKmMs(shiftBat.getShiftDistance(), 1)}</strong>
                </div>
                <div>
                    Current From:{' '}
                    <strong>{shiftBat?.effectiveDate ? shiftBat?.effectiveDate.format('DD/MM/YYYY') : '--/--/----'}</strong>
                </div>
                <div>
                    Printed: <strong>{dayjs().format('DD/MM/YYYY')}</strong>
                </div>
            </div>
            {customer && <div className="sb-header-info">
                <div>Customer Name: <strong>{customer.name}</strong></div>
                <div>Email: <strong>{customer.email}</strong></div>
                <div>Phone: <strong>{customer.phone}</strong></div>
            </div>}
            {(allocation?.employee || allocation?.vehicle) && <div className="sb-header-info">
                {allocation.employee && <div>Allocated Driver: <strong>{allocation.employee.firstName} {allocation.employee.lastName}</strong></div>}
                {allocation.vehicle && <div>Allocated Vehicle: <strong>{allocation.vehicle.vehicleName}</strong></div>}
            </div> }
            <Row className="mx-0">
                <Col xs={12} className="px-0">
                    <ShiftBatRows
                        disableEditForRow={() => false}
                        shiftBat={shiftBat}
                        editMode={false}
                        allStops={allStops}
                        allRoutes={allRoutes}
                        printLayout={printLayout}
                        printMode={printMode}
                        viewing={viewing}
                        setViewing={setViewing}
                    />
                </Col>
            </Row>
        </div>))}
    </>);
}

export default React.memo(ShiftBatPrint);
