import {API} from 'aws-amplify';
import dayjs from '../dayjs';
import util from 'util';
import {
    createSchedule,
    createStop,
    deleteRoute,
    deleteSchedule,
    getRoutesWithOpts,
    loadRoute,
    updateRoute,
    updateSchedule,
    updateStop,
} from './routeService';
import config from '../config';
import {BusRoute, LinkedStop, Stop} from '../model/busRoute';
import {DATE_STRING, Schedule} from '../model/schedule';
import {Vehicle} from '../model/vehicle';
import {ulid} from 'ulid';
import {Driver} from '../model/driver';
import {Shift} from '../model/shift';
import log from 'loglevel';
import {find, keyBy} from 'lodash/collection';
import {ShiftBat} from '../model/shiftBat';
import {CharterRouteRun, Deadrun} from '../model/deadrun';
import {cloneDeep} from 'lodash/lang';
import Dexie from 'dexie';
import {camelCase} from 'lodash/string';
import Features from '../model/features';
import {debounce} from 'lodash/function';
import {toHrsMinsSecs} from '../libs/formatLib';
import {chunk, findIndex} from 'lodash/array';
import {Transfer} from '../model/transfer';
import {pickBy, values} from 'lodash/object';
import {noop} from 'lodash/util';
import sleep from 'sleep-promise';
import {Employee} from '../model/hrm/employee';
import {Employment} from '../model/hrm/employment';
import {Entitlement} from '../model/hrm/entitlement';
import {Leave} from '../model/hrm/leave';
import {License} from '../model/hrm/license';
import {Qualification} from '../model/hrm/qualifications';
import {Training} from '../model/hrm/training';
import {Superannuation} from '../model/hrm/superannuation';
import {Tax} from '../model/hrm/tax';
import {Banking} from '../model/hrm/banking';
import {clone, omit, pick} from 'lodash';
import {Address, Student} from '../model/student';
import {getEditor} from '../hooks/getEditor';
import {calculateGeoHash, reverseGeocode} from '../libs/mapLib';
import {checkOnline} from '../libs/errorLib';
import {APP_VERSION} from '../App';
import {Charter, Itinerary, ItineraryShift} from '../model/charter';
import {Customer} from '../model/customer';
import {Job} from '../model/job';
import {VehicleType} from '../model/vehicleType';
import {WeeklyScenario} from '../model/roster';

const logger = log.getLogger('ModelService');

// const UPDATE_INTERVAL = 30000;
const DEFAULT_MODEL_FILTER = ['Operator', 'Stop', 'Transfer', 'Route', 'Schedule', 'Deadrun', 'Ref',
    'DriverShift', 'VehicleShift', 'ShiftBat', 'Employee', 'Sms', 'Charter', 'Customer', 'Student', 'RouteComment', 'StopComment',
    'StudentConfig', 'Roster', 'Vehicle', 'VehicleType'];

class ModelExpiryService {
    constructor(wss) {
        // this.stops = new StopModelData();
        // this.routes = {data: store.get('Route') || null, expired: false};
        // this.schedules = {data: store.get('Schedule') || null, expired: false};
        // return (async () => {
        //     await this.sync();
        //     this.timer = setInterval(this.sync, 30000);
        //     return this;
        // })();
        this.wss = wss;
        this.wsConnection = null;
        this.wsConnectionId = null;
        this.wsConnectionInterval = null;
        this.initialised = false;
        this.updatingObjects = {};
        this.models = {};
        this.messageApi = null;
        this.loadedListeners = [];

        // HACK:
        this.vehicleListener = null;
    }

    closeWSConnection() {
        this.wsConnection && this.wsConnection.close();
    }

    requestData() {
        if (this.wsChannel) {
            const connectionTime = Date.now();
            this.db.syncs.toArray().then((syncs) => {
                let syncByName = keyBy(syncs, 'name');
                Object.keys(syncByName).forEach((name) => {
                    if (this.modelFilter && !this.modelFilter.includes(name)) {
                        delete syncByName[name];
                    }
                });
                Promise.all(
                    Object.keys(syncByName).map(async (name) => {
                        if (name === 'Route') {
                            this.models[name]
                                .setFetchOption(
                                    'updatedAfter',
                                    syncByName.Route.lastUpdated
                                )
                                .setFetchOption('includePath', true)
                                .setFetchOption('includeStopTimes', true);
                        }
                        let done = false,
                            attempts = 0,
                            sleepMs = 100;
                        while (attempts++ < 5 && !done) {
                            try {
                                await this.models[name].fetchAll();
                                done = true;
                            } catch (e) {
                                console.log(
                                    'Error fetching all ' +
                                    name +
                                    '. Sleeping ' +
                                    sleepMs +
                                    'ms.',
                                    e
                                );
                                await sleep(sleepMs);
                                sleepMs *= 2;
                            }
                        }
                        this.models[name].setLoaded(connectionTime);
                        this.models[name].updateSyncsNow();
                    })
                ).then(() => {
                    console.log('Finished fetching all.');
                    const modelNames = Object.keys(this.models).filter(
                        (name) =>
                            !this.modelFilter || this.modelFilter.includes(name)
                    );
                    if (modelNames.every((name) => this.models[name].loaded)) {
                        Promise.all(
                            modelNames.map(async (name) => {
                                return await this.models[name].notifyLoaded();
                            })
                        ).then(() => {
                            console.log('Models notified.');
                            this.loaded = true;
                            this.loadedListeners.forEach((listener) =>
                                listener.loaded()
                            );
                        });
                    }
                }).catch(e => {
                    console.log('Caught issue with requestData: ', e);
                });
            });
        }
    }

    addLoadedListener(listener) {
        this.loadedListeners.push(listener);
        if (this.loaded) {
            listener.loaded();
        }
    }

    removeLoadedListener(listener) {
        this.loadedListeners.splice(
            this.loadedListeners.findIndex((l) => l === listener),
            1
        );
    }

    initConnection(apiKey) {
        console.log(this.wsConnection?.readyState, WebSocket.CONNECTING);
        if (
            this.wsConnection &&
            this.wsConnection.readyState === WebSocket.CONNECTING
        ) {
            return;
        }
        if (
            this.wsConnection &&
            this.wsConnection.readyState === WebSocket.OPEN
        ) {
            logger.debug('WebSocket already open.');
            checkOnline().then((online) => {
                if (!online) {
                    this.closeWSConnection();
                }
            });
            return;
        }
        logger.debug(`Attempting to connect to websocket: ${this.wss}`);
        this.wsConnection = new WebSocket(`${this.wss}?_k=${apiKey}`);
        this.connectionTime = Date.now();
        this.wsConnection.onopen = (event) => {
            console.log('WebSocket is now open.', event);
            logger.info('WebSocket is now open.');
            this.wsChannel = event.currentTarget;
            if (!this.noRequest) {
                this.requestData();
            }
        };

        this.wsConnection.onclose = (event) => {
            logger.info('WebSocket is now closed.');
        };

        this.wsConnection.onerror = (event) => {
            logger.error(
                `WebSocket error observed: ${util.inspect(event)}`,
                event
            );
        };

        this.wsConnection.onmessage = async (event) => {

            // console.log(event)
            const data = JSON.parse(event.data);
            if (!data) {
                return;
            }
            const {action} = data;

            if (action === 'navigate' && this.vehicleListener) {
                this.vehicleListener(data);
                return;
            }

            let model = this.models[data.model];
            if (!model) {
                // if (action === 'loaded') {
                //     Object.keys(this.models).forEach(name => this.models[name].init())
                // }
                return;
            }
            if (action === 'update') {
                logger.debug(`UPDATE WS message: `, data);
                const obj = await model.fetch(data.id);
                delete obj._status;

                if (model.getSubModelData) {
                    model = model.getSubModelData(obj);
                }
                model.notifyUpdate(obj);
                // model.lastUpdated = Date.now();
                // model.updateSyncs()
            } else if (action === 'delete') {
                logger.debug(`DELETE WS message: `, data);
                await model.delete(data.id);

                if (model.getSubModelData) {
                    model = model.getSubModelData(data);
                }
                model.notifyDelete(data.id);
                // model.lastUpdated = Date.now();
                // model.updateSyncs()
            } else if (action === 'chunk') {
                const {objectId, idx, chunk, length} = data;
                if (!this.updatingObjects[objectId]) {
                    this.updatingObjects[objectId] =
                        this.updatingObjects[objectId] || [];
                }
                this.updatingObjects[objectId].push({
                    objectId,
                    idx,
                    chunk,
                    length,
                });
                if (this.updatingObjects[objectId].length === length) {
                    logger.debug(`Received ws obj for ${data.model}.`);
                    const str = this.updatingObjects[objectId]
                        .filter((ch) => ch !== undefined)
                        .sort((a, b) => a.idx - b.idx)
                        .map((ch) => ch.chunk)
                        .join('');
                    try {
                        const obj = JSON.parse(str);
                        delete obj._status;

                        if (model.getSubModelData) {
                            model = model.getSubModelData(obj);
                        }
                        if (model.loaded) {
                            await model.set(obj);
                            // model.lastUpdated = Date.now();
                            // model.updateSyncs()
                        } else {
                            if (Array.isArray(obj)) {
                                model.loadingData =
                                    model.loadingData.concat(obj);
                            } else {
                                model.loadingData.push(obj);
                            }
                        }
                    } catch (e) {
                        console.error(
                            'Could not update %s from WS for the following string. Error: %s',
                            model.name,
                            e.toString(),
                            e
                        );
                        console.error(str);
                    }
                }
            } else if (action === 'loaded') {
                logger.debug(`Received loaded for ${data.model}.`);

                if (model.loadingData?.length) {

                    if (model.getSubModelData) {
                        model = model.getSubModelData(model.loadingData[0]);
                    }
                    await model.db.bulkPut(model.loadingData);
                    if (model.summaryDb) {
                        await model.summaryDb.bulkPut(model.loadingData.map(model.toSummary));
                    }
                    delete model.initData;
                }
                model.setLoaded(this.connectionTime);
                model.updateSyncsNow();

                // const modelNames = Object.keys(this.models).filter(
                //     (name) =>
                //         !this.modelFilter || this.modelFilter.includes(name)
                // );
                // if (modelNames.every((name) => this.models[name].loaded)) {
                //     await Promise.all(
                //         modelNames.map(async (name) => {
                //             return await this.models[name].notifyLoaded();
                //         })
                //     );
                // }
            } else if (action === 'refresh') {
                console.log('Received refresh message.');
                // model.refreshCache()
            }

            // append received message from the server to the DOM element
            // const data = event.data.split('::');
            // const username = data[0];
            // const message = data.slice(1).join('::'); // in case the message contains the separator '::'
            //
            // const newMessage = {
            //     timestamp: Date.now(),
            //     username,
            //     message
            // }
            //
            // setMessages((prevState) => {
            //     return [
            //         ...prevState,
            //         newMessage
            //     ];
            // });
        };
        console.log('WSS: ', this.wsConnection);
        this.initialised = true;
        window.addEventListener('offline', this.closeWSConnection.bind(this));
    }

    async init({
                   apiKey,
                   user,
                   modelFilter = DEFAULT_MODEL_FILTER,
                   messageApi,
                   superFast = true,
                   setFaultState,
                   noRequest = false
               }) {
        if (this.apiKey) {
            return;
        }

        this.noRequest = noRequest;
        this.apiKey = apiKey;
        this.user = user;
        const {editor} = getEditor({user});
        this.modelFilter = modelFilter;
        this.messageApi = messageApi;

        Object.keys(this.models).forEach((modelName) => {
            this.models[modelName].apiKey = apiKey;
            this.models[modelName].messageApi = messageApi;
            this.models[modelName].public = !user;
            this.models[modelName].user = user;
            this.models[modelName].editor = editor;
            this.models[modelName].localCache = superFast;
            this.models[modelName].setFaultState = setFaultState;
        });

        await this.openDb();
        if (!config.local) {
            this.initConnection(apiKey);
            this.wsConnectionInterval = setInterval(() => {
                this.initConnection(apiKey);
            }, 5000);
            this.saveAtSource();
        }
        window.removeEventListener(
            'offline',
            this.closeWSConnection.bind(this)
        );

        return this;
    }

    deinit() {
        this.wsConnectionInterval && clearInterval(this.wsConnectionInterval);
        this.wsConnection.close();
    }

    optOutSuperFast() {
        Object.keys(this.models).forEach((modelName) => {
            this.models[modelName].localCache = false;
            delete this.models[modelName].data;
        });
    }

    saveAtSource() {
        Object.keys(this.models).forEach((modelName) => {
            this.models[modelName].saveAtSource();
        });
    }

    async sync() {
        logger.debug('Checking for updates...');
        try {
            // const models = await API.get("routes", `/modelUpdates`);
            // models.forEach(model => {
            //     const localModel = this[model.name];
            // Expired is true if the lastUpdated is greater than local
            // localModel.setLastUpdated(model.lastUpdated);// && dayjs().diff(dayjs(model.lastUpdated), 's') > 0;
            // });
            const body = {
                source: 'busable.syncClient',
                detailType: 'SyncAll',
                detail: {
                    connectionId: this.wsConnectionId,
                    sync: await this.db.syncs.toArray(),
                },
            };
            await API.post('events', `/events`, {body});
            return body;
        } catch (e) {
            logger.debug(`Error loading model update data: ${e}`, e);
        }
    }

    addData(modelData) {
        this.models[modelData.name] = modelData;
    }

    setDeserialiseData(dataKey, data) {
        Object.keys(this.models).forEach((key) => {
            this.models[key].deserialiseData[dataKey] = data;
        });
    }

    //
    // async checkDb(cacheUpdate = 0) {
    //     const syncs = await Promise.all(Object.keys(this.models).map(async name => {
    //         return await this.db.syncs.get(name)
    //     }))
    //     if (syncs.some(sync => !sync || sync.lastUpdated < cacheUpdate)) {
    //         await this.refreshCache();
    //     }
    // }

    getDbName(operatorId) {
        return `${process.env.REACT_APP_STAGE}_${operatorId || this.apiKey}_db`;
    }

    async openDb() {
        if (this.db?.isOpen()) {
            return;
        }

        try {
            this.db = new Dexie(this.getDbName());
            const dbs = {syncs: 'name'};
            Object.keys(this.models).forEach((name) => {
                dbs[this.models[name].getDbName()] =
                    this.models[name].getDbIndexes();
                if (this.models[name].toSummary) {
                    dbs[this.models[name].getDbName() + '_summary'] =
                        this.models[name].getDbIndexes();
                }
            });
            const [major, minor] = APP_VERSION.split('.');
            const version = parseFloat(major + '.' + minor);
            this.db.version(version).stores(dbs);
            await this.db.open();


            await Promise.all(Object.keys(this.models).map(async name => {
                try {
                    this.models[name].db = this.db[name];
                    this.models[name].summaryDb = this.db[name + '_summary'];
                    this.models[name].syncDb = this.db.syncs;
                    let sync = await this.db.syncs.get(name);
                    if (sync) {
                        this.models[name].lastUpdated = sync.lastUpdated;
                    } else {
                        await this.db.syncs.add({name, lastUpdated: 0});
                    }
                    await this.models[name].init();
                } catch (e) {
                    console.log(`Error opening ${name} db: `, e);
                }
            }));
        } catch (err) {
            console.error(err.stack || err);
        }
    }

    async clearDb(operatorId) {
        if (this.db) {
            if (operatorId) {
                const dbName = this.getDbName(operatorId);
                await Dexie.getDatabaseNames((names, cb) => {
                    names.filter(name => name === dbName).forEach(name => {
                        var db = new Dexie(name);
                        db.delete().then(() => {
                            console.log('Database successfully deleted: ', name);
                        }).catch((err) => {
                            console.error('Could not delete database: ', name, err);
                        }).finally(() => {
                            console.log('Done. Now executing callback if passed.');
                            if (typeof cb === 'function') {
                                cb();
                            }
                        });
                    });
                });
            } else {
                await this.db.delete();
                delete this.db;
            }
        }
    }

    async deleteAllDbs() {
        await Dexie.getDatabaseNames((names, cb) => {
            console.log('database names: ', names);
            names.forEach((name) => {
                var db = new Dexie(name);
                db.delete().then(() => {
                    console.log('Database successfully deleted: ', name);
                }).catch((err) => {
                    console.error('Could not delete database: ', name, err);
                }).finally(() => {
                    console.log('Done. Now executing callback if passed.');
                    if (typeof cb === 'function') {
                        cb();
                    }
                });
            });
        });
    }
}

const routesModelExpiryService = new ModelExpiryService(config.apiGateway.WSS);

// const dutyModelExpiryService = new ModelExpiryService(config.apiGateway.duty.WSS);

class ModelData {
    constructor({
                    name,
                    path,
                    api,
                    expiryMins = 5,
                    fetchOptions,
                    idName,
                    dependents,
                    saveDebounce,
                    deleteAge = 0,
                    maxSave = 100,
                    baseKeyExpression, baseKeyExpressionValue
                }) {
        this.name = name;
        this.api = api || 'routes';
        this.path =
            path || (api ? '/' + api + 's' : '/' + camelCase(name) + 's');
        this.fetched = {};
        this.data = null;
        this.fetchedAll = false;
        this.expiryMins = expiryMins;
        this.apiKey = null;
        this.expired = false;
        this.getPromise = null;
        this.listeners = [];
        this.fetchOptions = fetchOptions;
        this.idName = idName || camelCase(name) + 'Id';
        this.lastUpdated = 0;
        this.loaded = false;
        this.loadingData = [];
        this.db = null;
        this.syncDb = null;
        this.saveDebounce = saveDebounce || 10000;
        this.dependents = dependents || [];
        this.localCache = true;
        this.localCacheInitialised = false;
        this.deleteAge = 0;
        this.maxSave = maxSave;
        this.messageApi = null;
        this.public = false;
        this.baseKeyExpression = baseKeyExpression;
        this.baseKeyExpressionValue = baseKeyExpressionValue;

        this.dependents?.forEach((dependent) => {
            dependent.addListener({
                notifyUpdate: () => this.refreshLocalCacheDebounced()
            });
        });
    }

    setLoaded(connectionTIme) {
        this.lastUpdated = connectionTIme;
        this.loaded = true;
    }

    updateSyncsNow = () => {
        try {
            console.log('Updating syncs: ', this.name);
            if (this.loaded) {
                const {name, lastUpdated} = this;
                this.syncDb.put({name, lastUpdated}).then(noop);
            }
        } catch (e) {
            console.log(e, e);
        }
    };

    async init() {
        const start = Date.now();

        if (this.localCache) {
            let dataAsArray = await this.db.toArray();
            dataAsArray = await this.deserialise(dataAsArray);
            this.data = keyBy(
                dataAsArray,
                this.idName
            );
        }
        this.localCacheInitialised = true;
        logger.warn(
            `Elapsed time for getting init ${this.name}: ${toHrsMinsSecs(
                (Date.now() - start) / 1000
            )}`
        );
    }

    updateSyncs = debounce(this.updateSyncsNow, 5000);

    refreshLocalCache() {
        delete this.data;
        this.notifyLoaded().then(() => console.log('Refreshed ', this.name));
    }

    refreshLocalCacheDebounced = debounce(this.refreshLocalCache, 2000);

    async bulkUpdate(objs) {
        try {
            const _this = this;
            if (this.db) {
                await this.db?.bulkDelete(objs.map(obj => _this.getId(obj)));
                await this.db?.bulkPut(objs);
            }
            if (this.summaryDb) {
                await this.summaryDb.bulkDelete(
                    objs.map((obj) => _this.getId(obj))
                );
                await this.summaryDb.bulkPut(objs);
            }
        } catch (e) {
            console.error(e, e);
        }
    }

    saveAtSourceNow = async () => {
        // await Promise.all(Object.keys(this.data).filter(id => this.data[id]._status).map(async id => {
        //     const obj = this.data[id]
        //     if (obj._status === 'dirty') {
        //         await this._save(obj)
        //     } else if (obj._status === 'new') {
        //         await this._create(obj)
        //     } else if (obj._status === 'delete') {
        //         await this._delete(this.getId(obj))
        //     }
        //     delete obj._status
        //     // await this.db.update(this.getId(obj), {_status: undefined})
        // }))
        if (this.public) {
            return;
        }
        const objs = await this.db
            .filter((obj) => obj._dirty || obj._new || obj._delete)
            .toArray();

        const _this = this;
        const newObjs = objs
            .filter((obj) => obj._new > 0)
            .map((obj) => {
                delete obj._new;
                return obj;
            });
        if (newObjs?.length) {
            try {
                await this._create(newObjs);
                await this.bulkUpdate(newObjs);
            } catch (e) {
                console.error(e, e);
                console.error('Error saving: ', newObjs);
                this.setFaultState({
                    type: 'saveRouteError',
                    error: `Error creating ${newObjs.length} ${this.name}${newObjs.length > 1 ? 's' : ''}: ` + e,
                    data: {response: e.response, exception: e, objs: newObjs}

                });
            }
        }

        const dirtyObjs = objs
            .filter((obj) => obj._dirty > 0)
            .map((obj) => {
                delete obj._dirty;
                return obj;
            });
        if (dirtyObjs?.length) {
            try {
                await this._create(dirtyObjs);
                await this.bulkUpdate(dirtyObjs);
            } catch (e) {
                console.error(e, e);
                console.error('Error updating: ', dirtyObjs);
                this.setFaultState({
                    type: 'saveRouteError',
                    error: `Error updating ${dirtyObjs.length} ${this.name}${dirtyObjs.length > 1 ? 's' : ''}: ` + e,
                    data: {response: e.response, exception: e, objs: dirtyObjs}
                });
            }
        }
        const deleteObjs = objs
            .filter((obj) => obj._delete > 0)
            .map((obj) => {
                delete obj._delete;
                return obj;
            });
        if (deleteObjs?.length) {
            try {
                await this._delete(deleteObjs.map(o => _this.getId(o)));
                await this.db.bulkDelete(deleteObjs.map(o => _this.getId(o)));
                if (this.summaryDb) {
                    await this.summaryDb.bulkDelete(
                        deleteObjs.map(o => _this.getId(o))
                    );
                }
                this.notifyDelete(deleteObjs.map(o => _this.getId(o)));

                // await Promise.all(objs.map(async obj => {
                //     if (obj._dirty > 0) {
                //         delete obj._dirty
                //         await this._save(obj)
                //         await this.db.update(this.getId(obj), {_status: undefined})
                //         this.summaryDb && await this.summaryDb.update(this.getId(obj), {_dirty: undefined})
                //         this.notifyUpdate(await this.deserialise(obj))
                //     } else if (obj._new > 0) {
                //         delete obj._new
                //         await this._create(obj)
                //         await this.db.update(this.getId(obj), {_status: undefined})
                //         this.summaryDb && await this.summaryDb.update(this.getId(obj), {_new: undefined})
                //         this.notifyUpdate(await this.deserialise(obj))
                //     } else if (obj._delete > 0 && (Date.now() - this.deleteAge) > obj._delete) {
                //         const id = this.getId(obj)
                //         await this._delete(id)
                //         await this.db.delete(id)
                //         this.summaryDb && await this.summaryDb.delete(id)
                //         this.notifyDelete(id)
                //     }
                // })).then(() => console.log('All updated data saved.'))
            } catch (e) {
                console.error(e, e);
                console.error('Error deleting: ', deleteObjs);
                // this.messageApi?.error(
                //     `Error deleting ${deleteObjs.length} ${this.name}s: ` +
                //     e,
                //     10
                // );
                this.setFaultState({
                    type: 'saveRouteError',
                    error: `Error deleting ${deleteObjs.length} ${this.name}${deleteObjs.length > 1 ? 's' : ''}: ` + e,
                    data: {response: e.response, exception: e, objs: deleteObjs}
                });
            }
        }
    };

    saveAtSource = debounce(this.saveAtSourceNow, this.saveDebounce);

    getDbName() {
        return this.name;
    }

    getDbIndexes() {
        return this.idName;
    }

    clear() {
        this.fetched = {};
        this.fetchedAll = false;
        delete this.apiKey;
        delete this.getPromise;
        this.listeners.length = 0;
        delete this.data;
    }

    create(obj) {
        return obj;
    }

    serialise(obj) {
        return obj;
    }

    async deserialise(objs) {
        objs = Array.isArray(objs) ? objs : [objs];
        objs = cloneDeep(objs);
        return objs.map(this.create);
    }

    async duplicate(id) {
        let obj = await this.db.get(id);
        if (obj.clone) {
            obj = obj.clone();
        } else {
            obj = cloneDeep(obj);
        }
        this.setId(obj);
        return await this.save(obj);
    }

    async set(objs) {
        logger.debug(`Setting ${this.name}...`);

        // obj = await this.deserialise(obj)
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        if (this.db) {
            await this.db.bulkPut(objs);
        }
        if (this.summaryDb) {
            const _this = this;
            await this.summaryDb.bulkPut(objs.map(_this.toSummary));
        }
        logger.debug(`Added ${this.name}. Notifying listeners...`);
        objs = await this.deserialise(objs);
        if (this.localCache) {
            this.data = this.data || {};
            objs.forEach((obj) => {
                this.data[this.getId(obj)] = obj;
            });
        }
        if (this.loaded) {
            this.notifyUpdate(objs);
        }
    }

    async delete(ids, deleteAtSource, immediate) {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        const time = Date.now();

        // TODO: use this instead once Dexie v4.0.1 has been released
        // if (deleteAtSource) {
        //     await this.db.bulkUpdate(ids.map(id => ({key: id, changes: {_delete: time}})))
        //     if (this.summaryDb) {
        //         await this.summaryDb.bulkUpdate(ids.map(id => ({key: id, changes: {_delete: time}})))
        //     }
        // } else {
        //     if (this.data) {
        //         this.data = omit(this.data, ids)
        //     }
        //     await this.db.bulkDelete(ids)
        //     if (this.summaryDb) {
        //         await this.summaryDb.bulkDelete(ids)
        //     }
        // }
        //
        // this.lastUpdated = Date.now()
        // ids.forEach(id => {
        //     this.notifyDelete(id);
        // });

        await Promise.all(
            ids.map(async (id) => {
                if (deleteAtSource) {
                    // this.data[id]._status = 'delete'
                    if (immediate) {
                        await this._delete(id);
                        await this.db.delete(id);
                        this.summaryDb && (await this.summaryDb.delete(id));
                        this.notifyDelete(id);
                    } else {
                        await this.db.update(id, {_delete: time});
                        if (this.summaryDb) {
                            await this.summaryDb.update(id, {_delete: time});
                        }
                    }
                } else {
                    if (this.data) {
                        delete this.data[id];
                    }
                    await this.db.delete(id);
                    if (this.summaryDb) {
                        await this.summaryDb.delete(id);
                    }
                }
                this.lastUpdated = Date.now();
            })
        );
        if (deleteAtSource) {
            this.saveAtSource();
        }
    }

    async fetch(id) {
        if (!this.path) {
            throw new Error('Please initialise fetch. No path.');
        }

        logger.debug(`Fetching Vehicle for ${this.apiKey}...`);

        let fetched;
        if (config.local) {
            fetched = await fetch(
                `/LOCAL_DATA/${this.apiKey}-${this.api}.json`,
                {
                    method: 'GET',
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    return find(data, (obj) => this.getId(obj) === id);
                });
        } else {
            fetched = await API.get(
                this.api,
                `${this.path}/${id}${this.apiKey ? `?_k=${this.apiKey}` : ''}`
            );
        }
        try {
            [fetched] = await this.deserialise(fetched);
        } catch (e) {
            console.log(e, e);
            this.messageApi?.error(
                'Error fetching ' + this.name + ': ' + e,
                10
            );
        }
        logger.debug(this.name, fetched);
        await this.db.put(fetched);

        if (this.summaryDb) {
            await this.summaryDb.put(this.toSummary(fetched));
        }
        return fetched;
    }

    async fetchAll(force) {
        if (!this.path) {
            throw new Error('Please initialise fetchAll. No path.');
        }

        logger.debug(`Fetching for ${this.apiKey}...`);
        this.expired = false;
        // this.lastUpdated = Date.now()

        let fetched = [];
        if (config.local) {
            const filename = `/LOCAL_DATA/${this.apiKey}-${this.api}.json`;
            fetched = await fetch(filename, {
                method: 'GET',
            }).then((response) => response.json());
        } else {
            // check the last updated for this model in the local db
            const ttl = await this.syncDb.get(this.name);
            this.syncDb.put({name: this.name, lastUpdated: Date.now()});
            let url = `/page${this.path}${this.apiKey ? `?_k=${this.apiKey}` : ''}`;

            let indexName, keyExpression, keyExpressionValue;
            if (this.baseKeyExpression) {
                keyExpression = this.baseKeyExpression;
                if (this.baseKeyExpressionValue) {
                    keyExpressionValue = this.baseKeyExpressionValue;
                }
            }
            if (!force && ttl?.lastUpdated > 0) {
                keyExpression = 'updatedAt > :lastUpdated';
                keyExpressionValue = `lastUpdated|${ttl.lastUpdated}|i`;
                indexName = 'UpdatedAtIdx';
                // url += `indexName=UpdatedAtIdx&${encodeURIComponent(keyExpression)}&keyExpressionValue=${encodeURIComponent(keyExpressionValue)}`;
                // fetched = await API.post(this.api, `/page${this.path}${this.apiKey ? `?_k=${this.apiKey}` : ''}&indexName=UpdatedAtIdx&keyExpression=${encodeURIComponent('updatedAt > :lastUpdated')}&keyExpressionValue=lastUpdated|${ttl.lastUpdated}|i`)
                // } else {
                //     fetched = await API.post(this.api, `/page${this.path}${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
            }

            if (indexName) {
                url += `&indexName=${indexName}`;
            }
            if (keyExpression && keyExpressionValue) {
                url += `&keyExpression=${encodeURIComponent(keyExpression)}&keyExpressionValue=${encodeURIComponent(keyExpressionValue)}`;
            }
            let response = await API.post(this.api, url, {headers: {'x-accept-encoding': 'gzip'}});
            while (response.LastEvaluatedKey) {
                fetched = fetched.concat(response.items || []);
                response = await API.post(this.api, url, {
                    body: {LastEvaluatedKey: response.LastEvaluatedKey},
                    headers: {'x-accept-encoding': 'gzip'}
                });
            }
            fetched = fetched.concat(response.items || []);
        }
        console.log(`Fetched ${fetched.length} ${this.name}'s.`);
        await this.db.bulkPut(fetched);
        if (this.summaryDb) {
            const fetchedSummary = fetched.map(this.toSummary);
            await this.summaryDb.bulkPut(fetchedSummary);
        }
        if (this.localCache) {
            this.data = this.data || {};
            fetched = await this.deserialise(fetched);
            const fetchedById = keyBy(
                fetched,
                this.idName
            );
            this.data = {...this.data, ...fetchedById};
        }
        this.fetchedAll = true;
        this.loaded = true;
    }

    async fetchCurrentIdBeginningWith(id) {
        if (!this.path) {
            throw new Error('Please initialise fetchAll. No path.');
        }

        logger.debug(
            `Fetching ${this.name} for ${this.apiKey}... current with id: ${id}`
        );
        this.expired = false;
        // this.lastUpdated = Date.now()

        let fetched = await API.get(
            this.api,
            `${this.path}/${encodeURIComponent(id)}`
        );
        logger.debug(`Fetched ${fetched} ${this.name}'s.`);
        if (fetched) {
            [fetched] = await this.deserialise(fetched);
        }
        return this.create(fetched);
    }

    async fetchAllIdBeginningWith(id, apiKey, force) {
        if (!this.path) {
            throw new Error('Please initialise fetchAll. No path.');
        }

        logger.debug(
            `Fetching ${this.name} for ${this.apiKey}... beginning with with id: ${id}`
        );
        this.expired = false;
        // this.lastUpdated = Date.now()
        if (!force && this.data && Object.keys(this.data)?.length) {
            return values(
                pickBy(this.data, (value, key) => key.startsWith(id))
            );
        }
        let fetched;
        try {
            fetched = await this.db.where(this.idName).startsWith(id).toArray();
        } catch (e) {
            console.log(e, e);
        }
        if (!force && fetched?.length) {
            return await this.deserialise(fetched);
        }
        if (config.local) {
            const filename = `/LOCAL_DATA/${this.apiKey}-${this.api}.json`;
            fetched = await fetch(filename, {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((data) => {
                    return data
                        .filter((obj) => this.getId(obj).startsWith(id))
                        .map((r) => this.create(r));
                });
        } else {
            fetched = (
                await API.get(
                    this.api,
                    `${this.path}${
                        (apiKey || this.apiKey) ? `?_k=${apiKey || this.apiKey}` : ''
                    }&keyExpression=begins_with(${
                        this.idName
                    }, :id)&keyExpressionValue=id|${encodeURIComponent(id)}`
                )
            ).map(this.create);
        }
        logger.debug(`Fetched ${fetched.length} ${this.name}'s.`);
        if (fetched) {
            fetched = await this.deserialise(fetched);
        }
        await Promise.all(
            fetched.map(async (s) => {
                logger.debug(this.name, s);
                await this.db.put(s);
                if (this.summaryDb) {
                    await this.summaryDb.put(this.toSummary(s));
                }
                this.fetched[id] = true;
            })
        );
        return fetched;
    }

    notifyAll() {
        this.db.toArray().then((data) => {
            this.listeners.forEach((listener) => {
                if (listener.notifyAll) {
                    listener.notifyAll(data);
                }
            });
        });
    }

    async notifyLoaded(objs) {
        logger.debug(`Notifying loaded for all listeners of ${this.name}...`);
        if (objs) {
            objs = await this.serialise(objs);
            this.listeners
                .filter((listener) => listener.loaded)
                .forEach((listener) => {
                    listener.loaded(keyBy(objs, this.idName));
                    logger.debug(
                        `Listener ${listener.id} of ${this.name} loaded.`
                    );
                });
        } else {
            await Promise.all(
                this.listeners
                    .filter((listener) => listener.loaded)
                    .map(async (listener) => {
                        // listener.loaded({})
                        let objs = await this.getAll(listener);
                        // objs = await this.deserialise(values(objs))
                        listener.loaded(Array.isArray(objs) ? keyBy(objs, this.idName) : objs);
                        logger.debug(
                            `Listener ${listener.id} of ${this.name} loaded.`
                        );
                        return Promise.resolve();
                    })
            );
        }
        logger.debug(`All listeners of ${this.name} loaded.`);
    }

    notifyUpdate(obj, local) {
        obj.userId = this.apiKey;
        this.listeners.forEach((listener) => {
            if (listener.notifyUpdate) {
                listener.notifyUpdate(obj, local);
            } else if (listener.setterFn) {
                let objs = obj;
                if (!Array.isArray(objs)) {
                    objs = [objs];
                }
                logger.debug(`${objs.length} ${this.name}s  updated.`);
                listener.setterFn((existingObjs) => {
                    if (!existingObjs) {
                        return;
                    }
                    if (Array.isArray(existingObjs)) {
                        objs.forEach((obj) => {
                            const idx = existingObjs.findIndex(
                                (existing) =>
                                    this.getId(obj) === this.getId(existing)
                            );
                            if (idx > -1) {
                                existingObjs[idx] = this.create({
                                    ...existingObjs[idx],
                                    ...obj,
                                });
                                // logger.debug(`Found existing ${this.name} idx@${idx}`)
                                // existingObjs.splice(idx, 1, obj);
                            } else {
                                // logger.debug(`New ${this.name}...`)
                                existingObjs.push(obj);
                            }
                        });
                        if (listener.sortFn) {
                            existingObjs.sort(listener.sortFn);
                        }
                        return [...existingObjs];
                    } else if (typeof existingObjs === 'object') {
                        objs.forEach((obj) => {
                            const id = this.getId(obj);
                            existingObjs[id] = this.create({
                                ...existingObjs[id],
                                ...obj,
                            });
                        });
                        return {...existingObjs};
                    }
                });
            }
            if (listener.refresh) {
                listener.refresh(obj);
            }
        });
    }

    notifyDelete(id) {
        this.listeners.forEach((listener) => {
            if (listener.notifyDelete) {
                listener.notifyDelete(id);
            } else if (listener.setterFn) {
                let ids = Array.isArray(id) ? id : [id];
                listener.setterFn((existingObjs) => {
                    if (Array.isArray(existingObjs)) {
                        return existingObjs.filter((obj) => !ids.includes(this.getId(obj)));
                    } else if (existingObjs) {
                        logger.debug('Deleting ', ids);
                        delete existingObjs[ids];
                        return omit(existingObjs, ids);
                    }
                });
            } else if (listener.refresh) {
                listener.refresh();
            }
        });
    }

    addListener(listener) {
        if (listener.id) {
            const existingIdx = findIndex(this.listeners, ['id', listener.id]);
            if (existingIdx > -1) {
                this.listeners[existingIdx] = listener;
            } else {
                this.listeners.push(listener);
            }
        } else {
            this.listeners.push(listener);
        }
        if (this.loaded) {
            logger.debug(this.name + ' already loaded...');
            this.notifyLoaded().then(noop);
        }
        return listener;
    }

    removeListener(listener) {
        if (!listener) {
            return;
        }
        if (typeof listener === 'string') {
            this.listeners = this.listeners.filter((l) => l.id !== listener);
        } else {
            this.listeners = this.listeners.filter((l) => listener !== l);
        }
    }

    async copy(obj) {
        const copy = this.create(obj);
        this.setId(copy, '_');
        return await this.save(copy);
    }

    async save(objs, opts = {indirect: false}) {
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        try {
            let create = false;
            objs = objs.map((obj) => {
                obj.createdAt = obj.createdAt || Date.now();
                if (!opts.indirect) {
                    obj.updatedAt = Date.now();
                    obj.author = !obj.author?.length || obj.author.includes('@') ? this.editor : obj.author;
                    obj.lastEditor = this.editor;
                }
                const id = this.getId(obj);
                if (!id || id === '_') {
                    create = true;
                    logger.debug('Creating new', obj);
                    this.setId(obj, ulid());
                }

                const serialised = obj.toJson
                    ? obj.toJson()
                    : this.serialise(obj);
                serialised.userId = this.apiKey;
                if (create) {
                    serialised._new = Date.now();
                } else {
                    serialised._dirty = Date.now();
                }
                return serialised;
            });
            let summaries;
            if (this.summaryDb) {
                summaries = objs.map(this.toSummary);
            }
            // const id = this.getId(obj)
            if (create) {
                // logger.debug('Creating new', obj)
                // this.setId(obj, ulid())
                // obj = await this._create(obj);
                // const serialised = obj.toJson ? obj.toJson() : this.serialise(obj)
                // serialised._new = Date.now()
                // obj = await this.deserialise(obj)
                await this.db.bulkPut(objs);
                if (summaries) {
                    await this.summaryDb.bulkPut(summaries);
                }
                // this.saveAtSource()
            } else {
                // obj = await this._save(obj);
                // const serialised = obj.toJson ? obj.toJson() : this.serialise(obj);
                // serialised._dirty = Date.now();
                // obj = await this.deserialise(obj)
                await Promise.all(
                    objs.map(async (serialised) => {
                        const result = await this.db.update(
                            this.getId(serialised),
                            serialised
                        );
                        if (result === 0) {
                            await this.db.put(serialised);
                        }
                    })
                );

                if (summaries) {
                    await Promise.all(
                        summaries.map(async (summary) => {
                            const result = await this.summaryDb.update(
                                this.getId(summary),
                                summary
                            );
                            if (result === 0) {
                                await this.summaryDb.put(summary);
                            }
                        })
                    );
                }
                // this.saveAtSource()
            }
            // this.lastUpdated = Date.now();

            if (this.localCache) {
                this.data = this.data || {};
                objs = await this.deserialise(objs);
                objs.forEach((obj) => {
                    this.data[this.getId(obj)] = this.create({...this.data[this.getId(obj)], ...obj});
                });
            }
            this.saveAtSource();
            this.notifyUpdate(await this.deserialise(objs), true);
            return objs?.length === 1 ? objs[0] : objs;
        } catch (e) {
            logger.error(`Couldn't save ${this.name}: ${e}`, e);
            this.messageApi?.error('Error saving ' + this.name + ': ' + e, 10);
            throw e;
        }
    }

    async _create(obj) {
        if (!this.path) {
            throw new Error('Please initialise. No path.');
        }
        if (Array.isArray(obj)) {
            const serialised = obj.map((o) => {
                o.userId = this.apiKey;
                return o.toJson ? o.toJson() : this.serialise(o);
            });
            await Promise.all(
                chunk(serialised, this.maxSave).map(async (chunk) => {
                    return await API.post(this.api, this.path, {
                        body: chunk,
                    });
                })
            );
            return obj.map(this.create);
        } else {
            console.log(
                'Saving',
                obj.toJson ? obj.toJson() : this.serialise(obj)
            );
            obj.userId = this.apiKey;
            if (config.local) {
                console.log('Creating...');
                console.log('POST: ', this.path);
                console.log({
                    body: obj.toJson ? obj.toJson() : this.serialise(obj),
                });
            } else {
                await API.post(this.api, this.path, {
                    body: obj.toJson ? obj.toJson() : this.serialise(obj),
                });
                return this.create(obj);
            }
        }
    }

    async _save(obj) {
        if (!this.path) {
            throw new Error('Please initialise. No path.');
        }
        if (Array.isArray(obj)) {
            return await this._create(obj);
        } else {
            obj.userId = this.apiKey;
            if (config.local) {
                console.log('Saving...');
                console.log(`PUT: ${this.path}/${this.getId(obj)}`);
                console.log({
                    body: obj.toJson ? obj.toJson() : this.serialise(obj),
                });
            } else {
                let id = this.getId(obj);
                id = encodeURIComponent(id)
                    .replace('(', '%28')
                    .replace(')', '%29');
                await API.put(this.api, `${this.path}/${id}`, {
                    body: obj.toJson ? obj.toJson() : this.serialise(obj),
                });
                return this.create(obj);
            }
        }
    }

    async _delete(ids) {
        if (!this.path || !this.apiKey) {
            throw new Error('Please initialise. No path.');
        }
        if (!config.local) {
            if (Array.isArray(ids)) {
                ids = ids.map((id) => ({[this.idName]: id}));
                await API.del(this.api, `${this.path}?_k=${this.apiKey}`, {
                    body: ids,
                });
            } else {
                await API.del(this.api, `${this.path}?_k=${this.apiKey}`, {
                    body: {[this.idName]: ids},
                });
            }
        }
    }

    setLastUpdated(lastUpdated) {
        logger.debug(
            `${this.name}'s last updated: ${lastUpdated}. Local: ${this.lastUpdated}`
        );
        if (lastUpdated > this.lastUpdated) {
            this.lastUpdated = lastUpdated;
            this.expired = true;
        }
        logger.debug(`${this.name}s expired: ${this.expired}`);
    }

    getId(obj) {
        if (this.idName) {
            return obj[this.idName];
        }
        throw new Error('Please implement getId(obj)');
    }

    setId(obj, id = ulid()) {
        if (this.idName) {
            obj[this.idName] = id;
            return;
        }
        throw new Error('Please implement setId(obj, id)');
    }

    getStoreId(id) {
        return `${this.name}:${id}`;
    }

    getIdFromKey(key) {
        logger.debug(`Splitting key: ${key}`);
        return key.split(':')[1];
    }

    _getAll(apiKey, batchFn) {
        // modelExpiryService.initConnection(apiKey).then(()=>{});
        if (this.getPromise) {
            if (batchFn && !this.getPromise.batchFns.contains(batchFn)) {
                this.getPromise.batchFns.push(batchFn);
            }
            return this.getPromise;
        }
        this.getPromise = {
            promise: new Promise(async (resolve) => {
                const result = await this._getAll(apiKey, batchFn);
                resolve(result);
                delete this.getPromise;
            }),
            batchFns: [],
        };

        if (batchFn) {
            this.getPromise.batchFns.push(batchFn);
        }
        return this.getPromise;
    }

    async getAll(opts = {summary: false}) {
        const start = Date.now();
        const {apiKey = null, asArray = false, sortFn = null} = opts;
        if (apiKey) {
            this.apiKey = apiKey;
        }
        // if (force) {
        //     logger.debug(`${this.name} data expired. Refreshing...`);
        //     // try {
        //     await this.fetchAll(force);
        //     // if (force) {
        //     //     this.db.each(obj => this.fetched[this.getId(obj)] = true)
        //     // }
        //     this.fetchedAll = true;
        //     logger.debug(`${this.name}s refreshed. Total of ${await this.db.count()} ${this.name}s fetched.`);
        //     // } catch (e) {
        //     //     logger.warn(`Couldn't fetch data. Error: ${e}`, e);
        //     // }
        // }
        // const dependents = await Promise.all(this.dependents.map(async dependent => dependent.getAll()))
        if (this.data && Object.keys(this.data).length) {
            if (asArray) {
                const asArray = clone(values(this.data));
                return await this.deserialise(asArray);
            }
            return clone(this.data);
        }
        try {
            let all;
            if (opts.summary && this.summaryDb) {
                all = await this.summaryDb.toArray();
            } else {
                all = await this.db.toArray();
                all = await this.deserialise(all);
            }
            logger.debug(this.name, all);
            if (asArray) {
                return sortFn ? all.sort(sortFn) : all;
            }
            const result = keyBy(all, this.idName);

            if (!opts.summary && this.localCache) {
                this.data = keyBy(all, this.idName);
            }
            logger.warn(
                `Elapsed time for getting all ${this.name}: ${toHrsMinsSecs(
                    (Date.now() - start) / 1000
                )}`
            );
            return result;
        } catch (e) {
            console.log('Couldn\'t get all data. Error: ' + e, e);
            return {};
        }
    }

    async get(apiKey, id) {
        const start = Date.now();
        this.apiKey = apiKey || this.apiKey;
        let obj;
        try {
            obj =
                (this.data && this.data[id] && this.create(this.data[id])) ||
                (await this.db.get(id)) ||
                null;
        } catch (e) {
            console.log(e, e);
        }
        if (!obj) return;
        [obj] = await this.deserialise(obj);
        logger.warn(
            `Elapsed time for get ${this.name}: ${toHrsMinsSecs(
                (Date.now() - start) / 1000
            )}`
        );
        return this.create(obj);
    }

    setApiKey(apiKey) {
        this.apiKey = apiKey;
    }

    setFetchOption(name, val) {
        this.fetchOptions = this.fetchOptions || {};
        this.fetchOptions[name] = val;
        return this;
    }
}

class StopModelData extends ModelData {
    constructor() {
        super({name: 'Stop'});
    }

    async save(objs, opts = {indirect: false}) {
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        objs.forEach(obj => {
            if (obj.master) {
                delete obj.master;
                obj.stopId = ulid();
                obj.verified = 1;
                obj.duplicate = -1;
            }
            // Retain author if shared stop
            // if(obj.authorityId) {
            //     obj.author = 'TFNSW'
            // }

        });
        await Promise.all(objs.map(async stop => {
            if (!stop.postcode?.length && !stop.suburb?.length) {
                const {postcode, suburb, town, city} = await reverseGeocode(stop) || {};
                stop.postcode = postcode;
                stop.suburb = suburb || town || city;
            }
        }));
        return await super.save(objs, opts);
    }

    async init() {
        const start = Date.now();
        let stops = await this.db.toArray();

        const duplicates = stops.filter(stop => stop.duplicate === 1);
        if (duplicates?.length) {
            this.db?.bulkDelete(duplicates.map(obj => obj.stopId))
                .then(() => console.log(duplicates.length, ' duplicate stops deleted.'));
        }

        if (this.localCache) {
            stops = stops.filter(stop => !stop.duplicate || stop.duplicate < 1);
            stops = (await this.deserialise(stops))
                .map((stop) => {
                    stop.setLinkedStops(stops);
                    return stop;
                });
            stops = await this.deserialise(stops);

            // if (!this.data) throw new Error('Stops must be fetched before ' + this.name)

            this.data = keyBy(
                stops,
                this.idName
            );
        }

        this.localCacheInitialised = true;
        logger.warn(
            `Elapsed time for getting init ${this.name}: ${toHrsMinsSecs(
                (Date.now() - start) / 1000
            )}`
        );
    }

    async getAll(opts = {summary: false}) {
        const allStops = await super.getAll(opts);
        const nonDuplicates = Object.keys(allStops).filter(stopId => !allStops[stopId].duplicate || allStops[stopId].duplicate < 1);
        return pick(allStops, nonDuplicates);
    }

    // async set(stops) {
    //     if (!Array.isArray(stops)) {
    //         stops = [stops]
    //     }
    //     // const duplicates = stops.filter(stop => stop.duplicate === 1)
    //     await super.set(stops);
    //     // if (duplicates.length) {
    //     //     await Promise.all(duplicates.map(async duplicateStop => {
    //     //         if (this.data) {
    //     //             delete this.data[duplicateStop.stopId];
    //     //         }
    //     //         await this.db.delete(duplicateStop.stopId);
    //     //         this.notifyDelete(duplicateStop.stopId);
    //     //
    //     //     }))
    //     // }
    // }

    create(obj) {
        return new Stop(obj);
    }

    async _create(obj) {
        if (Array.isArray(obj)) {
            obj.forEach(o => {
                o.userId = this.apiKey;
            });
        } else {
            obj.userId = this.apiKey;
        }
        await createStop(obj);
        return new Stop(obj);
    }

    async _save(objs) {
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        return await Promise.all(
            objs.map(async (obj) => {
                obj.userId = this.apiKey;
                delete obj._status;
                if (config.local) {
                    console.log('Saving...');
                    console.log(`PUT: stops/${this.getId(obj)}`);
                    console.log({
                        body: obj.toJson ? obj.toJson() : this.serialise(obj),
                    });
                } else {
                    await updateStop(obj);
                }
                return new Stop(obj);
            })
        );
    }

    // async _delete(ids) {
    //     await deleteStop(ids, this.apiKey);
    // }

    async fetch(id) {
        const stop = await API.get('routes', `/stops/${id}?_k=${this.apiKey}`);
        stop.center = [stop.lon, stop.lat];
        logger.debug(this.name, stop);
        // if (this.localCache) {
        //     this.data = this.data || {};
        //     this.data[stop.stopId] = this.create(stop);
        // }
        try {
            await this.db.put(stop);
        } catch (e) {
            console.log(e, e);
        }
        const obj = new Stop(stop);
        obj.setLinkedStops(await this.getAll());
        return obj;
    }

// async fetchAll() {
//     const startTime = Date.now();
//     logger.debug(`Fetching Stops for ${this.apiKey}...`);
//     this.expired = false;
//     this.lastUpdated = Date.now()
//
//     let fetched;
//
//     if (config.local) {
//         fetched = await fetch(`/LOCAL_DATA/${this.apiKey}-stops.json`, {
//             method: 'GET'
//         }).then(response => response.json())
//     } else {
//         fetched = await API.get("routes", `/stops${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
//     }
//     logger.debug(`Fetched ${fetched.length} ${this.name}'s.`)
//     fetched = fetched.map(stop => {
//         stop.center = [stop.lon, stop.lat];
//         return new Stop(stop)
//     });
//     if (this.localCache) {
//         this.data = keyBy(fetched.map(obj => this.create(obj)), 'stopId')
//     }
//     await this.db.bulkPut(fetched);
//
//     // Set linkedStops
//     // fetched.forEach(stop => stop.setLinkedStops(keyBy(fetched, 'stopId'), false))
//
//     const elapsed = Date.now() - startTime
//     const msg = `StopModelService - fetchAll elapsed: ${elapsed}`
//     elapsed > 5000 ? logger.warn(msg) : logger.info(msg)
// }

    async sendAll() {
        logger.debug(`Sending Stops for ${this.apiKey}...`);
        await API.get('routes', `/ws/stops?_k=${this.apiKey}`);
    }

    getId(obj) {
        if (obj) {
            return obj.stopId;
        }
    }

    setId(obj, id = ulid()) {
        obj.stopId = id;
    }

    serialise(stop) {
        const {
            userId,
            stopId,
            lat,
            lon,
            stopName,
            stopDesc,
            stopType,
            suburb,
            postcode,
            stopCode,
            routes,
            transfers,
            duplicate,
            verified,
            imported,
            linkedStops,
            startBell,
            startBellWindow,
            endBell,
            endBellWindow,
            radius,
            authorityId,
            outOfSync,
            aliases,
            wheelchair,
            author,
            lastEditor,
            bearing,
            website,
            email,
            phone,
            street,
            state,
            type,
            transferWindow
        } = stop;
        let stopToUpdate = {
            userId,
            stopId,
            lat,
            lon,
            stopName,
            stopDesc,
            stopType,
            suburb,
            postcode,
            stopCode,
            routes,
            transfers,
            duplicate,
            verified,
            imported,
            linkedStops,
            startBell,
            startBellWindow,
            endBell,
            endBellWindow,
            radius,
            authorityId,
            outOfSync,
            aliases,
            wheelchair,
            author,
            lastEditor,
            bearing,
            website,
            email,
            phone,
            street,
            state,
            type,
            transferWindow
        };
        if (!['venue', 'school'].includes(stopToUpdate.stopType)) {
            delete stopToUpdate.startBell;
            delete stopToUpdate.startBellWindow;
            delete stopToUpdate.endBell;
            delete stopToUpdate.endBellWindow;
        }
        if (stopToUpdate.routes) {
            stopToUpdate.routes.forEach((r) => {
                delete r.merged;
            });
        }

        stopToUpdate.linkedStops?.forEach(linkedStop => {
            Object.keys(linkedStop)
                .filter(key => !LinkedStop.SERIALISED_KEYS().includes(key))
                .forEach(key => {
                    delete linkedStop[key];
                });
        });

        Object.keys(stopToUpdate).forEach((key) => {
            if (stopToUpdate[key] === null) {
                delete stopToUpdate[key];
            }
        });
        stopToUpdate.suburb = stopToUpdate.suburb?.toUpperCase();
        return stopToUpdate;
    }

    async deserialise(objs) {
        console.log('Deserialising Stops...');
        const time = Date.now();
        objs = await super.deserialise(objs);

        if (this.loaded && this.data) {
            objs = objs.map((stop) => {
                stop.setLinkedStops(this.data);
                return stop;
            });
        }
        objs.forEach(s => calculateGeoHash(s));
        console.log(`STops deserialised. Elapsed: ${Date.now() - time}ms`);
        return objs;
    }
}

class RouteModelData extends ModelData {
    constructor(fetchOptions) {
        super({
            name: 'Route',
            fetchOptions: fetchOptions,
            saveDebounce: 30000,
            deleteAge: 1000 * 60 * 60 * 24 * 30,
            dependents: [stopModelData],
            maxSave: 5,
        });
    }

    toSummary(route) {
        let {
            routeId,
            routeNumber,
            routeName,
            routeDetails,
            colour,
            routeLogo,
            services = [],
            routeType,
            direction,
            published,
            driverShift,
            stopTimes,
            warnings,
        } = route;
        // services = services.map(trip => ({scheduleIds: trip.scheduleIds, stopTimes: [trip.stopTimes[0]]}))
        return {
            routeId,
            routeNumber,
            routeName,
            routeDetails,
            colour,
            routeLogo,
            services,
            routeType,
            direction,
            published,
            driverShift,
            stopTimes,
            warnings,
        };
    }

    async _create(obj) {
        return await this._save(obj);
    }

    async _save(objs) {
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        return await Promise.all(
            objs.map(async (obj) => {
                delete obj._status;
                obj.userId = this.apiKey;
                if (config.local) {
                    console.log('Saving...');
                    console.log(`PUT: routes/${this.getId(obj)}`);
                    console.log({
                        body: obj.toJson ? obj.toJson() : this.serialise(obj),
                    });
                } else {
                    await updateRoute(obj);
                }
                return new BusRoute(obj);
            })
        );
    }

    async _delete(ids) {
        await deleteRoute(ids, this.apiKey);
    }

    async fetch(id) {
        logger.debug('Fetching route: ', id);
        const obj = await loadRoute(id, true, this.apiKey, true);
        // const obj = await this.db.get(id);
        // if (this.localCache) {
        //     this.data = this.data || {};
        //     this.data[id] = this.create(obj);
        // }
        await this.db.put(obj);
        return obj;
    }

    // async fetchAll() {
    //     logger.debug(`Fetching ${this.name}s for ${this.apiKey}...`);
    //     this.expired = false;
    //     this.lastUpdated = Date.now()
    //     // await API.get("routes", `/ws/routes?_k=${this.apiKey}`);
    //     return new Promise((resolve) => {
    //         getRoutes(true, true, true, false, true, this.apiKey, fetchedRoutes => {
    //             logger.debug(`Fetched ${fetchedRoutes.length} ${this.name}'s.`)
    //             let batch = {};
    //             fetchedRoutes.forEach(o => batch[this.getId(o)] = o);
    //             if (this.getPromise.batchFns) {
    //                 logger.debug('We have batch fn.')
    //                 this.getPromise.batchFns.forEach(batchFn => batchFn(batch));
    //             }
    //             this.data = this.data || {};
    //             Object.assign(this.data, batch);
    //             this.write();
    //         }, resolve);
    //     })
    // }

    async fetchAll() {
        const startTime = Date.now();
        logger.debug(`Fetching ${this.name}s for ${this.apiKey}...`);
        this.expired = false;
        // this.lastUpdated = Date.now()
        const _this = this;
        // await API.get("routes", `/ws/routes?_k=${this.apiKey}`);
        return new Promise((resolve) => {
            return getRoutesWithOpts(
                this.apiKey,
                async (fetchedRoutes) => {
                    logger.debug(
                        `Fetched ${fetchedRoutes.length} ${
                            _this.name
                        }'s.  ${fetchedRoutes.map((r) => r.routeNumber)}`
                    );
                    fetchedRoutes.forEach(r => {
                        delete r.warning;
                        delete r.warnings;
                        delete r.patternChecked;
                    });

                    // await this.db.bulkPut(fetchedRoutes);
                    // fetchedRoutes.forEach((r) => {
                    //     // if (!_this.fetched[r.routeId]) {
                    //     _this.set(r);
                    //     // }
                    // });
                    // let batch = {};
                    // fetchedRoutes.forEach(o => batch[this.getId(o)] = o);
                    // if (this.getPromise.batchFns) {
                    //     logger.debug('We have batch fn.')
                    //     this.getPromise.batchFns.forEach(batchFn => batchFn(batch));
                    // }
                    // this.data = this.data || {};
                    // Object.assign(this.data, batch);
                    // this.write();
                },
                resolve,
                this.fetchOptions
            );
        })
            .then(routes => {
                this.db.bulkPut(routes);
                return routes;
            })
            .then(routes => {
                const elapsed = Date.now() - startTime;
                const msg = `RouteModelService - fetched: ${
                    routes?.length
                } routes with ${routes?.reduce(
                    (prev, route) => route.tripCount + prev,
                    0
                )} services, elapsed time: ${elapsed}`;
                elapsed > 5000 ? logger.warn(msg) : logger.info(msg);
                if (this.localCache) {
                    this.data = this.data || {};
                    this.deserialise(routes).then(routes => {
                        const fetchedById = keyBy(
                            routes,
                            this.idName
                        );
                        this.data = {...this.data, ...fetchedById};
                    });
                }
                this.loaded = true;
                this.fetchedAll = true;
            });
    }

    async sendAll() {
        logger.debug(`Sending ${this.name}s for ${this.apiKey}...`);
        await API.get('routes', `/ws/routes?_k=${this.apiKey}`);
    }

    getId(obj) {
        return obj.routeId;
    }

    setId(obj, id = ulid()) {
        obj.routeId = id;
    }

    create(obj) {
        const route = new BusRoute(obj);
        return route;
    }

    async deserialise(routes) {
        console.log('Deserialising ROutes...');
        const time = Date.now();
        routes = await super.deserialise(routes);
        const stopsById = await stopModelData.getAll();
        if (!stopsById)
            throw new Error('Stops must be fetched before ' + this.name);
        const result = routes.map((route) => {
            route.setBaseStops(stopsById);
            delete route.warnings;
            delete route.patternChecked;
            route.waypoints.forEach(wp => {
                delete wp.x;
                delete wp.y;
            });
            return route;
        });

        console.log(`Routes deserialised. Elapsed: ${Date.now() - time}ms`);
        return result;
    }

    serialise(route) {
        const {
            userId,
            charter,
            routeId,
            published,
            scheduleId,
            colour,
            direction,
            routeLogo,
            routeType,
            routeNumber,
            driverShift,
            vehicleShift,
            routeName,
            routeLabel,
            routeDescription,
            routeDetails,
            routeHeadsign,
            waypoints,
            stopTimes,
            startTime,
            services,
            author,
            createdAt,
            lastEditor,
            approvedBy,
            approvedAt,
            publishedBy,
            publishedAt,
            unpublishedBy,
            unpublishedAt,
            contractId,
            updatedAt,
            warnings,
        } = route;

        let routeToUpdate = {
            userId,
            charter,
            routeId,
            published,
            scheduleId,
            colour,
            direction,
            routeLogo,
            routeType,
            routeNumber,
            driverShift,
            vehicleShift,
            routeName,
            routeLabel,
            routeDescription,
            routeDetails,
            routeHeadsign,
            waypoints,
            stopTimes,
            startTime,
            services,
            author,
            createdAt,
            lastEditor,
            approvedBy,
            approvedAt,
            publishedBy,
            publishedAt,
            unpublishedBy,
            unpublishedAt,
            contractId,
            updatedAt,
            warnings,
        };
        routeToUpdate.routeId =
            routeToUpdate.routeId === '_' ? ulid() : routeToUpdate.routeId;

        routeToUpdate.services?.forEach(trip => {
            delete trip.transfersFrom;
            delete trip.transfersTo;
            delete trip.stops;
        });

        routeToUpdate.createdAt = routeToUpdate.createdAt || Date.now();
        routeToUpdate.stopTimes?.forEach((s) => {
            delete s.avgSpd;
            delete s.distance;
            delete s.linkTime;
            delete s.transfersFrom;
            delete s.transfersTo;
            Object.keys(s).forEach((key) => {
                if (s[key] === null) {
                    delete s[key];
                }
            });
        });

        Object.keys(routeToUpdate).forEach((key) => {
            if (routeToUpdate[key] === null) {
                delete routeToUpdate[key];
            }
        });
        routeToUpdate.waypoints.forEach(wp => {
            delete wp.x;
            delete wp.y;
        });
        return routeToUpdate;
    }
}

class ScheduleModelData extends ModelData {
    constructor() {
        super({name: 'Schedule'});
    }

    async _create(obj) {
        obj.userId = this.apiKey;
        await createSchedule(obj);
        return new Schedule(obj);
    }

    async _save(objs) {
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        return await Promise.all(
            objs.map(async (obj) => {
                delete obj._status;
                obj.userId = this.apiKey;
                if (config.local) {
                    console.log('Saving...');
                    console.log(`PUT: schedules/${this.getId(obj)}`);
                    console.log({
                        body: obj.toJson ? obj.toJson() : this.serialise(obj),
                    });
                } else {
                    await updateSchedule(obj);
                }
                return new Schedule(obj);
            })
        );
    }

    async _delete(ids) {
        await deleteSchedule(ids, this.apiKey);
    }

    // async fetch(id) {
    //     logger.debug(`Fetching Schedules for ${this.apiKey}...`);
    //     const obj = await API.get("routes", `/schedules/${id}${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
    //     this.db.add(obj)
    //     this.fetched[id] = true;
    //     return obj;
    // }

    // async fetchAll() {
    //     logger.debug(`Fetching Schedules for ${this.apiKey}...`);
    //     this.expired = false;
    //     this.lastUpdated = Date.now()
    //
    //     let fetched;
    //     if (config.local) {
    //         fetched = await fetch(`/LOCAL_DATA/${this.apiKey}-schedules.json`, {
    //             method: 'GET'
    //         }).then(response => response.json())
    //     } else {
    //         fetched = await API.get("routes", `/schedules${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
    //     }
    //     logger.debug(`Fetched ${fetched.length} ${this.name}'s.`)
    //     fetched.forEach(s => {
    //         this.data[this.getId(s)] = new Schedule(s);
    //     });
    //     values(this.data).forEach(s => s.setSubSchedules(this.data))
    //     // this.write();
    // }

    getId(obj) {
        if (obj) {
            return obj.scheduleId;
        }
    }

    setId(obj, id = ulid()) {
        obj.scheduleId = id;
    }

    create(obj, all) {
        return new Schedule(obj);
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        let schedules = this.db ? await this.db.toArray() : values(this.data) || objs;
        schedules = keyBy(schedules.map(s => new Schedule(s)), 'scheduleId');
        return objs.map((obj) => {
            obj.setSubSchedules(schedules);
            return obj;
        });
    }

    serialise(obj) {
        return Schedule.clone(obj).toJson();
    }
}

class VehicleModelData extends ModelData {
    constructor() {
        super({name: 'Vehicle', api: 'vehicle'});
    }

    getId(obj) {
        if (obj) {
            return obj.vehicleId;
        }
    }

    setId(obj, id = ulid()) {
        obj.vehicleId = id;
    }

    create(obj) {
        return new Vehicle(obj);
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        const allVehicleTypes = await vehicleTypeModelData.getAll();
        if (!allVehicleTypes)
            throw new Error('Vehicel Types must be fetched before ' + this.name);

        objs.forEach(vehicle => vehicle.vehicleType = allVehicleTypes[vehicle.vehicleTypeId]);
        return objs;
    }

}

class VehicleTypeModelData extends ModelData {
    constructor() {
        super({name: 'VehicleType', api: 'vehicleType'});
    }

    getId(obj) {
        if (obj) {
            return obj.vehicleTypeId;
        }
    }

    setId(obj, id = ulid()) {
        obj.vehicleTypeId = id;
    }

    create(obj) {
        return new VehicleType(obj);
    }

}

class DriverModelData extends ModelData {
    constructor() {
        super({name: 'Driver', api: 'driver'});
    }

    getId(obj) {
        if (obj) {
            return obj.driverId;
        }
    }

    setId(obj, id = ulid()) {
        obj.driverId = id;
    }

    create(obj) {
        return new Driver(obj);
    }
}

class DriverShiftModelData extends ModelData {
    constructor() {
        super({
            name: 'DriverShift',
            idName: 'shiftId',
            api: 'shift',
            path: '/shifts/driver',
        });
    }

    create(obj) {
        return new Shift(obj);
    }
}

class VehicleShiftModelData extends ModelData {
    constructor() {
        super({
            name: 'VehicleShift',
            idName: 'shiftId',
            api: 'shift',
            path: '/shifts/vehicle',
        });
    }

    create(obj) {
        return new Shift(obj);
    }
}

class ShiftBatModelData extends ModelData {
    constructor() {
        super({
            name: 'ShiftBat',
            api: 'shift_bat',
            path: '/shiftBats',
            dependents: [stopModelData, routeModelData],
            saveDebounce: 30000,
        });
    }

    toSummary(shiftBat) {
        let {
            shiftBatId,
            shiftBatNumber,
            shiftBatName,
            shiftBatDetails,
            shiftBatColour,
            shiftBatLogo,
            rows
        } = shiftBat;
        return {
            shiftBatId,
            shiftBatNumber,
            shiftBatName,
            shiftBatDetails,
            shiftBatColour,
            shiftBatLogo,
            rows
        };
    }

    getId(obj) {
        if (obj) {
            return obj.shiftBatId;
        }
    }

    setId(obj, id = ulid()) {
        obj.shiftBatId = id;
    }

    create(obj) {
        return new ShiftBat(obj);
    }

    validateShiftBatNumber(shiftBatNumber, shiftBat) {
        return values(this.data)?.find(sb => sb.shiftBatId !== shiftBat?.shiftBatId && sb.charter === false && sb.shiftBatNumber === shiftBatNumber);
    }


    async load(apiKey, id) {
        scheduleModelData.localCache = true;
        transferModelData.localCache = true;
        stopModelData.localCache = true;
        routeModelData.localCache = true;
        deadrunModelData.localCache = true;
        shiftBatModelData.localCache = true;

        const data = await API.get('routes', `/viewShiftBat/${apiKey}/${id}`);
        await scheduleModelData.set(data.schedules);
        await stopModelData.set(data.stops);
        await routeModelData.set(data.routes);
        await transferModelData.set(data.transfers);
        await deadrunModelData.set(data.deadruns);
        await shiftBatModelData.set(data.shiftBat);
        // shiftBatModelData.data = {[id]: (await shiftBatModelData.deserialise([new ShiftBat(data.shiftBat)]))[0]}
        return shiftBatModelData.data[id];

        //         const sb = await this.fetch(id)
        //
        //         const scheduleIds = sb.scheduleIds
        //         await scheduleModelData.fetchAll();
        //         await transferModelData.fetchAll();
        //         const stopIds = sb.rows.filter(r => r.type === ShiftBatRowType.stop).map(r => r.stopId)
        //         const stops = keyBy(await Promise.all(stopIds.map(async sId => await stopModelData.fetch(sId))), 'stopId')
        // // const schedules = keyBy(await Promise.all(scheduleIds.map(async sId => await scheduleModelData.fetch(sId))), 'scheduleId')
        //         const routeIds = sb.rows.filter(r => r.type === ShiftBatRowType.service).map(r => r.routeId)
        //         const routes = keyBy(await Promise.all(routeIds.map(async sId => await routeModelData.fetch(sId))), 'routeId')
        //         routes.forEach(r => r.stops.forEach(s => stops[s.stopId] = s))
        //         const deadrunIds = sb.rows.filter(r => r.type === ShiftBatRowType.dead).map(r => r.routeId)
        //         const deadruns = keyBy(await Promise.all(deadrunIds.map(async dId => await deadrunModelData.fetchAllIdBeginningWith(dId))), 'deadrunId')
        //
        //
        //
    }

    async deserialise(objs) {
        console.log('Deserialising ShiftBats...');
        const time = Date.now();
        objs = await super.deserialise(objs);

        const allStops = await stopModelData.getAll();
        if (!allStops || !Object.keys(allStops).length)
            throw new Error('Stops must be fetched before ' + this.name);

        const allTransfers = await transferModelData.getAll();
        if (!allTransfers)
            throw new Error('Transfers must be fetched before ' + this.name);

        const allRoutes = await routeModelData.getAll();
        if (!allRoutes || !Object.keys(allRoutes).length)
            throw new Error('ROutes must be fetched before ' + this.name);

        const result = await Promise.all(objs.map(async obj => {
            obj.published = Number.isFinite(obj.published) ? obj.published : -1;
            obj.effectiveDate = dayjs(obj.effectiveDate);
            await obj.updateRows({
                apiKey: this.apiKey,
                allStops,
                allRoutes,
                allTransfers,
                // deadrunModelData,
                opts: {directions: false}
            });
            return obj;
        }));
        console.log(`ShiftBats deserialised. Elapsed: ${Date.now() - time}ms`);
        return result;
    }
}

class OperatorModelData extends ModelData {
    constructor() {
        super({
            name: 'Operator',
            api: 'operators',
            path: '/operators',
        });
    }

    getId(obj) {
        if (obj) {
            return obj.operatorId;
        }
    }

    setId(obj, id) {
    }

    create(obj) {
        if (!obj?.features) {
            obj.features = {};
        }
        obj.features = new Features(obj.features);
        return obj;
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        return objs.map((obj) => {
            if (!obj?.features) {
                throw new Error('No Operator ' + this.apiKey);
            }
            obj.features = new Features(obj.features);
            if (obj.operatorAddress) {
                obj.operatorAddress = new Address(obj.operatorAddress);
            }
            if (obj.operatorPostalAddress) {
                obj.operatorPostalAddress = new Address(obj.operatorPostalAddress);
            }
            return obj;
        });
    }

    async get(apiKey) {

        if (!this.localCacheInitialised) {
            await this.init();
        }
        const start = Date.now();
        let obj = await this.db.get(apiKey);
        if (!obj) {
            obj = await this.fetch(apiKey);
        }
        [obj] = await this.deserialise(obj);
        logger.warn(
            `Elapsed time for get ${this.name}: ${toHrsMinsSecs(
                (Date.now() - start) / 1000
            )}`
        );
        return this.create(obj);
    }

    async _create(obj) {
        return await this._save(obj);
    }

    async _save(obj) {
        let operator = obj;
        if (Array.isArray(operator)) {
            operator = operator[0];
        }
        config.operator = operator;
        await API.put('operators', `/operators/${operator.operatorId}`, {
            body: operator
        });
        return this.create(operator);
    }
}

class TransferModelData extends ModelData {
    constructor() {
        super({
            name: 'Transfer',
            api: 'transfer',
            dependents: [stopModelData, scheduleModelData],
        });
    }

    // async setLoaded() {
    //     await super.setLoaded();
    //     const transfers = await this.db.toArray()
    //     transfers.forEach(transfer => {
    //         transfer._trx = []
    //         transfers.filter(t => !t.invalid && t !== transfer).forEach(leg2 => {
    //             if (leg2.fromStopId !== transfer.fromStopId &&
    //                 leg2.fromRouteNumber === transfer.toRouteNumber &&
    //                 leg2.time >= transfer.time &&
    //                 intersection(leg2.scheduleIds, transfer.scheduleIds).length
    //             ) {
    //                 leg2 = cloneDeep(leg2);
    //                 delete leg2._trx;
    //                 transfer._trx.push(leg2);
    //             }
    //         })
    //     })
    // }

    // loaded() {
    //     super.loaded()
    //
    //     transfers.forEach(transfer => {
    //         transfer.schedule = schedules[transfer.scheduleId]
    //         if (typeof transfer.waypoints === 'string') {
    //             transfer.waypoints = JSON.parse(transfer.waypoints);
    //             transfer.distance = parseInt(transfer.distance);
    //             transfer.duration = parseInt(transfer.duration);
    //             transfer.time = parseInt(transfer.time);
    //             transfer.window = parseInt(transfer.window);
    //         }
    //         delete transfer.fromTripId
    //         delete transfer.warning;
    //         //distance
    //         //duration
    //         //time
    //         //window
    //         const fromLog = transfer.fromRouteNumber === '571'
    //
    //         fromLog && logger.info('The TRANSFER: ', transfer)
    //
    //         const fromStop = allStops[transfer.fromStopId];
    //         fromLog && logger.info("From STOP: ", fromStop);
    //         if (fromStop) {
    //             fromLog && logger.info("From stop: ", fromStop.stopName);
    //             const fromStopPassingTimes = fromStop.getPassingTimes(r => r.routeNumber === transfer.fromRouteNumber, transfer)
    //                 .filter(fromStopPassingTime => fromStopPassingTime.scheduleIds.some(sId => schedules[sId] && (!schedules[sId].isFuture() && !schedules[sId].isObsolete())));
    //             fromLog && logger.info('FROM PASSING TIME: ', fromStopPassingTimes)
    //
    //             let fromStopPassingTime = null
    //             if (!fromStopPassingTimes.length) {
    //                 logger.warn('NO TRIPS FOR Transfer fromRoute %s @ %s.', transfer.fromRouteNumber, fromStop.stopName)
    //             } else {
    //                 fromStopPassingTime = fromStopPassingTimes[0]
    //             }
    //             if (fromStopPassingTimes.length > 1) {
    //                 transfer.warning = `MULTIPLE TRIPS FOR Transfer from route ${transfer.fromRouteNumber} @ ${fromStop.stopName}.`
    //                 logger.warn('MULTIPLE TRIPS FOR Transfer fromRoute %s @ %s. Using first one.', transfer.fromRouteNumber, fromStop.stopName)
    //             }
    //             transfer.fromTripId = fromStopPassingTime?.tripId;
    //             transfer.fromScheduleIds = fromStopPassingTime?.scheduleIds;
    //             transfer.fromRouteId = fromStopPassingTime?.routeId
    //             transfer.fromPassingTime = fromStopPassingTime?.passingTime
    //             transfer.fromStopPassingTimes = fromStopPassingTimes
    //         } else {
    //             logger.warn('Could not find from stop with id ', transfer.fromStopId)
    //             transfer.warning = `From stop does not exist.`
    //         }
    //
    //         const toLog = false;//transfer.toRouteNumber === 'S266'
    //         const toStop = allStops[transfer.toStopId]
    //         toLog && logger.info("To STOP: ", toStop);
    //         if (toStop) {
    //             toLog && logger.info("To stop: ", toStop.stopName)
    //             const toStopPassingTimes = toStop.getPassingTimes(r => r.routeNumber === transfer.toRouteNumber, transfer)
    //                 .filter(pt => {
    //                     toLog && logger.info(pt, transfer.fromPassingTime)
    //                     return !isFinite(transfer.fromPassingTime) || pt.passingTime >= transfer.fromPassingTime
    //                 }).filter(toStopPassingTime => toStopPassingTime.scheduleIds.some(sId => schedules[sId] && (!schedules[sId].isFuture() && !schedules[sId].isObsolete())));
    //             toLog && logger.info('TO PASSING TIME: ', toStopPassingTimes)
    //
    //             let toStopPassingTime = null
    //
    //             if (!toStopPassingTimes.length) {
    //                 logger.warn('NO TRIPS FOR Transfer toRoute %s @ %s.', transfer.toRouteNumber, toStop.stopName)
    //             } else {
    //                 toStopPassingTime = toStopPassingTimes[0]
    //             }
    //             if (toStopPassingTimes.length > 1) {
    //                 transfer.warning = `MULTIPLE TRIPS FOR Transfer to route ${transfer.toRouteNumber} @ ${fromStop.stopName}.`;
    //                 logger.warn('MULTIPLE TRIPS FOR Transfer toRoute %s @ %s. Using first one.', transfer.toRouteNumber, toStop.stopName)
    //             }
    //
    //             transfer.toTripId = toStopPassingTime?.tripId;
    //             transfer.toScheduleIds = toStopPassingTime?.scheduleIds;
    //             transfer.toRouteId = toStopPassingTime?.routeId
    //             transfer.toPassingTime = toStopPassingTime?.passingTime
    //             transfer.toStopPassingTimes = toStopPassingTimes
    //         } else {
    //             logger.info('Could not find to stop with id ', transfer.toStopId)
    //             transfer.warning = `To stop does not exist.`
    //         }
    //
    //         if (!transfer.fromTripId) {
    //             transfer.invalid = true;
    //             transfer.warning = `Transfer from ${transfer.fromRouteNumber} @ ${toTime(transfer.time, false)} cannot be mapped to a trip.`
    //         } else if (fromLog) {
    //             logger.info(transfer.fromTripId, transfer.fromRouteId)
    //         }
    //
    //         if (!transfer.toTripId) {
    //             transfer.warning = transfer.invalid ? `Routes ${transfer.fromRouteNumber} -> ${transfer.toRouteNumber} @ ${toTime(transfer.time, false, transfer.window)} do not map to any trips.` : `Transfer to ${transfer.toRouteNumber} @ ${toTime(transfer.time, false, transfer.window)} cannot be mapped to a trip.`
    //             transfer.invalid = true;
    //         } else if (fromLog) {
    //             logger.info(transfer.toTripId, transfer.toRouteId)
    //         }
    //
    //         if (transfer.fromScheduleIds?.length && transfer.toScheduleIds?.length) {
    //             transfer.scheduleIds = intersection(transfer.fromScheduleIds, transfer.toScheduleIds)
    //         }
    //         if (transfer.warning) {
    //             logger.warn(transfer.warning);
    //         }
    //
    //     });
    //
    //     transfers = transfers.map(t => new Transfer(t))
    //     transfers.filter(t => !t.invalid).forEach((leg1) => {
    //         leg1._trx = []
    //         transfers.filter(t => !t.invalid && t !== leg1).forEach(leg2 => {
    //             if (leg2.fromStopId !== leg1.fromStopId &&
    //                 leg2.fromRouteNumber === leg1.toRouteNumber &&
    //                 leg2.time >= leg1.time &&
    //                 intersection(leg2.scheduleIds, leg1.scheduleIds).length
    //             ) {
    //                 leg2 = cloneDeep(leg2);
    //                 delete leg2._trx;
    //                 leg1._trx.push(leg2);
    //             }
    //         })
    //     });
    // }

    // async fetchAll() {
    //     logger.debug(`Fetching Transfers for ${this.apiKey}...`);
    //
    //     let fetched = await getTransfers(this.apiKey, this.fetchOptions.allSchedules, this.fetchOptions.allStops);
    //     logger.debug(`Fetched ${fetched.length} ${this.name}'s.`)
    //
    //     await this.db.bulkPut(fetched)
    //
    //     fetched = fetched.map(s => {
    //         return this.create(s);
    //     });
    //     if (this.localCache) {
    //         this.data = keyBy(fetched.map(obj => this.create(obj)), this.idName)
    //     }
    //     return fetched;
    // }

    getId(obj) {
        if (obj) {
            return obj.transferId;
        }
    }

    setId(obj, id = ulid()) {
        obj.transferId = id;
    }

    async deserialise(transfers) {
        transfers = await super.deserialise(transfers);
        const allStops = await stopModelData.getAll();
        if (!allStops)
            throw new Error('Stops must be fetched before ' + this.name);
        const schedules = await scheduleModelData.getAll();
        if (!schedules)
            throw new Error('Schedules must be fetched before ' + this.name);
        transfers = transfers.map((transfer) => {
            transfer.schedule = schedules[transfer.scheduleId];
            if (typeof transfer.waypoints === 'string') {
                transfer.waypoints = JSON.parse(transfer.waypoints);
                transfer.distance = parseInt(transfer.distance);
                transfer.duration = parseInt(transfer.duration);
                transfer.time = parseInt(transfer.time);
                transfer.window = parseInt(transfer.window);
            }


            const fromStop = allStops[transfer.fromStopId];
            const fromRoute = fromStop?.routes[fromStop.routes.findIndex(r => r.routeId === transfer.fromRouteId)];
            const fromTrip = fromRoute?.services[transfer.fromTripId];//find(fromRoute.passingTimes, ['tripId', transfer.fromTripId])
            const arriveStopTime = find(fromTrip?.passingTimes, ['stopTimeId', transfer.fromStopTimeId]);
            transfer.arriveSecs = arriveStopTime?.arriveSecs;
            transfer.time = arriveStopTime?.arriveSecs;
            transfer.fromRouteColour = fromRoute?.colour;

            const toStop = allStops[transfer.toStopId];
            const toRoute = toStop?.routes[toStop.routes.findIndex(r => r.routeId === transfer.toRouteId)];
            const toTrip = toRoute?.services[transfer.toTripId];// find(toRoute.passingTimes, ['tripId', transfer.toTripId])
            const departStopTime = find(toTrip?.passingTimes, ['stopTimeId', transfer.toStopTimeId]);
            transfer.departSecs = departStopTime?.departSecs;
            transfer.window = departStopTime?.departSecs - arriveStopTime?.arriveSecs + 60;
            transfer.toRouteColour = toRoute?.colour;

            transfer.warnings = [];
            if (!transfer.fromRouteId || !transfer.fromTripId || !transfer.fromStopTimeId) {
                const missing = [];
                !transfer.fromRouteId && missing.push('route');
                !transfer.fromTripId && missing.push('trip');
                !transfer.fromStopTimeId && missing.push('stop time');
                transfer.warnings.push(`The transfer cannot be mapped to a setdown service. Missing: ${missing.join(', ')} (REF-026)`);
            }
            if (!transfer.toRouteId || !transfer.toTripId || !transfer.toStopTimeId) {
                const missing = [];
                !transfer.toRouteId && missing.push('route');
                !transfer.toTripId && missing.push('trip');
                !transfer.toStopTimeId && missing.push('stop time');
                transfer.warnings.push(`The transfer cannot be mapped to a pickup service. Missing: ${missing.join(', ')} (REF-027)`);
            }
            if (transfer.departSecs < transfer.arriveSecs) {
                transfer.warnings.push(`Invalid transfer times. Setdown service  ${transfer.fromRouteNumber} arrives ${toHrsMinsSecs(transfer.arriveSecs - transfer.departSecs, false, true)} after pickup service ${transfer.toRouteNumber} (REF-028)`);
            }

            transfer.invalid = !!transfer.warnings.length;
            // transfer.invalid = !transfer.fromTripId || !transfer.fromStopTimeId || !transfer.toTripId || !transfer.toStopTimeId || transfer.departSecs < transfer.arriveSecs;

            // delete transfer.fromTripId;
            // delete transfer.warning;
            // //distance
            // //duration
            // //time
            // //window
            // const fromLog = transfer.fromRouteNumber === "571";
            //
            // fromLog && logger.info("The TRANSFER: ", transfer);
            //
            // const fromStop = allStops[transfer.fromStopId];
            // fromLog && logger.info("From STOP: ", fromStop);
            // if (fromStop) {
            //     fromLog && logger.info("From stop: ", fromStop.stopName);
            //     const fromStopPassingTimes = fromStop
            //         .getPassingTimes(
            //             (r) => r.routeNumber === transfer.fromRouteNumber,
            //             transfer
            //         )
            //         .filter((fromStopPassingTime) =>
            //             fromStopPassingTime.scheduleIds.some(
            //                 (sId) =>
            //                     schedules[sId] &&
            //                     !schedules[sId].isFuture() &&
            //                     !schedules[sId].isObsolete()
            //             )
            //         );
            //     fromLog &&
            //     logger.info("FROM PASSING TIME: ", fromStopPassingTimes);
            //
            //     let fromStopPassingTime = null;
            //     if (!fromStopPassingTimes.length) {
            //         logger.warn(
            //             "NO TRIPS FOR Transfer fromRoute %s @ %s.",
            //             transfer.fromRouteNumber,
            //             fromStop.stopName
            //         );
            //     } else {
            //         fromStopPassingTime = fromStopPassingTimes[0];
            //     }
            //     if (fromStopPassingTimes.length > 1) {
            //         transfer.warning = `MULTIPLE TRIPS FOR Transfer from route ${transfer.fromRouteNumber} @ ${fromStop.stopName}.`;
            //         logger.warn(
            //             "MULTIPLE TRIPS FOR Transfer fromRoute %s @ %s. Using first one.",
            //             transfer.fromRouteNumber,
            //             fromStop.stopName
            //         );
            //     }
            //     transfer.fromTripId = fromStopPassingTime?.tripId;
            //     transfer.fromScheduleIds = fromStopPassingTime?.scheduleIds;
            //     transfer.fromRouteId = fromStopPassingTime?.routeId;
            //     transfer.fromPassingTime = fromStopPassingTime?.passingTime;
            //     transfer.fromStopPassingTimes = fromStopPassingTimes;
            // } else {
            //     logger.warn(
            //         "Could not find from stop with id ",
            //         transfer.fromStopId
            //     );
            //     transfer.warning = `From stop does not exist.`;
            // }
            //
            // const toLog = false; //transfer.toRouteNumber === 'S266'
            // const toStop = allStops[transfer.toStopId];
            // toLog && logger.info("To STOP: ", toStop);
            // if (toStop) {
            //     toLog && logger.info("To stop: ", toStop.stopName);
            //     const toStopPassingTimes = toStop
            //         .getPassingTimes(
            //             (r) => r.routeNumber === transfer.toRouteNumber,
            //             transfer
            //         )
            //         .filter((pt) => {
            //             toLog && logger.info(pt, transfer.fromPassingTime);
            //             return (
            //                 !isFinite(transfer.fromPassingTime) ||
            //                 pt.passingTime >= transfer.fromPassingTime
            //             );
            //         })
            //         .filter((toStopPassingTime) =>
            //             toStopPassingTime.scheduleIds.some(
            //                 (sId) =>
            //                     schedules[sId] &&
            //                     !schedules[sId].isFuture() &&
            //                     !schedules[sId].isObsolete()
            //             )
            //         );
            //     toLog && logger.info("TO PASSING TIME: ", toStopPassingTimes);
            //
            //     let toStopPassingTime = null;
            //
            //     if (!toStopPassingTimes.length) {
            //         logger.warn(
            //             "NO TRIPS FOR Transfer toRoute %s @ %s.",
            //             transfer.toRouteNumber,
            //             toStop.stopName
            //         );
            //     } else {
            //         toStopPassingTime = toStopPassingTimes[0];
            //     }
            //     if (toStopPassingTimes.length > 1) {
            //         transfer.warning = `MULTIPLE TRIPS FOR Transfer to route ${transfer.toRouteNumber} @ ${fromStop?.stopName}.`;
            //         logger.warn(
            //             "MULTIPLE TRIPS FOR Transfer toRoute %s @ %s. Using first one.",
            //             transfer.toRouteNumber,
            //             toStop?.stopName
            //         );
            //     }
            //
            //     transfer.toTripId = toStopPassingTime?.tripId;
            //     transfer.toScheduleIds = toStopPassingTime?.scheduleIds;
            //     transfer.toRouteId = toStopPassingTime?.routeId;
            //     transfer.toPassingTime = toStopPassingTime?.passingTime;
            //     transfer.toStopPassingTimes = toStopPassingTimes;
            // } else {
            //     logger.info(
            //         "Could not find to stop with id ",
            //         transfer.toStopId
            //     );
            //     transfer.warning = `To stop does not exist.`;
            // }
            //
            // if (!transfer.fromTripId) {
            //     transfer.invalid = true;
            //     transfer.warning = `Transfer from ${
            //         transfer.fromRouteNumber
            //     } @ ${toTime(
            //         transfer.time,
            //         false
            //     )} cannot be mapped to a trip.`;
            // } else if (fromLog) {
            //     logger.info(transfer.fromTripId, transfer.fromRouteId);
            // }
            //
            // if (!transfer.toTripId) {
            //     transfer.warning = transfer.invalid
            //         ? `Routes ${transfer.fromRouteNumber} -> ${
            //             transfer.toRouteNumber
            //         } @ ${toTime(
            //             transfer.time,
            //             false,
            //             transfer.window
            //         )} do not map to any trips.`
            //         : `Transfer to ${transfer.toRouteNumber} @ ${toTime(
            //             transfer.time,
            //             false,
            //             transfer.window
            //         )} cannot be mapped to a trip.`;
            //     transfer.invalid = true;
            // } else if (fromLog) {
            //     logger.info(transfer.toTripId, transfer.toRouteId);
            // }
            //
            // if (
            //     transfer.fromScheduleIds?.length &&
            //     transfer.toScheduleIds?.length
            // ) {
            //     transfer.scheduleIds = intersection(
            //         transfer.fromScheduleIds,
            //         transfer.toScheduleIds
            //     );
            // }
            // if (transfer.warning) {
            //     logger.warn(transfer.warning);
            // }
            return transfer;
        });

        transfers.forEach((leg1) => {
            leg1._trx = [];
            transfers.filter(t => !t.invalid && t !== leg1).forEach(leg2 => {
                if (leg2.fromStopId !== leg1.fromStopId &&
                    leg2.fromRouteId === leg1.toRouteId &&
                    leg2.fromTripId === leg1.toTripId &&
                    leg2.time >= leg1.time
                ) {
                    leg2 = cloneDeep(leg2);
                    delete leg2._trx;
                    leg1._trx.push(leg2);
                }
            });
        });

        return transfers;
    }

    create(transfer) {
        return new Transfer(transfer);
    }
}

export class DeadrunModelData extends ModelData {
    static createId(obj) {
        return `${obj.startStopId}#${obj.endStopId}#${
            obj.routeName?.length ? obj.routeName : ''
        }`;
    }

    constructor() {
        super({
            name: 'Deadrun',
            api: 'deadrun',
            idName: 'routeId',
            dependents: [stopModelData],
        });
    }

    async fetch({startStopId, endStopId}) {
        return await this.fetchAllIdBeginningWith(
            `${startStopId}#${endStopId}#`
        );
    }

    setId(obj) {
        obj.routeId = DeadrunModelData.createId(obj);
    }

    create(obj) {
        return new Deadrun(obj);
    }

    serialise(obj) {
        obj = obj.clone ? obj.clone() : cloneDeep(obj);
        Object.keys(obj).forEach((key) => {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            }
        });
        obj.waypoints = obj.waypoints.map((wp) => {
            const {lat, lon, distance, delta} = wp;
            return {lat, lon, distance, delta};
        });
        obj.startStopId = obj.startStopId || obj.startStop?.stopId;
        obj.endStopId = obj.endStopId || obj.endStop?.stopId;
        if (obj.stops) {
            obj.stops.length = 0;
        }
        if (obj.stopTimes) {
            obj.stopTimes.length = 0;
        }
        delete obj.firstStop;
        delete obj.lastStop;
        delete obj.startStop;
        delete obj.endStop;
        return obj;
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        const stopsById = await stopModelData.getAll();
        if (!stopsById)
            throw new Error('Stops must be fetched before ' + this.name);
        return objs.map((obj) => {
            obj.startStop = stopsById[obj.startStopId];
            obj.endStop = stopsById[obj.endStopId];
            return obj;
        });
        // obj.stops = [obj.startStop, obj.endStop];
        // obj.stopTimes = [obj.startStop, obj.endStop];
    }
}

class LogModelData extends ModelData {
    constructor({type, name = 'Log', api = 'log', idName = 'logId'}) {
        super({name, api, idName});
        this.type = type;
    }

    async fetchAll({asArray = false, companyId} = {}) {
        // if (this.fetchedAll) {
        //     return keyBy(await this.db.toArray(), this.idName);
        // }
        await this.fetchAllIdBeginningWith(`${this.type}#`, companyId, true);
        this.fetchedAll = true;
        this.loaded = true;
        // this.lastUpdated = Date.now();
        const result = await this.db.toArray();
        if (asArray) return result;
        return keyBy(result, this.idName);
    }
}

class PublishTfNSWLogModelData extends LogModelData {
    constructor() {
        super({type: 'integration#publish#tfnsw'});
    }

    async getCurrent() {
        return await this.fetchCurrentIdBeginningWith(this.type);
    }
}

class EmployeeModelData extends ModelData {
    constructor() {
        super({
            name: 'Employee',
            api: 'third_party',
            path: '/employees',
            idName: 'sk'
        });
    }

    getId(obj) {
        if (obj) {
            return obj.sk;
        }
    }

    setId(obj, id = ulid()) {
        obj.id = id;
    }

    create(obj) {
        const type = obj?.sk?.split('#')[3];
        switch (type) {
            case 'DETAILS':
                return new Employee(obj);
            case 'EMPLOYMENT':
                return new Employment(obj);
            case 'ENTITLEMENT':
                return new Entitlement(obj);
            case 'LEAVE':
                return new Leave(obj);
            case 'LICENSE':
                return new License(obj);
            case 'QUALIFICATIONS':
                return new Qualification(obj);
            case 'TRAINING':
                return new Training(obj);
            case 'SUPERANNUATION':
                return new Superannuation(obj);
            case 'TAX':
                return new Tax(obj);
            case 'BANKING':
                return new Banking(obj);
            default:
                return obj;
        }
    }

    async fetch(id) {
        return await this.fetch(`#EMPLOYEE#${id}`);
    }

    async _fetchAllLeaves() {
        const leaves = await this.getLeaves();
        const leaveObjects = leaves.map(leave => ({
            ...leave,
            sk: `#EMPLOYEE#${leave.employeeID}#LEAVE#${leave.leaveApplicationID}`
        }));
        return leaveObjects;
    }

    async getAll(opts = {summary: true}, keyPrefix = '#EMPLOYEE#') {
        const allData = await super.getAll(opts);
        const filteredData = {};

        for (const key in allData) {
            if (key.startsWith(keyPrefix)) {
                filteredData[key] = allData[key];
            }
        }
        return filteredData;
    }

    async getAllStaffGroups(opts = {summary: true}) {
        const allData = await super.getAll(opts);
        const filteredData = {};

        for (const key in allData) {
            if (key.startsWith('#STAFFGROUP#')) {
                filteredData[key] = allData[key];
            }
        }
        return filteredData;
    }


    // async _create(obj) {
    //     return this.create(obj)
    // }


    async saveToXero(obj) {
        const keysToRemove = [
            'id',
            'createdAt',
            'totalEmployees',
            'updatedAt',
            'userId',
            '_new',
            'sk',
            'updatedDateUTC',
            'bankAccounts',
            'payTemplate',
            'openingBalances',
            'taxDeclaration',
            'leaveBalances',
            'superMemberships',
        ];
        await API.put(
            'third_party',
            `/employees/external/${obj.employeeID}${
                this.apiKey ? `?_k=${this.apiKey}` : ''
            }`,
            {
                body: omit(obj, keysToRemove),
            }
        );
        this.save(obj);
    }

    async createToXero(obj) {
        const res = await API.post(
            'third_party',
            `/employees/external${
                this.apiKey ? `?_k=${this.apiKey}` : ''
            }`,
            {
                body: obj,
            }
        );
        await super.save(res.employees);
        return res;
    }

    async getLeaves() {
        return API.get('third_party',
            `/employees/leaves${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
    }

    async createLeaves(employeeId, leaves) {
        return API.post('third_party',
            `/employees/${employeeId}/leaves${this.apiKey ? `?_k=${this.apiKey}` : ''}`,
            {
                body: leaves
            });
    }

    async approveLeave(employeeId, leaveId) {
        return API.put('third_party',
            `/employees/${employeeId}/leaves/${leaveId}/approve${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
    }

    async rejectLeave(employeeId, leaveId) {
        return API.put('third_party',
            `/employees/${employeeId}/leaves/${leaveId}/reject${this.apiKey ? `?_k=${this.apiKey}` : ''}`);
    }

    async saveDriverAuthorityCheck(body) {
        return API.post('third_party',
            `/employees/driverAuthority/save${
                this.apiKey ? `?_k=${this.apiKey}` : ''
            }`, {
                body
            });
    }

    async getAllDARecords() {
        return API.get('third_party',
            `/employees/driverAuthority${
                this.apiKey ? `?_k=${this.apiKey}` : ''
            }`);
    }

    async getDriverDARecord(employeeId) {
        return API.get('third_party',
            `/employees/driverAuthority/${employeeId}${
                this.apiKey ? `?_k=${this.apiKey}` : ''
            }`);
    }

    async getLatestDriverAuthorityCheck() {
        const driverAuthRecords = await this.getAllDARecords();
        console.log('driverAuthRecords', driverAuthRecords);
        if (driverAuthRecords.length === 0) return [];
        const sortedRecords = driverAuthRecords.sort((a, b) => new Date(b.checkedDate) - new Date(a.checkedDate));
        const latestDate = sortedRecords[0].checkedDate;
        const latestRecords = sortedRecords.filter(record => record.checkedDate === latestDate);
        return latestRecords;
    }

    convertToDate(dateString) {
        if (!dateString) return null;
        const [day, month, year] = dateString.split('/');
        return new Date(year, month - 1, day);
    }

    async processDriverAuthorityCheck(data) {
        const threshold = await this.getDAConfig();
        const errors = [];
        const warnings = [];
        data.forEach((obj, index) => {
            const currentDate = new Date();
            const errorDAExpiry = new Date(currentDate.getTime() + threshold.driverErrorThreshold * 24 * 60 * 60 * 1000);
            const errorMedicalExpiry = new Date(currentDate.getTime() + threshold.medicalErrorThreshold * 24 * 60 * 60 * 1000);
            const warningDAExpiry = new Date(currentDate.getTime() + threshold.driverWarningThreshold * 24 * 60 * 60 * 1000);
            const warningMedicalExpiry = new Date(currentDate.getTime() + threshold.medicalWarningThreshold * 24 * 60 * 60 * 1000);
            let error = {...obj};
            let warning = {...obj};
            if (obj.status !== 'CURRENT') {
                error = {...error, error: 'A driver DA is not CURRENT'};
            } else if (!obj.expiryDate || this.convertToDate(obj.expiryDate) <= errorDAExpiry) {
                error = {
                    ...error,
                    error: `A driver DA has expired or is due to expire in ${threshold.driverErrorThreshold} days`
                };
            } else if (!obj.medicalDueDate || this.convertToDate(obj.medicalDueDate) <= errorMedicalExpiry) {
                error = {
                    ...error,
                    error: `A driver medical check is overdue or is due in ${threshold.medicalErrorThreshold} days`
                };
            } else if (obj.expiryDate && this.convertToDate(obj.expiryDate) <= warningDAExpiry) {
                warning = {
                    ...warning,
                    warning: `A driver authority is due to expire in less than ${threshold.driverWarningThreshold} days`
                };
            } else if (obj.medicalDueDate && this.convertToDate(obj.medicalDueDate) <= warningMedicalExpiry) {
                warning = {
                    ...warning,
                    warning: `A medical check is due in less than ${threshold.medicalWarningThreshold} days`
                };
            }
            if (error.error) {
                errors.push(error);
            }
            if (warning.warning) {
                warnings.push(warning);
            }
        });

        return {errors, warnings};
    }

    async getDAConfig() {
        const driverConfig = await this.get(this.apiKey, '#CONFIG#DRIVERAUTHORITY#');
        if (!driverConfig) return {
            driverErrorThreshold: 1,
            medicalErrorThreshold: 1,
            driverWarningThreshold: 30,
            medicalWarningThreshold: 30,
        };
        return driverConfig;
    }

    async updateLocalDArecord(employee) {
        const daRecords = await this.getDriverDARecord(employee.details.driverId);
        if (daRecords.length === 0) return;
        // replace name in every record and save it
        const records = daRecords.map(record => {
            record.name = `${employee.external.firstName} ${employee.external.lastName}`;
            return record;
        });
        this.save(records);
    }
}

class StudentModelData extends ModelData {
    constructor() {
        super({
            name: 'Student',
            path: '/students',
            api: 'student',
            idName: 'studentId'
        });
    }

    create(obj, all) {
        return new Student(obj);
    }

    serialise(obj) {
        obj = super.serialise(obj);
        // const student = new Student(cloneDeep(obj))
        // delete student.schoolStop
        // delete student.homeStop
        // delete student.expired
        // delete student.modified
        // delete student.key
        // delete student.warnings
        // student.guardians = student.guardians.map(s => s.toJson ? s.toJson() : s)
        // student.travelPasses = student.travelPasses.map(s => s.toJson ? s.toJson() : s)
        // student.misbehaviours = student.misbehaviours.map(s => s.toJson ? s.toJson() : s)
        return (new Student(obj)).toJson();
    }

    async deserialise(objs) {
        console.log('deserialising Students ', objs.length);
        objs = await super.deserialise(objs);
        const stopsById = await stopModelData.getAll();
        if (!stopsById)
            throw new Error('Stops must be fetched before ' + this.name);

        const schoolStops = values(stopsById).filter(s => s.stopType === 'school');

        objs.forEach(student => {
            student.fromJson(stopsById, schoolStops);
            // if (student.schoolStopId) {
            //     student.schoolStop = stopsById[student.schoolStopId]
            //     student.schoolStop?.setLinkedStops(stopsById)
            // } else if (student.schoolName?.length) {
            //     const schoolStop = findSchoolStop(schoolStops, student.schoolName, student.schoolSuburb);
            //     student.schoolStop = schoolStop
            //     student.schoolStopId = schoolStop?.stopId
            //     student.schoolSuburb = student.schoolSuburb || schoolStop?.suburb;
            //     student.schoolStop?.setLinkedStops(stopsById)
            // }
            // student.travelPasses.forEach(tp => {
            //     tp.stop = stopsById[tp.stopId]
            //     tp.homeStop = stopsById[tp.stopId]
            //     tp.schoolStop = stopsById[tp.schoolStopId]
            //     tp.schoolStop?.setLinkedStops(stopsById)
            //     tp.guardian = student.guardians.find(g => g.guardianId === tp.guardianId)
            //     tp.student = student;
            // })
            // student.guardians.forEach(g => {
            //     if (g.stopId) {
            //         g.amStop = stopsById[g.amStopId];
            //         g.amStop?.setLinkedStops(stopsById)
            //         g.pmStop = stopsById[g.pmStopId];
            //         g.pmStop?.setLinkedStops(stopsById)
            //     }
            //     g.student = student;
            // });
        });
        return objs;
    }

    save(objs) {
        objs = Array.isArray(objs) ? objs : [objs];
        const allStudents = (this.data ? values(this.data) : []).filter(s => s.status !== 'archived');
        objs.filter(o => o.status !== 'archived').forEach(obj => {
            obj.warnings = obj.validateStudent(allStudents);
        });
        return super.save(objs);
    }

}

class SchoolModelData extends ModelData {
    constructor() {
        super({name: 'School', api: 'school', idName: 'schoolId'});
    }
}

class SMSModelData extends ModelData {
    constructor() {
        super({name: 'Sms', api: 'sms', idName: 'smsId'});
    }

    async sendSMS(body) {
        let mock = false;
        if (process.env.NODE_ENV === 'development') {
            mock = true;
        }
        return await API.post(this.api, `/send?mock=${mock}${
            this.apiKey ? `&_k=${this.apiKey}` : ''
        }`, {
            body
        });
    }
}

class RefModelData extends ModelData {
    constructor({type, subIdName, name = 'Ref'}) {
        super({name, api: 'ref', idName: 'refId'});
        this.type = type;
        this.subIdName = subIdName;
    }

    getSubModelData(data) {
        let id = data.refId;
        if (Array.isArray(data) && data.length) {
            id = data[0].refId;
        }
        if (id?.startsWith('comment#route')) {
            return routeCommentModelData;
        } else if (id?.startsWith('comment#stop')) {
            return stopCommentModelData;
        } else if (id?.startsWith('note')) {
            return noteRefModelData;
        }
        return this;
    }

    async get(apiKey, id) {
        return (await super.fetchAllIdBeginningWith(id, apiKey))?.[0];
    }

    async fetchAll() {
        if (this.fetchedAll) {
            return keyBy(await this.db.toArray(), this.idName);
        }
        let fetched = await this.fetchAllIdBeginningWith(this.getBeginningId(), this.apiKey, true);
        await this.db.bulkPut(fetched);
        if (this.localCache) {
            this.data = this.data || {};
            fetched = await this.deserialise(fetched);
            const fetchedById = keyBy(
                fetched,
                this.idName
            );
            this.data = {...this.data, ...fetchedById};
        }
        this.fetchedAll = true;
        this.loaded = true;
        // this.lastUpdated = Date.now();
        return keyBy(await this.db.toArray(), this.idName);
    }

    getBeginningId() {
        return `${this.type}#`;
    }

    setId(obj) {
        obj.refId = `${this.type}#${obj[this.subIdName]}`;
    }
}

export class NoteRefModelData extends RefModelData {
    constructor() {
        super({type: 'note', subIdName: 'title'});
    }

    serialise(obj) {
        const {refId, title, description, priority} = obj;
        return {refId, title, description, priority};
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        return objs.map((obj) => {
            const {refId, title, description, priority} = obj;
            return {refId, title, description, priority};
        });
    }
}


class CommentModelData extends RefModelData {
    constructor(data) {
        super(data);
    }

    getBeginningId() {
        return `${this.type}#${this.subIdName}#`;
    }

    async save(objs) {
        if (!Array.isArray(objs)) {
            objs = [objs];
        }
        objs.forEach(obj => {
            if (!obj.refId) {
                this.setId(obj);
            }
        });
        objs = await this.serialise(objs);
        objs = await API.post('third_party', `/comments?_k=${this.apiKey}`, {body: {comments: objs}});
        await this.db.bulkPut(objs);

        objs = await this.deserialise(objs);
        if (this.localCache) {
            this.data = this.data || {};
            objs.forEach((obj) => {
                this.data[this.getId(obj)] = this.create({...this.data[this.getId(obj)], ...obj});
            });
        }
        this.notifyUpdate(objs, true);
        return objs?.length === 1 ? objs[0] : objs;
    }

    serialise(objs) {
        objs = Array.isArray(objs) ? objs : [objs];
        return objs.map(obj => {
            obj.routeId = obj.route?.routeId;
            obj.stopId = obj.stop?.stopId || obj.stopTime?.stopId;
            obj.stopTimeId = obj.stopTime?.stopTimeId;
            delete obj.route;
            delete obj.stop;
            delete obj.stopTime;
            return obj;
        });
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        const stopsById = await stopModelData.getAll();
        if (!stopsById)
            throw new Error('Stops must be fetched before ' + this.name);

        const routesById = await routeModelData.getAll();
        if (!routesById)
            throw new Error('Routes must be fetched before ' + this.name);

        objs.forEach(comment => {
            if (comment.routeId) {
                comment.route = routesById[comment.routeId];

                if (comment.stopTimeId && comment.tripId && comment.route) {
                    const trip = find(comment.route.services, {tripId: comment.tripId});
                    if (trip) {
                        comment.stopTime = find(trip.stopTimes, {stopTimeId: comment.stopTimeId});
                    }
                }
            }
            if (comment.stopId) {
                comment.stop = stopsById[comment.stopId];
            }
        });
        return objs;
    }
}

export class RouteCommentModelData extends CommentModelData {
    constructor() {
        super({type: 'comment', subIdName: 'route', name: 'RouteComment'});
    }

    setId(obj) {
        obj.refId = `comment#route#${obj.route.routeId}#${Date.now()}`;
    }
}

class StopCommentModelData extends CommentModelData {
    constructor() {
        super({type: 'comment', subIdName: 'stop', name: 'StopComment'});
    }

    setId(obj) {
        obj.refId = `comment#stop#${obj.stop.stopId}#${Date.now()}`;
    }
}

class ConfigModelData extends RefModelData {
    constructor({subIdName, name}) {
        super({type: 'config', subIdName, name});
    }

    getBeginningId() {
        return `${this.type}#${this.subIdName}#`;
    }


    async get(apiKey, id) {
        return await super.get(apiKey, `config#${this.subIdName}`);
    }

    setId(obj) {
        obj.refId = `config#${this.subIdName}#${Date.now()}`;
    }
}

class StudentConfigModelData extends ConfigModelData {
    constructor() {
        super({subIdName: 'student', name: 'StudentConfig'});
    }
}

class CharterModelData extends ModelData {
    constructor() {
        super({name: 'Charter', api: 'charter', idName: 'charterId', dependents: [stopModelData]});
    }

    create(obj) {
        if (!obj) {
            return null;
        }
        switch (obj.type) {
            case 'charter#itinerary':
                return new ItineraryShift(obj);
            case 'charter#route':
                return new CharterRouteRun(obj);
            default:
                return new Charter(obj);
        }
    }

    async fetch({startStopId, endStopId}) {
        const charters = await this.getAll() ?? {};
        const routes = values(charters).filter(c => c?.type === 'charter#route' && c?.routeId?.startsWith(`${startStopId}#${endStopId}#`)).map(r => new CharterRouteRun(r));
        return routes;
    }

    async getTemplate(templateId) {
        const charters = await this.getAll() ?? {};
        const template = values(charters).find(c => c.charterId === templateId);
        return template;
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        const stopsById = await stopModelData.getAll();
        if (!stopsById)
            throw new Error('Stops must be fetched before ' + this.name);
        const allRoutes = await routeModelData.getAll();
        if (!allRoutes)
            throw new Error('Routes must be fetched before ' + this.name);
        const allVehicles = await vehicleModelData.getAll();
        if (!allVehicles)
            throw new Error('Vehicles must be fetched before ' + this.name);
        const allVehicleTypes = await vehicleTypeModelData.getAll();
        if (!allVehicleTypes)
            throw new Error('Vehicle Types must be fetched before ' + this.name);
        const allCustomers = await customerModelData.getAll();
        if (!allCustomers)
            throw new Error('Customers must be fetched before ' + this.name);
        await Promise.all(objs.map(async obj => {
            if (obj.itinerary) {
                obj.itinerary = obj.itinerary.map?.(route => {
                    route = new BusRoute(route);
                    route.setBaseStops(stopsById);
                    return route;
                });
            }
            obj.vehicle = allVehicles[obj.vehicleId];
            obj.customer = allCustomers[obj.customerId];
            if (obj.duty && !obj.duties?.length) {
                obj.duties = [obj.duty];
                delete obj.duty;
            }
            if (obj.duties?.length) {
                obj.duties = obj.duties.map(duty => {
                    if (duty.vehicleTypeId) {
                        duty.vehicleType = allVehicleTypes[duty.vehicleTypeId];
                    }
                    return new ShiftBat(duty);
                });
                await Promise.all(obj.duties.map(async duty => {
                    await Promise.all(duty.rows.filter(row => row.updateRow).map(async row => {
                        await row.updateRow({deadrunModelData, allStops: stopsById, allRoutes});
                    }));
                }));

                if (obj.duties && (!Number.isFinite(obj.duration) || !obj.duration)) {
                    obj.duration = Math.ceil(obj.duties.reduce((prev, next) => prev + next.getShiftTime(), 0) / 3600);
                }
                if (obj.duties && (!Number.isFinite(obj.distance) || !obj.distance)) {
                    obj.distance = Math.ceil(obj.duties.reduce((prev, next) => prev + next.getShiftDistance(), 0) / 1000);
                }
            }
        }));
        return objs;
    }
}

class CustomerModelData extends ModelData {
    constructor() {
        super({name: 'Customer', api: 'customer', idName: 'customerId'});
    }

    create(obj) {
        switch (obj.type) {
            case 'charter#itinerary':
                return new Itinerary(obj);
            default:
                return new Customer(obj);
        }
    }

}

class TrackingModelData extends ModelData {
    constructor() {
        super({
            name: 'Tracking',
            api: 'tracking',
            idName: 'trackingId',
            baseKeyExpression: `begins_with(trackingId, :date)`,
            baseKeyExpressionValue: `date|${dayjs().format('YYYYMMDD')}|s`
        });
    }
}

class JobModelData extends ModelData {
    constructor() {
        super({
            name: 'Job',
            api: 'job',
            idName: 'jobId',
            baseKeyExpression: `jobId > :date`,
            baseKeyExpressionValue: `date|${dayjs().subtract(14, 'days').format('YYYYMMDD')}|s`
        });
    }

    create(obj) {
        return new Job(obj);
    }

    setId(obj) {
        obj.jobId = `${obj.date.format('YYYYMMDD')}#${obj.type || 'shiftbat'}#${obj.typeId}#${obj.allocationType || 'employee'}#${obj.allocationId}#${Date.now()}`;
        obj.recurrenceId = `${obj.recurrence || 'none'}#${obj.type || 'shiftbat'}#${obj.typeId}#${obj.allocationType || 'employee'}#${obj.allocationId}#${Date.now()}`;
    }

    serialise(obj) {
        if (Array.isArray(obj)) {
            return obj.map(o => (new Job(o)).toJson());
        }
        return (new Job(obj)).toJson();
    }

    async fetchForDate(date) {
        const jobs = await API.get('job', `/jobs?_k=${this.apiKey}&queryDate=${date.format(DATE_STRING)}`);
        return await this.deserialise(jobs);
    }

    // async fetchAll() {
    //     this.expired = false;
    //     const time = Date.now();
    //     // Fetch todays worth of jobs from 7 days ago
    //     // let date = dayjs().subtract(7, 'd');
    //     // let promises = []
    //     // Array.from(Array(28).keys()).map(async () => {
    //     //     promises.push(this.fetchForDate(date));
    //     //     date = date.add(1, 'day');
    //     // });
    //     let promises = [await this.fetchForDate(dayjs())];
    //     let jobs = uniqBy(flatten(await Promise.all(promises)), 'jobId');
    //     // let jobs = await API.get('job', `/jobs?_k=${this.apiKey}`);
    //
    //     await this.db.bulkPut(jobs);
    //     if (this.localCache) {
    //         this.data = this.data || {};
    //         jobs = await this.deserialise(jobs);
    //         const jobsById = keyBy(
    //             jobs,
    //             this.idName
    //         );
    //         this.data = {...this.data, ...jobsById};
    //     }
    //     this.fetchedAll = true;
    //     this.loaded = true;
    //
    //     console.log('Time taken to fetch all jobs', Date.now() - time, 'ms');
    // }

    async deserialise(objs) {
        if (!objs?.length) {
            return objs;
        }
        objs = await super.deserialise(objs);
        const shiftBatsById = await shiftBatModelData.getAll();
        if (!shiftBatsById)
            throw new Error('ShiftBats must be fetched before ' + this.name);

        const chartersById = await charterModelData.getAll();
        if (!chartersById)
            throw new Error('Charters must be fetched before ' + this.name);

        const employeesById = await employeeModelData.getAll();
        if (!employeesById)
            throw new Error('Employees must be fetched before ' + this.name);

        const vehiclesById = await vehicleModelData.getAll();
        if (!vehiclesById)
            throw new Error('Vehicles must be fetched before ' + this.name);

        objs.forEach(job => {
            job.fromJson(shiftBatsById, chartersById, employeesById, vehiclesById);
        });
        return objs;
    }

    async fetchJobsForDate(date) {
        return await API.get('job', `/jobs?_k=${this.apiKey}&queryDate=${date.format(DATE_STRING)}`);
    }
}

class RosterModelData extends ModelData {
    constructor() {
        super({
            name: 'Roster',
            api: 'roster',
            idName: 'rosterId',
            baseKeyExpression: `rosterId > :date`,
            baseKeyExpressionValue: `date|${dayjs().startOf('week').subtract(28, 'days').format('YYYYMMDD')}|s`,
            dependents: [shiftBatModelData, employeeModelData, vehicleModelData, charterModelData]
        });
    }

    setId(obj, id = ulid()) {
        obj.rosterId = obj.date.format('YYYYMMDD');
    }

    getId(obj) {
        return obj.rosterId || obj.date.unix();
    }

    create(obj) {
        obj.scenarios = obj.scenarios?.map(s => WeeklyScenario.from(s));
        return obj;
    }

    serialise(obj) {
        return {
            ...obj,
            date: obj.date.format?.('YYYYMMDD') || obj.date,
            scenarios: obj.scenarios?.map(s => s.toJson?.() || s)
        };
    }

    async deserialise(objs) {
        objs = await super.deserialise(objs);
        const vehicles = await vehicleModelData.getAll();
        if (!vehicles)
            throw new Error('Vehicle must be fetched before ' + this.name);

        const employees = await employeeModelData.getAll();
        if (!employees)
            throw new Error('Employees must be fetched before ' + this.name);

        const shiftBats = await shiftBatModelData.getAll();
        if (!shiftBats)
            throw new Error('ShiftBats must be fetched before ' + this.name);
        objs.forEach(roster => {
            roster.date = dayjs(roster.date, 'YYYYMMDD').startOf('day');
            roster.scenarios = roster.scenarios?.map(s => WeeklyScenario.from(s).fromJson(shiftBats, employees, vehicles));
        });
        return objs;
    }

}

const operatorModelData = new OperatorModelData();
routesModelExpiryService.addData(operatorModelData);

const stopModelData = new StopModelData();
routesModelExpiryService.addData(stopModelData);
const routeModelData = new RouteModelData({
    includeStops: false,
    includePath: false,
    publishedOnly: false,
    loadSchedules: false,
});
routesModelExpiryService.addData(routeModelData);

// const routeSummaryModelData = new RouteSummaryModelData();
// routesModelExpiryService.addData(routeSummaryModelData);

const scheduleModelData = new ScheduleModelData();
routesModelExpiryService.addData(scheduleModelData);

const vehicleModelData = new VehicleModelData();
routesModelExpiryService.addData(vehicleModelData);

const vehicleTypeModelData = new VehicleTypeModelData();
routesModelExpiryService.addData(vehicleTypeModelData);

const driverModelData = new DriverModelData();
routesModelExpiryService.addData(driverModelData);

const driverShiftModelData = new DriverShiftModelData();
routesModelExpiryService.addData(driverShiftModelData);

const vehicleShiftModelData = new VehicleShiftModelData();
routesModelExpiryService.addData(vehicleShiftModelData);

const transferModelData = new TransferModelData();
routesModelExpiryService.addData(transferModelData);

const deadrunModelData = new DeadrunModelData();
routesModelExpiryService.addData(deadrunModelData);

const shiftBatModelData = new ShiftBatModelData();
routesModelExpiryService.addData(shiftBatModelData);

const publishTfNSWModelData = new PublishTfNSWLogModelData();
routesModelExpiryService.addData(publishTfNSWModelData);

const employeeModelData = new EmployeeModelData();
routesModelExpiryService.addData(employeeModelData);

const studentModelData = new StudentModelData();
routesModelExpiryService.addData(studentModelData);

const schoolModelData = new SchoolModelData();
routesModelExpiryService.addData(schoolModelData);

const smsModelData = new SMSModelData();
routesModelExpiryService.addData(smsModelData);

const noteRefModelData = new NoteRefModelData();
routesModelExpiryService.addData(noteRefModelData);

const routeCommentModelData = new RouteCommentModelData();
routesModelExpiryService.addData(routeCommentModelData);

const stopCommentModelData = new StopCommentModelData();
routesModelExpiryService.addData(stopCommentModelData);

const studentConfigModelData = new StudentConfigModelData();
routesModelExpiryService.addData(studentConfigModelData);
const charterModelData = new CharterModelData();
routesModelExpiryService.addData(charterModelData);

const customerModelData = new CustomerModelData();
routesModelExpiryService.addData(customerModelData);

const jobModelData = new JobModelData();
routesModelExpiryService.addData(jobModelData);

const rosterModelData = new RosterModelData();
routesModelExpiryService.addData(rosterModelData);

const trackingModelData = new TrackingModelData();

export {
    routesModelExpiryService,
    stopModelData,
    routeModelData,
    scheduleModelData,
    vehicleModelData,
    vehicleTypeModelData,
    driverModelData,
    driverShiftModelData,
    transferModelData,
    shiftBatModelData,
    deadrunModelData,
    publishTfNSWModelData,
    operatorModelData,
    vehicleShiftModelData,
    employeeModelData,
    studentModelData,
    schoolModelData,
    smsModelData,
    stopCommentModelData,
    routeCommentModelData,
    noteRefModelData,
    studentConfigModelData,
    charterModelData,
    customerModelData,
    jobModelData,
    rosterModelData,
    trackingModelData
};
