import React, { useCallback, useEffect, useState } from "react";
import "./Customers.css";
import { customerModelData } from "../../services/ModelService";
import { Customer } from "../../model/customer";
import useModel from "../../hooks/useModel";
import SaveMenu from "../../components/SaveMenu";
import LoadMessage from "../../components/LoadMessage";
import { Card, Col, Flex, Input, Row, Form, Typography } from "antd";
import CustomerMenu from "./CustomerMenu";
import { startCase } from "lodash";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { AUSTRALIAN_PHONE_REGEX, EMAIL_REGEX } from "../../libs/regex";
import {DeleteOutlined} from "@ant-design/icons";

const { TextArea } = Input;
const {Paragraph} = Typography;

export const CustomerDetailsCard = ({ mode, customer, setCustomerWith, form }) => {
  // Initialize form values when customer data changes
  useEffect(() => {
    if (mode.edit) {
      form?.setFieldsValue(customer);
      form?.validateFields(); // Trigger validation to show errors for invalid fields
    }
  }, [customer, mode.edit, form]);

  return (
    <Card
      title={
        <Flex justify="space-between">
          <div>Customer Details</div>
        </Flex>
      }
      bordered={false}
      className="card-main card-info"
    >
      <Form form={form} layout="vertical">
        <Row gutter={[30, 30]}>
          <Col xs={24} lg={12}>
            <Form.Item label="Name" name="name" initialValue={customer.name} rules={[{ required: true, message: "Please enter the name" }]}>
              {mode.edit ? (
                <Input
                  type="text"
                  placeholder="Enter name"
                  value={customer.name}
                  onChange={({ target: { value } }) => setCustomerWith("name", value)}
                />
              ) : (
                <strong>{startCase(customer.name)}</strong>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Email"
              name="email"
              initialValue={customer.email}
              rules={[{ pattern: EMAIL_REGEX, message: "Please enter a valid email address" }]}
            >
              {mode.edit ? (
                <Input
                  type="email"
                  placeholder="Enter email"
                  value={customer.email}
                  onChange={({ target: { value } }) => setCustomerWith("email", value)}
                />
              ) : (
                <strong>{customer.email}</strong>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Phone number"
              name="phone"
              initialValue={customer.phone}
              rules={[{ pattern: AUSTRALIAN_PHONE_REGEX, message: "Please enter a valid australian phone number (04XXXXXXXX, +614XXXXXXXX)" }]}
            >
              {mode.edit ? (
                <Input
                  type="tel"
                  placeholder="Enter phone"
                  value={customer.phone}
                  onChange={({ target: { value } }) => setCustomerWith("phone", value)}
                />
              ) : (
                <strong>{startCase(customer.phone)}</strong>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Billing Address" name="addressLine1" initialValue={customer.addressLine1}>
              {mode.edit ? (
                <>
                  <Input
                    placeholder="Enter Address line 1"
                    value={customer.addressLine1}
                    onChange={({ target: { value } }) => setCustomerWith("addressLine1", value)}
                    className="address-line-1"
                  />
                  <Input
                    placeholder="Enter Address line 2"
                    value={customer.addressLine2}
                    onChange={({ target: { value } }) => setCustomerWith("addressLine2", value)}
                  />
                </>
              ) : (
                <>
                  <strong>{startCase(customer.addressLine1)}</strong>
                  <strong>{startCase(customer.addressLine2)}</strong>
                </>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label="Additional Information" name="note" initialValue={customer.note}>
              {mode.edit ? (
                <TextArea
                  placeholder="Enter Additional Information"
                  value={customer.note}
                  onChange={({ target: { value } }) => setCustomerWith("note", value)}
                />
              ) : (
                <strong><Paragraph>{startCase(customer.note)}</Paragraph></strong>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export const CustomerAdditionalContactsCard = ({ mode, customer, setCustomerWith, form }) => {
  // Initialize form values when customer data changes
  useEffect(() => {
    if (mode.edit) {
      form.setFieldsValue({ additionalContacts: customer.additionalContacts });
      form.validateFields(); // Trigger validation to show errors for invalid fields
    }
  }, [customer, mode.edit, form]);

  const addContact = () => {
    const newContacts = [...(customer.additionalContacts || []), { name: "", email: "", phone: "" }];
    setCustomerWith("additionalContacts", newContacts);
    form.setFieldsValue({ additionalContacts: newContacts });
  };

  const removeContact = (index) => {
    const newContacts = customer.additionalContacts.filter((_, i) => i !== index);
    setCustomerWith("additionalContacts", newContacts);
    form.setFieldsValue({ additionalContacts: newContacts });
  };

  const updateContact = (index, propName, value) => {
    const newContacts = customer.additionalContacts.map((contact, i) => (i === index ? { ...contact, [propName]: value } : contact));
    setCustomerWith("additionalContacts", newContacts);
    form.setFieldsValue({ additionalContacts: newContacts });
  };

  return (
    <Card
      title="Additional Contacts"
      bordered={false}
      className="card-main card-info row-separator"
    >
      {mode.edit && (
        <Button type="primary" className="icon-button btn-sm card-btn" onClick={addContact} icon={<PlusOutlined/>}>Add Contact</Button>
      )}
      <Form form={form} layout="vertical">
        {(customer.additionalContacts || []).map((contact, index) => (
          <React.Fragment key={index}>
            <Row gutter={[10, 20]}>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Name"
                  name={['additionalContacts', index, 'name']}
                  initialValue={contact.name}
                  rules={[{ required: true, message: "Please enter the name" }]}
                >
                  {mode.edit ? (
                    <Input placeholder="Enter name" onChange={({ target: { value } }) => updateContact(index, "name", value)} />
                  ) : (
                    <strong>{startCase(contact.name)}</strong>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Email"
                  name={['additionalContacts', index, 'email']}
                  initialValue={contact.email}
                  rules={[{ required: true, pattern: EMAIL_REGEX, message: "Please enter a valid email address" }]}
                >
                  {mode.edit ? (
                    <Input placeholder="Enter email" onChange={({ target: { value } }) => updateContact(index, "email", value)} />
                  ) : (
                    <strong>{contact.email}</strong>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Phone number"
                  name={['additionalContacts', index, 'phone']}
                  initialValue={contact.phone}
                  rules={[{ pattern: AUSTRALIAN_PHONE_REGEX, message: "Please enter a valid phone number" }]}
                >
                  {mode.edit ? (
                    <Input placeholder="Enter phone" onChange={({ target: { value } }) => updateContact(index, "phone", value)} />
                  ) : (
                    <strong>{startCase(contact.phone)}</strong>
                  )}
                </Form.Item>
              </Col>
              {mode.edit && (
                <Col xs={24} className={"align-content-end mb-4"}>
                  <Button type="danger" className="icon-button btn-sm card-btn btn-filled btn-error" style={{marginTop: -15}} icon={<DeleteOutlined/>} onClick={() => removeContact(index)}>Delete</Button>
                </Col>
              )}
            </Row>
          </React.Fragment>
        ))}
      </Form>
    </Card>
  );
};

function CustomerDetails({ menu = true, edit = false, onSave }) {
  const {
    model: customer,
    setModel: setCustomer,
    initialModelRef: initialCustomerRef,
    mode,
    setMode,
    controls,
  } = useModel({
    modelService: customerModelData,
    initialInstance: new Customer({ customerId: "_" }),
    edit,
    onSave,
  });

  const [isValid, setIsValid] = useState(false);

  const [form] = Form.useForm();
  const [contactsForm] = Form.useForm();

  const setCustomerWith = useCallback(
    (propName, value) => {
      if (!Array.isArray(propName)) {
        propName = [propName];
        value = [value];
      }
      setCustomer((customer) => {
        const newCustomer = new Customer({ ...customer });
        propName.forEach((prop, idx) => {
          if (value[idx] === undefined || value[idx] === null) {
            delete newCustomer[prop];
            return;
          }
          newCustomer[prop] = value[idx];
        });
        return newCustomer;
      });
    },
    [setCustomer]
  );

  const handleSave = () => {
    customerModelData.save(customer).then(() => {
      setMode({ edit: false });
      form.resetFields();
    });
  };

  useEffect(() => {
    if (mode.edit) {
      Promise.all([form.validateFields(), contactsForm.validateFields()])
        .then((s) => {
          setIsValid(true);
        })
        .catch((e) => {
          setIsValid(false);
        });
    }
  }, [mode.edit, form, contactsForm, customer]);

  return (
    <div className="customer-details w-secondary-menu">
      {customer ? (
        <>
          <SaveMenu
            save={handleSave}
            editMode={mode.edit}
            id={customer.customerId}
            setEditMode={(edit) => setMode({ edit })}
            controls={controls}
            updated={controls.updated}
            modelInstance={customer}
            initialInstanceRef={initialCustomerRef}
            listUrl={`/customers`}
            modelService={customerModelData}
            closeView={true}
          />
          <Row gutter={[20, 20]} className="w-100">
            {!mode.overview && !menu && (
              <CustomerMenu
                customer={customer}
                activeKey={"1"}
                setCustomer={setCustomer}
                disabled={{
                  customer: false,
                  itinerary: mode.edit,
                  quote: mode.edit,
                }}
                hide={{
                  customer: false,
                  itinerary: customer.customerId === "_",
                  quote: customer.customerId === "_",
                }}
              />
            )}
            <Col xs={24} lg={!mode.overview && !menu ? 19 : 24}>
              <Row gutter={[20, 20]}>
                <Col xs={12}>
                  <CustomerDetailsCard mode={mode} customer={customer} setCustomerWith={setCustomerWith} form={form} />
                </Col>
                <Col xs={12}>
                  <CustomerAdditionalContactsCard mode={mode} customer={customer} setCustomerWith={setCustomerWith} form={contactsForm} />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <LoadMessage message="Loading Customer Details..." />
      )}
    </div>
  );
}

export default React.memo(CustomerDetails);
